import { connect } from 'react-redux';

import Source from './Source';
import { getSource, setFilter, ignoreEvents } from '../../../../store/inbox/dashboard/source/actions';

const mapStateToProps = (state) => ({
  loading: state?.inbox?.dashboard?.source?.loading,
  data: state?.inbox?.dashboard?.source?.data,
  region: state?.auth?.authenticatedUser?.['custom:sb_region'],
  filter: state?.inbox?.dashboard?.source?.filter,
});

const mapDispatchToProps = (dispatch) => ({
  getSource: (sourceId, properties) => dispatch(getSource(sourceId, properties)),
  setFilter: (filter) => dispatch(setFilter(filter)),
  ignoreEvents: (events) => dispatch(ignoreEvents(events)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Source);
