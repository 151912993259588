import {
  INFERENCE_EVENT_GET_ATTEMPT,
  INFERENCE_EVENT_GET_SUCCESS,
  INFERENCE_EVENT_GET_FAIL,
  INFERENCE_EVENT_CLEAR,
} from './types';
import userUtils from '../../../../util/user';
import isUnAuth from '../../../../util/unauth';
import interceptor, { getInboxUrl } from '../../../../util/interceptor';
import { logoutUser } from '../../../../store/auth/actions';
import { showAlert } from '../../../../store/state/actions';

export const getInferenceEventAttempt = () => ({
  type: INFERENCE_EVENT_GET_ATTEMPT,
});

export const getInferenceEventSuccess = (data) => ({
  type: INFERENCE_EVENT_GET_SUCCESS,
  payload: { data },
});

export const getInferenceEventFail = () => ({
  type: INFERENCE_EVENT_GET_FAIL,
});

export const getInferenceEvent = (id) => async (dispatch, getState) => {
  const ERROR_MESSAGE = 'Failed to load data, please try again.';
  dispatch(getInferenceEventAttempt());
  try {
    const state = getState();
    const { authToken, authenticatedUser } = state.auth;
    const tenant = userUtils.getTenant(authenticatedUser);
    const region = userUtils.getRegion(authenticatedUser);
    const res = await interceptor({
      method: 'post',
      url: `${getInboxUrl(region)}/${tenant}/DescribeInferenceEvent`,
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: {
        inferenceEventId: id,
      },
    });
    const { data } = res;
    dispatch(getInferenceEventSuccess(data));
  } catch (_err) {
    const status = _err?.response?.status;
    if (isUnAuth(status)) {
      dispatch(logoutUser({ location: window.location.href }));
    } else {
      dispatch(dispatch(getInferenceEventFail()));
      dispatch(showAlert({ type: 'error', message: ERROR_MESSAGE }));
    }
  }
};

export const clearInferenceEvent = () => ({
  type: INFERENCE_EVENT_CLEAR,
});
