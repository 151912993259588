import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import { sortOrgs } from '../util';

function OrgDetails({
  data,
  setSelectedOrg,
}) {
  const [hover, setHover] = useState({});
  return (
    <Box sx={{ display: 'flex', mt: 2, flexDirection: 'column' }} data-testid="OrgDetails-Container">
      <Typography variant="h6" color="text.secondary">
        Organization Details
      </Typography>
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        {sortOrgs(data?.orgValues || []).map((_data, index) => (
          <Card
            elevation={hover[index] ? 4 : 2}
            sx={{ mt: 2, mr: 2, cursor: 'pointer' }}
            key={_data.name}
            onMouseEnter={() => setHover({ [index]: true })}
            onMouseLeave={() => setHover({ [index]: false })}
            onClick={() => setSelectedOrg(_data?.name)}
          >
            <CardContent>
              <Box sx={{ display: 'flex' }}>
                <Typography sx={{ mr: 1 }} color="primary.main">
                  Organization:
                </Typography>
                <Typography color="text.secondary">
                  {_data?.name}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex' }}>
                <Typography sx={{ mr: 1 }} color="primary.main">
                  Inference Events:
                </Typography>
                <Typography color="text.secondary">
                  {_data?.inferenceEvents}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex' }}>
                <Typography sx={{ mr: 1 }} color="primary.main">
                  Missed Inputs:
                </Typography>
                <Typography color="text.secondary">
                  {_data?.missedInputs}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex' }}>
                <Typography sx={{ mr: 1 }} color="primary.main">
                  Treatments:
                </Typography>
                <Typography color="text.secondary">
                  {_data?.treatment}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        ))}
      </Box>
    </Box>
  );
}

OrgDetails.propTypes = {
  data: PropTypes.object,
  setSelectedOrg: PropTypes.func,
};

OrgDetails.defaultProps = {
  data: {},
  setSelectedOrg: () => {
    // Default
  },
};

export default OrgDetails;
