import {
  ROUTE_ADMIN,
  ROUTE_ADMIN_BILLING,
} from '../../../Routes/routes';

const breadcrumbs = (t) => [{
  title: t('Views.adminTitle'),
  route: `/${ROUTE_ADMIN}`,
}, {
  title: t('Admin.billingTitle'),
  route: `/${ROUTE_ADMIN_BILLING}`,
}];

export default breadcrumbs;
