const columns = [{
  title: 'Name',
  sort: true,
  sortKey: 'name',
}, {
  title: 'Bot',
  sort: true,
  sortKey: 'botName',
}, {
  title: 'Bot Design ID',
  sort: true,
  sortKey: 'botDesignId',
}, {
  title: 'Applied',
  sort: true,
  sortKey: 'applied',
}, {
  title: 'Created',
  sort: true,
  sortKey: 'created',
}, {
  title: '',
}];

export default columns;
