import {
  ANALYTICS_CUSTOM_GET,
  ANALYTICS_CUSTOM_GET_SUCCESS,
  ANALYTICS_CUSTOM_GET_FAIL,
  ANALYTICS_CUSTOM_CLEAR,
  ANALYTICS_CUSTOM_GOAL_REMOVE,
  ANALYTICS_CUSTOM_GOAL_ADD,
} from './types';

export const initialState = {
  loading: false,
  availableGoals: [],
  selectedGoals: [],
  data: {},
};

const reducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case ANALYTICS_CUSTOM_GET: {
      return {
        ...state,
        loading: true,
        data: {},
        availableGoals: [],
        selectedGoals: [],
      };
    }
    case ANALYTICS_CUSTOM_GET_SUCCESS: {
      const { data } = action.payload;
      const availableGoals = (data?.values || []).map((_dat) => _dat.name);
      return {
        ...state,
        loading: false,
        data,
        availableGoals,
        selectedGoals: availableGoals,
      };
    }
    case ANALYTICS_CUSTOM_GET_FAIL: {
      return { ...state, loading: false, data: {} };
    }
    case ANALYTICS_CUSTOM_CLEAR: {
      return initialState;
    }
    case ANALYTICS_CUSTOM_GOAL_REMOVE: {
      const { goal } = action.payload;
      const { selectedGoals } = state;
      const currentSelectedGoals = selectedGoals.filter((_dat) => _dat !== goal);
      return { ...state, selectedGoals: currentSelectedGoals };
    }
    case ANALYTICS_CUSTOM_GOAL_ADD: {
      const { goal } = action.payload;
      const { data, selectedGoals } = state;
      const currentSelectedGoals = [...selectedGoals];
      currentSelectedGoals.push(goal);
      return { ...state, data, selectedGoals: currentSelectedGoals };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
