import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import AppBar from '../AppBar';
import Loader from './Loader';
import { DRAWER_OPEN_WIDTH, DRAWER_CLOSED_WIDTH } from '../../theme';

function ViewPage({
  children,
  title,
  toggleDrawer,
  loading,
  breadcrumbs,
  alertOpen,
  alertMessage,
  alertType,
  hideAlert,
  fullWidth,
  displayAppBar,
  drawerOpen,
}) {
  const DRAWER_WIDTH = drawerOpen ? DRAWER_OPEN_WIDTH : DRAWER_CLOSED_WIDTH;
  return (
    <Box
      data-testid="ViewPage-Container"
      sx={{
        display: 'flex',
        marginLeft: fullWidth ? '0px' : `${DRAWER_WIDTH}px`,
        flex: 1,
      }}
    >
      <AppBar
        visible={displayAppBar}
        fullWidth={fullWidth}
        title={title}
        toggleDrawer={toggleDrawer}
        breadcrumbs={breadcrumbs}
      />
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          m: 2,
          marginTop: 8,
          width: '100%',
        }}
      >
        <Snackbar open={alertOpen} autoHideDuration={6000} onClose={hideAlert}>
          <Alert
            severity={alertType}
            onClose={hideAlert}
            sx={{ position: 'fixed', left: '50%', bottom: 16 }}
          >
            {alertMessage}
          </Alert>
        </Snackbar>
        {loading ? <Loader /> : children}
      </Box>
    </Box>
  );
}

ViewPage.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
  toggleDrawer: PropTypes.any,
  loading: PropTypes.bool,
  breadcrumbs: PropTypes.array,
  alertOpen: PropTypes.bool,
  alertMessage: PropTypes.string,
  alertType: PropTypes.string,
  hideAlert: PropTypes.func,
  fullWidth: PropTypes.bool,
  displayAppBar: PropTypes.bool,
  drawerOpen: PropTypes.bool,
};

ViewPage.defaultProps = {
  children: null,
  title: '',
  toggleDrawer: null,
  loading: false,
  breadcrumbs: [],
  alertOpen: false,
  alertMessage: '',
  alertType: '',
  hideAlert: () => {
    // Default
  },
  fullWidth: false,
  displayAppBar: true,
  drawerOpen: true,
};

export default ViewPage;
