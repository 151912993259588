const i18n = {
  en: {
    inboxTitle: 'Inbox',
    discusstionTitle: 'Discussion',
    sourceTitle: 'Source',
    Discussion: {
      TopicDistribution: {
        topicDistributionTitle: 'Topic Distribution',
      },
    },
  },
  fr: {
    inboxTitle: 'Boîte de réception',
    discusstionTitle: 'Discussion',
    sourceTitle: 'Source',
    Discussion: {
      TopicDistribution: {
        topicDistributionTitle: 'Répartition des sujets',
      },
    },
  },
};

export default i18n;
