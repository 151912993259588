import { connect } from 'react-redux';

import DesignRegistry from './DesignRegistry';
import { listBotDesigns, clearBotDesigns } from '../../../store/designInsights/designRegistry/actions';

const mapStateToProps = (state) => ({
  loading: state?.designInsights?.designRegistry?.index?.loading,
  botDesigns: state?.designInsights?.designRegistry?.index?.botDesigns,
});

const mapDispatchToProps = (dispatch) => ({
  listBotDesigns: () => dispatch(listBotDesigns()),
  clearBotDesigns: () => dispatch(clearBotDesigns()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DesignRegistry);
