import React from 'react';

function Botty() {
  return (
    <svg width="42" height="42" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.7577 31.5965C24.3787 31.5965 31.3675 24.6077 31.3675 15.9867C31.3675 7.36568 24.3787 0.376953 15.7577 0.376953C7.13667 0.376953 0.147949
      7.36568 0.147949 15.9867C0.147949 24.6077 7.13667 31.5965 15.7577 31.5965Z"
        fill="#73C4DB"
      />
      <path
        d="M15.5947 7.33301C11.5664 7.33301 8.30811 10.5913 8.30811 14.6196V19.3885C8.30811 21.6101 10.115 23.4466 12.3661 23.4466H18.8234C21.0449
      23.4466 22.8814 21.6397 22.8814 19.3885V14.6196C22.8814 10.5913 19.6231 7.33301 15.5947 7.33301ZM15.5947 17.7298C14.6173 17.7298 13.699 17.5225
      12.8993 17.167C12.5438 16.9893 10.7962 18.3815 10.5 18.1741C10.2927 17.9964 11.5071 16.2488 11.3294 16.0711C10.7666 15.449 10.4408 14.7085
      10.4408 13.9384C10.4408 11.8353 12.7512 10.1173 15.5947 10.1173C18.4383 10.1173 20.7487 11.8353 20.7487 13.9384C20.7487 16.0414 18.4087 17.7298 15.5947 17.7298Z"
        fill="white"
      />
      <path
        d="M14.7951 5.67387C14.9135 5.76273 14.9728 5.91083 14.9728 6.05894V6.88831C14.9728 6.91793 15.0024 6.94755 15.032 6.94755C15.2097
      6.94755 15.3875 6.91793 15.5652 6.91793C15.7429 6.91793 15.9206 6.91793 16.0984 6.94755C16.128 6.94755 16.1576 6.91793 16.1576
      6.88831V6.05894C16.1576 5.91083 16.2168 5.76273 16.3353 5.67387C16.6315 5.43691 16.8092 5.08146 16.8092 4.69639C16.8092 3.9855
      16.2168 3.39309 15.4763 3.45233C14.8543 3.48195 14.3507 3.9855 14.2915 4.57791C14.2915 5.02222 14.4692 5.40729 14.7951 5.67387Z"
        fill="white"
      />
      <path
        d="M12.4253 23.9492C12.9288 25.1933 14.1433 26.0819 15.5651 26.0819C16.9868 26.0819 18.2309 25.1933 18.7048 23.9492H12.4253Z"
        fill="white"
      />
      <path
        d="M7.0937 21.6988C6.64939 21.6988 6.29395 21.3433 6.29395 20.899V15.9228C6.29395 15.4785 6.64939 15.123 7.0937 15.123C7.53801
      15.123 7.89345 15.4785 7.89345 15.9228V20.899C7.86383 21.3433 7.50839 21.6988 7.0937 21.6988Z"
        fill="white"
      />
      <path
        d="M24.0957 21.6988C23.6513 21.6988 23.2959 21.3433 23.2959 20.899V15.9228C23.2959 15.4785 23.6513 15.123 24.0957 15.123C24.54
      15.123 24.8954 15.4785 24.8954 15.9228V20.899C24.8658 21.3433 24.5103 21.6988 24.0957 21.6988Z"
        fill="white"
      />
      <path
        d="M13.5803 14.2935C14.0875 14.2935 14.4986 13.8824 14.4986 13.3753C14.4986 12.8681 14.0875 12.457 13.5803 12.457C13.0732 12.457
      12.6621 12.8681 12.6621 13.3753C12.6621 13.8824 13.0732 14.2935 13.5803 14.2935Z"
        fill="white"
      />
      <path
        d="M17.6092 14.2935C18.1163 14.2935 18.5274 13.8824 18.5274 13.3753C18.5274 12.8681 18.1163 12.457 17.6092 12.457C17.102 12.457
      16.6909 12.8681 16.6909 13.3753C16.6909 13.8824 17.102 14.2935 17.6092 14.2935Z"
        fill="white"
      />
    </svg>
  );
}

export default Botty;
