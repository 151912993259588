import React from 'react';

import PropTypes from 'prop-types';

function Compliance({ width, height }) {
  return (
    <svg width={width} height={height} viewBox="0 0 130 129" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="85.0409" cy="77.583" r="25.6746" stroke="white" strokeWidth="6" />
      <path d="M67.8361 76.5077L82.8907 89.4113L101.171 68.2637" stroke="white" strokeWidth="6" strokeLinecap="round" />
      <path d="M85.7577 47.8329V27.7424C85.7577 24.981 83.5192 22.7424 80.7577 22.7424H31.6163C28.8549 22.7424 26.6163 24.981 26.6163 27.7424V94.4474C26.6163 97.2088 28.8549 99.4474 31.6163 99.4474H70.704M39.1617 39.2304H75.0052" stroke="white" strokeWidth="6" strokeLinecap="round" />
      <path d="M38.8029 55.3599H59.9513" stroke="white" strokeWidth="6" strokeLinecap="round" />
      <path d="M40.2374 70.4143H52.424" stroke="white" strokeWidth="6" strokeLinecap="round" />
      <path d="M40.2374 86.9021H50.2736" stroke="white" strokeWidth="6" strokeLinecap="round" />
    </svg>
  );
}

Compliance.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

Compliance.defaultProps = {
  width: 130,
  height: 129,
};

export default Compliance;
