import { connect } from 'react-redux';

import Event from './Event';
import { getEvent, clearEvent } from '../../../../../store/inbox/treatment/inferenceEvents/event/actions';

const mapStateToProps = (state) => ({
  loading: state?.inbox?.treatment?.inferenceEvents?.event?.loading,
  event: state?.inbox?.treatment?.inferenceEvents?.event?.event,
});

const mapDispatchToProps = (dispatch) => ({
  getEvent: (eventId) => dispatch(getEvent(eventId)),
  clearEvent: () => dispatch(clearEvent()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Event);
