import {
  BLUE_ONE,
  BLUE_TWO,
  BLUE_THREE,
  BLUE_FIVE,
} from '../../../colors';

export const pieChartOptions = () => {
  const options = {
    series: [50, 100, 150],
    chart: {
      width: 300,
      type: 'pie',
    },
    labels: ['Ignored', 'Utterance Added', 'Intent Created'],
    colors: [BLUE_ONE, BLUE_TWO, BLUE_THREE, BLUE_FIVE],
    legend: {
      show: true,
      position: 'bottom',
    },
    plotOptions: {
      pie: {
        dataLabels: {
          offset: -20,
        },
      },
    },
  };
  return options;
};

const graphOptions = (data, selectedOrg) => {
  const orgData = (data?.orgValues || []).find((_val) => _val.name === selectedOrg);
  let inferenceEvents = [];
  let missedInputs = [];
  let treatment = [];
  if (orgData) {
    inferenceEvents = (orgData?.sources || []).map((_source) => _source.inferenceEvents);
    missedInputs = (orgData?.sources || []).map((_source) => _source.missedInputs);
    treatment = (orgData?.sources || []).map((_source) => _source.treatment);
  }
  const series = [{
    name: 'Inference Events',
    data: inferenceEvents,
  }, {
    name: 'Missed Inputs',
    data: missedInputs,
  }, {
    name: 'Treatments',
    data: treatment,
  }];
  const labels = (orgData?.sources || []).map((_per) => _per?.name);
  return {
    chart: {
      type: 'bar',
      height: '400px',
      toolbar: {
        show: false,
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
      },
      selection: {
        enabled: false,
      },
    },
    series,
    labels,
    colors: [BLUE_ONE, BLUE_TWO, BLUE_THREE, BLUE_FIVE],
    tooltip: {
      y: {
        formatter: (_val) => `${_val}`,
      },
    },
    dataLabels: {
      enabled: false,
    },
  };
};

export default graphOptions;
