import {
  ANALYTICS_CUSTOM_GET,
  ANALYTICS_CUSTOM_GET_SUCCESS,
  ANALYTICS_CUSTOM_GET_FAIL,
  ANALYTICS_CUSTOM_CLEAR,
  ANALYTICS_CUSTOM_GOAL_REMOVE,
  ANALYTICS_CUSTOM_GOAL_ADD,
} from './types';
import { showAlert } from '../../state/actions';

// eslint-disable-next-line
const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export const getCustomAttempt = () => ({
  type: ANALYTICS_CUSTOM_GET,
});
export const getCustomSuccess = (data) => ({
  type: ANALYTICS_CUSTOM_GET_SUCCESS,
  payload: { data },
});
export const getCustomFail = () => ({
  type: ANALYTICS_CUSTOM_GET_FAIL,
});

export const YEAR_DATA = {
  values: [{
    name: 'Goal One',
    value: 500,
    monthly: [35, 40, 45, 30, 20, 60, 80, 100, 30, 35, 32, 35],
  }, {
    name: 'Goal Two',
    value: 568,
    monthly: [25, 35, 42, 36, 20, 22, 35, 45, 38, 28, 29, 35],
  }, {
    name: 'Goal Three',
    value: 1790,
    monthly: [10, 45, 28, 60, 55, 52, 42, 38, 30, 35, 38, 40],
  }, {
    name: 'Goal Four',
    value: 1278,
    monthly: [5, 52, 64, 69, 75, 69, 63, 48, 38, 46, 48, 50],
  }, {
    name: 'Goal Five',
    value: 1366,
    monthly: [90, 72, 87, 82, 80, 76, 72, 65, 80, 98, 100, 98],
  }, {
    name: 'Goal Six',
    value: 450,
    monthly: [50, 60, 62, 90, 98, 92, 88, 99, 110, 105, 100, 105],
  }, {
    name: 'Goal Seven',
    value: 1982,
    monthly: [72, 40, 80, 40, 20, 25, 35, 45, 40, 45, 40, 48],
  }, {
    name: 'Goal Eight',
    value: 3000,
    monthly: [84, 60, 72, 75, 89, 98, 125, 100, 88, 77, 85, 90],
  }],
};

const HALF_YEAR_DATA = {
  values: [{
    name: 'Goal One',
    value: 500,
    monthly: [35, 40, 45, 30, 20, 60],
  }, {
    name: 'Goal Two',
    value: 568,
    monthly: [25, 35, 42, 36, 20, 22],
  }, {
    name: 'Goal Three',
    value: 1790,
    monthly: [10, 45, 28, 60, 55, 52],
  }, {
    name: 'Goal Four',
    value: 1278,
    monthly: [5, 52, 64, 69, 75, 69],
  }],
};

const QUARTER_YEAR_DATA = {
  values: [{
    name: 'Goal One',
    value: 500,
    monthly: [35, 40, 45, 30],
  }, {
    name: 'Goal Two',
    value: 568,
    monthly: [25, 35, 42, 36],
  }, {
    name: 'Goal Three',
    value: 1790,
    monthly: [10, 45, 28, 60],
  }, {
    name: 'Goal Four',
    value: 1278,
    monthly: [5, 52, 64, 69],
  }],
};

const periodMap = {
  YEAR: YEAR_DATA,
  '3MONTHS': QUARTER_YEAR_DATA,
  '6MONTHS': HALF_YEAR_DATA,
};

export const getCustom = (period) => async (dispatch) => {
  const ERROR_MESSAGE = 'Failed to load data, please try again.';
  dispatch(getCustomAttempt());
  try {
    await delay(1000);
    dispatch(getCustomSuccess(periodMap[period]));
  } catch (_err) {
    dispatch(showAlert({ type: 'error', message: ERROR_MESSAGE }));
    dispatch(getCustomFail());
  }
};

export const addGoal = (goal) => ({
  type: ANALYTICS_CUSTOM_GOAL_ADD,
  payload: { goal },
});

export const removeGoal = (goal) => ({
  type: ANALYTICS_CUSTOM_GOAL_REMOVE,
  payload: { goal },
});

export const clearCustom = () => ({ type: ANALYTICS_CUSTOM_CLEAR });
