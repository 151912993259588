import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import ApexCharts from 'apexcharts';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import Metric from './Metric';

import graphOptions from './graphOptions';

let chart;

function Metrics({
  sections,
}) {
  const chartRef = useRef(null);
  useEffect(() => {
    if (chartRef.current) {
      chart = new ApexCharts(chartRef.current, graphOptions);
      chart.render();
    }
  }, [chartRef]);
  return (
    <Box>
      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
        <Box sx={{ width: 220, mt: 2 }}>
          <FormControl fullWidth size="small">
            <InputLabel id="time-period">Time Period</InputLabel>
            <Select
              labelId="time-period"
              id="time-period"
              label="Time Period"
              defaultValue="3MONTHS"
            >
              <MenuItem value="3MONTHS">3 Months</MenuItem>
              <MenuItem value="6MONTHS">6 Months</MenuItem>
              <MenuItem value="YEAR">12 Months</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '80%' }}>
          {sections.map((_section) => (
            <Metric
              key={_section?.title}
              title={_section?.title}
              data={_section?.data}
            />
          ))}
        </Box>
        <Card sx={{
          display: 'flex',
          flexDirection: 'column',
          mt: 5,
          width: '20%',
          minWidth: 300,
          height: 400,
          flex: 1,
        }}
        >
          <CardHeader
            title="User Channels"
            titleTypographyProps={{ color: 'primary.main' }}
          />
          <CardContent sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Box ref={chartRef} />
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
}

Metrics.propTypes = {
  sections: PropTypes.array,
};

Metrics.defaultProps = {
  sections: [],
};

export default Metrics;
