import React from 'react';

import PropTypes from 'prop-types';

function Admin({ width, height }) {
  return (
    <svg width={width} height={height} viewBox="0 0 118 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M98.1608 69.8169C98.1608 74.9568 94.1379 78.993 89.3338 78.993C84.5298 78.993 80.5068 74.9568 80.5068 69.8169C80.5068 64.677 84.5298 60.6408 89.3338 60.6408C94.1379 60.6408 98.1608 64.677 98.1608 69.8169Z" stroke="white" strokeWidth="5" />
      <path d="M67.0761 59.7528L69.455 61.2749C70.6586 62.045 71.36 63.3986 71.2951 64.826L70.7862 76.0169C70.7271 77.3153 70.0409 78.5041 68.9461 79.2046L66.5036 80.7673C65.0304 81.7099 64.5856 83.6598 65.5043 85.148C66.42 86.6312 68.3536 87.11 69.8555 86.2253L71.6935 85.1426C72.9655 84.3934 74.5467 84.4056 75.8069 85.1744L83.4563 89.8412C84.6155 90.5484 85.3227 91.8083 85.3227 93.1662C85.3227 95.3173 87.0666 97.0611 89.2177 97.0611H89.6871C91.8382 97.0611 93.582 95.3173 93.582 93.1662C93.582 91.8083 94.2892 90.5484 95.4485 89.8412L103.025 85.219C104.323 84.4268 105.959 84.4396 107.245 85.252L109.434 86.6356C111.088 87.68 113.277 87.1439 114.261 85.4539C115.221 83.8046 114.66 81.6891 113.009 80.7318L110.339 79.1835C109.161 78.5004 108.411 77.2651 108.349 75.9048L107.841 64.7196C107.779 63.3497 108.422 62.0434 109.547 61.2583L112.5 59.1959C113.904 58.2158 114.229 56.2744 113.222 54.8901C112.266 53.5758 110.448 53.2393 109.085 54.1242L107.184 55.3577C105.931 56.1709 104.33 56.2183 103.031 55.4806L95.3718 51.1301C94.2655 50.5017 93.582 49.3274 93.582 48.0551C93.582 46.102 91.9987 44.5187 90.0456 44.5187H88.8863C86.9182 44.5187 85.3227 46.1141 85.3227 48.0822C85.3227 49.3413 84.6583 50.5069 83.575 51.1485L76.2005 55.5154C74.9807 56.2377 73.4697 56.2605 72.2287 55.5752L70.2221 54.4671C68.7724 53.6665 66.9491 54.1568 66.0965 55.5766C65.239 57.0044 65.6732 58.8552 67.0761 59.7528Z" stroke="white" strokeWidth="5" strokeLinejoin="round" />
      <circle cx="59.0936" cy="23.5756" r="20.5756" stroke="white" strokeWidth="6" />
      <path d="M58.6871 44.7123C32.3117 44.7123 3 58.1932 3 94.5357" stroke="white" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

Admin.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

Admin.defaultProps = {
  width: 118,
  height: 100,
};

export default Admin;
