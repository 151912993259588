import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const titles = ['Utterance:', 'Matched Intent:', 'Confidence Score:', 'Resource Name:', 'Resource Version:'];
const values = ['utterance', 'matchedIntent', 'confidenceScore', 'resourceName', 'resourceVersion'];

function Details({ data }) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }} data-testid="Details-Container">
      <Typography sx={{ mr: 1 }} variant="h5" color="text.secondary" gutterBottom>
        Event Details
      </Typography>
      <Box sx={{ display: 'flex' }}>
        <Typography sx={{ mr: 1 }} color="primary.main">
          Outcome:
        </Typography>
        <Typography sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: data?.outcome === 'Classified' ? 'green' : 'red',
        }}
        >
          {data?.outcome === 'Classified' ? 'Classified' : 'Missed Input'}
        </Typography>
      </Box>
      {titles.map((_val, idx) => (
        <Box sx={{ display: 'flex' }} key={_val}>
          <Typography sx={{ mr: 1 }} color="primary.main">
            {_val}
          </Typography>
          <Typography color="text.secondary">
            {data?.[values?.[idx]]}
          </Typography>
        </Box>
      ))}
    </Box>
  );
}

Details.propTypes = {
  data: PropTypes.object,
};

Details.defaultProps = {
  data: {},
};

export default Details;
