import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';

import ViewPage from '../../../../Components/ViewPage';
import HeaderDetails from '../../../../Components/HeaderDetails';
import Events from './Events';
import breadcrumbs from './breadcrumbs';
import util from './util';

const FILTERS = [{
  title: 'All',
  value: 'all',
}, {
  title: 'Missed',
  value: 'missed',
}];

const getEvents = (data, filter) => {
  if (filter === 'missed') {
    return util.appendStatus((data?.inferenceEvents || []).filter((_event) => _event.outcome === 'MissedInput'));
  }
  return util.appendStatus(data?.inferenceEvents || []);
};

function Source({
  toggleDrawer,
  loading,
  data,
  getSource,
  region,
  filter,
  setFilter,
  ignoreEvents,
}) {
  const { t } = useTranslation();
  const params = useParams();
  const { sourceId } = params;
  useEffect(() => {
    if (sourceId && filter) {
      getSource(sourceId);
    }
    return () => {
      setFilter('all');
    };
  }, []);

  const events = getEvents(data, filter);
  return (
    <ViewPage
      title={t('OperationalInsights.sourceTitle')}
      toggleDrawer={toggleDrawer}
      loading={loading}
      breadcrumbs={breadcrumbs(t, sourceId)}
    >
      <Box
        data-testid="Source-View-Container"
        sx={{
          mt: 1,
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
        }}
      >
        <HeaderDetails title={sourceId} data={util.formatData(data, filter)} />
        <Events
          region={region}
          getSource={(properties) => getSource(sourceId, properties)}
          events={events}
          filter={filter}
          setFilter={setFilter}
          pagination={data?.nextToken}
          filters={FILTERS}
          ignoreEvents={ignoreEvents}
        />
      </Box>
    </ViewPage>
  );
}

Source.propTypes = {
  toggleDrawer: PropTypes.func,
  loading: PropTypes.bool,
  data: PropTypes.object,
  getSource: PropTypes.func,
  region: PropTypes.string,
  filter: PropTypes.string,
  setFilter: PropTypes.func,
  ignoreEvents: PropTypes.func,
};

Source.defaultProps = {
  toggleDrawer: () => {
    // Default
  },
  loading: false,
  data: {},
  getSource: () => {
    // Default
  },
  region: '',
  filter: 'all',
  setFilter: () => {
    // Default
  },
  ignoreEvents: () => {
    // Default
  },
};

export default Source;
