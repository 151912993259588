import {
  ROUTE_ADMIN,
  ROUTE_ADMIN_REDACTION,
} from '../../../Routes/routes';

const breadcrumbs = (t) => [{
  title: t('Views.adminTitle'),
  route: `/${ROUTE_ADMIN}`,
}, {
  title: t('Admin.redactionTitle'),
  route: `/${ROUTE_ADMIN_REDACTION}`,
}];

export default breadcrumbs;
