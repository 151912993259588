import { connect } from 'react-redux';

import Create from './Create';
import { createUser } from '../../../../store/admin/users/actions';

const mapStateToProps = (state) => ({
  loading: state?.admin?.users?.creatingUser,
});

const mapDispatchToProps = (dispatch) => ({
  create: (user) => dispatch(createUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Create);
