import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import TableCell from '@mui/material/TableCell';
import Chip from '@mui/material/Chip';

import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';

import AdvancedSearch from './AdvancedSearch';
import Actions from './Actions';
import Row from './Row';
import SortableColumn, { ASC } from '../../../../../Components/Table/SortableColumn';
import util from './util';
import COLUMNS from './columns';

function Raw({
  events,
  getSource,
  openTreatment,
  region,
  pagination,
  filter,
  setFilter,
  filters,
  ignoreEvents,
}) {
  const params = useParams();
  const [search, setSearch] = useState('');
  const [duplicateSearch, setDuplicateSearch] = useState('');
  const [sort, setSort] = useState({
    title: 'Age',
    sortKey: 'messageIngressTimestamp',
    direction: ASC,
  });
  const page = (pagination.nextOffset - pagination.limit) / pagination.limit;
  const [rowsPerPage, setRowsPerPage] = useState(pagination?.limit);
  const [selectedRows, setSelectedRows] = useState([]);
  const [regexAnchor, setRegexAnchor] = useState(null);
  const [advancedSearch, setAdvancedSearch] = useState('');

  const searchTerm = advancedSearch || duplicateSearch || search;
  const filteredAndSorted = util.filterAndSortRaw(searchTerm, events, sort);

  const selectRow = (id) => {
    setSelectedRows((cur) => [...cur, id]);
  };

  const deselectRow = (id) => {
    setDuplicateSearch('');
    setSelectedRows((sel) => sel.filter((_sel) => _sel !== id));
  };

  const updateDuplicateSearch = (term) => {
    setAdvancedSearch('');
    setDuplicateSearch(term);
  };

  const handleOpenRegexMenu = (event) => {
    if (advancedSearch) {
      setAdvancedSearch('');
    } else {
      setRegexAnchor(event.currentTarget);
    }
  };

  const handleCloseRegexMenu = () => {
    setRegexAnchor(null);
  };

  const advSearch = (term) => {
    setDuplicateSearch('');
    setSelectedRows(() => []);
    setAdvancedSearch(term);
    handleCloseRegexMenu();
  };

  const onPageChange = (_e, _page) => {
    getSource({
      limit: rowsPerPage,
      offset: _page * rowsPerPage,
    });
  };

  const onRowsPerPageChange = (e) => {
    const limit = parseInt(e.target.value, 10);
    setRowsPerPage(() => limit);
    getSource({
      limit,
      offset: 0,
    });
  };

  return (
    <Box
      data-testid="Raw-Events-Container"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        mt: 2,
        width: '100%',
        mb: 2,
      }}
    >
      <Card sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
        <CardHeader
          sx={{ pb: 0 }}
          title="Events"
          titleTypographyProps={{ color: 'primary.main' }}
          subheader={`Inference Events for source: ${params.sourceId}`}
          action={(
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {filters.map((_filter) => (
                <Chip
                  key={_filter?.title}
                  sx={{ mr: 1 }}
                  label={_filter?.title}
                  onClick={() => {
                    setSelectedRows([]);
                    setFilter(_filter.value);
                  }}
                  variant={filter === _filter.value ? 'filled' : 'outlined'}
                />
              ))}
              <TextField
                data-testid="Search"
                autoComplete="off"
                size="small"
                placeholder="Search"
                id="Search-Field"
                sx={{ ml: 2, mr: 2 }}
                onChange={(e) => setSearch(e.target.value)}
                value={search}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <AdvancedSearch
                advSearch={advSearch}
                handleCloseRegexMenu={handleCloseRegexMenu}
                regexAnchor={regexAnchor}
                advancedSearch={advancedSearch}
                handleOpenRegexMenu={handleOpenRegexMenu}
              />
              <Tooltip title="Refresh Details" placement="top">
                <IconButton aria-label="Refresh" onClick={() => getSource()}>
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        />
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            mr: 1,
            pt: 0,
          }}
        >
          <TableContainer sx={{ maxHeight: 'calc(100vh - 410px)' }}>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  {COLUMNS.slice(0, -1).map((_col) => (
                    <SortableColumn
                      key={_col?.title}
                      column={_col}
                      sort={sort}
                      setSort={setSort}
                    />
                  ))}
                  <TableCell align="right">
                    <Actions
                      duplicateSearch={duplicateSearch}
                      events={events}
                      selectedRows={selectedRows}
                      region={region}
                      openTreatment={openTreatment}
                      setSearch={updateDuplicateSearch}
                      ignoreEvents={ignoreEvents}
                      setSelectedRows={setSelectedRows}
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredAndSorted.map((row) => (
                  <Row
                    multiselect
                    region={region}
                    key={row.id}
                    data={row}
                    openTreatment={openTreatment}
                    selectRow={selectRow}
                    deselectRow={deselectRow}
                    selectedRows={selectedRows}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            showFirstButton
            showLastButton
            rowsPerPageOptions={[10, 20, 50, 100]}
            colSpan={COLUMNS.length}
            count={advancedSearch || duplicateSearch || search
              ? filteredAndSorted.length
              : pagination.totalRows}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true,
            }}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
          />
        </CardContent>
      </Card>
    </Box>
  );
}

Raw.propTypes = {
  events: PropTypes.array,
  getSource: PropTypes.func,
  openTreatment: PropTypes.func,
  region: PropTypes.string,
  pagination: PropTypes.object,
  filter: PropTypes.string,
  setFilter: PropTypes.func,
  filters: PropTypes.array,
  ignoreEvents: PropTypes.func,
};

Raw.defaultProps = {
  events: [],
  getSource: () => {
    // Default
  },
  openTreatment: () => {
    // Default
  },
  region: '',
  pagination: {
    nextOffset: 51,
    limit: 50,
    offset: 0,
    totalRows: 0,
  },
  filter: 'all',
  setFilter: () => {
    // Default
  },
  filters: [],
  ignoreEvents: () => {
    // Default
  },
};

export default Raw;
