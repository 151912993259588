const columns = [{
  title: 'Age',
  sort: true,
  sortKey: 'messageIngressTimestamp',
}, {
  title: 'Utterance',
  sort: false,
}, {
  title: 'Intent',
  sort: true,
  sortKey: 'matchedIntent',
}, {
  title: 'Result',
  sort: true,
  sortKey: 'outcome',
}, {
  title: 'Confidence',
  sort: true,
  sortKey: 'confidenceScore',
}, {
  title: 'Recommended Treatment',
  sort: true,
  sortKey: 'clientRecommendation',
}, {
  title: 'Status',
  sort: false,
}, {
  title: '',
  sort: false,
  sortKey: '',
}];

export default columns;
