import ServisBotSDK from '@servisbot/sb-sdk';

import {
  DESIGN_REGISTRY_LIST_BOT_DESIGNS,
  DESIGN_REGISTRY_LIST_BOT_DESIGNS_SUCCESS,
  DESIGN_REGISTRY_LIST_BOT_DESIGNS_FAIL,
  DESIGN_REGISTRY_IMPORT_BOT_DESIGNS,
  DESIGN_REGISTRY_IMPORT_BOT_DESIGNS_SUCCESS,
  DESIGN_REGISTRY_IMPORT_BOT_DESIGNS_FAIL,
  DESIGN_REGISTRY_BOT_DESIGNS_CLEAR,
} from './types';
import { showAlert } from '../../state/actions';
import userUtils from '../../../util/user';
import isUnAuth from '../../../util/unauth';
import interceptor, { getInboxUrl } from '../../../util/interceptor';
import { logoutUser } from '../../auth/actions';
import sbAuth from '../../../util/sbAuth';

export const listBotDesignsAttempt = () => ({ type: DESIGN_REGISTRY_LIST_BOT_DESIGNS });
export const listBotDesignsSuccess = (botDesigns) => ({
  type: DESIGN_REGISTRY_LIST_BOT_DESIGNS_SUCCESS,
  payload: { botDesigns },
});
export const listBotDesignsFail = () => ({ type: DESIGN_REGISTRY_LIST_BOT_DESIGNS_FAIL });

export const listBotDesigns = () => async (dispatch, getState) => {
  const ERROR_MESSAGE = 'Failed to list Bot Designs, please try again.';
  dispatch(listBotDesignsAttempt());
  try {
    const state = getState();
    const { authToken, authenticatedUser } = state.auth;
    const tenant = userUtils.getTenant(authenticatedUser);
    const region = userUtils.getRegion(authenticatedUser);
    const res = await interceptor({
      method: 'post',
      url: `${getInboxUrl(region)}/${tenant}/ListBotDesigns`,
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    });
    const { data } = res;
    // Can remove once api deployed to stg / prod
    dispatch(listBotDesignsSuccess(data?.botDesigns || data?.bot_designs));
  } catch (_err) {
    const status = _err?.response?.status;
    if (isUnAuth(status)) {
      dispatch(logoutUser({ location: window.location.href }));
    } else {
      dispatch(showAlert({ type: 'error', message: ERROR_MESSAGE }));
      dispatch(listBotDesignsFail());
    }
  }
};

export const botDesignImport = () => ({ type: DESIGN_REGISTRY_IMPORT_BOT_DESIGNS });
export const botDesignImportSuccess = (botDesign) => ({
  type: DESIGN_REGISTRY_IMPORT_BOT_DESIGNS_SUCCESS,
  payload: { botDesign },
});
export const botDesignImportFail = () => ({ type: DESIGN_REGISTRY_IMPORT_BOT_DESIGNS_FAIL });

const uploadDesign = async (region, tenant, authToken, botDesign, csv) => {
  const formData = new FormData();
  Object.keys(botDesign).forEach((key) => {
    formData.append(key, botDesign[key]);
  });
  formData.append('botDesignCsv', csv);
  const res = await interceptor({
    method: 'post',
    url: `${getInboxUrl(region)}/${tenant}/ImportBotDesign`,
    headers: {
      'content-type': 'application/json',
      'content-Type': 'multipart/form-data',
      Authorization: `Bearer ${authToken}`,
    },
    data: formData,
  });
  const { data } = res;
  return data;
};

export const importBotDesign = (botDesign, csv) => async (dispatch, getState) => {
  const ERROR_MESSAGE = 'Failed to import Bot Design, please try again.';
  const SUCCESS_MESSAGE = 'Bot Design imported.';
  dispatch(botDesignImport());
  try {
    const state = getState();
    const { authToken, authenticatedUser } = state.auth;
    const tenant = userUtils.getTenant(authenticatedUser);
    const region = userUtils.getRegion(authenticatedUser);
    const data = await uploadDesign(region, tenant, authToken, botDesign, csv);
    dispatch(botDesignImportSuccess(data));
    dispatch(showAlert({ type: 'success', message: SUCCESS_MESSAGE }));
  } catch (_err) {
    const status = _err?.response?.status;
    if (isUnAuth(status)) {
      dispatch(logoutUser({ location: window.location.href }));
    } else {
      dispatch(showAlert({ type: 'error', message: ERROR_MESSAGE }));
      dispatch(botDesignImportFail());
    }
  }
};

export const clearBotDesigns = () => ({ type: DESIGN_REGISTRY_BOT_DESIGNS_CLEAR });

export const getSaasBots = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const { discovery, authToken } = state.auth;
    const auth = sbAuth(discovery, authToken);
    const SB = ServisBotSDK(fetch);
    const Bot = new SB.Bot({ auth });
    const res = await Bot.list({});
    if (!res?.ok && res?.status === 401) {
      dispatch(logoutUser({ location: window.location.href }));
      return [];
    }
    return res?.body?.Bots || [];
  } catch (_err) {
    return [];
  }
};

const getBotIntentsCSV = async (auth, bot, errorMessage) => {
  const SB = ServisBotSDK(fetch);
  const Intent = new SB.Intent({ auth });
  const params = { bot };
  const res = await Intent.export(params);
  if (!res.ok) {
    throw new Error(errorMessage);
  }
  const { utterances, actions } = res.body;
  const { utterancesCSVString } = await SB.Intent.generateIntentExportCSV(utterances, actions);
  const csv = new Blob([utterancesCSVString], { type: 'text/csv' });
  return csv;
};

export const importSassBot = (botDesign, bot) => async (dispatch, getState) => {
  const ERROR_MESSAGE = 'Failed to import Bot Design, please try again.';
  const SUCCESS_MESSAGE = 'Bot Design imported.';
  dispatch(botDesignImport());
  try {
    const state = getState();
    const { discovery, authToken, authenticatedUser } = state.auth;
    const tenant = userUtils.getTenant(authenticatedUser);
    const region = userUtils.getRegion(authenticatedUser);
    const auth = sbAuth(discovery, authToken);
    const csv = await getBotIntentsCSV(auth, bot, ERROR_MESSAGE);
    const data = await uploadDesign(region, tenant, authToken, botDesign, csv);
    dispatch(botDesignImportSuccess(data));
    dispatch(showAlert({ type: 'success', message: SUCCESS_MESSAGE }));
  } catch (_err) {
    const status = _err?.response?.status;
    if (isUnAuth(status)) {
      dispatch(logoutUser({ location: window.location.href }));
    } else {
      dispatch(showAlert({ type: 'error', message: ERROR_MESSAGE }));
      dispatch(botDesignImportFail());
    }
  }
};
