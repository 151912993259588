import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';

import ViewPage from '../../../Components/ViewPage';
import Metrics from './Components/Metrics';

import {
  ROUTE_ANALYTICS,
  ROUTE_ANALYTICS_USAGE,
} from '../../../Routes/routes';

const breadcrumbs = (t) => [{
  title: t('Views.analyticsTitle'),
  route: `/${ROUTE_ANALYTICS}`,
}, {
  title: t('Analytics.usageTitle'),
  route: `/${ROUTE_ANALYTICS_USAGE}`,
}];

const sections = [{
  title: 'Consumption',
  data: [{
    title: 'DAU',
    data: 3128,
    tooltip: 'Daily Active Users',
  }, {
    title: 'Active Bots',
    data: 3,
    tooltip: 'Bots currently operating live',
  }, {
    title: 'User Input',
    data: 5367,
    tooltip: 'Input provided by users to your bots',
  }, {
    title: 'Documents',
    data: 5367,
    tooltip: 'Documents uploaded by users and handled by your bots',
  }, {
    title: 'Active Content',
    data: 75,
    tooltip: 'Content that is being displayed to your users',
  }],
}, {
  title: 'Session',
  data: [{
    title: 'Session',
    data: 3128,
    tooltip: 'Active user sessions',
  }, {
    title: 'Avg. Session Time',
    data: '2min 7sec',
    tooltip: 'Average duration of a user interacting with your bots',
  }, {
    title: 'Avg. Session Input',
    data: '1.2',
    tooltip: 'Average input provided by a user to your bots per session',
  }, {
    title: 'Engagement Rate',
    data: '91%',
    tooltip: 'The percentage of users who interact with your bots',
  }, {
    title: 'Escalation Rate',
    data: '3%',
    tooltip: 'The percentage of users that transfer to a user agent',
  }],
}, {
  title: 'Billing',
  data: [{
    title: 'API Invokes',
    data: 3128,
    tooltip: 'The number of api requests your bots make',
  }, {
    title: 'Messages',
    data: '15,782',
    tooltip: 'The ammount of messages sent and received by your bots',
  }, {
    title: 'Orchestration',
    data: '5201',
    tooltip: 'Inter-Bot communication events',
  }, {
    title: 'Flow Execution',
    data: 1320,
    tooltip: 'The ammount of messages passed between flows within your bots',
  }, {
    title: 'Goals',
    data: '21,241',
    tooltip: 'Goals hit by users interacting with your bots',
  }],
}];

function Usage({
  toggleDrawer,
  loading,
}) {
  const { t } = useTranslation();
  return (
    <ViewPage
      title={t('Analytics.usageTitle')}
      toggleDrawer={toggleDrawer}
      loading={loading}
      breadcrumbs={breadcrumbs(t)}
    >
      <Box
        data-testid="Usage-View-Container"
        sx={{
          mt: 1,
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
        }}
      >
        <Metrics sections={sections} />
      </Box>
    </ViewPage>
  );
}

Usage.propTypes = {
  toggleDrawer: PropTypes.func,
  loading: PropTypes.bool,
};

Usage.defaultProps = {
  toggleDrawer: () => {
    // Default
  },
  loading: false,
};

export default Usage;
