import {
  BLUE_ONE,
  BLUE_TWO,
  BLUE_THREE,
  BLUE_FIVE,
} from '../../../../colors';

const graphOptions = {
  series: [20, 49, 31],
  chart: {
    width: 300,
    height: 300,
    type: 'pie',
  },
  labels: ['Web', 'Mobile Web', 'SMS'],
  legend: {
    show: true,
    position: 'bottom',
  },
  dataLabels: {
    enabled: true,
    formatter(val) {
      return `${val}%`;
    },
  },
  tooltip: {
    y: {
      formatter: (_val) => `${_val}%`,
    },
  },
  colors: [BLUE_ONE, BLUE_TWO, BLUE_THREE, BLUE_FIVE],
};

export default graphOptions;
