import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import CircleIcon from '@mui/icons-material/Circle';

import HeaderDetails from '../../../../../Components/HeaderDetails';

const formatSecrets = (secrets) => secrets.map((_secret) => ({
  title: 'SRN',
  data: _secret,
}));

const formatAllowedDomains = (bots) => bots.map((_bot) => ({
  title: 'Url',
  data: _bot,
}));

const formatHttp = (http) => http.map((_url) => ({
  title: 'Url',
  data: _url,
}));

const formatAWSSdk = (aws) => aws.map((_aws) => ({
  title: 'Resource',
  data: _aws,
}));

function References({
  data,
}) {
  return (
    <Box
      data-testid="References"
      sx={{
        display: 'flex',
        mt: 2,
        flexDirection: 'column',
        flexWrap: 'wrap',
      }}
    >
      <HeaderDetails title="Secrets" data={formatSecrets(data?.secrets || [])} />
      <HeaderDetails title="Allowed Domains" data={formatAllowedDomains(data?.allowedDomains || [])} mt={2} />
      <Box sx={{ mt: 2 }}>
        <Typography variant="h6" color="text.secondary">
          External References
        </Typography>
        {(data?.externalReferences?.http && data?.externalReferences?.http?.length > 0) && (
          <Card
            sx={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              mt: 2,
            }}
          >
            <CardContent>
              <Typography variant="h6" color="text.secondary">Http</Typography>
              <List sx={{ wordBreak: 'break-word' }}>
                {formatHttp(data?.externalReferences?.http || []).map((_row) => (
                  <ListItem dense disablePadding key={`${_row.title}-${_row.data}`}>
                    <ListItemIcon sx={{ minWidth: 16 }}>
                      <CircleIcon sx={{ width: 8 }} />
                    </ListItemIcon>
                    <ListItemText primary={_row.data} />
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </Card>
        )}
        {(data?.externalReferences?.['aws-sdk'] && data?.externalReferences?.['aws-sdk']?.length > 0) && (
          <Card sx={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            mt: 2,
          }}
          >
            <CardContent>
              <Typography variant="h6" color="text.secondary">AWS SDK</Typography>
              <List sx={{ wordBreak: 'break-word' }}>
                {formatAWSSdk(data?.externalReferences?.['aws-sdk'] || []).map((_row) => (
                  <ListItem dense disablePadding key={`${_row.title}-${_row.data}`}>
                    <ListItemIcon sx={{ minWidth: 16 }}>
                      <CircleIcon sx={{ width: 8 }} />
                    </ListItemIcon>
                    <ListItemText primary={_row.data} />
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </Card>
        )}
      </Box>
    </Box>
  );
}

References.propTypes = {
  data: PropTypes.object,
};

References.defaultProps = {
  data: {},
};

export default References;
