import React from 'react';
import PropTypes from 'prop-types';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

import GeneralInfo from './GeneralInfo';
import TopicInfo from './TopicInfo';

function Queries({
  selectedTopic,
  data,
}) {
  return (
    <Card
      sx={{
        display: 'flex',
        flex: 1,
        ml: 1,
        flexDirection: 'column',
      }}
    >
      <CardHeader
        title="Relevant Queries"
        titleTypographyProps={{ color: 'primary.main' }}
        subheader={`Utterances containing ${selectedTopic || 'topic keywords'}`}
      />
      <CardContent sx={{ display: 'flex', flex: 1 }}>
        {!selectedTopic && (<GeneralInfo data={data} />)}
        {selectedTopic && (<TopicInfo selectedTopic={selectedTopic} data={data} />)}
      </CardContent>
    </Card>
  );
}

Queries.propTypes = {
  selectedTopic: PropTypes.string,
  data: PropTypes.array,
};

Queries.defaultProps = {
  selectedTopic: '',
  data: [],
};

export default Queries;
