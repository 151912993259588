import { connect } from 'react-redux';

import ImpactAnalysis from './ImpactAnalysis';
import { getSummary, clearSummary } from '../../../store/compliance/impactanalysis/actions';

const mapStateToProps = (state) => ({
  loading: state?.compliance?.impactanalysis?.loading,
  data: state?.compliance?.impactanalysis?.data,
});

const mapDispatchToProps = (dispatch) => ({
  getSummary: (id) => dispatch(getSummary(id)),
  clearSummary: () => dispatch(clearSummary()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImpactAnalysis);
