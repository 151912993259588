import { auth } from '@servisbot/npm-pages-authguard';
import {
  AUTH_LOG_OUT,
  AUTH_REHYDRATE_USER,
  AUTH_REHYDRATE_USER_SUCCESS,
  AUTH_REHYDRATE_USER_FAIL,
  AUTH_AUTHENTICATE_USER,
  AUTH_AUTHENTICATE_USER_SUCCESS,
  AUTH_AUTHENTICATE_USER_FAIL,
} from './types';
import parseStatusCode from '../../util/parseStatusCode';

export const logoutUser = (opts) => ({
  type: AUTH_LOG_OUT,
  payload: { ...opts },
});

export const logout = () => async (dispatch) => {
  await auth.logout();
  dispatch(logoutUser({ reason: 'LOGOUT' }));
};

// eslint-disable-next-line
const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export const rehydrateUserAttempt = () => ({
  type: AUTH_REHYDRATE_USER,
});

export const rehydrateUserSuccess = (params) => ({
  type: AUTH_REHYDRATE_USER_SUCCESS,
  payload: { ...params },
});

export const rehydrateUserFail = (error) => ({
  type: AUTH_REHYDRATE_USER_FAIL,
  payload: { error },
});

export const rehydrateUser = () => async (dispatch) => {
  dispatch(rehydrateUserAttempt());
  try {
    const details = await auth.rehydrate();
    const { discovery, auth: authDetails } = details;
    const { cognitoJwtToken } = authDetails;
    dispatch(rehydrateUserSuccess({ token: cognitoJwtToken, discovery }));
  } catch (_err) {
    if (_err.message === 'Session Expired') {
      dispatch(logoutUser({ location: window.location.href }));
      dispatch(rehydrateUserFail());
    } else {
      dispatch(rehydrateUserFail(parseStatusCode(_err)));
    }
  }
};

export const authenticateUserAttempt = () => ({
  type: AUTH_AUTHENTICATE_USER,
});

export const authenticateUserSuccess = (params) => ({
  type: AUTH_AUTHENTICATE_USER_SUCCESS,
  payload: { ...params },
});

export const authenticateUserFail = (error) => ({
  type: AUTH_AUTHENTICATE_USER_FAIL,
  payload: { error },
});

export const authenticateUser = () => async (dispatch) => {
  dispatch(authenticateUserAttempt());
  try {
    await delay(3000);
    dispatch(authenticateUserSuccess());
  } catch (_err) {
    dispatch(authenticateUserFail());
  }
};
