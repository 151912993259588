import React from 'react';
import PropTypes from 'prop-types';

function Botty({ width, height }) {
  return (
    <svg width={width} height={height} viewBox="0 0 68 81" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M7.9209 39.6872C7.9209 25.3756 19.6017 13.8 34.0434 13.8C48.485 13.8 60.1658 25.3756 60.1658 39.6872V56.6296C60.1658 64.6272 53.5821 71.0464 45.6179 71.0464H22.4688C14.3984 71.0464 7.9209 64.522 7.9209 56.6296V39.6872ZM24.3802 48.7371C27.2473 49.9999 30.5391 50.7365 34.0434 50.7365C44.1313 50.7365 52.5202 44.7383 52.5202 37.2668C52.5202 29.7953 44.2375 23.6919 34.0434 23.6919C23.8492 23.6919 15.5665 29.7953 15.5665 37.2668C15.5665 40.0029 16.7346 42.6337 18.7522 44.8435C19.0625 45.1511 18.1887 46.7819 17.2967 48.4468C16.3573 50.2001 15.3976 51.9912 15.7789 52.315C16.3082 52.6822 18.1304 51.6374 19.9829 50.5753C21.8467 49.5067 23.7411 48.4205 24.3802 48.7371Z" fill="#3493CF" />
      <path d="M31.1763 7.90695C31.6011 8.22264 31.8134 8.74881 31.8134 9.27497V12.2215C31.8134 12.3267 31.9196 12.4319 32.0258 12.4319C32.6629 12.4319 33.3001 12.3267 33.9372 12.3267C34.5743 12.3267 35.2115 12.3267 35.8486 12.4319C35.9548 12.4319 36.061 12.3267 36.061 12.2215V9.27497C36.061 8.74881 36.2734 8.22264 36.6981 7.90695C37.76 7.06509 38.3971 5.8023 38.3971 4.43429C38.3971 1.90871 36.2734 -0.195931 33.6186 0.0145329C31.3887 0.119765 29.5835 1.90871 29.3711 4.01336C29.3711 5.59184 30.0082 6.95986 31.1763 7.90695Z" fill="#3493CF" />
      <path d="M22.6812 72.8352C24.4864 77.255 28.8401 80.4119 33.9372 80.4119C39.0342 80.4119 43.4942 77.255 45.1932 72.8352H22.6812Z" fill="#3493CF" />
      <path d="M3.56729 64.8376C1.97446 64.8376 0.700195 63.5748 0.700195 61.9964V44.3173C0.700195 42.7389 1.97446 41.4761 3.56729 41.4761C5.16013 41.4761 6.43439 42.7389 6.43439 44.3173V61.9964C6.3282 63.5748 5.05394 64.8376 3.56729 64.8376Z" fill="#3493CF" />
      <path d="M64.5199 64.8376C62.9271 64.8376 61.6528 63.5748 61.6528 61.9964V44.3173C61.6528 42.7389 62.9271 41.4761 64.5199 41.4761C66.1128 41.4761 67.387 42.7389 67.387 44.3173V61.9964C67.2808 63.5748 66.0066 64.8376 64.5199 64.8376Z" fill="#3493CF" />
      <ellipse cx="26.8226" cy="35.2673" rx="3.29185" ry="3.2622" fill="#3493CF" />
      <ellipse cx="41.2645" cy="35.2673" rx="3.29185" ry="3.2622" fill="#3493CF" />
    </svg>
  );
}

Botty.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

Botty.defaultProps = {
  width: 68,
  height: 81,
};

export default Botty;
