import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

import HeaderDetails from '../../../../Components/HeaderDetails';

import {
  CREATE_INTENT,
  ADD_UTTERANCE,
  REMOVE_UTTERANCE,
  IGNORE,
} from '../../../OperationalInsights/Dashboard/Source/Events/Treatment/util';

const createIntentCount = (data = []) => {
  const filtered = data.filter((_dat) => _dat.action === CREATE_INTENT);
  return filtered.length;
};

const addUtteranceCount = (data = []) => {
  const filtered = data.filter((_dat) => _dat.action === ADD_UTTERANCE);
  return filtered.length;
};

const removeUtteranceCount = (data = []) => {
  const filtered = data.filter((_dat) => _dat.action === REMOVE_UTTERANCE);
  return filtered.length;
};

const ignoreCount = (data = []) => {
  const filtered = data.filter((_dat) => _dat.action === IGNORE);
  return filtered.length;
};

const formatData = (data) => [{
  title: 'New Intents',
  data: createIntentCount(data),
}, {
  title: 'Utterances Added',
  data: addUtteranceCount(data),
}, {
  title: 'Utterances Removed',
  data: removeUtteranceCount(data),
}, {
  title: 'Utterances Ignored',
  data: ignoreCount(data),
}];

const formatUpdates = (data) => {
  const count = (data?.treatmentEvents || []).length;
  if (count === 1) {
    return `${count} Update to approve`;
  }
  return `${count} Updates to approve`;
};

function Approve({
  data,
  onClose,
  approve,
  changeSetId,
  setApproved,
}) {
  const filedownloadRef = useRef(null);
  const [href, setHref] = useState(null);
  const [loading, setLoading] = useState(false);
  const onCreate = async () => {
    setLoading(true);
    const res = await approve(changeSetId);
    setLoading(false);
    if (res && filedownloadRef?.current) {
      const { changeSetUrl, applied } = res;
      setHref(changeSetUrl);
      filedownloadRef.current.click();
      setApproved(applied);
      onClose();
    }
  };
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open
      onClose={onClose}
    >
      <a href={href} ref={filedownloadRef} download style={{ display: 'none' }}>download</a>
      <DialogTitle>
        Approve Change Set
      </DialogTitle>
      <DialogContent>
        <Typography color="text.secondary" gutterBottom>
          The following changes will be applied to your bot.
        </Typography>
        <Typography color="text.secondary">
          {formatUpdates(data)}
        </Typography>
        <HeaderDetails data={formatData(data?.treatmentEvents || [])} elevation={0} />
      </DialogContent>
      <DialogActions>
        <Box sx={{ m: 2 }}>
          <Button
            onClick={onClose}
            disabled={loading}
            sx={{ mr: 2, width: 100 }}
          >
            Cancel
          </Button>
          <Button
            sx={{ width: 100 }}
            onClick={onCreate}
            variant="contained"
            disabled={loading}
          >
            {loading ? 'Approving...' : 'Approve'}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

Approve.propTypes = {
  onClose: PropTypes.func,
  approve: PropTypes.func,
  changeSetId: PropTypes.string,
  data: PropTypes.object,
  setApproved: PropTypes.func,
};

Approve.defaultProps = {
  onClose: () => {
    // Default
  },
  approve: () => {
    // Default
  },
  changeSetId: '',
  data: {},
  setApproved: () => {
    // Default
  },
};

export default Approve;
