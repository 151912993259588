import React from 'react';

import PropTypes from 'prop-types';

function Analytics({ width, height }) {
  return (
    <svg width={width} height={height} viewBox="0 0 93 68" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M87.5353 28.3528L59.1825 56.7056L38.0461 35.5692L15.6716 57.9437L9.69336 51.9655L38.0461 23.6127L59.1825 44.7491L81.557 22.3746L87.5353 28.3528Z" fill="white" />
      <circle cx="8.45455" cy="59.1818" r="8.45455" fill="white" />
      <circle cx="38.0454" cy="29.5909" r="8.45455" fill="white" />
      <circle cx="59.1821" cy="50.7272" r="8.45455" fill="white" />
      <circle cx="84.5454" cy="25.3637" r="8.45455" fill="white" />
      <circle cx="59.1821" cy="8.45455" r="8.45455" fill="white" />
      <circle cx="8.45455" cy="16.9091" r="8.45455" fill="white" />
    </svg>
  );
}

Analytics.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

Analytics.defaultProps = {
  width: 93,
  height: 68,
};

export default Analytics;
