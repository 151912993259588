import {
  INBOX_TREATMENT_LIST_QUEUES,
  INBOX_TREATMENT_LIST_QUEUES_SUCCESS,
  INBOX_TREATMENT_LIST_QUEUES_FAIL,
  INBOX_TREATMENT_LIST_QUEUES_CLEAR,
} from './types';
import { showAlert } from '../../state/actions';
import userUtils from '../../../util/user';
import isUnAuth from '../../../util/unauth';
import interceptor, { getInboxUrl } from '../../../util/interceptor';
import { logoutUser } from '../../auth/actions';

export const listTreatmentsAttempt = () => ({ type: INBOX_TREATMENT_LIST_QUEUES });
export const listTreatmentsSuccess = (queues) => ({
  type: INBOX_TREATMENT_LIST_QUEUES_SUCCESS,
  payload: { queues },
});
export const listTreatmentsFail = () => ({ type: INBOX_TREATMENT_LIST_QUEUES_FAIL });

export const listTreatments = () => async (dispatch, getState) => {
  const ERROR_MESSAGE = 'Failed to list Queues, please try again.';
  dispatch(listTreatmentsAttempt());
  try {
    const state = getState();
    const { authToken, authenticatedUser } = state.auth;
    const tenant = userUtils.getTenant(authenticatedUser);
    const region = userUtils.getRegion(authenticatedUser);
    const res = await interceptor({
      method: 'post',
      url: `${getInboxUrl(region)}/${tenant}/ListUserQueues`,
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    });
    const { data } = res;
    const { queues } = data;
    dispatch(listTreatmentsSuccess(queues));
  } catch (_err) {
    const status = _err?.response?.status;
    if (isUnAuth(status)) {
      dispatch(logoutUser({ location: window.location.href }));
    } else {
      dispatch(listTreatmentsFail());
      dispatch(showAlert({ type: 'error', message: ERROR_MESSAGE }));
    }
  }
};

export const clearTreatments = () => ({ type: INBOX_TREATMENT_LIST_QUEUES_CLEAR });
