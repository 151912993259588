import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ApexCharts from 'apexcharts';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';

import ViewPage from '../../../Components/ViewPage';
import HeaderDetails from '../../../Components/HeaderDetails';
import breadcrumbs from './breadcrumbs';
import graphOptions from './graphOptions';
import Summary from './Summary';

const formatAccuracy = () => [{
  title: 'New Test Accuracy',
  data: '56.84%',
}, {
  title: 'Improvement',
  data: '42.91%',
}];

const formatSummary = () => [{
  title: 'Total Utterances',
  data: '991',
}, {
  title: 'Training Utterances',
  data: '747',
}, {
  title: 'Testing Utterances',
  data: '244',
}, {
  title: 'Topic Count',
  data: '442',
}];

let pieChart;

function ImpactAnalysis({
  toggleDrawer,
  loading,
  getSummary,
  clearSummary,
}) {
  const { t } = useTranslation();
  const pieChartRef = useRef(null);
  const params = useParams();
  const { changeSetId } = params;

  useEffect(() => {
    getSummary();
    return () => {
      clearSummary();
    };
  }, []);

  useEffect(() => {
    if (pieChartRef.current) {
      pieChart = new ApexCharts(pieChartRef.current, graphOptions());
      pieChart.render();
    }
  }, [loading]);

  useEffect(() => {
    if (pieChart) {
      pieChart.updateOptions(graphOptions());
    }
  }, [pieChart, loading]);

  return (
    <ViewPage
      loading={loading}
      title={t('Compliance.impactAnalysisTitle')}
      toggleDrawer={toggleDrawer}
      breadcrumbs={breadcrumbs(t, changeSetId)}
    >
      <Box
        data-testid="ImpactAnalysis-View-Container"
        sx={{
          display: 'flex',
          flex: 1,
          flexWrap: 'wrap',
          mt: 2,
          flexDirection: 'column',
        }}
      >
        <HeaderDetails title="Analysis Results Summary" data={formatAccuracy()} />
        <HeaderDetails data={formatSummary()} />
        <Box sx={{ mt: 2, display: 'flex' }}>
          <Card sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h6" color="text.secondary">
              Intent Summary
            </Typography>
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ mt: 2 }} ref={pieChartRef} />
              <Summary />
            </Box>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              {[{ title: 'Containing Bad Samples', data: '19' }, { title: 'Poorly Defined', data: '27' }, { title: 'Under Trained', data: '49' }].map((_data) => (
                <Box sx={{ mt: 2, mr: 2 }} key={_data.title}>
                  <CardHeader
                    title={_data.data}
                    titleTypographyProps={{ color: 'primary.main' }}
                    subheader={_data.title}
                  />
                </Box>
              ))}
            </Box>
          </Card>
        </Box>
      </Box>
    </ViewPage>
  );
}

ImpactAnalysis.propTypes = {
  toggleDrawer: PropTypes.func,
  loading: PropTypes.bool,
  getSummary: PropTypes.func,
  clearSummary: PropTypes.func,
};

ImpactAnalysis.defaultProps = {
  toggleDrawer: () => {
    // Provide defaults
  },
  loading: false,
  getSummary: () => {
    // default
  },
  clearSummary: () => {
    // Default
  },
};

export default ImpactAnalysis;
