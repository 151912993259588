import { connect } from 'react-redux';

import Import from './Import';
import { importBotDesign, getSaasBots, importSassBot } from '../../../../store/designInsights/designRegistry/actions';

const mapStateToProps = (state) => ({
  loading: state?.designInsights?.designRegistry?.index?.importing,
});

const mapDispatchToProps = (dispatch) => ({
  importBotDesign: (botDesign, csv) => dispatch(importBotDesign(botDesign, csv)),
  getSaasBots: () => dispatch(getSaasBots()),
  importSassBot: (botDesign, bot) => dispatch(importSassBot(botDesign, bot)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Import);
