import {
  DASHBOARD_SOURCE_GET_SOURCE,
  DASHBOARD_SOURCE_GET_SOURCE_SUCCESS,
  DASHBOARD_SOURCE_GET_SOURCE_FAIL,
  DASHBOARD_SOURCE_SET_FILTER,
  DASHBOARD_SOURCE_REMOVE_EVENT,
} from './types';
import isUnAuth from '../../../../util/unauth';
import userUtils from '../../../../util/user';
import interceptor, { getInboxUrl } from '../../../../util/interceptor';
import { logoutUser } from '../../../auth/actions';
import { showAlert } from '../../../state/actions';

export const fetchSourceAttempt = () => ({
  type: DASHBOARD_SOURCE_GET_SOURCE,
});

export const fetchSourceSuccess = (data) => ({
  type: DASHBOARD_SOURCE_GET_SOURCE_SUCCESS,
  payload: { data },
});

export const fetchSourceFail = () => ({
  type: DASHBOARD_SOURCE_GET_SOURCE_FAIL,
});

const PAGINATION = {
  limit: 50,
  offset: 0,
};

export const getSource = (sourceId, properties = PAGINATION) => async (dispatch, getState) => {
  const ERROR_MESSAGE = 'Failed to load events, please try again.';
  dispatch(fetchSourceAttempt());
  try {
    const state = getState();
    const { authToken, authenticatedUser } = state.auth;
    const tenant = userUtils.getTenant(authenticatedUser);
    const region = userUtils.getRegion(authenticatedUser);
    const res = await interceptor({
      method: 'post',
      url: `${getInboxUrl(region)}/${tenant}/ListSourceInferenceEvents`,
      data: {
        view: 'raw',
        sources: [{ resourceName: sourceId }],
        properties,
      },
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    });
    const { data } = res;
    dispatch(fetchSourceSuccess(data));
  } catch (_err) {
    const status = _err?.response?.status;
    if (isUnAuth(status)) {
      dispatch(logoutUser({ location: window.location.href }));
    } else {
      dispatch(fetchSourceFail());
      dispatch(showAlert({ type: 'error', message: ERROR_MESSAGE }));
    }
  }
};

export const removeEvent = (eventId) => ({
  type: DASHBOARD_SOURCE_REMOVE_EVENT,
  payload: { eventId },
});

export const addTreatment = (treatment) => async (dispatch, getState) => {
  const ERROR_MESSAGE = 'Failed to create Treatment, please try again.';
  try {
    const state = getState();
    const { authToken, authenticatedUser } = state.auth;
    const tenant = userUtils.getTenant(authenticatedUser);
    const region = userUtils.getRegion(authenticatedUser);
    const res = await interceptor({
      method: 'post',
      url: `${getInboxUrl(region)}/${tenant}/CreateTreatmentEvent`,
      data: treatment,
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    });
    const { data } = res;
    const { inferenceEventId } = treatment;
    dispatch(removeEvent(inferenceEventId));
    dispatch(showAlert({ type: 'success', message: 'Treatment added.' }));
    return data;
  } catch (_err) {
    const status = _err?.response?.status;
    if (isUnAuth(status)) {
      dispatch(logoutUser({ location: window.location.href }));
    } else {
      dispatch(showAlert({ type: 'error', message: ERROR_MESSAGE }));
    }
    return {};
  }
};

export const setFilter = (filter) => ({
  type: DASHBOARD_SOURCE_SET_FILTER,
  payload: { filter },
});

export const ignoreEvents = (events) => async (dispatch, getState) => {
  const ERROR_MESSAGE = 'Failed to ignore events, please try again.';
  try {
    const state = getState();
    const { authToken, authenticatedUser } = state.auth;
    const tenant = userUtils.getTenant(authenticatedUser);
    const region = userUtils.getRegion(authenticatedUser);
    const treatmentEvents = events.map((_event) => ({
      inferenceEventId: _event.id,
      botDesignId: _event.botDesignId,
      action: 'IGNORE',
      properties: {},
    }));
    await interceptor({
      method: 'post',
      url: `${getInboxUrl(region)}/${tenant}/BatchCreateTreatmentEvents`,
      data: { treatmentEvents },
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    });
    treatmentEvents.forEach((_event) => dispatch(removeEvent(_event.inferenceEventId)));
    dispatch(showAlert({ type: 'success', message: `Ignored ${events.length} events` }));
  } catch (_err) {
    const status = _err?.response?.status;
    if (isUnAuth(status)) {
      dispatch(logoutUser({ location: window.location.href }));
    } else {
      dispatch(showAlert({ type: 'error', message: ERROR_MESSAGE }));
    }
  }
};
