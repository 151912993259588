import {
  INBOX_TREATMENT_GET_INFERENCE_EVENT,
  INBOX_TREATMENT_GET_INFERENCE_EVENT_SUCCESS,
  INBOX_TREATMENT_GET_INFERENCE_EVENT_FAIL,
  INBOX_TREATMENT_GET_INFERENCE_EVENT_CLEAR,
} from './types';

export const initialState = {
  loading: false,
  event: {},
};

const reducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case INBOX_TREATMENT_GET_INFERENCE_EVENT: {
      return { ...state, loading: true, event: {} };
    }
    case INBOX_TREATMENT_GET_INFERENCE_EVENT_SUCCESS: {
      const { event } = action.payload;
      return { ...state, loading: false, event };
    }
    case INBOX_TREATMENT_GET_INFERENCE_EVENT_FAIL: {
      return { ...state, loading: false, event: {} };
    }
    case INBOX_TREATMENT_GET_INFERENCE_EVENT_CLEAR: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default reducer;
