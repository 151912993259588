export const calculateRedaction = (redactionRates) => {
  let redactionRate = 0;
  if (redactionRates.length > 0) {
    redactionRate = Math.round(redactionRates
      .reduce((curr, next) => curr + next, 0) / redactionRates.length);
  }
  return redactionRate;
};

export const formatTotalData = (totals, redactionRate) => [{
  title: 'Redaction Rate',
  data: `${redactionRate}%`,
}, ...Object.keys(totals).map((key) => ({
  title: key,
  data: totals[key],
}))];

export const formatTotals = (data) => {
  const totals = {};
  const redactionRates = [];
  const redactionData = data?.redactionActivityReports || [];
  if (!redactionData || data.redactionData === 0) {
    return [];
  }
  redactionData.forEach((_dat) => {
    const orgInfo = _dat?.orgs?.[0] || {};
    redactionRates.push(orgInfo.redactionRate || 0);
    (orgInfo?.activity?.totals || []).forEach((_total) => {
      const name = _total?.name;
      let count = 0;
      (_total?.entities || []).forEach((_entity) => {
        count += _entity.count;
      });
      if (!totals[name]) {
        totals[name] = count;
      } else {
        totals[name] += count;
      }
    });
  });
  const redactionRate = calculateRedaction(redactionRates);
  return formatTotalData(totals, redactionRate);
};

export const getSubtitle = (timePeriod) => {
  if (timePeriod === 'day') {
    return 'Redactions over the last 24 hours';
  }
  if (timePeriod === 'week') {
    return 'Redactions over the last week';
  }
  return 'Redactions over the last month';
};

export const formatSources = (data) => {
  const redactionData = data?.redactionActivityReports || [];
  if (!redactionData || data.redactionData === 0) {
    return [];
  }
  const totals = {};
  redactionData.forEach((_dat) => {
    const orgInfo = _dat?.orgs?.[0] || {};
    (orgInfo?.activity?.sources || []).forEach((_source) => {
      const name = _source?.name;
      if (!totals[name]) {
        totals[name] = {};
      }
      (_source?.categories || []).forEach((_category) => {
        let total = 0;
        _category.entities.forEach((_ent) => {
          total += _ent.count;
        });
        if (!totals[name]?.[_category.name]) {
          totals[name][_category.name] = total;
        } else {
          totals[name][_category.name] += total;
        }
      });
    });
  });
  return totals;
};
