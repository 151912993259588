const i18n = {
  en: {
    usageTitle: 'Usage',
    performanceTitle: 'Performance',
    customTitle: 'Custom',
    learningTitle: 'Learning',
    treatmentTitle: 'Treatment',
    redactionTitle: 'Redaction',
  },
  fr: {
    usageTitle: 'Usage',
    performanceTitle: 'Performance',
    customTitle: 'Personnalisé',
    learningTitle: 'Apprentissage',
    treatmentTitle: 'Traitement',
    redactionTitle: 'Rédaction',
  },
};

export default i18n;
