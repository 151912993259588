import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CategoryIcon from '@mui/icons-material/Category';
import MessageIcon from '@mui/icons-material/Message';

function KeyWords({
  data,
}) {
  return (
    <Card
      data-testid="KeyWords-Container"
      sx={{
        display: 'flex',
        flex: 1,
        mr: 1,
        flexDirection: 'column',
      }}
    >
      <CardHeader
        title="Topic Keywords"
        titleTypographyProps={{ color: 'primary.main' }}
        subheader="What people are saying within a conversation"
        action={(
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CategoryIcon sx={{ width: 32, height: 32, color: 'primary.main' }} />
              <Box
                sx={{
                  ml: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Typography variant="h5" component="div" sx={{ fontWeight: 400 }} color="text.tertiary">
                  {data.length}
                </Typography>
                <Typography variant="subtitle1" component="div" color="text.secondary">
                  Topics
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                ml: 2,
                mr: 2,
              }}
            >
              <MessageIcon sx={{ width: 32, height: 32, color: 'primary.main' }} />
              <Box
                sx={{
                  ml: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Typography variant="h5" component="div" sx={{ fontWeight: 400 }} color="text.tertiary">
                  {data.reduce((prev, curr) => prev + curr.value, 0)}
                </Typography>
                <Typography variant="subtitle1" component="div" color="text.secondary">
                  Utterances
                </Typography>
              </Box>
            </Box>
          </Box>
      )}
      />
      <CardContent sx={{ display: 'flex', flex: 1 }}>
        {/* Word cloud needs to go back in here but does not support react 17+ */}
      </CardContent>
    </Card>
  );
}

KeyWords.propTypes = {
  data: PropTypes.array,
};

KeyWords.defaultProps = {
  data: [],
};

export default KeyWords;
