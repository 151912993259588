import { options, formatOptions } from '../../Analytics/Redaction/graphOptions';

export const graphOptions = (data, selectedBot, selectedOrg) => {
  const redactionData = data?.redactionActivityReports || [];
  let totals = {};
  redactionData.forEach((_dat) => {
    const foundOrg = _dat.orgs.find((_org) => _org.name === selectedOrg);
    if (foundOrg) {
      totals = formatOptions(totals, foundOrg);
    }
  });
  const selectedData = totals?.[selectedBot] || {};
  return options(selectedData);
};

export const test = () => {};
