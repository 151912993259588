import { connect } from 'react-redux';

import Redaction from './Redaction';
import {
  getRedaction,
  clearRedaction,
} from '../../../store/analytics/redaction/actions';

const mapStateToProps = (state) => ({
  loading: state?.analytics?.redaction?.loading,
  data: state?.analytics?.redaction?.data,
});

const mapDispatchToProps = (dispatch) => ({
  getRedaction: (period) => dispatch(getRedaction(period)),
  clearRedaction: () => dispatch(clearRedaction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Redaction);
