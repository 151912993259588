import React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

function AppLoader() {
  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
      data-testid="AppLoader-Container"
    >
      <CircularProgress />
    </Box>
  );
}

export default AppLoader;
