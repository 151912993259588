const parseStatusCode = (_err) => {
  const statusCode = _err?.response?.status;
  let error = '';
  if (statusCode) {
    if (statusCode === 400) {
      error = 'Bad Request';
    } else if (statusCode === 401) {
      error = 'Unauthorized';
    } else if (statusCode === 403) {
      error = 'Access Forbidden';
    } else if (statusCode === 404) {
      error = 'Resource not Found';
    } else if (statusCode >= 500) {
      error = 'Internal Server Error';
    }
  }
  return error || _err?.message || 'An Error has occurred, please try again';
};

export default parseStatusCode;
