import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import ApexCharts from 'apexcharts';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { graphOptions } from '../graphOptions';

let chart;

function SelectedSource({
  data,
  selectedOrg,
  selectedSource,
}) {
  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef.current) {
      chart = new ApexCharts(chartRef.current, graphOptions(data, selectedSource, selectedOrg));
      chart.render();
    }
  }, [chartRef, data]);

  useEffect(() => {
    if (chart) {
      chart.updateOptions(graphOptions(data, selectedSource, selectedOrg));
    }
  }, [chartRef, data, selectedSource, selectedOrg]);

  return (
    <Box sx={{ mt: 2 }} data-testid="SelectedSource-Container">
      <Card sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
        <CardHeader
          title={selectedSource}
          titleTypographyProps={{ color: 'primary.main' }}
          subheader={`Redaction Details for ${selectedSource}`}
        />
        <CardContent sx={{ display: 'flex', flex: 1, mr: 1 }}>
          <Box ref={chartRef} sx={{ width: '100%', height: 400 }} />
        </CardContent>
      </Card>
    </Box>
  );
}

SelectedSource.propTypes = {
  data: PropTypes.object,
  selectedOrg: PropTypes.string,
  selectedSource: PropTypes.string,
};

SelectedSource.defaultProps = {
  data: {},
  selectedOrg: '',
  selectedSource: '',
};

export default SelectedSource;
