import React from 'react';

import PropTypes from 'prop-types';

function DesignRegistry({ width, height }) {
  return (
    <svg width={width} height={height} viewBox="0 0 130 130" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1_3320_734" fill="white">
        <rect x="13.2651" y="56.0312" width="30" height="30" rx="2" />
      </mask>
      <rect x="13.2651" y="56.0312" width="30" height="30" rx="2" stroke="white" strokeWidth="12" mask="url(#path-1-inside-1_3320_734)" />
      <mask id="path-2-inside-2_3320_734" fill="white">
        <rect x="13.2651" y="23.0312" width="30" height="30" rx="2" />
      </mask>
      <rect x="13.2651" y="23.0312" width="30" height="30" rx="2" stroke="white" strokeWidth="12" mask="url(#path-2-inside-2_3320_734)" />
      <mask id="path-3-inside-3_3320_734" fill="white">
        <rect x="46.2651" y="56.0312" width="30" height="30" rx="2" />
      </mask>
      <rect x="46.2651" y="56.0312" width="30" height="30" rx="2" stroke="white" strokeWidth="12" mask="url(#path-3-inside-3_3320_734)" />
      <mask id="path-4-inside-4_3320_734" fill="white">
        <rect x="46.2651" y="89.0312" width="30" height="30" rx="2" />
      </mask>
      <rect x="46.2651" y="89.0312" width="30" height="30" rx="2" stroke="white" strokeWidth="12" mask="url(#path-4-inside-4_3320_734)" />
      <mask id="path-5-inside-5_3320_734" fill="white">
        <rect x="79.2651" y="89.0312" width="30" height="30" rx="2" />
      </mask>
      <rect x="79.2651" y="89.0312" width="30" height="30" rx="2" stroke="white" strokeWidth="12" mask="url(#path-5-inside-5_3320_734)" />
      <mask id="path-6-inside-6_3320_734" fill="white">
        <rect x="102.479" y="41.0312" width="30" height="30" rx="2" transform="rotate(45 102.479 41.0312)" />
      </mask>
      <rect x="102.479" y="41.0312" width="30" height="30" rx="2" transform="rotate(45 102.479 41.0312)" stroke="white" strokeWidth="12" mask="url(#path-6-inside-6_3320_734)" />
      <mask id="path-7-inside-7_3320_734" fill="white">
        <rect x="73.4785" y="6.03125" width="30" height="30" rx="2" transform="rotate(45 73.4785 6.03125)" />
      </mask>
      <rect x="73.4785" y="6.03125" width="30" height="30" rx="2" transform="rotate(45 73.4785 6.03125)" stroke="white" strokeWidth="12" mask="url(#path-7-inside-7_3320_734)" />
      <mask id="path-8-inside-8_3320_734" fill="white">
        <rect x="13.2651" y="89.0312" width="30" height="30" rx="2" />
      </mask>
      <rect x="13.2651" y="89.0312" width="30" height="30" rx="2" stroke="white" strokeWidth="12" mask="url(#path-8-inside-8_3320_734)" />
    </svg>
  );
}

DesignRegistry.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

DesignRegistry.defaultProps = {
  width: 130,
  height: 130,
};

export default DesignRegistry;
