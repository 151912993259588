import { combineReducers } from 'redux';

import indexReducer from './reducer';
import changeSetReducer from './changeset/reducer';
import imactAnalysisReducer from './impactanalysis/reducer';

const reducers = combineReducers({
  index: indexReducer,
  changeSet: changeSetReducer,
  impactanalysis: imactAnalysisReducer,
});

export default reducers;
