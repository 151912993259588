import { connect } from 'react-redux';

import Billing from './Billing';
import { getBilling, clearBilling } from '../../../store/admin/billing/actions';

const mapStateToProps = (state) => ({
  loading: state?.admin?.billing?.loading,
  data: state?.admin?.billing?.data,
});

const mapDispatchToProps = (dispatch) => ({
  getBilling: (period) => dispatch(getBilling(period)),
  clearBilling: () => dispatch(clearBilling()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Billing);
