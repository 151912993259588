import { BLUE_ONE } from '../../../colors';

export const options = (selectedData) => ({
  chart: {
    type: 'bar',
    height: '400px',
    toolbar: {
      show: false,
      tools: {
        download: false,
        selection: false,
        zoom: false,
        zoomin: false,
        zoomout: false,
        pan: false,
        reset: false,
      },
    },
    selection: {
      enabled: false,
    },
  },
  series: [{ name: 'Redactions', data: Object.keys(selectedData).map((key) => selectedData[key]) }],
  labels: Object.keys(selectedData).map((key) => key),
  colors: [BLUE_ONE],
  legend: {
    show: false,
  },
  dataLabels: {
    enabled: false,
  },
});

export const formatOptions = (currentTotals, orgInfo) => {
  const totals = currentTotals;
  (orgInfo?.activity?.sources || []).forEach((_source) => {
    const name = _source?.name;
    if (!totals[name]) {
      totals[name] = {};
    }
    (_source?.categories || []).forEach((_category) => {
      _category.entities.forEach((_ent) => {
        if (!totals[name]?.[_ent.name]) {
          totals[name][_ent.name] = _ent.count;
        } else {
          totals[name][_ent.name] += _ent.count;
        }
      });
    });
  });
  return totals;
};

export const graphOptions = (data, selectedBot) => {
  const redactionData = data?.redactionActivityReports || [];
  let totals = {};
  redactionData.forEach((_dat) => {
    const orgInfo = _dat?.orgs?.[0] || {};
    totals = formatOptions(totals, orgInfo);
  });
  const selectedData = totals?.[selectedBot] || {};
  return options(selectedData);
};

export const test = () => {};
