import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { ROUTE_OPERATIONAL_INSIGHTS_DASHBOARD } from '../../../../Routes/routes';

function Sources({
  sources,
}) {
  const navigate = useNavigate();
  const viewEvents = (resourceName) => navigate(`/${ROUTE_OPERATIONAL_INSIGHTS_DASHBOARD}/source/${resourceName}`);
  return (
    <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column' }} data-testid="Sources-Container">
      <Typography variant="h6" color="text.secondary">
        Queue Sources
      </Typography>
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        {(sources || []).map((_source) => (
          <Card key={_source?.resourceName} sx={{ mr: 2, mt: 2 }}>
            <CardContent>
              <Box sx={{ display: 'flex' }}>
                <Typography sx={{ mr: 1 }} color="primary.main">
                  Source:
                </Typography>
                <Typography color="text.secondary">
                  {_source?.resourceName}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex' }}>
                <Typography sx={{ mr: 1 }} color="primary.main">
                  Missed Input:
                </Typography>
                <Typography color="text.secondary">
                  {_source?.missedInput}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex' }}>
                <Typography sx={{ mr: 1 }} color="primary.main">
                  Classified:
                </Typography>
                <Typography color="text.secondary">
                  {_source?.classified}
                </Typography>
              </Box>
              <Box sx={{ mt: 2 }}>
                <Button size="small" variant="outlined" sx={{ width: 180 }} onClick={() => viewEvents(_source?.resourceName)}>View Events</Button>
              </Box>
            </CardContent>
          </Card>
        ))}
      </Box>
    </Box>
  );
}

Sources.propTypes = {
  sources: PropTypes.array,
};

Sources.defaultProps = {
  sources: [],
};

export default Sources;
