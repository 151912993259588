import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';

import Info from './Info';

const formatSummary = (summary) => {
  const summaryDetails = [];
  if (summary?.markup) {
    summaryDetails.push({
      title: 'Markup:',
      value: summary?.markup,
    });
  }
  if (summary?.pushBotToStack) {
    summaryDetails.push({
      title: 'Push Bot to Stack:',
      value: summary?.pushBotToStack,
    });
  }
  if (summary?.flow) {
    summaryDetails.push({
      title: 'Flow:',
      value: summary?.flow,
    });
  }
  if (summary?.goal) {
    summaryDetails.push({
      title: 'Goal:',
      value: summary?.goal,
    });
  }
  if (summary?.message) {
    summaryDetails.push({
      title: 'Message:',
      value: summary?.message,
    });
  }
  if (summary?.hostnotification) {
    summaryDetails.push({
      title: 'Host Notification:',
      value: summary?.hostnotification,
    });
  }
  if (summary?.messageVariance) {
    summaryDetails.push({
      title: 'Message Variance:',
      value: summary?.messageVariance,
    });
  }
  if (summary?.content) {
    summaryDetails.push({
      title: 'Content:',
      value: summary?.content,
    });
  }
  if (summary?.setContext) {
    summaryDetails.push({
      title: 'Set Content:',
      value: summary?.setContext,
    });
  }
  return summaryDetails;
};

const formatTimelineElements = (_timeline) => Object.keys(_timeline).map((key) => ({
  title: key,
  value: _timeline[key],
}));

function IntentStats({
  data,
}) {
  return (
    <Box
      data-testid="IntentStats-Container"
      sx={{ display: 'flex', flexWrap: 'wrap' }}
    >
      <Card sx={{ mr: 2, mb: 2, minWidth: 400 }}>
        <CardHeader
          title="Summary"
          titleTypographyProps={{ color: 'primary.main' }}
          subheader="Nodes triggered by Intents"
        />
        <CardContent>
          {formatSummary(data?.summary || []).map((_summary) => (
            <Info
              summary={_summary}
              key={_summary.title}
            />
          ))}
        </CardContent>
      </Card>
      <Card sx={{ mr: 2, mb: 2, minWidth: 400 }}>
        <CardHeader
          title="Timeline Distribution"
          titleTypographyProps={{ color: 'primary.main' }}
          subheader="Timeline elements produced by intents"
        />
        <CardContent>
          {formatTimelineElements(data?.TimelineElementDistro || []).map((_summary) => (
            <Info
              summary={_summary}
              key={_summary.title}
            />
          ))}
        </CardContent>
      </Card>
    </Box>
  );
}

IntentStats.propTypes = {
  data: PropTypes.object,
};

IntentStats.defaultProps = {
  data: {},
};

export default IntentStats;
