import {
  ADMIN_TREATMENT_GET,
  ADMIN_TREATMENT_GET_SUCCESS,
  ADMIN_TREATMENT_GET_FAIL,
  ADMIN_TREATMENT_CLEAR,
} from './types';

export const initialState = {
  loading: false,
  data: {},
};

const reducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case ADMIN_TREATMENT_GET: {
      return {
        ...state,
        loading: true,
        data: {},
      };
    }
    case ADMIN_TREATMENT_GET_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        loading: false,
        data,
      };
    }
    case ADMIN_TREATMENT_GET_FAIL: {
      return { ...state, loading: false, data: {} };
    }
    case ADMIN_TREATMENT_CLEAR: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default reducer;
