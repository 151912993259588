import {
  BLUE_ONE,
  BLUE_TWO,
  BLUE_THREE,
  BLUE_FIVE,
} from '../../../colors';

const pieChartOptions = () => {
  const options = {
    series: [95, 2],
    chart: {
      width: 300,
      type: 'pie',
    },
    labels: ['Good Intents', 'Bad Intents'],
    colors: [BLUE_ONE, BLUE_TWO, BLUE_THREE, BLUE_FIVE],
    legend: {
      show: true,
      position: 'bottom',
    },
    dataLabels: {
      enabled: false,
    },
  };
  return options;
};

export default pieChartOptions;
