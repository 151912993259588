import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';

import ViewPage from '../../../Components/ViewPage';

import {
  ROUTE_OPERATIONAL_INSIGHTS,
  ROUTE_OPERATIONAL_INSIGHTS_TREATMENT,
} from '../../../Routes/routes';

const BREADCRUMBS = [{
  title: 'Inbox',
  route: `/${ROUTE_OPERATIONAL_INSIGHTS}`,
}, {
  title: 'Treatment',
  route: `/${ROUTE_OPERATIONAL_INSIGHTS_TREATMENT}`,
}];

const COLUMNS = ['Name', 'Description', 'Sources', ''];

const searchSource = (_source, term) => _source?.resourceName?.toLowerCase()?.includes(term);

const filterTreatments = (searchterm, queues) => {
  try {
    if (!searchterm) {
      return queues;
    }
    const term = searchterm.toLowerCase();
    return queues.filter((queue) => queue?.name?.toLowerCase().includes(term)
      || queue?.description?.toLowerCase()?.includes(term)
      || queue?.sourcesFilter?.sources?.find((_source) => searchSource(_source, term)));
  } catch (_err) {
    return [];
  }
};

function Treatment({
  loading,
  toggleDrawer,
  listTreatments,
  queues,
  clearTreatments,
}) {
  const navigate = useNavigate();
  const [search, setSearch] = useState('');

  useEffect(() => {
    listTreatments();
    return () => {
      clearTreatments();
    };
  }, []);

  const refreshTreatments = () => {
    listTreatments();
  };
  return (
    <ViewPage
      title="Treatment"
      toggleDrawer={toggleDrawer}
      breadcrumbs={BREADCRUMBS}
      loading={loading}
    >
      <Box
        data-testid="Treatment-View-Container"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          mt: 2,
          width: '100%',
          mb: 2,
        }}
      >
        <Card sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
          <CardHeader
            title="Queues"
            titleTypographyProps={{ color: 'primary.main' }}
            subheader="Subscribed Queues"
            action={(
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  autoComplete="off"
                  size="small"
                  placeholder="Search"
                  id="Search-Field"
                  sx={{ mr: 2 }}
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <Tooltip title="Refresh Queues" placement="top">
                  <IconButton aria-label="Refresh Queues" onClick={refreshTreatments}>
                    <RefreshIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          />
          <CardContent sx={{ display: 'flex', flex: 1, mr: 1 }}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    {COLUMNS.map((_col) => <TableCell key={_col}>{_col}</TableCell>)}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filterTreatments(search, queues).map((row) => (
                    <TableRow key={`${row.id}${row.name}`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.description}</TableCell>
                      <TableCell>
                        <Stack direction="row" spacing={1}>
                          {(row?.sourcesFilter?.sources || []).map((_source) => (
                            <Chip
                              key={_source.resourceName}
                              label={_source.resourceName}
                            />
                          ))}
                        </Stack>
                      </TableCell>
                      <TableCell align="right">
                        <Tooltip title="View Inference Events" placement="top">
                          <IconButton aria-label="View" onClick={() => navigate(`/${ROUTE_OPERATIONAL_INSIGHTS_TREATMENT}/inference-events/${row.id}`)}>
                            <MoreHorizIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </Box>
    </ViewPage>
  );
}

Treatment.propTypes = {
  toggleDrawer: PropTypes.func,
  loading: PropTypes.bool,
  listTreatments: PropTypes.func,
  queues: PropTypes.array,
  clearTreatments: PropTypes.func,
};

Treatment.defaultProps = {
  toggleDrawer: () => {
    // Default
  },
  loading: false,
  listTreatments: () => {
    // Default
  },
  queues: [],
  clearTreatments: () => {
    // Default
  },
};

export default Treatment;
