import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import getHighlightedText from './getHighlightedText';

function GeneralInfo({
  data,
}) {
  const sampleTopics = data.slice(0, 10);
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }} data-testid="GeneralInfo-Container">
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {sampleTopics.map((_topicData) => {
          const { unigram, bigrams } = _topicData;
          return _topicData.utterances.slice(0, 1).map((_utterance) => (
            <Typography variant="subtitle1" component="div" color="text.secondary" key={_utterance}>
              {getHighlightedText(_utterance, unigram, bigrams)}
            </Typography>
          ));
        })}
      </Box>
    </Box>
  );
}

GeneralInfo.propTypes = {
  data: PropTypes.array,
};

GeneralInfo.defaultProps = {
  data: [],
};

export default GeneralInfo;
