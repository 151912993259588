import moment from 'moment';
import i18next from 'i18next';

const formatEvents = (data) => data?.inferenceEvents || [];

const util = {
  // Add client recommendation so we can sort based on recommendation
  appendStatus: (events) => {
    const getStatus = (data) => {
      const d = { ...data };
      d.clientRecommendation = 'Awaiting Review';
      if (data?.reviewDetails?.type === 'NOTHING_SIMILAR') {
        d.clientRecommendation = 'No Recommendation';
      }
      if (data?.reviewDetails?.type === 'REFINE_TOPIC') {
        d.clientRecommendation = 'Refine Topic';
      }
      return d;
    };
    return events.map(getStatus);
  },
  formatData: (data) => {
    const events = formatEvents(data);
    const missed = events?.filter((_event) => _event?.outcome?.toLowerCase() === 'missedinput');
    const classified = events?.filter((_event) => _event?.outcome?.toLowerCase() === 'classified');
    const sorted = events.sort((a, b) => a?.messageIngressTimestamp > b?.messageIngressTimestamp);
    const oldest = sorted?.[0]?.messageIngressTimestamp;
    const latest = sorted?.[sorted.length - 1]?.messageIngressTimestamp;
    const items = [{
      title: 'Missed Input',
      data: Number.isInteger(missed?.length) ? missed?.length : 'N/A',
    }, {
      title: 'Classified',
      data: Number.isInteger(classified?.length) ? classified?.length : 'N/A',
    }, {
      title: 'Oldest Event',
      data: oldest ? moment(oldest).format(i18next.t('moment.standard')) : 'N/A',
    }, {
      title: 'Latest Event',
      data: latest ? moment(latest).format(i18next.t('moment.standard')) : 'N/A',
    }];
    return items;
  },
};

export default util;
