import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import getHighlightedText from './getHighlightedText';

function TopicInfo({
  selectedTopic,
  data,
}) {
  const topicData = data.find((_dat) => _dat.text === selectedTopic);
  const sampleUtterances = (topicData?.utterances || []).slice(0, 10);
  const { unigram, bigrams } = topicData;
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }} data-testid="TopicInfo-Container">
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {sampleUtterances.map((_utterance) => (
          <Typography variant="subtitle1" component="div" color="text.secondary" key={_utterance}>
            {getHighlightedText(_utterance, unigram, bigrams)}
          </Typography>
        ))}
      </Box>
    </Box>
  );
}

TopicInfo.propTypes = {
  selectedTopic: PropTypes.string,
  data: PropTypes.array,
};

TopicInfo.defaultProps = {
  selectedTopic: '',
  data: [],
};

export default TopicInfo;
