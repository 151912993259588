import sorting from '../../util/sorting';

export const filterAndSort = (searchterm, array, sortOptions) => {
  let filtered = [];
  try {
    if (!searchterm) {
      filtered = array;
    }
    const term = searchterm.toLowerCase();
    filtered = array.filter((_changeset) => _changeset?.name?.toLowerCase().includes(term));
  } catch (_err) {
    filtered = [];
  }
  return sorting.sortByOptions(filtered, sortOptions);
};

export const applyFilter = (arr, filter) => {
  if (filter === 'awaiting') {
    return arr.filter((_item) => _item.applied === undefined || _item.applied === null);
  }
  if (filter === 'applied') {
    return arr.filter((_item) => !!_item.applied);
  }
  return arr;
};

export const getCount = (search, filter, filteredAndSorted, data) => (search || filter
  ? filteredAndSorted.length
  : data.length);

export const combineData = (changeSets = [], botDesigns = []) => {
  const combined = changeSets.map((_changeSet) => {
    const design = botDesigns.find((_design) => _design.id === _changeSet.botDesignId);
    return {
      ..._changeSet,
      botName: design?.name,
    };
  });
  return combined;
};
