import { connect } from 'react-redux';

import Compliance from './Compliance';
import { listChangeSets, clearChangeSets } from '../../store/compliance/actions';

const mapStateToProps = (state) => ({
  loading: state?.compliance?.index?.loading,
  data: state?.compliance?.index?.data,
  botDesigns: state?.compliance?.index?.botDesigns,
});

const mapDispatchToProps = (dispatch) => ({
  listChangeSets: () => dispatch(listChangeSets()),
  clearChangeSets: () => dispatch(clearChangeSets()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Compliance);
