import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import ApexCharts from 'apexcharts';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import ViewPage from '../../../Components/ViewPage';
import HeaderDetails from '../../../Components/HeaderDetails';
import OrgDetails from './OrgDetails';
import graphOptions, { pieChartOptions } from './graphOptions';
import {
  getTotalsHeader,
  getSubHeader,
  formatData,
  sortOrgs,
} from './util';
import breadcrumbs from './breadcrumbs';

let chart;
let pieChart;

function Treatment({
  loading,
  toggleDrawer,
  getTreatment,
  clearTreatment,
  data,
}) {
  const { t } = useTranslation();
  const chartRef = useRef(null);
  const chartSlectionRef = useRef(null);
  const pieChartRef = useRef(null);
  const [timePeriod, setTimePeriod] = useState('DAY');
  const [selectedOrg, setSelectedOrg] = useState();

  useEffect(() => {
    getTreatment(timePeriod);
    return () => {
      clearTreatment();
    };
  }, [timePeriod]);

  useEffect(() => {
    setSelectedOrg(sortOrgs(data?.orgValues || [])?.[0]?.name);
  }, [data]);

  useEffect(() => {
    if (chartRef.current) {
      chart = new ApexCharts(chartRef.current, graphOptions(data));
      chart.render();
    }
    if (pieChartRef.current) {
      pieChart = new ApexCharts(pieChartRef.current, pieChartOptions());
      pieChart.render();
    }
  }, [chartRef, chartSlectionRef, data]);

  useEffect(() => {
    if (chart) {
      chart.updateOptions(graphOptions(data, selectedOrg));
    }
  }, [chartRef, data, selectedOrg]);

  useEffect(() => {
    if (pieChart) {
      pieChart.updateOptions(pieChartOptions());
    }
  }, [data]);

  return (
    <ViewPage
      title={t('Admin.treatmentTitle')}
      toggleDrawer={toggleDrawer}
      breadcrumbs={breadcrumbs(t)}
      loading={loading}
    >
      <Box
        data-testid="Treatment-View-Container"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          mt: 2,
          width: '100%',
          mb: 2,
        }}
      >
        <Box sx={{ display: 'flex' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              mt: 1,
            }}
          >
            <Typography variant="h6" color="text.secondary" sx={{ display: 'flex', flex: 1 }}>
              {getTotalsHeader(timePeriod)}
            </Typography>
            <HeaderDetails data={formatData()} mt={-1} />
            <OrgDetails data={data} setSelectedOrg={setSelectedOrg} />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            <FormControl size="small" sx={{ width: 220 }}>
              <InputLabel id="time-period">Period</InputLabel>
              <Select
                labelId="time-period"
                id="time-period"
                label="Selected Queue"
                value={timePeriod}
                onChange={(e) => setTimePeriod(e.target.value)}
              >
                <MenuItem value="DAY">Day</MenuItem>
                <MenuItem value="WEEK">Week</MenuItem>
                <MenuItem value="MONTH">Month</MenuItem>
              </Select>
            </FormControl>
            <Card
              sx={{
                mt: 1,
                p: 1,
                display: 'flex',
                flexDirection: 'column',
                minWidth: 280,
              }}
            >
              <Typography variant="h6" color="text.secondary">
                Treatment Insights
              </Typography>
              <Box ref={pieChartRef} />
            </Card>
          </Box>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Card sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
            <CardHeader
              title={`Source Details for ${selectedOrg}`}
              titleTypographyProps={{ color: 'primary.main' }}
              subheader={getSubHeader(timePeriod)}
            />
            <CardContent sx={{ display: 'flex', flex: 1, mr: 1 }}>
              <Box ref={chartRef} sx={{ width: '100%', height: 400 }} />
            </CardContent>
          </Card>
        </Box>
      </Box>
    </ViewPage>
  );
}

Treatment.propTypes = {
  loading: PropTypes.bool,
  toggleDrawer: PropTypes.func,
  getTreatment: PropTypes.func,
  clearTreatment: PropTypes.func,
  data: PropTypes.object,
};

Treatment.defaultProps = {
  loading: false,
  toggleDrawer: () => {
    // Default
  },
  getTreatment: () => {
    // Default
  },
  clearTreatment: () => {
    // Default
  },
  data: {},
};

export default Treatment;
