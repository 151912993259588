import { showAlert } from '../../state/actions';
import userUtils from '../../../util/user';
import isUnAuth from '../../../util/unauth';
import interceptor, { getInboxUrl } from '../../../util/interceptor';
import { logoutUser } from '../../auth/actions';

import {
  DASHBOARD_FETCH_TOTALS,
  DASHBOARD_FETCH_TOTALS_SUCCESS,
  DASHBOARD_FETCH_TOTALS_FAIL,
  DASHBOARD_CLEAR_TOTALS,
} from './types';

export const fetchTotalsAttempt = () => ({
  type: DASHBOARD_FETCH_TOTALS,
});

export const fetchTotalsSuccess = (data, queues) => ({
  type: DASHBOARD_FETCH_TOTALS_SUCCESS,
  payload: { data, queues },
});

export const fetchTotalsFail = () => ({
  type: DASHBOARD_FETCH_TOTALS_FAIL,
});

const getMetrics = async (region, tenant, token, queueId) => {
  const res = await interceptor({
    method: 'post',
    url: `${getInboxUrl(region)}/${tenant}/GetDashboardMetrics`,
    data: { queueId },
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  const { data } = res;
  return data;
};

const getUserQueues = async (region, tenant, token) => {
  const res = await interceptor({
    method: 'post',
    url: `${getInboxUrl(region)}/${tenant}/ListUserQueues`,
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  const { data } = res;
  const { queues } = data;
  return queues;
};

export const fetchTotals = (queueId) => async (dispatch, getState) => {
  const ERROR_MESSAGE = 'Failed to load data, please try again.';
  dispatch(fetchTotalsAttempt());
  try {
    const state = getState();
    const { authToken, authenticatedUser } = state.auth;
    const tenant = userUtils.getTenant(authenticatedUser);
    const region = userUtils.getRegion(authenticatedUser);
    const queues = await getUserQueues(region, tenant, authToken);
    let metrics;
    if (queueId) {
      metrics = await getMetrics(region, tenant, authToken, queueId);
    }
    dispatch(fetchTotalsSuccess(metrics, queues));
  } catch (_err) {
    const status = _err?.response?.status;
    if (isUnAuth(status)) {
      dispatch(logoutUser({ location: window.location.href }));
    } else {
      dispatch(fetchTotalsFail());
      dispatch(showAlert({ type: 'error', message: ERROR_MESSAGE }));
    }
  }
};

export const clearTotals = () => ({
  type: DASHBOARD_CLEAR_TOTALS,
});
