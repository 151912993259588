import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import ViewPage from '../../../Components/ViewPage';
import util from './util';

const getErrorMessage = (reason, error) => {
  let message = 'Session Expired';
  if (reason && reason === 'LOGOUT') {
    message = 'You have been logged out';
  } else if (error) {
    message = 'An Error has Occured';
  }
  return message;
};

function Redirect({
  reason,
  location,
  errorMessage,
}) {
  let navigationURL;
  const [redirectCount, setRedirectCount] = useState(10);
  if (reason === 'LOGOUT' || !location) {
    // We don't want to come back
    navigationURL = util.consoleUrl(errorMessage);
  } else {
    // We want to come back to tools console
    navigationURL = util.consoleUrlWithLocation(location, errorMessage);
  }
  useEffect(() => {
    // Navigate to Pages-Console
    setTimeout(() => {
      if (navigationURL) {
        window.location.href = navigationURL;
      }
    }, 2000);
  });
  useEffect(() => {
    setTimeout(() => {
      if (redirectCount > 0) {
        setRedirectCount(redirectCount - 1);
      }
    }, 1000);
  });
  return (
    <ViewPage title="Console" fullWidth displayAppBar={false}>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          m: 4,
          justifyContent: 'center',
        }}
        data-testid="Redirect-View-Container"
      >
        <Card sx={{ flexDirection: 'column' }}>
          <CardHeader
            title={getErrorMessage(reason, errorMessage)}
            titleTypographyProps={{ color: 'primary.main' }}
            subheader="Redirecting, please wait."
          />
          <CardContent>
            <Box
              sx={{
                display: 'flex',
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </Box>
            <Box sx={{ mt: 4, minWidth: 460 }}>
              <Typography>
                Click the link below if you are not redirected within
                {' '}
                {redirectCount}
                {' '}
                seconds.
              </Typography>
              <Link href={navigationURL} underline="always">
                Here
              </Link>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </ViewPage>
  );
}

Redirect.propTypes = {
  reason: PropTypes.string,
  location: PropTypes.string,
  errorMessage: PropTypes.string,
};

Redirect.defaultProps = {
  reason: '',
  location: '',
  errorMessage: '',
};

export default Redirect;
