import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Chip from '@mui/material/Chip';
import Checkbox from '@mui/material/Checkbox';

import RefreshIcon from '@mui/icons-material/Refresh';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SearchIcon from '@mui/icons-material/Search';

import ViewPage from '../../Components/ViewPage';
import Create from './Create';
import SortableColumn, { DESC } from '../../Components/Table/SortableColumn';
import Actions from './Actions';

import COLUMNS from './columns';
import {
  filterAndSort,
  applyFilter,
  getCount,
  combineData,
} from './util';

const FILTERS = [{
  title: 'All',
  value: 'all',
}, {
  title: 'Awaiting Approval',
  value: 'awaiting',
}, {
  title: 'Approved',
  value: 'applied',
}];

function Compliance({
  toggleDrawer,
  loading,
  data,
  listChangeSets,
  clearChangeSets,
  botDesigns,
}) {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState({
    title: 'Created',
    sortKey: 'created',
    direction: DESC,
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showCreate, setShowCreate] = useState(false);
  const [filter, setFilter] = useState('all');
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    listChangeSets();
    return () => {
      clearChangeSets();
    };
  }, []);

  const refreshChangeSets = () => {
    listChangeSets();
  };

  const selectRow = (id) => {
    setSelectedRows(() => [id]);
  };

  const deselectRow = (id) => {
    setSelectedRows((sel) => sel.filter((_sel) => _sel !== id));
  };

  const filteredAndSorted = filterAndSort(
    search,
    applyFilter(combineData(data, botDesigns), filter),
    sort,
  );
  const rowsToDisplay = rowsPerPage > 0
    ? filteredAndSorted.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : filteredAndSorted;

  return (
    <ViewPage title={t('Views.complianceTitle')} toggleDrawer={toggleDrawer} loading={loading}>
      <Box
        data-testid="Compliance-View-Container"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          mt: 2,
          width: '100%',
          mb: 2,
        }}
      >
        <Card sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
          <CardHeader
            title="Change Sets"
            titleTypographyProps={{ color: 'primary.main' }}
            subheader="Current Change Sets"
            action={(
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {FILTERS.map((_filter) => (
                  <Chip
                    key={_filter?.title}
                    sx={{ mr: 1 }}
                    label={_filter?.title}
                    onClick={() => {
                      setPage(0);
                      setFilter(_filter?.value);
                    }}
                    variant={filter === _filter?.value ? 'filled' : 'outlined'}
                  />
                ))}
                <TextField
                  autoComplete="off"
                  size="small"
                  placeholder="Search"
                  id="Search-Field"
                  sx={{ mr: 2 }}
                  onChange={(e) => {
                    setPage(0);
                    setSearch(e.target.value);
                  }}
                  value={search}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <Tooltip title="Refresh Change Sets" placement="top">
                  <IconButton aria-label="Refresh Change Sets" onClick={refreshChangeSets}>
                    <RefreshIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Add new Change Set" placement="top" onClick={() => setShowCreate(true)}>
                  <IconButton aria-label="Add Change Set">
                    <AddCircleOutlineIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          />
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              mr: 1,
              pt: 0,
            }}
          >
            <TableContainer sx={{ maxHeight: 'calc(100vh - 280px)' }}>
              <Table stickyHeader size="small">
                <TableHead>
                  <TableRow>
                    {COLUMNS.slice(0, -1).map((_col) => (
                      <SortableColumn
                        key={_col?.title}
                        column={_col}
                        sort={sort}
                        setSort={setSort}
                      />
                    ))}
                    <TableCell align="right">
                      <Actions selectedRows={selectedRows} data={rowsToDisplay} />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsToDisplay.map((row) => (
                    <TableRow key={`${row.id}${row.name}`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row?.botName}</TableCell>
                      <TableCell>{row.botDesignId}</TableCell>
                      <TableCell>{row?.applied && moment(row?.applied).format(t('moment.standard'))}</TableCell>
                      <TableCell>{moment(row?.created).format(t('moment.standard'))}</TableCell>
                      <TableCell align="right">
                        <Checkbox
                          data-testid={`RowCheckBox-${row.id}`}
                          onClick={selectedRows.includes(row.id)
                            ? () => deselectRow(row.id)
                            : () => selectRow(row.id)}
                          checked={selectedRows.includes(row.id)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[3, 10, 20, 50, { label: 'All', value: -1 }]}
              colSpan={COLUMNS.length}
              count={getCount(search, filter, filteredAndSorted, data)}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={(e, _page) => setPage(_page)}
              onRowsPerPageChange={(e) => {
                setRowsPerPage(parseInt(e.target.value, 10));
                setPage(0);
              }}
            />
          </CardContent>
        </Card>
      </Box>
      {showCreate && <Create onClose={() => setShowCreate(false)} />}
    </ViewPage>
  );
}

Compliance.propTypes = {
  toggleDrawer: PropTypes.func,
  loading: PropTypes.bool,
  data: PropTypes.array,
  listChangeSets: PropTypes.func,
  clearChangeSets: PropTypes.func,
  botDesigns: PropTypes.array,
};

Compliance.defaultProps = {
  toggleDrawer: () => {
    // Default
  },
  loading: false,
  data: [],
  listChangeSets: () => {
    // Default
  },
  clearChangeSets: () => {
    // Default
  },
  botDesigns: [],
};

export default Compliance;
