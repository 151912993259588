const REGEX_COMMANDS = '/^.{0,10}$/';
const REGEX_LONG_TEXT = '/^.{100,}$/';
const REGEX_INCLUDES_NUMBERS = '/\\d/g';
const REGEX_SPECIAL_CHARACTERS = '/[^A-Za-z 0-9]/';
const REGEX_COMPUND_INTENTS = '/(.*)(\\sand\\s|\\sor\\s|\\.).{1,}/';

const options = [{
  title: 'Commands',
  pattern: REGEX_COMMANDS,
}, {
  title: 'Long text',
  pattern: REGEX_LONG_TEXT,
}, {
  title: 'Text containing numbers',
  pattern: REGEX_INCLUDES_NUMBERS,
}, {
  title: 'Text containing special characters',
  pattern: REGEX_SPECIAL_CHARACTERS,
}, {
  title: 'Compound intents',
  pattern: REGEX_COMPUND_INTENTS,
}];

export default options;
