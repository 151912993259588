import React from 'react';

import PropTypes from 'prop-types';

function Discussion({ width, height }) {
  return (
    <svg width={width} height={height} viewBox="0 0 130 129" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="85.0303" cy="69.5" r="13.5" stroke="white" strokeWidth="6" />
      <path d="M64.9837 89C54.5744 89 53.8572 106.805 54.8635 114.659C54.926 115.147 55.3455 115.5 55.8371 115.5H115.437C116.008 115.5 116.463 115.032 116.433 114.462C116.015 106.512 114.395 89 105.984 89C92.0303 89 95.5303 97 85.9839 97C75.9714 97 82.0303 89 64.9837 89Z" stroke="white" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round" />
      <circle cx="40.7104" cy="72.54" r="9.54" stroke="white" strokeWidth="6" />
      <path fillRule="evenodd" clipRule="evenodd" d="M56.2282 84.3627C53.4931 84.4 51.3764 84.8157 49.6433 85.5446C47.7954 86.3218 46.5945 87.3696 45.6905 88.2463C45.466 88.464 45.2756 88.6523 45.1087 88.8173C44.5477 89.3721 44.2529 89.6638 43.8199 89.9273C43.422 90.1694 42.7851 90.44 41.435 90.44C39.9596 90.44 39.4539 90.1544 39.2362 90.0068C38.9458 89.81 38.8077 89.6509 38.4972 89.2933C38.306 89.0732 38.0496 88.778 37.6522 88.3524C36.724 87.3585 35.4784 86.2794 33.4241 85.5035C31.458 84.7609 28.923 84.36 25.4748 84.36C22.5405 84.36 20.2361 85.6519 18.5905 87.568C17.0255 89.3904 16.084 91.7276 15.5041 93.9907C14.3435 98.5208 14.3871 103.739 14.7799 107.017C15.0297 109.102 16.8087 110.5 18.7283 110.5H43.269C42.7858 108.734 42.5303 106.892 42.5303 105C42.5303 104.833 42.5323 104.666 42.5362 104.5H20.5831C20.4348 101.816 20.5734 98.3797 21.3164 95.4799C21.7785 93.6763 22.4134 92.3258 23.1423 91.4772C23.7905 90.7224 24.4974 90.36 25.4748 90.36C28.5043 90.36 30.2521 90.7191 31.304 91.1165C32.2679 91.4805 32.7758 91.9215 33.267 92.4475C33.3376 92.5231 33.4305 92.6302 33.543 92.7598L33.543 92.7598C34.0304 93.3215 34.8842 94.3053 35.8694 94.9732C37.3333 95.9656 39.1057 96.44 41.435 96.44C42.5922 96.44 43.6136 96.3136 44.532 96.0715C46.832 91.1555 50.975 87.0432 56.2282 84.3627Z" fill="white" />
      <path d="M54.3239 47.0892L56.9752 48.4928L54.3239 47.0892L54.2042 47.3152C53.7862 48.1047 52.6734 48.1534 52.1881 47.4034L51.8798 46.9269L49.3611 48.5567L51.8798 46.9269C50.4972 44.7902 48.1256 43.5 45.5806 43.5H31.0303C29.9257 43.5 29.0303 42.6046 29.0303 41.5V27.5C29.0303 26.3954 29.9257 25.5 31.0303 25.5H59.0303C60.1348 25.5 61.0303 26.3954 61.0303 27.5V42.7549C61.0303 43.1664 60.6967 43.5 60.2851 43.5C57.7867 43.5 55.4929 44.8811 54.3239 47.0892Z" stroke="white" strokeWidth="6" />
      <path d="M111.53 20C111.53 18.8954 110.635 18 109.53 18H73.5303C72.4257 18 71.5303 18.8954 71.5303 20V40.018C71.5303 40.8577 72.211 41.5385 73.0507 41.5385C75.8548 41.5385 78.426 43.0985 79.721 45.5856L79.9003 45.93C80.6115 47.2958 82.5348 47.3793 83.3615 46.0801L83.8942 45.2432C85.3627 42.9358 87.9082 41.5385 90.6433 41.5385H109.53C110.635 41.5385 111.53 40.643 111.53 39.5385V20Z" stroke="white" strokeWidth="6" />
    </svg>
  );
}

Discussion.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

Discussion.defaultProps = {
  width: 130,
  height: 129,
};

export default Discussion;
