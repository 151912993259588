import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

function Create({
  onClose,
  loading,
  create,
  botDesigns,
}) {
  const [changeSetName, setChangeSetName] = useState('');
  const [selectedName, setSelectedName] = useState('');
  const [selectedVersion, setSelectedVersion] = useState('');
  const onCreate = async () => {
    const found = botDesigns
      .find((bd) => bd.name === selectedName && bd.version === selectedVersion);
    if (changeSetName && found) {
      await create({
        name: changeSetName,
        botDesignId: found.id,
      });
      onClose();
    }
  };

  const availableNames = [...new Set(botDesigns.map((bd) => bd.name))];
  const availableVersions = botDesigns
    .filter((bd) => bd.name === selectedName)
    .map((bd) => bd.version);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open
      onClose={onClose}
    >
      <DialogTitle>
        Create Change Set
      </DialogTitle>
      <DialogContent>
        <TextField
          autoComplete="off"
          fullWidth
          id="Changeset-Name"
          label="Name"
          variant="outlined"
          margin="normal"
          value={changeSetName}
          onChange={(e) => setChangeSetName(e.target.value)}
        />
        <Autocomplete
          fullWidth
          id="BotDesign-Id"
          label="Select A Bot Design"
          options={availableNames}
          value={selectedName}
          onChange={(e, val) => setSelectedName(val)}
          renderInput={(params) => (
            <TextField
              {...params}
              autoComplete="off"
              fullWidth
              margin="normal"
              label="Select A Bot Design"
              placeholder="Select A Bot Design"
            />
          )}
        />
        {selectedName && (
          <Autocomplete
            fullWidth
            id="BotDesign-Version"
            label="Select A Version"
            options={availableVersions}
            value={selectedVersion}
            onChange={(e, val) => setSelectedVersion(val)}
            renderInput={(params) => (
              <TextField
                {...params}
                autoComplete="off"
                fullWidth
                margin="normal"
                label="Select A Version"
                placeholder="Select A Version"
              />
            )}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Box sx={{ m: 2 }}>
          <Button
            onClick={onClose}
            disabled={loading}
            sx={{ mr: 2, width: 100 }}
          >
            Cancel
          </Button>
          <Button
            sx={{ width: 100 }}
            onClick={onCreate}
            variant="contained"
            disabled={loading}
          >
            {loading ? 'Creating...' : 'Create'}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

Create.propTypes = {
  onClose: PropTypes.func,
  loading: PropTypes.bool,
  create: PropTypes.func,
  botDesigns: PropTypes.array,
};

Create.defaultProps = {
  onClose: () => {
    // Default
  },
  loading: false,
  create: () => {
    // Default
  },
  botDesigns: [],
};

export default Create;
