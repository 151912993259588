import {
  DESIGN_REGISTRY_GET_BOT_REPORT,
  DESIGN_REGISTRY_GET_BOT_REPORT_SUCCESS,
  DESIGN_REGISTRY_GET_BOT_REPORT_FAIL,
  DESIGN_REGISTRY_GET_BOT_REPORT_CLEAR,
} from './types';

export const initialState = {
  loading: false,
  data: {},
};

const reducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case DESIGN_REGISTRY_GET_BOT_REPORT: {
      return { ...state, loading: true, data: {} };
    }
    case DESIGN_REGISTRY_GET_BOT_REPORT_SUCCESS: {
      const { data } = action.payload;
      return { ...state, loading: false, data };
    }
    case DESIGN_REGISTRY_GET_BOT_REPORT_FAIL: {
      return { ...state, loading: false, data: {} };
    }
    case DESIGN_REGISTRY_GET_BOT_REPORT_CLEAR: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default reducer;
