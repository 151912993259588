import { connect } from 'react-redux';

import Event from './Event';
import { getInferenceEvent, clearInferenceEvent } from '../store/event/actions';

const mapStateToProps = (state) => ({
  loading: state?.inferenceEvents?.event?.loading,
  data: state?.inferenceEvents?.event?.data,
  region: state?.auth?.authenticatedUser?.['custom:sb_region'],
});

const mapDispatchToProps = (dispatch) => ({
  getInferenceEvent: (eventId) => dispatch(getInferenceEvent(eventId)),
  clearInferenceEvent: () => dispatch(clearInferenceEvent()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Event);
