import axios from 'axios';

export const getInboxUrl = (region) => {
  switch (region) {
    case 'us1':
    case 'us-1':
      return process.env.REACT_APP_US_INBOX_API_URL;
    case 'eu-1':
      return process.env.REACT_APP_EU_INBOX_API_URL;
    case 'eu-private-1':
      return process.env.REACT_APP_LOWER_INBOX_API_URL;
    default:
      return process.env.REACT_APP_INBOX_API_URL;
  }
};

export const getOutboxUrl = (region) => {
  switch (region) {
    case 'us1':
    case 'us-1':
      return process.env.REACT_APP_US_OUTBOX_API_URL;
    case 'eu-1':
      return process.env.REACT_APP_EU_OUTBOX_API_URL;
    case 'eu-private-1':
      return process.env.REACT_APP_LOWER_OUTBOX_API_URL;
    default:
      return process.env.REACT_APP_OUTBOX_API_URL;
  }
};

export const getSauronUrl = (region) => {
  switch (region) {
    case 'us1':
    case 'us-1':
      return process.env.REACT_APP_US_SAURON_API_URL;
    case 'eu-1':
      return process.env.REACT_APP_EU_SAURON_API_URL;
    case 'eu-private-1':
      return process.env.REACT_APP_LOWER_SAURON_API_URL;
    default:
      return process.env.REACT_APP_SAURON_API_URL;
  }
};

const interceptor = async (opts = {}) => {
  const res = await axios(opts);
  return res;
};

export default interceptor;
