import {
  BLUE_ONE,
  BLUE_TWO,
  BLUE_THREE,
  BLUE_FOUR,
  BLUE_FIVE,
} from '../../../../colors';

const chartOptions = {
  series: [{
    name: 'Treatment',
    data: [44, 55, 41, 37],
  }, {
    name: 'Pain',
    data: [0, 32, 33, 52],
  }, {
    name: 'Experience',
    data: [0, 17, 11, 9],
  }, {
    name: 'Office',
    data: [0, 7, 5, 8],
  }, {
    name: 'Doctor',
    data: [0, 12, 19, 32],
  }],
  grid: {
    show: false,
  },
  chart: {
    type: 'bar',
    height: 350,
    stacked: true,
    stackType: '100%',
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      horizontal: true,
    },
  },
  xaxis: {
    categories: ['30 mins ago', '20 mins ago', '10 mins ago', 'now'].reverse(),
    labels: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  fill: {
    opacity: 1,
  },
  legend: {
    show: false,
  },
  colors: [BLUE_ONE, BLUE_TWO, BLUE_THREE, BLUE_FOUR, BLUE_FIVE],
  tooltip: {
    y: {
      formatter: (_val) => _val,
    },
  },
};

export default chartOptions;
