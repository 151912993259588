import {
  COMPLIANCE_CHANGESET_GET_CHANGESET,
  COMPLIANCE_CHANGESET_GET_CHANGESET_SUCCESS,
  COMPLIANCE_CHANGESET_GET_CHANGESET_FAIL,
  COMPLIANCE_CHANGESET_GET_CHANGESET_CLEAR,
} from './types';
import userUtils from '../../../util/user';
import isUnAuth from '../../../util/unauth';
import interceptor, { getOutboxUrl } from '../../../util/interceptor';
import { logoutUser } from '../../auth/actions';
import { showAlert } from '../../state/actions';

export const getChangeSetAttempt = () => ({
  type: COMPLIANCE_CHANGESET_GET_CHANGESET,
});

export const getChangeSetSuccess = (data) => ({
  type: COMPLIANCE_CHANGESET_GET_CHANGESET_SUCCESS,
  payload: { data },
});

export const getChangeSetFail = () => ({
  type: COMPLIANCE_CHANGESET_GET_CHANGESET_FAIL,
});

export const getChangeSet = (changeSetId) => async (dispatch, getState) => {
  const ERROR_MESSAGE = 'Failed to load Change Set, please try again.';
  dispatch(getChangeSetAttempt());
  try {
    const state = getState();
    const { authToken, authenticatedUser } = state.auth;
    const tenant = userUtils.getTenant(authenticatedUser);
    const region = userUtils.getRegion(authenticatedUser);
    const res = await interceptor({
      method: 'post',
      url: `${getOutboxUrl(region)}/${tenant}/DescribeChangeset`,
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: { changeSetId },
    });
    const { data } = res;
    dispatch(getChangeSetSuccess(data));
  } catch (_err) {
    const status = _err?.response?.status;
    if (isUnAuth(status)) {
      dispatch(logoutUser({ location: window.location.href }));
    } else {
      dispatch(getChangeSetFail());
      dispatch(showAlert({ type: 'error', message: ERROR_MESSAGE }));
    }
  }
};

export const rejectChangeSet = (changeSetId) => async (dispatch, getState) => {
  const ERROR_MESSAGE = 'Failed to reject Change Set, please try again.';
  const SUCCESS_MESSAGE = 'Change Set rejected.';
  try {
    const state = getState();
    const { authToken, authenticatedUser } = state.auth;
    const tenant = userUtils.getTenant(authenticatedUser);
    const region = userUtils.getRegion(authenticatedUser);
    const res = await interceptor({
      method: 'post',
      url: `${getOutboxUrl(region)}/${tenant}/RejectChangeset`,
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: { changeSetId },
    });
    const { data } = res;
    dispatch(showAlert({ type: 'success', message: SUCCESS_MESSAGE }));
    return data;
  } catch (_err) {
    const status = _err?.response?.status;
    if (isUnAuth(status)) {
      dispatch(logoutUser({ location: window.location.href }));
    } else {
      dispatch(showAlert({ type: 'error', message: ERROR_MESSAGE }));
    }
    return false;
  }
};

export const approveChangeSet = (changeSetId) => async (dispatch, getState) => {
  const ERROR_MESSAGE = 'Failed to approve Change Set, please try again.';
  const SUCCESS_MESSAGE = 'Change Set approved.';
  try {
    const state = getState();
    const { authToken, authenticatedUser } = state.auth;
    const tenant = userUtils.getTenant(authenticatedUser);
    const region = userUtils.getRegion(authenticatedUser);
    const res = await interceptor({
      method: 'post',
      url: `${getOutboxUrl(region)}/${tenant}/ApproveChangeset`,
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: { changeSetId, treatmentType: 'servisbot' },
    });
    const { data } = res;
    dispatch(showAlert({ type: 'success', message: SUCCESS_MESSAGE }));
    return data;
  } catch (_err) {
    const status = _err?.response?.status;
    if (isUnAuth(status)) {
      dispatch(logoutUser({ location: window.location.href }));
    } else {
      dispatch(showAlert({ type: 'error', message: ERROR_MESSAGE }));
    }
    return false;
  }
};

export const clearChangeSet = () => ({
  type: COMPLIANCE_CHANGESET_GET_CHANGESET_CLEAR,
});
