import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';

import AddIcon from '@mui/icons-material/Add';
import RedoIcon from '@mui/icons-material/Redo';
import Checkbox from '@mui/material/Checkbox';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

import utils from './util';

const getStatus = (data) => {
  if (data?.reviewDetails?.type === 'NOTHING_SIMILAR') {
    return 'No Recommendation';
  }
  if (data?.reviewDetails?.type === 'REFINE_TOPIC') {
    return 'Refine Topic';
  }
  return 'Awaiting Review';
};

function Row({
  data,
  openTreatment,
  region,
  multiselect,
  selectRow,
  deselectRow,
  selectedRows,
}) {
  const openConversation = () => {
    utils.viewConversation(region, data?.conversationId);
  };
  return (
    <TableRow
      data-testid="Row-Container"
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell>{moment(data?.messageIngressTimestamp).fromNow()}</TableCell>
      <TableCell>{utils.highlightRedaction(data)}</TableCell>
      <TableCell>{data?.matchedIntent}</TableCell>
      <TableCell>
        {data?.outcome === 'Classified' ? (
          <Tooltip placement="top" title="Classified">
            <CheckCircleOutlineOutlinedIcon sx={{ color: 'green' }} />
          </Tooltip>
        ) : (
          <Tooltip placement="top" title="Missed">
            <CancelOutlinedIcon sx={{ color: 'red' }} />
          </Tooltip>
        )}
      </TableCell>
      <TableCell>{data?.confidenceScore > 0 ? data?.confidenceScore : 'N/A'}</TableCell>
      <TableCell><Chip sx={{ mr: 1 }} label={getStatus(data)} /></TableCell>
      <TableCell>
        {data?.properties?.treatmentUserId && data?.properties?.treatmentTTL > Date.now() ? (
          <Tooltip placement="top" title={`Assigned to ${data.properties.treatmentUserId}`}>
            <VisibilityOutlinedIcon sx={{ color: 'green' }} />
          </Tooltip>
        ) : (
          <Tooltip placement="top" title="Not assigned">
            <VisibilityOffOutlinedIcon sx={{ color: 'lightgrey' }} />
          </Tooltip>
        )}
      </TableCell>
      {!multiselect ? (
        <TableCell align="right">
          <Tooltip title="View Conversation" placement="top">
            <IconButton
              data-testid="ViewConversation-Button"
              aria-label="View"
              onClick={openConversation}
            >
              <RedoIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Add Treatment" placement="top">
            <IconButton aria-label="Treatment" onClick={() => openTreatment(data)}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      ) : (
        <TableCell align="right">
          <Checkbox
            data-testid={`RowCheckBox-${data.id}`}
            onClick={selectedRows.includes(data.id)
              ? () => deselectRow(data.id)
              : () => selectRow(data.id)}
            checked={selectedRows.includes(data.id)}
          />
        </TableCell>
      )}
    </TableRow>
  );
}

Row.propTypes = {
  data: PropTypes.object,
  openTreatment: PropTypes.func,
  region: PropTypes.string,
  multiselect: PropTypes.bool,
  selectRow: PropTypes.func,
  deselectRow: PropTypes.func,
  selectedRows: PropTypes.array,
};

Row.defaultProps = {
  data: {},
  openTreatment: () => {
    // Default
  },
  region: '',
  multiselect: false,
  selectRow: () => {
    // Default
  },
  deselectRow: () => {
    // Default
  },
  selectedRows: [],
};

export default Row;
