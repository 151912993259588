import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function Info({ summary }) {
  return (
    <Box sx={{ display: 'flex', mb: 1 }}>
      <Typography gutterBottom variant="body2" sx={{ mr: 1 }} color="text.secondary">
        {summary.title}
      </Typography>
      <Typography variant="body2">
        {summary?.value}
      </Typography>
    </Box>
  );
}

Info.propTypes = {
  summary: PropTypes.object,
};

Info.defaultProps = {
  summary: {},
};

export default Info;
