import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import ServisBot from './ServisBot';
import File from './File';

import BottyIcon from './Icons/Botty';
import FileIcon from './Icons/File';

const flowMap = [ServisBot, File];

const flows = [{
  title: 'ServisBot',
  icon: BottyIcon,
}, {
  title: 'Upload from file',
  icon: FileIcon,
}];

function Import({
  loading,
  onClose,
  importBotDesign,
  getSaasBots,
  importSassBot,
}) {
  const [flow, setFlow] = useState(null);
  const Flow = flowMap?.[flow];

  const onBack = () => {
    setFlow(null);
  };
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open
      onClose={onClose}
    >
      <DialogTitle color="primary.main">
        Import Bot Design
      </DialogTitle>
      {!Flow ? (
        <>
          <DialogContent>
            <DialogContentText>
              Generate a snapshot from an existing bot.
              {' '}
              <br />
              Please choose from one of the following sources.
            </DialogContentText>
            <Box sx={{ mt: 2, display: 'flex', height: 200 }}>
              {flows.map((_flow, index) => {
                const Icon = _flow.icon;
                return (
                  <Card sx={{ display: 'flex', flex: 1, mr: 2 }} key={_flow.title}>
                    <CardActionArea
                      data-testid={`Option-${_flow.title}`}
                      onClick={() => setFlow(index)}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Icon />
                      <Typography sx={{ mt: 2 }}>
                        {_flow.title}
                      </Typography>
                    </CardActionArea>
                  </Card>
                );
              })}
            </Box>
          </DialogContent>
          <DialogActions>
            <Box sx={{ mx: 2, mb: 2 }}>
              <Button
                onClick={onClose}
                disabled={loading}
                sx={{ width: 100 }}
              >
                Cancel
              </Button>
            </Box>
          </DialogActions>
        </>
      ) : (
        <Flow
          loading={loading}
          importBotDesign={importBotDesign}
          getSaasBots={getSaasBots}
          importSassBot={importSassBot}
          onClose={onClose}
          onBack={onBack}
        />
      )}
    </Dialog>
  );
}

Import.propTypes = {
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  importBotDesign: PropTypes.func,
  getSaasBots: PropTypes.func,
  importSassBot: PropTypes.func,
};

Import.defaultProps = {
  loading: false,
  onClose: () => {
    // Default
  },
  importBotDesign: () => {
    // Default
  },
  getSaasBots: () => {
    // Default
  },
  importSassBot: () => {
    // Default
  },
};

export default Import;
