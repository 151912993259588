import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Tooltip from '@mui/material/Tooltip';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function Queue({
  selectedQueue,
  onClose,
  loading,
  save,
  sources,
}) {
  const [queue, setQueue] = useState({ ...selectedQueue });
  const updateQueueValue = (key, value) => {
    const currentQueue = { ...queue };
    currentQueue[key] = value;
    setQueue(currentQueue);
  };
  const updateSourceValue = (value) => {
    setQueue((currentQueue) => ({
      ...currentQueue,
      sourcesFilter: { sources: [...value] },
    }));
  };
  const addUser = () => {
    setQueue((currentQueue) => ({
      ...currentQueue,
      permittedUsers: {
        users: [...currentQueue.permittedUsers.users, {
          userId: '',
        }],
      },
    }));
  };
  const removeUser = (val) => {
    setQueue((currentQueue) => ({
      ...currentQueue,
      permittedUsers: {
        users: currentQueue.permittedUsers.users.filter((_user) => _user.userId !== val),
      },
    }));
  };
  const updateUser = (index, val) => {
    setQueue((currentQueue) => {
      const currentUsers = currentQueue?.permittedUsers?.users || [];
      currentUsers[index] = {
        userId: val,
      };
      return {
        ...currentQueue,
        permittedUsers: {
          users: [...currentUsers],
        },
      };
    });
  };
  const onSave = async () => {
    await save(queue);
    onClose();
  };
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open
      onClose={onClose}
    >
      <DialogContent>
        <DialogContentText>
          Edit Queue details below
        </DialogContentText>
        <TextField
          autoComplete="off"
          fullWidth
          id="Queue-Name"
          label="Name"
          variant="outlined"
          margin="normal"
          value={queue?.name || ''}
          onChange={(e) => updateQueueValue('name', e.target.value)}
        />
        <TextField
          autoComplete="off"
          fullWidth
          id="Queue-Description"
          label="Description"
          variant="outlined"
          margin="normal"
          value={queue?.description || ''}
          onChange={(e) => updateQueueValue('description', e.target.value)}
        />
        <Autocomplete
          fullWidth
          multiple
          id="Queue-Sources"
          options={sources}
          disableCloseOnSelect
          getOptionLabel={(option) => option.resourceName}
          value={(queue?.sourcesFilter?.sources || [])
            .map((_source) => ({ resourceName: _source.resourceName }))}
          isOptionEqualToValue={(option, value) => option.resourceName === value.resourceName}
          onChange={(event, val) => updateSourceValue(val)}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.resourceName}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              autoComplete="off"
              fullWidth
              margin="normal"
              label="Source"
              placeholder="Source"
            />
          )}
        />
        <Box sx={{ display: 'flex', flex: 1, alignItems: 'center' }}>
          <DialogContentText>
            Permitted Users
          </DialogContentText>
          <Tooltip title="Add User" placement="top">
            <IconButton size="small" sx={{ ml: 2 }} onClick={() => addUser()} data-testid="AddUser-Button">
              <AddIcon />
            </IconButton>
          </Tooltip>
        </Box>
        {(queue?.permittedUsers?.users || []).map((_user, index) => (
          <Box sx={{ display: 'flex', flex: 1, alignItems: 'center' }}>
            <TextField
              autoComplete="off"
              fullWidth
              id="Assigned User"
              label="Email Address"
              variant="outlined"
              margin="normal"
              value={_user.userId}
              onChange={(e) => updateUser(index, e.target.value)}
            />
            <Tooltip title="Remove User" placement="top">
              <IconButton size="small" sx={{ ml: 2 }} onClick={() => removeUser(_user.userId)} data-testid="RemoveUser-Button">
                <RemoveIcon />
              </IconButton>
            </Tooltip>
          </Box>
        ))}
      </DialogContent>
      <DialogActions>
        <Box sx={{ m: 2 }}>
          <Button
            onClick={onClose}
            disabled={loading}
            sx={{ mr: 2, width: 100 }}
          >
            Cancel
          </Button>
          <Button
            sx={{ width: 100 }}
            onClick={onSave}
            variant="contained"
            disabled={loading}
            data-testid="Save-Button"
          >
            {loading ? 'Saving...' : 'Save'}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

Queue.propTypes = {
  selectedQueue: PropTypes.any,
  onClose: PropTypes.func,
  loading: PropTypes.bool,
  save: PropTypes.func,
  sources: PropTypes.array,
};

Queue.defaultProps = {
  selectedQueue: null,
  onClose: () => {
    // Default
  },
  loading: false,
  save: () => {
    // Default
  },
  sources: [],
};

export default Queue;
