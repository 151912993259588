import { combineReducers } from 'redux';

import dashboard from './dashboard/reducer';
import source from './dashboard/source/reducer';
import discussion from './discussion/reducer';
import treatment from './treatment/reducer';
import inferenceEvents from './treatment/inferenceEvents/reducer';
import inferenceEvent from './treatment/inferenceEvents/event/reducer';

const reducers = combineReducers({
  dashboard: combineReducers({
    index: dashboard,
    source,
  }),
  discussion,
  treatment: combineReducers({
    index: treatment,
    inferenceEvents: combineReducers({
      index: inferenceEvents,
      event: inferenceEvent,
    }),
  }),
});

export default reducers;
