import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import ApexCharts from 'apexcharts';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import ViewPage from '../../../Components/ViewPage';
import HeaderDetails from '../../../Components/HeaderDetails';
import Totals from './Totals';
import graphOptions, { selectedGraphOptions, pieChartOptions } from './graphOptions';
import breadcrumbs from './breadcrumbs';
import { formatData } from './util';

let chart;
let selectedChart;
let pieChart;

const PERIOD_WEEK = 'week';
const PERIOD_MONTH = 'month';

function Treatment({
  loading,
  toggleDrawer,
  getTreatment,
  clearTreatment,
  data,
  lang,
}) {
  const { t } = useTranslation();
  const chartRef = useRef(null);
  const chartSlectionRef = useRef(null);
  const pieChartRef = useRef(null);
  const [timePeriod, setTimePeriod] = useState(PERIOD_WEEK);
  const [chartSelected, setChartSelected] = useState(false);

  const selectChart = (selected) => {
    setChartSelected(selected);
  };

  useEffect(() => {
    getTreatment(timePeriod);
    return () => {
      clearTreatment();
    };
  }, [timePeriod]);

  useEffect(() => {
    if (chartRef.current) {
      chart = new ApexCharts(chartRef.current, graphOptions(data, selectChart));
      chart.render();
    }
    if (chartSlectionRef.current) {
      selectedChart = new ApexCharts(chartSlectionRef.current, selectedGraphOptions(chartSelected));
      selectedChart.render();
    }
    if (pieChartRef.current) {
      pieChart = new ApexCharts(pieChartRef.current, pieChartOptions());
      pieChart.render();
    }
  }, [chartRef, chartSlectionRef, data]);

  useEffect(() => {
    if (chart) {
      chart.updateOptions(graphOptions(data, selectChart));
    }
  }, [data, chartSelected, lang]);

  useEffect(() => {
    if (pieChart) {
      pieChart.updateOptions(pieChartOptions());
    }
  }, [data, chartSelected]);

  useEffect(() => {
    if (chartSlectionRef.current) {
      selectedChart.updateOptions(selectedGraphOptions(chartSelected));
    }
  }, [chartSlectionRef, chartSelected]);

  return (
    <ViewPage
      title={t('Analytics.treatmentTitle')}
      toggleDrawer={toggleDrawer}
      breadcrumbs={breadcrumbs(t)}
      loading={loading}
    >
      <Box
        data-testid="Treatment-View-Container"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          mt: 2,
          width: '100%',
          mb: 2,
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <HeaderDetails title="Last 24 hours" data={formatData(data)} />
          <Card
            sx={{
              p: 1,
              display: 'flex',
              flexDirection: 'column',
              minWidth: 280,
            }}
          >
            <Typography variant="h6" color="text.secondary">
              Treatment Insights
            </Typography>
            <Box ref={pieChartRef} />
          </Card>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Card sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
            <CardHeader
              title="Events"
              titleTypographyProps={{ color: 'primary.main' }}
              subheader={`Event details for the last ${timePeriod}`}
              action={(
                <Box sx={{ display: 'flex' }}>
                  <Totals data={data} chartSelected={chartSelected} />
                  <Box sx={{ width: 220 }}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="time-period">Period</InputLabel>
                      <Select
                        labelId="time-period"
                        id="time-period"
                        label="Time Period"
                        defaultValue={PERIOD_WEEK}
                        value={timePeriod}
                        onChange={(e) => {
                          setTimePeriod(e.target.value);
                          setChartSelected(false);
                        }}
                      >
                        <MenuItem value={PERIOD_WEEK}>Week</MenuItem>
                        <MenuItem value={PERIOD_MONTH}>Month</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
              )}
            />
            <CardContent sx={{ display: 'flex', flex: 1, mr: 1 }}>
              <Box ref={chartRef} sx={{ width: '100%', height: 400 }} />
              <Box ref={chartSlectionRef} sx={{ display: chartSelected ? 'block' : 'none', width: '100%', height: 400 }} />
            </CardContent>
          </Card>
        </Box>
      </Box>
    </ViewPage>
  );
}

Treatment.propTypes = {
  loading: PropTypes.bool,
  toggleDrawer: PropTypes.func,
  getTreatment: PropTypes.func,
  clearTreatment: PropTypes.func,
  data: PropTypes.object,
  lang: PropTypes.string,
};

Treatment.defaultProps = {
  loading: false,
  toggleDrawer: () => {
    // Default
  },
  getTreatment: () => {
    // Default
  },
  clearTreatment: () => {
    // Default
  },
  data: {},
  lang: '',
};

export default Treatment;
