import React from 'react';

import PropTypes from 'prop-types';

function Billing({ width, height }) {
  return (
    <svg width={width} height={height} viewBox="0 0 130 129" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="30.5303" y="24" width="70" height="85" rx="4" stroke="white" strokeWidth="6" />
      <path d="M46.5303 39H81.5303" stroke="white" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M46.5303 54.5H81.5303" stroke="white" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M46.5303 69.5H81.5303" stroke="white" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M50.9134 100.182V78.3636H52.3112V100.182H50.9134ZM54.9447 85.3438C54.8651 84.5994 54.5299 84.0199 53.939 83.6051C53.3538 83.1903 52.5924 82.983 51.6549 82.983C50.9958 82.983 50.4305 83.0824 49.9589 83.2812C49.4873 83.4801 49.1265 83.75 48.8765 84.0909C48.6265 84.4318 48.4987 84.821 48.493 85.2585C48.493 85.6222 48.5754 85.9375 48.7401 86.2045C48.9106 86.4716 49.1407 86.6989 49.4305 86.8864C49.7203 87.0682 50.0413 87.2216 50.3936 87.3466C50.7458 87.4716 51.1009 87.5767 51.4589 87.6619L53.0953 88.071C53.7544 88.2244 54.3879 88.4318 54.9958 88.6932C55.6095 88.9545 56.1578 89.2841 56.6407 89.6818C57.1294 90.0795 57.5157 90.5597 57.7998 91.1222C58.0839 91.6847 58.2259 92.3437 58.2259 93.0994C58.2259 94.1222 57.9646 95.0227 57.4419 95.8011C56.9191 96.5739 56.1634 97.179 55.1748 97.6165C54.1919 98.0483 53.0015 98.2642 51.6038 98.2642C50.2458 98.2642 49.0669 98.054 48.0669 97.6335C47.0725 97.2131 46.2941 96.5994 45.7316 95.7926C45.1748 94.9858 44.8737 94.0028 44.8282 92.8438H47.939C47.9845 93.4517 48.172 93.9574 48.5015 94.3608C48.8311 94.7642 49.26 95.0653 49.7884 95.2642C50.3225 95.4631 50.9191 95.5625 51.5782 95.5625C52.2657 95.5625 52.868 95.4602 53.385 95.2557C53.9078 95.0455 54.3169 94.7557 54.6123 94.3864C54.9078 94.0114 55.0583 93.5739 55.064 93.0739C55.0583 92.6193 54.9248 92.2443 54.6634 91.9489C54.4021 91.6477 54.0356 91.3977 53.564 91.1989C53.0981 90.9943 52.5526 90.8125 51.9276 90.6534L49.9419 90.142C48.5044 89.7727 47.368 89.2131 46.5328 88.4631C45.7032 87.7074 45.2884 86.7045 45.2884 85.4545C45.2884 84.4261 45.5669 83.5256 46.1237 82.7528C46.6862 81.9801 47.4504 81.3807 48.4163 80.9545C49.3822 80.5227 50.4759 80.3068 51.6975 80.3068C52.9362 80.3068 54.0214 80.5227 54.9532 80.9545C55.8907 81.3807 56.6265 81.9744 57.1606 82.7358C57.6947 83.4915 57.9703 84.3608 57.9873 85.3438H54.9447Z" fill="white" />
      <path d="M67.5303 86L76.3232 94.7929C76.7137 95.1834 77.3469 95.1834 77.7374 94.7929L90.0303 82.5" stroke="white" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

Billing.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

Billing.defaultProps = {
  width: 130,
  height: 129,
};

export default Billing;
