import {
  ADMIN_USERS_LIST,
  ADMIN_USERS_LIST_SUCCESS,
  ADMIN_USERS_LIST_FAIL,
  ADMIN_USER_EDIT,
  ADMIN_USER_EDIT_SUCCESS,
  ADMIN_USER_EDIT_FAIL,
  ADMIN_USER_CREATE,
  ADMIN_USER_CREATE_SUCCESS,
  ADMIN_USER_CREATE_FAIL,
  ADMIN_USERS_CLEAR,
} from './types';
import { showAlert } from '../../state/actions';

// eslint-disable-next-line
const delay = (ms) => new Promise((res) => setTimeout(res, ms));

const listUsersAttempt = () => ({ type: ADMIN_USERS_LIST });
const listUsersSuccess = (users) => ({ type: ADMIN_USERS_LIST_SUCCESS, payload: { users } });
const listUsersFail = () => ({ type: ADMIN_USERS_LIST_FAIL });

const createData = (email, firstName, lastName, role, queues) => ({
  email,
  firstName,
  lastName,
  role,
  queues,
});

const users = [
  createData('sampleone@sample.com', 'Sample', 'One', 'Admin', ['First Queue', 'Second Queue', 'Third Queue']),
  createData('sampletwo@sample.com', 'Sample', 'Two', 'Admin', ['First Queue', 'Second Queue', 'Third Queue']),
  createData('samplethree@sample.com', 'Sample', 'Three', 'Reviewer', ['First Queue', 'Second Queue', 'Third Queue']),
  createData('samplefour@sample.com', 'Sample', 'Four', 'Reviewer', ['First Queue', 'Second Queue', 'Third Queue']),
  createData('samplefive@sample.com', 'Sample', 'Five', 'Reviewer', ['First Queue', 'Second Queue', 'Third Queue']),
  createData('samplesix@sample.com', 'Sample', 'Six', 'Reviewer', ['First Queue', 'Second Queue', 'Third Queue']),
];

export const listUsers = () => async (dispatch) => {
  const ERROR_MESSAGE = 'Failed to load users, please try again.';
  dispatch(listUsersAttempt());
  try {
    await delay(1000);
    dispatch(listUsersSuccess(users));
  } catch (_err) {
    dispatch(showAlert({ type: 'error', message: ERROR_MESSAGE }));
    dispatch(listUsersFail());
  }
};

const updateUserAttempt = () => ({ type: ADMIN_USER_EDIT });
const updateUserSuccess = (user) => ({ type: ADMIN_USER_EDIT_SUCCESS, payload: { user } });
const updateUserFail = () => ({ type: ADMIN_USER_EDIT_FAIL });

export const updateUser = (user) => async (dispatch) => {
  const ERROR_MESSAGE = 'Failed to update user, please try again.';
  const SUCCESS_MESSAGE = 'User updated.';
  dispatch(updateUserAttempt());
  try {
    await delay(1000);
    dispatch(updateUserSuccess(user));
    dispatch(showAlert({ type: 'success', message: SUCCESS_MESSAGE }));
  } catch (_err) {
    dispatch(showAlert({ type: 'error', message: ERROR_MESSAGE }));
    dispatch(updateUserFail());
  }
};

const createUserAttempt = () => ({ type: ADMIN_USER_CREATE });
const createUserSuccess = (user) => ({ type: ADMIN_USER_CREATE_SUCCESS, payload: { user } });
const createUserFail = () => ({ type: ADMIN_USER_CREATE_FAIL });

export const createUser = (user) => async (dispatch) => {
  const ERROR_MESSAGE = 'Failed to update user, please try again.';
  const SUCCESS_MESSAGE = 'User created.';
  dispatch(createUserAttempt());
  try {
    await delay(1000);
    dispatch(createUserSuccess(user));
    dispatch(showAlert({ type: 'success', message: SUCCESS_MESSAGE }));
  } catch (_err) {
    dispatch(showAlert({ type: 'error', message: ERROR_MESSAGE }));
    dispatch(createUserFail());
  }
};

export const clearUsers = () => ({ type: ADMIN_USERS_CLEAR });
