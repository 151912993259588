import React from 'react';
import PropTypes from 'prop-types';

function Learning({ width, height }) {
  return (
    <svg width={width} height={height} viewBox="0 0 129 129" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M69.0315 102.974C68.5983 101.593 68.2354 98.507 68.2354 98.507C68.2354 98.507 67.5653 95.7992 67.1207 94.2431C66.2068 91.0443 63.0285 88.7743 60.0436 87.7233C58.6289 87.2251 57.0671 87.2102 55.585 87.2102C52.4647 87.2102 48.5616 87.5819 45.5531 86.6971C42.0236 85.659 40.0507 82.7584 40.0507 79.0626C40.0507 76.4279 39.5001 72.8744 41.3245 70.685C43.4321 68.1559 47.0067 67.8312 50 67" stroke="white" strokeWidth="6" strokeLinecap="round" />
      <path d="M38.4581 72.879C37.1834 73.0579 35.8411 73.3567 34.5569 73.3567C31.2808 73.3567 29.5 70 28.2317 67.5003C27.3383 65.7397 26.9932 63.0297 26.9932 61.3344C26.9932 60.048 27.6743 57.6853 28.7536 56.8758C31.0605 55.1456 33.5721 53.293 36.2288 53.293" stroke="white" strokeWidth="6" strokeLinecap="round" />
      <path d="M37.1846 55.8408C37.1846 48.0491 38.624 40.4096 46.7033 36.9271C49.1137 35.8882 51.2073 35.2111 53.8335 35.1401C58.2042 35.022 68.0763 37.2168 68.0763 43.2611" stroke="white" strokeWidth="6" strokeLinecap="round" />
      <path d="M67.4395 44.535C69.2874 39.9694 74.4158 37.3694 79.1433 37.3694C80.9538 37.3694 83.1095 37.8893 84.7077 38.8025C92.8411 43.4502 88.0225 52.372 86.07 58.2293" stroke="white" strokeWidth="6" strokeLinecap="round" />
      <path d="M90.2101 52.019C102 56.4999 100.115 64.0734 97.9773 69.0573C95 75.9999 86.0593 77.065 81.4521 73.9936" stroke="white" strokeWidth="6" strokeLinecap="round" />
      <path d="M79.7167 65C84 68 83.5206 71.3159 81.4349 73.6941C79.3008 76.1276 75.8337 76.6447 73 75.1992" stroke="white" strokeWidth="6" strokeLinecap="round" />
      <path d="M66.165 66.3503C69.5 63.4999 69.1639 58.8711 67.2 56.7961C65.5 55 60.978 55.1586 58.0439 57.1146" stroke="white" strokeWidth="6" strokeLinecap="round" />
    </svg>
  );
}

Learning.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

Learning.defaultProps = {
  width: 129,
  height: 129,
};

export default Learning;
