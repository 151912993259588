import {
  COMPLIANCE_INDEX_LIST_CHANGESETS,
  COMPLIANCE_INDEX_LIST_CHANGESETS_SUCCESS,
  COMPLIANCE_INDEX_LIST_CHANGESETS_FAIL,
  COMPLIANCE_INDEX_CREATE_CHANGESET,
  COMPLIANCE_INDEX_CREATE_CHANGESET_SUCCESS,
  COMPLIANCE_INDEX_CREATE_CHANGESET_FAIL,
  COMPLIANCE_INDEX_LIST_CHANGESETS_CLEAR,
} from './types';

export const initialState = {
  loading: false,
  data: [],
  creating: false,
  botDesigns: [],
};

const reducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case COMPLIANCE_INDEX_LIST_CHANGESETS: {
      return {
        ...state,
        loading: true,
        data: [],
        creating: false,
        botDesigns: [],
      };
    }
    case COMPLIANCE_INDEX_LIST_CHANGESETS_SUCCESS: {
      const { data, botDesigns } = action.payload;
      return {
        ...state,
        loading: false,
        data,
        creating: false,
        botDesigns,
      };
    }
    case COMPLIANCE_INDEX_LIST_CHANGESETS_FAIL: {
      return {
        ...state,
        loading: false,
        data: [],
        creating: false,
        botDesigns: [],
      };
    }
    case COMPLIANCE_INDEX_CREATE_CHANGESET: {
      return {
        ...state,
        creating: true,
      };
    }
    case COMPLIANCE_INDEX_CREATE_CHANGESET_SUCCESS: {
      const { data } = action.payload;
      const { data: currentDesigns } = state;
      currentDesigns.push(data);
      return {
        ...state,
        creating: false,
        data: currentDesigns,
      };
    }
    case COMPLIANCE_INDEX_CREATE_CHANGESET_FAIL: {
      return {
        ...state,
        creating: false,
      };
    }
    case COMPLIANCE_INDEX_LIST_CHANGESETS_CLEAR: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default reducer;
