import {
  BLUE_ONE,
  BLUE_TWO,
  BLUE_THREE,
  BLUE_FIVE,
} from '../../../colors';

const SERIES_NAMES = ['Job Requests', 'Classification Reviews', 'Recommended Treatments', 'Auto Applies'];

const bar = (data) => {
  const series = SERIES_NAMES.map((name) => {
    const seriesData = (data?.values || []).map((_val) => {
      if (name === 'Job Requests') {
        return _val.requests;
      }
      if (name === 'Classification Reviews') {
        return _val.reviews;
      }
      if (name === 'Recommended Treatments') {
        return _val.treatments;
      }
      return _val.applies;
    });
    return {
      name,
      data: seriesData,
    };
  });
  return {
    chart: {
      stacked: true,
      type: 'bar',
      height: '300px',
      toolbar: {
        show: false,
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
      },
    },
    series,
    labels: (data?.values || []).map((_dat) => _dat.period),
    colors: [BLUE_ONE, BLUE_TWO, BLUE_THREE, BLUE_FIVE],
    tooltip: {
      y: {
        formatter: (_val) => `${_val} ${data?.denomination}`,
      },
    },
    dataLabels: {
      enabled: false,
    },
  };
};

const line = (data) => ({
  chart: {
    type: 'line',
    height: '300px',
    toolbar: {
      show: false,
      tools: {
        download: false,
        selection: false,
        zoom: false,
        zoomin: false,
        zoomout: false,
        pan: false,
        reset: false,
      },
    },
  },
  series: [{
    name: 'Cost',
    data: (data?.values || []).map((_dat) => _dat.value),
  }],
  labels: (data?.values || []).map((_dat) => _dat.period),
  colors: [BLUE_ONE],
  tooltip: {
    y: {
      formatter: (_val) => `${_val} ${data?.denomination}`,
    },
  },
});

const graphOptions = (data, type) => {
  if (type === 'LINE') {
    return line(data);
  }
  return bar(data);
};

export default graphOptions;
