import React from 'react';

import PropTypes from 'prop-types';

function Inbox({ width, height }) {
  return (
    <svg width={width} height={height} viewBox="0 0 130 130" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="18.2651" y="22.0312" width="89" height="89" rx="7" stroke="white" strokeWidth="6" />
      <path d="M21.2651 83.0312H42.0151L62.7651 96.5312L83.5151 83.0312H104.265V108.031H21.2651V83.0312Z" fill="white" />
      <path d="M65.2651 37.0313C65.2651 35.3744 63.922 34.0313 62.2651 34.0312C60.6083 34.0312 59.2651 35.3744 59.2651 37.0312L65.2651 37.0313ZM60.1438 84.1526C61.3154 85.3241 63.2149 85.3241 64.3865 84.1526L83.4783 65.0607C84.6499 63.8891 84.6499 61.9896 83.4783 60.818C82.3068 59.6465 80.4073 59.6465 79.2357 60.818L62.2651 77.7886L45.2946 60.818C44.123 59.6465 42.2235 59.6465 41.0519 60.818C39.8804 61.9896 39.8804 63.8891 41.0519 65.0607L60.1438 84.1526ZM59.2651 37.0312L59.2651 82.0312L65.2651 82.0313L65.2651 37.0313L59.2651 37.0312Z" fill="white" />
    </svg>
  );
}

Inbox.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

Inbox.defaultProps = {
  width: 130,
  height: 130,
};

export default Inbox;
