import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import ViewPage from '../../../Components/ViewPage';

import {
  ROUTE_OPERATIONAL_INSIGHTS,
  ROUTE_OPERATIONAL_INSIGHTS_UNSUPERISED_JOBS,
} from '../../../Routes/routes';

const BREADCRUMBS = [{
  title: 'Inbox',
  route: `/${ROUTE_OPERATIONAL_INSIGHTS}`,
}, {
  title: 'Unsupervised Jobs',
  route: `/${ROUTE_OPERATIONAL_INSIGHTS_UNSUPERISED_JOBS}`,
}];

function UnsupervisedJobs({
  toggleDrawer,
}) {
  return (
    <ViewPage title="Unsupervised Jobs" toggleDrawer={toggleDrawer} breadcrumbs={BREADCRUMBS}>
      <Box data-testid="UnsupervisedJobs-View-Container">
        <h1>Unsupervised Jobs</h1>
      </Box>
    </ViewPage>
  );
}

UnsupervisedJobs.propTypes = {
  toggleDrawer: PropTypes.func,
};

UnsupervisedJobs.defaultProps = {
  toggleDrawer: () => {
    // Default
  },
};

export default UnsupervisedJobs;
