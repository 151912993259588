import { connect } from 'react-redux';

import Create from './Create';
import { createQueue } from '../../../../store/admin/queues/actions';

const mapStateToProps = (state) => ({
  loading: state?.admin?.queues?.creatingQueue,
  sources: state?.admin?.queues?.sources,
});

const mapDispatchToProps = (dispatch) => ({
  create: (queue) => dispatch(createQueue(queue)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Create);
