import {
  COMPLIANCE_INDEX_LIST_CHANGESETS,
  COMPLIANCE_INDEX_LIST_CHANGESETS_SUCCESS,
  COMPLIANCE_INDEX_LIST_CHANGESETS_FAIL,
  COMPLIANCE_INDEX_CREATE_CHANGESET,
  COMPLIANCE_INDEX_CREATE_CHANGESET_SUCCESS,
  COMPLIANCE_INDEX_CREATE_CHANGESET_FAIL,
  COMPLIANCE_INDEX_LIST_CHANGESETS_CLEAR,
} from './types';
import userUtils from '../../util/user';
import isUnAuth from '../../util/unauth';
import interceptor, { getInboxUrl, getOutboxUrl } from '../../util/interceptor';
import { logoutUser } from '../auth/actions';
import { showAlert } from '../state/actions';

export const listChangeSetsAttempt = () => ({
  type: COMPLIANCE_INDEX_LIST_CHANGESETS,
});

export const listChangeSetsSuccess = (data, botDesigns) => ({
  type: COMPLIANCE_INDEX_LIST_CHANGESETS_SUCCESS,
  payload: { data, botDesigns },
});

export const listChangeSetsFail = () => ({
  type: COMPLIANCE_INDEX_LIST_CHANGESETS_FAIL,
});

export const listChangeSets = () => async (dispatch, getState) => {
  const ERROR_MESSAGE = 'Failed to load data, please try again.';
  dispatch(listChangeSetsAttempt());
  try {
    const state = getState();
    const { authToken, authenticatedUser } = state.auth;
    const tenant = userUtils.getTenant(authenticatedUser);
    const region = userUtils.getRegion(authenticatedUser);
    const changeSetRes = await interceptor({
      method: 'post',
      url: `${getOutboxUrl(region)}/${tenant}/ListChangesets`,
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: {
        properties: {},
      },
    });
    const { data: changeSetData } = changeSetRes;
    const { changeSets } = changeSetData;
    const botDesignRes = await interceptor({
      method: 'post',
      url: `${getInboxUrl(region)}/${tenant}/ListBotDesigns`,
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    });
    const { data: botDesignData } = botDesignRes;
    const { botDesigns } = botDesignData;
    dispatch(listChangeSetsSuccess(changeSets, botDesigns));
  } catch (_err) {
    const status = _err?.response?.status;
    if (isUnAuth(status)) {
      dispatch(logoutUser({ location: window.location.href }));
    } else {
      dispatch(listChangeSetsFail());
      dispatch(showAlert({ type: 'error', message: ERROR_MESSAGE }));
    }
  }
};

export const createChangeSetAttempt = () => ({
  type: COMPLIANCE_INDEX_CREATE_CHANGESET,
});

export const createChangeSetSuccess = (data) => ({
  type: COMPLIANCE_INDEX_CREATE_CHANGESET_SUCCESS,
  payload: { data },
});

export const createChangeSetFail = () => ({
  type: COMPLIANCE_INDEX_CREATE_CHANGESET_FAIL,
});

export const createChangeSet = (changeSet) => async (dispatch, getState) => {
  const ERROR_MESSAGE = 'Failed to create Change Set, please try again.';
  const SUCCESS_MESSAGE = 'Change Set Created.';
  dispatch(createChangeSetAttempt());
  try {
    const state = getState();
    const { authToken, authenticatedUser } = state.auth;
    const tenant = userUtils.getTenant(authenticatedUser);
    const region = userUtils.getRegion(authenticatedUser);
    const changeSetRes = await interceptor({
      method: 'post',
      url: `${getOutboxUrl(region)}/${tenant}/OpenChangeset`,
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: changeSet,
    });
    const { data } = changeSetRes;
    if (data?.message === 'No treatments to process') {
      dispatch(createChangeSetFail());
      dispatch(showAlert({ type: 'error', message: 'No treatments to process' }));
    } else {
      dispatch(showAlert({ type: 'success', message: SUCCESS_MESSAGE }));
      dispatch(createChangeSetSuccess(data));
    }
  } catch (_err) {
    const status = _err?.response?.status;
    if (isUnAuth(status)) {
      dispatch(logoutUser({ location: window.location.href }));
    } else {
      dispatch(createChangeSetFail());
      dispatch(showAlert({ type: 'error', message: ERROR_MESSAGE }));
    }
  }
};

export const clearChangeSets = () => ({
  type: COMPLIANCE_INDEX_LIST_CHANGESETS_CLEAR,
});
