import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import i18next from 'i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { TEXT_TERTIARY } from '../../../../theme';

const getRecommendation = (data) => {
  if (data?.reviewDetails?.type === 'NOTHING_SIMILAR') {
    return (
      <Typography color="text.secondary">
        Intent was not matched. Create new intent with utterance.
      </Typography>
    );
  }
  if (data?.reviewDetails?.type === 'REFINE_TOPIC') {
    const distance = data?.reviewDetails?.properties?.distance || 0;
    const convertedDistance = (1 - distance) * 100;
    return (
      <Box>
        <Typography sx={{ mr: 1 }} color="text.secondary" gutterBottom>
          Add utterance to existing intent.
        </Typography>
        <Typography variant="body1" sx={{ mr: 2 }} color="primary.main">
          Nearest Utterance:
        </Typography>
        <Typography color="text.secondary">
          <span style={{ color: TEXT_TERTIARY }}>
            {data?.reviewDetails?.properties?.closestUtterance}
          </span>
          {' '}
          with
          {' '}
          {`${Math.round(convertedDistance)}%`}
          {' '}
          match
        </Typography>
      </Box>
    );
  }
  return (
    <Typography color="text.secondary">
      This event is currently awaiting review. No recommendation is currently available.
    </Typography>
  );
};

function Recommendation({ data }) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', maxWidth: 400 }} data-testid="Recommendation-Container">
      <Typography variant="h5" color="text.secondary" gutterBottom>
        Review Details
      </Typography>
      <Box sx={{ display: 'flex' }}>
        <Typography sx={{ mr: 1 }} color="primary.main">
          Bot Design ID:
        </Typography>
        <Typography color="text.secondary">
          {data?.reviewDetails?.botDesignId}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex' }}>
        <Typography sx={{ mr: 1 }} color="primary.main">
          Created:
        </Typography>
        <Typography color="text.secondary">
          {moment(data?.reviewDetails?.created).format(i18next.t('moment.standard'))}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2 }}>
        <Typography sx={{ mr: 1 }} color="primary.main">
          Recommendation:
        </Typography>
        {getRecommendation(data)}
      </Box>
    </Box>
  );
}

Recommendation.propTypes = {
  data: PropTypes.object,
};

Recommendation.defaultProps = {
  data: {},
};

export default Recommendation;
