// Inject our own custom auth module into the sb-sdk
const sbAuth = (discovery, token) => {
  const {
    lithiumHttpApiUrl,
    organization,
    sbRegion,
    brocaApiUrl,
    paperTrailApiUrl,
  } = discovery;
  // Return required functions sb-sdk expects to see when interacting with the auth object
  return {
    getLithiumHttpApiUrl: () => lithiumHttpApiUrl,
    getOrganization: () => organization,
    getCognitoJwtToken: () => token,
    getRegion: () => sbRegion,
    getBrocaUrl: () => brocaApiUrl,
    getPaperTrailUrl: () => paperTrailApiUrl,
  };
};

export default sbAuth;
