import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ApexCharts from 'apexcharts';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import ViewPage from '../../../Components/ViewPage';
import HeaderDetails from '../../../Components/HeaderDetails';

import graphOptions from './graphOptions';
import breadcrumbs from './breadcrumbs';

const formatData = (data) => [{
  title: 'Total cost to date',
  data: `${data?.total?.value} ${data?.denomination}`,
}, {
  title: 'Current cost for month',
  data: `${data?.currentMonth?.value} ${data?.denomination}`,
}];

let chart;

const getSubTitle = (period) => {
  const map = {
    YEAR: 'the last 12 months',
    '3MONTHS': 'the last 3 months',
    '6MONTHS': 'the last 6 months',
  };
  return map[period];
};

function Billing({
  loading,
  toggleDrawer,
  data,
  getBilling,
  clearBilling,
}) {
  const { t } = useTranslation();
  const chartRef = useRef(null);
  const [type, setType] = useState('LINE');
  const [timePeriod, setTimePeriod] = useState('YEAR');
  useEffect(() => {
    getBilling(timePeriod);
    return () => {
      clearBilling();
    };
  }, [timePeriod]);

  useEffect(() => {
    if (chartRef.current) {
      chart = new ApexCharts(chartRef.current, graphOptions(data, type));
      chart.render();
    }
  }, [chartRef, data, type]);

  useEffect(() => {
    if (chart) {
      chart.updateOptions(graphOptions(data, type));
    }
  }, [data, type]);
  return (
    <ViewPage
      title={t('Admin.billingTitle')}
      toggleDrawer={toggleDrawer}
      breadcrumbs={breadcrumbs(t)}
      loading={loading}
    >
      <Box
        data-testid="Billing-View-Container"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          mt: 2,
          width: '100%',
          mb: 2,
        }}
      >
        <HeaderDetails title="Billing Overview" data={formatData(data)} />
        <Box sx={{ mt: 2 }}>
          <Card sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
            <CardHeader
              title="Costs"
              titleTypographyProps={{ color: 'primary.main' }}
              subheader={`Cost details for ${getSubTitle(timePeriod)}`}
              action={(
                <Box sx={{ display: 'flex' }}>
                  <Box sx={{ width: 220, mr: 2 }}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="chart-type">Display</InputLabel>
                      <Select
                        labelId="chart-type"
                        id="chart-type"
                        label="Display"
                        defaultValue="LINE"
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                      >
                        <MenuItem value="LINE">Totals</MenuItem>
                        <MenuItem value="BAR">Detailed</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box sx={{ width: 220 }}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="time-period">Time Period</InputLabel>
                      <Select
                        labelId="time-period"
                        id="time-period"
                        label="Time Period"
                        defaultValue="YEAR"
                        value={timePeriod}
                        onChange={(e) => setTimePeriod(e.target.value)}
                      >
                        <MenuItem value="3MONTHS">3 Months</MenuItem>
                        <MenuItem value="6MONTHS">6 Months</MenuItem>
                        <MenuItem value="YEAR">12 Months</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
              )}
            />
            <CardContent sx={{ display: 'flex', flex: 1, mr: 1 }}>
              <Box ref={chartRef} sx={{ width: '100%', height: 300 }} />
            </CardContent>
          </Card>
        </Box>
      </Box>
    </ViewPage>
  );
}

Billing.propTypes = {
  loading: PropTypes.bool,
  toggleDrawer: PropTypes.func,
  data: PropTypes.object,
  getBilling: PropTypes.func,
  clearBilling: PropTypes.func,
};

Billing.defaultProps = {
  loading: false,
  toggleDrawer: () => {
    // Default
  },
  data: {},
  getBilling: () => {
    // Default
  },
  clearBilling: () => {
    // Default
  },
};

export default Billing;
