import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import ViewPage from '../../../Components/ViewPage';

import {
  ROUTE_DESIGN_INSIGHTS,
  ROUTE_DESIGN_INSIGHTS_ACCURACY_REPORT,
} from '../../../Routes/routes';

const BREADCRUMBS = [{
  title: 'Design Insights',
  route: `/${ROUTE_DESIGN_INSIGHTS}`,
}, {
  title: 'Accuracy Report',
  route: `/${ROUTE_DESIGN_INSIGHTS_ACCURACY_REPORT}`,
}];

function Report({
  toggleDrawer,
}) {
  return (
    <ViewPage title="Report" toggleDrawer={toggleDrawer} breadcrumbs={BREADCRUMBS}>
      <Box data-testid="Report-View-Container">
        <h1>Report</h1>
      </Box>
    </ViewPage>
  );
}

Report.propTypes = {
  toggleDrawer: PropTypes.func,
};

Report.defaultProps = {
  toggleDrawer: () => {
    // Default
  },
};

export default Report;
