import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';

import ViewPage from '../../../../Components/ViewPage';
import breadcrumbs from './breadcrumbs';
import { ROUTE_OPERATIONAL_INSIGHTS_TREATMENT } from '../../../../Routes/routes';

const COLUMNS = ['Resource Name', 'Event Type', 'Outcome', 'Conversation ID', ''];

const filterEvents = (searchterm, events) => {
  try {
    if (!searchterm) {
      return events;
    }
    const term = searchterm.toLowerCase();
    return events.filter((event) => event?.name?.toLowerCase().includes(term)
      || event?.description?.toLowerCase()?.includes(term));
  } catch (_err) {
    return [];
  }
};

function InferenceEvents({
  loading,
  toggleDrawer,
  listEvents,
  events,
  clearEvents,
}) {
  const navigate = useNavigate();
  const params = useParams();
  const [search, setSearch] = useState('');

  useEffect(() => {
    listEvents(params.queueId);
    return () => {
      clearEvents();
    };
  }, []);

  const refreshEvents = () => {
    listEvents(params.queueId);
  };
  return (
    <ViewPage
      title="Inference Events"
      toggleDrawer={toggleDrawer}
      breadcrumbs={breadcrumbs(params.queueId)}
      loading={loading}
    >
      <Box
        data-testid="InferenceEvents-View-Container"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          mt: 2,
          width: '100%',
          mb: 2,
        }}
      >
        <Card sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
          <CardHeader
            title="Inference Events"
            titleTypographyProps={{ color: 'primary.main' }}
            subheader="Inference Events for Queue"
            action={(
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  autoComplete="off"
                  size="small"
                  placeholder="Search"
                  id="Search-Field"
                  sx={{ mr: 2 }}
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <Tooltip title="Refresh Queues" placement="top">
                  <IconButton aria-label="Refresh Queues" onClick={refreshEvents}>
                    <RefreshIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          />
          <CardContent sx={{ display: 'flex', flex: 1, mr: 1 }}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    {COLUMNS.map((_col) => <TableCell key={_col}>{_col}</TableCell>)}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filterEvents(search, events).map((row) => (
                    <TableRow key={`${row.id}${row.name}`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell>{row.resourceName}</TableCell>
                      <TableCell>{row.eventType}</TableCell>
                      <TableCell>{row.outcome}</TableCell>
                      <TableCell>{row.conversationId}</TableCell>
                      <TableCell align="right">
                        <Tooltip title="View Event" placement="top">
                          <IconButton aria-label="View" onClick={() => navigate(`/${ROUTE_OPERATIONAL_INSIGHTS_TREATMENT}/inference-events/event/${row?.id}`)}>
                            <MoreHorizIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </Box>
    </ViewPage>
  );
}

InferenceEvents.propTypes = {
  toggleDrawer: PropTypes.func,
  loading: PropTypes.bool,
  listEvents: PropTypes.func,
  events: PropTypes.array,
  clearEvents: PropTypes.func,
};

InferenceEvents.defaultProps = {
  toggleDrawer: () => {
    // Default
  },
  loading: false,
  listEvents: () => {
    // Default
  },
  events: [],
  clearEvents: () => {
    // Default
  },
};

export default InferenceEvents;
