import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import HomeIcon from '@mui/icons-material/Home';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import InsightsIcon from '@mui/icons-material/Insights';
import BrushIcon from '@mui/icons-material/Brush';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';

import {
  ROUTE_HOME,
  ROUTE_OPERATIONAL_INSIGHTS,
  ROUTE_COMPLIANCE,
  ROUTE_ANALYTICS,
  ROUTE_DESIGN_INSIGHTS,
  ROUTE_ADMIN,
} from '../../Routes/routes';
import { DRAWER_OPEN_WIDTH, DRAWER_CLOSED_WIDTH } from '../../theme';

const getRootPath = (pathname) => pathname?.split('/')?.[1] || '';

function AppDrawer({
  open,
}) {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const onMenuItemClick = (route) => {
    navigate(route);
  };
  const SECTIONS = [{
    id: 'one',
    title: 'Home',
    options: [{
      id: 'home-one',
      title: t('Views.homeTitle'),
      route: ROUTE_HOME,
      icon: HomeIcon,
      rootPath: 'home',
    }],
  }, {
    id: 'two',
    title: 'General',
    options: [{
      id: 'general-one',
      title: t('Views.operationalInsightsTitle'),
      route: ROUTE_OPERATIONAL_INSIGHTS,
      icon: MoveToInboxIcon,
      rootPath: 'operational-insights',
    }, {
      id: 'general-two',
      title: t('Views.complianceTitle'),
      route: ROUTE_COMPLIANCE,
      icon: AssuredWorkloadIcon,
      rootPath: 'compliance',
    }, {
      id: 'general-three',
      title: t('Views.designInsightsTitle'),
      route: ROUTE_DESIGN_INSIGHTS,
      icon: BrushIcon,
      rootPath: 'design-insights',
    }, {
      id: 'general-four',
      title: t('Views.analyticsTitle'),
      route: ROUTE_ANALYTICS,
      icon: InsightsIcon,
      rootPath: 'analytics',
    }, {
      id: 'general-five',
      title: t('Views.adminTitle'),
      route: ROUTE_ADMIN,
      icon: AdminPanelSettingsIcon,
      rootPath: 'admin',
    }],
  }];
  const DRAWER_WIDTH = open ? DRAWER_OPEN_WIDTH : DRAWER_CLOSED_WIDTH;
  return (
    <Drawer
      data-testid="AppDrawer-Container"
      anchor="left"
      open={open}
      variant="permanent"
      sx={{
        '& .MuiDrawer-paper': {
          display: 'flex',
          justifyContent: 'space-between',
          width: DRAWER_WIDTH,
          height: '100%',
          border: '1px solid rgba(0, 0, 0, 0.12)',
          color: 'primary.main',
        },
      }}
    >
      <Box role="presentation">
        {SECTIONS.map((section, index) => (
          <Box key={section?.id}>
            <List sx={{ minHeight: 48 }}>
              {(section?.options || []).map((option) => {
                const Icon = option?.icon || Fragment;
                const Wrapper = open ? Box : Tooltip;
                const isSelected = getRootPath(pathname) === option?.rootPath;
                return (
                  <Wrapper title={option?.title} key={option?.title} placement="right">
                    <ListItem
                      selected={getRootPath(pathname) === option?.rootPath}
                      sx={{
                        minHeight: 48,
                        borderRight: isSelected && index === 1 ? '2px solid #488b9b' : 'none',
                      }}
                      button
                      key={option?.id}
                      onClick={option?.route
                        ? () => onMenuItemClick(option?.route)
                        : () => option.action()}
                    >
                      <Icon />
                      {open && <ListItemText primary={option?.title} sx={{ ml: 2 }} />}
                    </ListItem>
                  </Wrapper>
                );
              })}
            </List>
            {(index < SECTIONS.length - 1) && <Divider />}
          </Box>
        ))}
      </Box>
      {open && (
        <Box sx={{
          display: 'flex',
          m: 1,
          justifyContent: 'center',
          alignItems: 'center',
          wordBreak: 'break-word',
        }}
        >
          <Typography>
            {process.env.REACT_APP_CONSOLE_VERSION}
          </Typography>
        </Box>
      )}
    </Drawer>
  );
}

AppDrawer.propTypes = {
  open: PropTypes.bool,
};

AppDrawer.defaultProps = {
  open: true,
};

export default AppDrawer;
