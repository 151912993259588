import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import AppLoader from './Components/AppLoader';
import Authenticated from './Routes/Authenticated.container';
import UnAuthenticated from './Routes/UnAuthenticated.container';

function App({
  isAuthenticating,
  authenticated,
  rehydrateUser,
}) {
  useEffect(() => {
    rehydrateUser();
  }, []);
  if (isAuthenticating) {
    return <AppLoader />;
  }
  if (!isAuthenticating && !authenticated) {
    return <UnAuthenticated />;
  }
  return <Authenticated />;
}

App.propTypes = {
  isAuthenticating: PropTypes.bool,
  authenticated: PropTypes.bool,
  rehydrateUser: PropTypes.func,
};

App.defaultProps = {
  isAuthenticating: true,
  authenticated: false,
  rehydrateUser: () => {
    // Default
  },
};

export default App;
