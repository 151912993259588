import {
  INBOX_TREATMENT_LIST_INFERENCE_EVENTS,
  INBOX_TREATMENT_LIST_INFERENCE_EVENTS_SUCCESS,
  INBOX_TREATMENT_LIST_INFERENCE_EVENTS_FAIL,
  INBOX_TREATMENT_LIST_INFERENCE_EVENTS_CLEAR,
} from './types';

export const initialState = {
  loading: false,
  events: [],
};

const reducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case INBOX_TREATMENT_LIST_INFERENCE_EVENTS: {
      return { ...state, loading: true, events: [] };
    }
    case INBOX_TREATMENT_LIST_INFERENCE_EVENTS_SUCCESS: {
      const { events } = action.payload;
      return { ...state, loading: false, events };
    }
    case INBOX_TREATMENT_LIST_INFERENCE_EVENTS_FAIL: {
      return { ...state, loading: false, events: [] };
    }
    case INBOX_TREATMENT_LIST_INFERENCE_EVENTS_CLEAR: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default reducer;
