const i18n = {
  en: {
    designRegistryTitle: 'Design Registry',
    designTitle: 'Design',
    reportTitle: 'Report',
  },
  fr: {
    designRegistryTitle: 'Registre des dessins et modèles',
    designTitle: 'Motif',
    reportTitle: 'Rapport',
  },
};

export default i18n;
