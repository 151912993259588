import {
  DASHBOARD_FETCH_TOTALS,
  DASHBOARD_FETCH_TOTALS_SUCCESS,
  DASHBOARD_FETCH_TOTALS_FAIL,
  DASHBOARD_CLEAR_TOTALS,
} from './types';

export const initialState = {
  loading: false,
  data: {},
};

const reducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case DASHBOARD_FETCH_TOTALS: {
      return { ...state, loading: true, data: {} };
    }
    case DASHBOARD_FETCH_TOTALS_SUCCESS: {
      const { data } = action.payload;
      return { ...state, loading: false, data };
    }
    case DASHBOARD_FETCH_TOTALS_FAIL: {
      return initialState;
    }
    case DASHBOARD_CLEAR_TOTALS: {
      return initialState;
    }
    default:
      return state;
  }
};

export default reducer;
