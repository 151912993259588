import React from 'react';

import { COLOR_PINK } from '../../../../../colors';

const getHighlightedText = (text, unigram, bigrams) => {
  let pattern = unigram;
  bigrams.forEach((bigram) => {
    pattern = `${pattern}|${bigram}`;
  });
  const parts = text.split(new RegExp(`(${pattern})`, 'gi'));
  return (
    <span>
      {parts.map((part, i) => (
        // eslint-disable-next-line
        <span key={i} style={part.toLowerCase() === unigram.toLowerCase() || bigrams.includes(part.toLowerCase()) ? { fontWeight: 'bold', color: COLOR_PINK } : {}}>
          {part}
        </span>
      ))}
    </span>
  );
};

export default getHighlightedText;
