import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';

import ViewPage from '../../Components/ViewPage';
import GalleryCard from '../../Components/GalleryCard';

import OperationalInsightsIcon from './Icons/OperationalInsights';
import InsightsIcon from './Icons/Insights';
import AnalyticsIcon from './Icons/Analytics';
import AdminIcon from './Icons/Admin';
import ComplianceIcon from './Icons/Compliance';

import {
  ROUTE_OPERATIONAL_INSIGHTS,
  ROUTE_DESIGN_INSIGHTS,
  ROUTE_COMPLIANCE,
  ROUTE_ANALYTICS,
  ROUTE_ADMIN,
} from '../../Routes/routes';

const sections = (t) => [{
  title: t('Views.operationalInsightsTitle'),
  route: ROUTE_OPERATIONAL_INSIGHTS,
  icon: OperationalInsightsIcon,
}, {
  title: t('Views.complianceTitle'),
  route: ROUTE_COMPLIANCE,
  icon: ComplianceIcon,
}, {
  title: t('Views.designInsightsTitle'),
  route: ROUTE_DESIGN_INSIGHTS,
  icon: InsightsIcon,
}, {
  title: t('Views.analyticsTitle'),
  route: ROUTE_ANALYTICS,
  icon: AnalyticsIcon,
}, {
  title: t('Views.adminTitle'),
  route: ROUTE_ADMIN,
  icon: AdminIcon,
}];

function Home({
  toggleDrawer,
}) {
  const { t } = useTranslation();
  return (
    <ViewPage title={t('Views.homeTitle')} toggleDrawer={toggleDrawer}>
      <Box
        data-testid="Home-View-Container"
        sx={{
          display: 'flex',
          flex: 1,
          flexWrap: 'wrap',
        }}
      >
        {sections(t).map((section) => (
          <GalleryCard
            key={section?.route}
            title={section?.title}
            route={section?.route}
            description={section?.description}
            icon={section?.icon}
          />
        ))}
      </Box>
    </ViewPage>
  );
}

Home.propTypes = {
  toggleDrawer: PropTypes.func,
};

Home.defaultProps = {
  toggleDrawer: () => {
    // Provide defaults
  },
};

export default Home;
