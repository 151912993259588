import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import locales from '../../../../util/locales';

const validDesign = (design) => (!design.name || !design.version || !design.locale);

function ServisBot({
  loading,
  onClose,
  onBack,
  getSaasBots,
  importSassBot,
}) {
  const [loadingBots, setLoadingBots] = useState(true);
  const [availableBots, setAvailableBots] = useState([]);
  const [selectedBot, setSelectedBot] = useState('');
  const [botDesign, setBotDesign] = useState({
    name: '',
    version: '',
    locale: '',
  });

  const updateBotDesignValue = (key, value) => {
    const currentBotDesign = { ...botDesign };
    currentBotDesign[key] = value || '';
    setBotDesign(currentBotDesign);
  };

  useEffect(() => {
    const loadBots = async () => {
      const res = await getSaasBots();
      setAvailableBots(res);
      setLoadingBots(false);
    };
    loadBots();
  }, [getSaasBots]);

  const onImport = async () => {
    await importSassBot(botDesign, selectedBot);
    onClose();
  };

  const selectBot = (_botName) => {
    const bot = availableBots.find((_bot) => _bot.Name === _botName);
    updateBotDesignValue('version', bot.LastPublish);
    setSelectedBot(_botName);
  };

  return (
    <>
      <DialogContent>
        <Autocomplete
          fullWidth
          id="ServisBot-Bot"
          options={(availableBots || []).map((_bot) => _bot.Name)}
          value={selectedBot}
          onChange={(e, val) => selectBot(val)}
          renderInput={(params) => (
            <TextField
              {...params}
              autoComplete="off"
              fullWidth
              margin="normal"
              label="Select A Bot"
              placeholder="Select A Bot"
            />
          )}
        />
        {(selectedBot && botDesign.version) && (
          <>
            <TextField
              autoComplete="off"
              fullWidth
              id="BotDesign-Name"
              label="Name"
              variant="outlined"
              margin="normal"
              value={botDesign.name}
              onChange={(e) => updateBotDesignValue('name', e.target.value)}
            />
            <Autocomplete
              fullWidth
              id="BotDesign-Locale"
              label="Locale"
              options={Object.keys(locales).map((loc) => loc)}
              value={botDesign.locale}
              onChange={(e, val) => updateBotDesignValue('locale', val)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  autoComplete="off"
                  fullWidth
                  margin="normal"
                  label="Locale"
                  placeholder="Locale"
                />
              )}
            />
          </>
        )}
        {(selectedBot && !botDesign.version) && (
          <Typography variant="body2" sx={{ color: 'red' }}>
            Selected Bot does not contain published intents, please choose another Bot.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Box sx={{ display: 'flex', flex: 1, m: 2 }}>
          <Button
            onClick={onBack}
            disabled={loading}
            sx={{ mr: 2, width: 100 }}
          >
            Back
          </Button>
        </Box>
        <Box sx={{ m: 2 }}>
          <Button
            onClick={onClose}
            disabled={loadingBots || loading}
            sx={{ mr: 2, width: 100 }}
          >
            Cancel
          </Button>
          <Button
            sx={{ width: 100 }}
            onClick={onImport}
            variant="contained"
            disabled={loadingBots || !selectedBot || validDesign(botDesign) || loading}
          >
            {loading ? 'Importing...' : 'Import'}
          </Button>
        </Box>
      </DialogActions>
    </>
  );
}

ServisBot.propTypes = {
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  getSaasBots: PropTypes.func,
  importSassBot: PropTypes.func,
  onBack: PropTypes.func,
};

ServisBot.defaultProps = {
  loading: false,
  onClose: () => {
    // Defaults
  },
  getSaasBots: () => {
    // Defaults
  },
  onBack: () => {
    // Defaults
  },
  importSassBot: () => {
    // Defaults
  },
};

export default ServisBot;
