import { connect } from 'react-redux';

import DashBoard from './DashBoard';
import { fetchTotals, clearTotals } from '../../../store/inbox/dashboard/actions';
import { setQueue } from '../../../store/state/actions';

const mapStateToProps = (state) => ({
  loading: state?.inbox?.dashboard?.index?.loading,
  data: state?.inbox?.dashboard?.index?.data,
  selectedQueue: state?.state?.selectedQueue,
  queues: state?.auth?.queues,
});

const mapDispatchToProps = (dispatch) => ({
  fetchTotals: (queueId) => dispatch(fetchTotals(queueId)),
  setQueue: (queue) => dispatch(setQueue(queue)),
  clearTotals: () => dispatch(clearTotals()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashBoard);
