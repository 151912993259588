import React, { useRef, useEffect } from 'react';
import ApexCharts from 'apexcharts';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';

import chartOptions from './chartOptions';

function TopicTrends() {
  const chartRef = useRef(null);
  useEffect(() => {
    if (chartRef.current) {
      const chart = new ApexCharts(chartRef.current, chartOptions);
      chart.render();
    }
  }, [chartRef]);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
      }}
    >
      <Card>
        <CardHeader
          title="Topic Trends"
          titleTypographyProps={{ color: 'primary.main' }}
          subheader="Topics trending within the last 15 minutes"
        />
        <CardContent>
          <div ref={chartRef} />
        </CardContent>
      </Card>
    </Box>
  );
}

export default TopicTrends;
