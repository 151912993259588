export const CREATE_INTENT = 'CREATE_INTENT';
export const ADD_UTTERANCE = 'ADD_UTTERANCE';
export const REMOVE_UTTERANCE = 'REMOVE_UTTERANCE';
export const IGNORE = 'IGNORE';

export const getPayloadForType = (event, type) => {
  switch (type) {
    case IGNORE: {
      return {
        inferenceEventId: event?.id,
        botDesignId: event?.botDesignId || event?.reviewDetails?.botDesignId,
        action: IGNORE,
        properties: {},
      };
    }
    case CREATE_INTENT: {
      return {
        inferenceEventId: event?.id,
        botDesignId: event?.botDesignId || event?.reviewDetails?.botDesignId,
        action: CREATE_INTENT,
        properties: {
          intent: event?.reviewDetails?.actualIntent || event?.matchedIntent || '',
          utterance: event?.utterance || '',
        },
      };
    }
    case ADD_UTTERANCE: {
      return {
        inferenceEventId: event?.id,
        botDesignId: event?.botDesignId || event?.reviewDetails?.botDesignId,
        action: ADD_UTTERANCE,
        properties: {
          intent: event?.reviewDetails?.actualIntent || event?.matchedIntent || '',
          utterance: event?.utterance || '',
        },
      };
    }
    case REMOVE_UTTERANCE: {
      return {
        inferenceEventId: event?.id,
        botDesignId: event?.botDesignId || event?.reviewDetails?.botDesignId,
        action: REMOVE_UTTERANCE,
        properties: {
          intent: event?.reviewDetails?.actualIntent || event?.matchedIntent || '',
          utterance: event?.utterance || '',
        },
      };
    }
    default: {
      return {};
    }
  }
};
