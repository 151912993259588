import React from 'react';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';

function Empty() {
  return (
    <Box sx={{ display: 'flex' }}>
      <Card sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardHeader
          titleTypographyProps={{ color: 'primary.main' }}
          subheader="No data available for selected time period"
        />
      </Card>
    </Box>
  );
}

export default Empty;
