import React from 'react';

import Box from '@mui/material/Box';

import Botty from './Botty';

const bubbleStyle = {
  background: '#73C4DB',
  color: 'white',
  padding: 8,
  borderRadius: 8,
  marginBottom: 12,
  maxWidth: 480,
};

const DATA = [
  'You had 96 intents in total.',
  '95 Good intents and 2 bad intents',
  'Good intents are intents with more than 5 utterances, with those utterances being closely related / similar',
  'The "lost_passport_urgent_travel" intent is an example of a good intent',
];

function Summary() {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {DATA.map((_dat) => <span style={bubbleStyle} key={_dat}>{_dat}</span>)}
      <Botty />
    </Box>
  );
}

export default Summary;
