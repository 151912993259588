import moment from 'moment';
import i18next from 'i18next';

const util = {
  formatData: (data) => [{
    title: 'Missed Input',
    data: Number.isInteger(data?.occurrences?.missedInput) ? data?.occurrences?.missedInput : 'N/A',
  }, {
    title: 'Classified',
    data: Number.isInteger(data?.occurrences?.classified) ? data?.occurrences?.classified : 'N/A',
  }, {
    title: 'Oldest Event',
    data: data?.oldestItem ? moment(data?.oldestItem).format(i18next.t('moment.standard')) : 'N/A',
  }, {
    title: 'Active Sources',
    data: Number.isInteger(data?.sources?.length) ? data?.sources?.length : 'N/A',
  }, {
    title: 'Ready For Archiving',
    data: '351 (Placeholder)',
  }, {
    title: 'Since Last Archive',
    data: '7 Days (Placeholder)',
  }],
};

export default util;
