import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableCell from '@mui/material/TableCell';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import SearchIcon from '@mui/icons-material/Search';

import SortableColumn, { ASC } from '../../../../../Components/Table/SortableColumn';
import HeaderDetails from '../../../../../Components/HeaderDetails';

const COLUMNS = [{
  title: 'Name',
  sort: true,
  sortKey: 'name',
}];

const formatEndpoints = (endpoints) => endpoints.map((_endpoint) => ({
  title: 'Name',
  data: _endpoint,
}));

const formatWorkers = (workers) => workers.map((_worker) => ({
  name: _worker.Name,
  type: _worker.Type,
}));

const filterAndSort = (searchterm, array, sortOptions) => {
  let filtered = [];
  try {
    if (!searchterm) {
      filtered = array;
    }
    const term = searchterm.toLowerCase();
    filtered = array.filter((item) => item?.toLowerCase()?.includes(term));
  } catch (_err) {
    filtered = [];
  }
  return filtered.sort((a, b) => {
    let aVal = '';
    let bVal = '';
    if (sortOptions.direction === ASC) {
      aVal = a;
      bVal = b;
    } else {
      aVal = b;
      bVal = a;
    }
    if (aVal.toString().toLowerCase() < bVal.toString().toLowerCase()) {
      return -1;
    }
    if (aVal.toString().toLowerCase() > bVal.toString().toLowerCase()) {
      return 1;
    }
    return 0;
  });
};

function Resources({
  data,
}) {
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState({
    title: 'Name',
    sortKey: 'name',
    direction: ASC,
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const filteredAndSorted = filterAndSort(search, data?.intents || [], sort);
  const rowsToDisplay = rowsPerPage > 0
    ? filteredAndSorted.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : filteredAndSorted;
  return (
    <Box
      data-testid="Resources"
      sx={{
        display: 'flex',
        mt: 2,
        flexDirection: 'column',
        flexWrap: 'wrap',
      }}
    >
      <HeaderDetails title="Endpoints" data={formatEndpoints(data?.endpoints || [])} />
      <Box sx={{ mt: 2, mb: 2 }}>
        <Typography variant="h6" color="text.secondary">
          Workers
        </Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
          {formatWorkers(data?.workers || []).map((_data) => (
            <Card sx={{ mt: 2, mr: 2 }} key={_data.name}>
              <CardContent>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <Typography variant="subtitle1" color="text.secondary" sx={{ mr: 1 }}>
                    Name:
                  </Typography>
                  <Typography variant="h6" color="primary.main">
                    {_data.name}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <Typography variant="subtitle1" color="text.secondary" sx={{ mr: 1 }}>
                    Type:
                  </Typography>
                  <Typography variant="h6" color="primary.main">
                    {_data.type}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          ))}
        </Box>
      </Box>
      <Card sx={{ mt: 2 }}>
        <CardHeader
          title="Intents"
          titleTypographyProps={{ color: 'primary.main' }}
          subheader="List of Intents contained within the Bot"
          action={(
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                autoComplete="off"
                size="small"
                placeholder="Search"
                id="Search-Field"
                sx={{ mr: 2 }}
                onChange={(e) => {
                  setPage(0);
                  setSearch(e.target.value);
                }}
                value={search}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            )}
        />
        <CardContent sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {COLUMNS.map((_col) => (
                    <SortableColumn
                      key={_col?.title}
                      column={_col}
                      sort={sort}
                      setSort={setSort}
                    />
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rowsToDisplay.map((_intent) => (
                  <TableRow key={`${_intent}`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell>{_intent}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10, 20, 50, { label: 'All', value: -1 }]}
                    colSpan={COLUMNS.length}
                    count={search ? filteredAndSorted.length : data?.intents?.length || 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        'aria-label': 'rows per page',
                      },
                      native: true,
                    }}
                    onPageChange={(e, _page) => setPage(_page)}
                    onRowsPerPageChange={(e) => {
                      setRowsPerPage(parseInt(e.target.value, 10));
                      setPage(0);
                    }}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </Box>
  );
}

Resources.propTypes = {
  data: PropTypes.object,
};

Resources.defaultProps = {
  data: {},
};

export default Resources;
