import { connect } from 'react-redux';

import Treatment from './Treatment';
import { listTreatments, clearTreatments } from '../../../store/inbox/treatment/actions';

const mapStateToProps = (state) => ({
  loading: state?.inbox?.treatment?.index?.loading,
  queues: state?.inbox?.treatment?.index?.queues,
});

const mapDispatchToProps = (dispatch) => ({
  listTreatments: () => dispatch(listTreatments()),
  clearTreatments: () => dispatch(clearTreatments()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Treatment);
