import {
  DISCUSSION_FETCH_TOPIC_DISTRIBUTION,
  DISCUSSION_FETCH_TOPIC_DISTRIBUTION_SUCCESS,
  DISCUSSION_FETCH_TOPIC_DISTRIBUTION_FAIL,
} from './types';

export const initialState = {
  loading: false,
  defaultXMin: 0,
  defaultXMax: 0,
  data: [],
};

const getMinFromData = (_data) => _data.reduce((prev, curr) => (prev.x < curr.x ? prev : curr), 0);
const getMaxFromData = (_data) => _data.reduce((prev, curr) => (prev.x > curr.x ? prev : curr), 0);

const reducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case DISCUSSION_FETCH_TOPIC_DISTRIBUTION: {
      return { ...state, loading: true };
    }
    case DISCUSSION_FETCH_TOPIC_DISTRIBUTION_SUCCESS: {
      const { data } = action.payload;
      const min = getMinFromData(data).x || 0;
      const max = getMaxFromData(data).x || 0;
      return {
        ...state,
        loading: false,
        defaultXMin: min,
        defaultXMax: max,
        data,
      };
    }
    case DISCUSSION_FETCH_TOPIC_DISTRIBUTION_FAIL: {
      return { ...state, loading: false };
    }
    default:
      return state;
  }
};

export default reducer;
