import UsageIcon from './Icons/Usage';
import PerformanceIcon from './Icons/Performance';
import CustomIcon from './Icons/Custom';
import LearningIcon from './Icons/Learning';

import {
  ROUTE_ANALYTICS_USAGE,
  ROUTE_ANALYTICS_PERFORMANCE,
  ROUTE_ANALYTICS_CUSTOM,
  ROUTE_ANALYTICS_LEARNING,
  ROUTE_ANALYTICS_TREATMENT,
  ROUTE_ANALYTICS_REDACTION,
} from '../../Routes/routes';

const sections = (t) => [{
  title: t('Analytics.usageTitle'),
  route: ROUTE_ANALYTICS_USAGE,
  icon: UsageIcon,
}, {
  title: t('Analytics.performanceTitle'),
  route: ROUTE_ANALYTICS_PERFORMANCE,
  icon: PerformanceIcon,
}, {
  title: t('Analytics.customTitle'),
  route: ROUTE_ANALYTICS_CUSTOM,
  icon: CustomIcon,
}, {
  title: t('Analytics.learningTitle'),
  route: ROUTE_ANALYTICS_LEARNING,
  icon: LearningIcon,
}, {
  title: t('Analytics.treatmentTitle'),
  route: ROUTE_ANALYTICS_TREATMENT,
  icon: LearningIcon,
}, {
  title: t('Analytics.redactionTitle'),
  route: ROUTE_ANALYTICS_REDACTION,
  icon: LearningIcon,
}];

export default sections;
