import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import ViewPage from '../../../Components/ViewPage';
import HeaderDetails from '../../../Components/HeaderDetails';
import Sources from './Sources';
import breadcrumbs from './breadcrumbs';
import util from './util';

function DashBoard({
  toggleDrawer,
  loading,
  fetchTotals,
  data,
  queues,
  setQueue,
  selectedQueue,
  clearTotals,
}) {
  const { t } = useTranslation();

  useEffect(() => {
    fetchTotals(selectedQueue);
    return () => {
      clearTotals();
    };
  }, [selectedQueue]);

  return (
    <ViewPage
      title={t('OperationalInsights.inboxTitle')}
      toggleDrawer={toggleDrawer}
      loading={loading}
      breadcrumbs={breadcrumbs(t)}
    >
      <Box
        data-testid="Inbox-View-Container"
        sx={{
          mt: 1,
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
        }}
      >
        {(queues && queues.length > 0) && (
          <Box sx={{ display: 'flex', flex: 1, mt: 2 }}>
            <Typography variant="h6" color="text.secondary" sx={{ display: 'flex', flex: 1 }}>
              Queue Information
            </Typography>
            <FormControl size="small" sx={{ width: 220 }}>
              <InputLabel id="selected-queue">Select Queue</InputLabel>
              <Select
                labelId="selected-queue"
                id="selected-queue"
                label="Selected Queue"
                value={selectedQueue || ''}
                onChange={(e) => setQueue(e.target.value)}
              >
                {queues.map((_queue) => (
                  <MenuItem key={_queue.id} value={_queue.id}>{_queue.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )}
        {selectedQueue && <HeaderDetails data={util.formatData(data)} />}
        {selectedQueue && (
          <Sources
            sources={(data?.sources || [])}
            fetchTotals={() => fetchTotals(selectedQueue.id)}
          />
        )}
        {(!queues || queues.length === 0) && (
          <Card sx={{ mt: 1 }}>
            <CardHeader
              title="You have not being assigned any queues"
              titleTypographyProps={{ color: 'primary.main' }}
              subheader="Contact your administrator for assistance."
            />
          </Card>
        )}
        {(queues && queues.length > 0 && !selectedQueue) && (
          <Card sx={{ mt: 1 }}>
            <CardHeader
              titleTypographyProps={{ color: 'primary.main' }}
              subheader="Select a queue to view queue details"
            />
          </Card>
        )}
      </Box>
    </ViewPage>
  );
}

DashBoard.propTypes = {
  toggleDrawer: PropTypes.func,
  loading: PropTypes.bool,
  fetchTotals: PropTypes.func,
  data: PropTypes.object,
  selectedQueue: PropTypes.object,
  queues: PropTypes.array,
  setQueue: PropTypes.func,
  clearTotals: PropTypes.func,
};

DashBoard.defaultProps = {
  toggleDrawer: () => {
    // Default
  },
  loading: false,
  fetchTotals: () => {
    // Default
  },
  data: {},
  selectedQueue: {},
  queues: [],
  setQueue: () => {
    // Default
  },
  clearTotals: () => {
    // Default
  },
};

export default DashBoard;
