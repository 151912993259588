import {
  DASHBOARD_SOURCE_GET_SOURCE,
  DASHBOARD_SOURCE_GET_SOURCE_SUCCESS,
  DASHBOARD_SOURCE_GET_SOURCE_FAIL,
  DASHBOARD_SOURCE_SET_FILTER,
  DASHBOARD_SOURCE_REMOVE_EVENT,
} from './types';

export const initialState = {
  loading: false,
  data: {},
  filter: 'all',
};

const removeInferenceEvent = (data, eventId) => {
  const currentData = JSON.parse(JSON.stringify(data));
  if (currentData?.inferenceEvents) {
    const inferenceEvents = currentData.inferenceEvents.filter((_dat) => _dat.id !== eventId);
    currentData.inferenceEvents = inferenceEvents;
    return currentData;
  }
  if (currentData?.conversations) {
    const updatedConversations = currentData.conversations.map((_conv) => {
      const updated = (_conv?.inference_events || _conv?.inferenceEvents || [])
        .filter((_dat) => _dat.id !== eventId);
      return {
        ..._conv,
        inferenceEvents: updated,
      };
    });
    currentData.conversations = updatedConversations;
    return currentData;
  }
  return currentData;
};

const reducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case DASHBOARD_SOURCE_GET_SOURCE: {
      return { ...state, loading: true, data: {} };
    }
    case DASHBOARD_SOURCE_GET_SOURCE_SUCCESS: {
      const { data } = action.payload;
      return { ...state, loading: false, data };
    }
    case DASHBOARD_SOURCE_GET_SOURCE_FAIL: {
      return { ...state, loading: false, data: {} };
    }
    case DASHBOARD_SOURCE_REMOVE_EVENT: {
      const { eventId } = action.payload;
      const updatedData = removeInferenceEvent(state.data, eventId);
      return { ...state, data: updatedData };
    }
    case DASHBOARD_SOURCE_SET_FILTER: {
      const { filter } = action.payload;
      return { ...state, filter };
    }
    default:
      return state;
  }
};

export default reducer;
