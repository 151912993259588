import {
  INFERENCE_EVENT_GET_ATTEMPT,
  INFERENCE_EVENT_GET_SUCCESS,
  INFERENCE_EVENT_GET_FAIL,
  INFERENCE_EVENT_CLEAR,
} from './types';

export const initialState = {
  loading: false,
  data: {},
};

const reducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case INFERENCE_EVENT_GET_ATTEMPT: {
      return {
        ...state,
        loading: true,
        data: {},
      };
    }
    case INFERENCE_EVENT_GET_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        loading: false,
        data,
      };
    }
    case INFERENCE_EVENT_GET_FAIL: {
      return {
        ...state,
        loading: false,
        data: {},
      };
    }
    case INFERENCE_EVENT_CLEAR: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default reducer;
