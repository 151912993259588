import {
  ADMIN_USERS_LIST,
  ADMIN_USERS_LIST_SUCCESS,
  ADMIN_USERS_LIST_FAIL,
  ADMIN_USER_EDIT,
  ADMIN_USER_EDIT_SUCCESS,
  ADMIN_USER_EDIT_FAIL,
  ADMIN_USER_CREATE,
  ADMIN_USER_CREATE_SUCCESS,
  ADMIN_USER_CREATE_FAIL,
  ADMIN_USERS_CLEAR,
} from './types';

export const initialState = {
  loading: false,
  updatingUser: false,
  creatingUser: false,
  users: [],
};

const reducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case ADMIN_USERS_LIST: {
      return { ...state, loading: true };
    }
    case ADMIN_USERS_LIST_SUCCESS: {
      const { users } = action.payload;
      return { ...state, loading: false, users };
    }
    case ADMIN_USERS_LIST_FAIL: {
      return { ...state, loading: false };
    }
    case ADMIN_USER_EDIT: {
      return { ...state, updatingUser: true };
    }
    case ADMIN_USER_EDIT_SUCCESS: {
      const { user } = action.payload;
      const currentUsers = [...state.users];
      const currentUserIndex = currentUsers.findIndex((_user) => _user.email === user.email);
      if (currentUserIndex !== -1) {
        currentUsers[currentUserIndex] = user;
      }
      return { ...state, updatingUser: false, users: currentUsers };
    }
    case ADMIN_USER_EDIT_FAIL: {
      return { ...state, updatingUser: false };
    }
    case ADMIN_USER_CREATE: {
      return { ...state, creatingUser: true };
    }
    case ADMIN_USER_CREATE_SUCCESS: {
      const { user } = action.payload;
      const currentUsers = [...state.users];
      currentUsers.push(user);
      return { ...state, creatingUser: false, users: currentUsers };
    }
    case ADMIN_USER_CREATE_FAIL: {
      return { ...state, creatingUser: false };
    }
    case ADMIN_USERS_CLEAR: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default reducer;
