import React from 'react';
import PropTypes from 'prop-types';

function File({ width, height }) {
  return (
    <svg width={width} height={height} viewBox="0 0 98 98" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M12.8789 3.84863H65.2069L75.7326 14.4369L85.1212 23.7563V94.1515H12.8789V3.84863Z" fill="#FFF5F5" />
      <path fillRule="evenodd" clipRule="evenodd" d="M64.2537 24.8884L64.2314 4.80444L84.1173 24.8884H64.2537Z" fill="#FF9100" />
      <path d="M34.7947 43.5401L31.6021 40.3474L18.7417 53.2078L31.6021 66.0681L34.7947 62.8754L25.1271 53.2078L34.7947 43.5401Z" fill="#64E1DC" />
      <path d="M61.714 62.8754L64.9067 66.0681L77.7671 53.2078L64.9067 40.3474L61.714 43.5401L71.3817 53.2078L61.714 62.8754Z" fill="#64E1DC" />
      <path d="M55.3438 34.0034L37.0854 72.5175L41.1653 74.4517L59.4237 35.9376L55.3438 34.0034Z" fill="#64E1DC" />
    </svg>
  );
}

File.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

File.defaultProps = {
  width: 98,
  height: 98,
};

export default File;
