import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import KeyWords from './KeyWords';
import Queries from './Queries';

function TopicKeyWords({
  data,
  selectedTopic,
  setSelectedTopic,
}) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', flex: 1 }} data-testid="TopicKeyWords-Container">
      <KeyWords data={data} setSelectedTopic={setSelectedTopic} />
      <Queries data={data} selectedTopic={selectedTopic} />
    </Box>
  );
}

TopicKeyWords.propTypes = {
  data: PropTypes.array,
  selectedTopic: PropTypes.string,
  setSelectedTopic: PropTypes.func,
};

TopicKeyWords.defaultProps = {
  data: [],
  selectedTopic: '',
  setSelectedTopic: () => {
    // Default
  },
};

export default TopicKeyWords;
