import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';

import RedirectView from '../Views/Auth/Redirect';

function UnAuthenticated() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<Navigate push to="/auth/redirect" />} />
        <Route path="/auth/redirect" element={<RedirectView />} />
      </Routes>
    </BrowserRouter>
  );
}

export default UnAuthenticated;
