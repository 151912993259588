import {
  ADMIN_QUEUES_LIST,
  ADMIN_QUEUES_LIST_SUCCESS,
  ADMIN_QUEUES_LIST_FAIL,
  ADMIN_QUEUE_EDIT,
  ADMIN_QUEUE_EDIT_SUCCESS,
  ADMIN_QUEUE_EDIT_FAIL,
  ADMIN_QUEUE_CREATE,
  ADMIN_QUEUE_CREATE_SUCCESS,
  ADMIN_QUEUE_CREATE_FAIL,
  ADMIN_QUEUE_DELETE,
  ADMIN_QUEUE_DELETE_SUCCESS,
  ADMIN_QUEUE_DELETE_FAIL,
  ADMIN_QUEUES_CLEAR,
} from './types';
import { showAlert } from '../../state/actions';
import userUtils from '../../../util/user';
import isUnAuth from '../../../util/unauth';
import interceptor, { getInboxUrl } from '../../../util/interceptor';
import { logoutUser } from '../../auth/actions';

export const listQueuesAttempt = () => ({ type: ADMIN_QUEUES_LIST });
export const listQueuesSuccess = (queues, sources) => ({
  type: ADMIN_QUEUES_LIST_SUCCESS,
  payload: { queues, sources },
});
export const listQueuesFail = () => ({ type: ADMIN_QUEUES_LIST_FAIL });

export const listQueues = () => async (dispatch, getState) => {
  const ERROR_MESSAGE = 'Failed to load queues, please try again.';
  dispatch(listQueuesAttempt());
  try {
    const state = getState();
    const { authToken, authenticatedUser } = state.auth;
    const tenant = userUtils.getTenant(authenticatedUser);
    const region = userUtils.getRegion(authenticatedUser);
    const queuesRes = await interceptor({
      method: 'post',
      url: `${getInboxUrl(region)}/${tenant}/ListQueues`,
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    });
    const { data: queuesData } = queuesRes;
    const { queues } = queuesData;
    const sourcesRes = await interceptor({
      method: 'post',
      url: `${getInboxUrl(region)}/${tenant}/ListSources`,
      data: {},
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    });
    const { data: sourcesData } = sourcesRes;
    const { sources } = sourcesData;
    dispatch(listQueuesSuccess(queues, sources));
  } catch (_err) {
    const status = _err?.response?.status;
    if (isUnAuth(status)) {
      dispatch(logoutUser({ location: window.location.href }));
    } else {
      dispatch(showAlert({ type: 'error', message: ERROR_MESSAGE }));
      dispatch(listQueuesFail());
    }
  }
};

export const updateQueueAttempt = () => ({ type: ADMIN_QUEUE_EDIT });
export const updateQueueSuccess = (queue) => ({
  type: ADMIN_QUEUE_EDIT_SUCCESS,
  payload: { queue },
});
export const updateQueueFail = () => ({ type: ADMIN_QUEUE_EDIT_FAIL });

export const updateQueue = (queue) => async (dispatch, getState) => {
  const ERROR_MESSAGE = 'Failed to update queue, please try again.';
  const SUCCESS_MESSAGE = 'Queue updated.';
  dispatch(updateQueueAttempt());
  try {
    const state = getState();
    const { authToken, authenticatedUser } = state.auth;
    const tenant = userUtils.getTenant(authenticatedUser);
    const region = userUtils.getRegion(authenticatedUser);
    const res = await interceptor({
      method: 'post',
      url: `${getInboxUrl(region)}/${tenant}/UpdateQueue`,
      data: queue,
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    });
    const { data } = res;
    dispatch(updateQueueSuccess(data));
    dispatch(showAlert({ type: 'success', message: SUCCESS_MESSAGE }));
  } catch (_err) {
    const status = _err?.response?.status;
    if (isUnAuth(status)) {
      dispatch(logoutUser({ location: window.location.href }));
    } else {
      dispatch(showAlert({ type: 'error', message: ERROR_MESSAGE }));
      dispatch(updateQueueFail());
    }
  }
};

export const createQueueAttempt = () => ({ type: ADMIN_QUEUE_CREATE });
export const createQueueSuccess = (queue) => ({
  type: ADMIN_QUEUE_CREATE_SUCCESS,
  payload: { queue },
});
export const createQueueFail = () => ({ type: ADMIN_QUEUE_CREATE_FAIL });

export const createQueue = (queue) => async (dispatch, getState) => {
  const ERROR_MESSAGE = 'Failed to create queue, please try again.';
  const SUCCESS_MESSAGE = 'Queue created.';
  dispatch(createQueueAttempt());
  try {
    const state = getState();
    const { authToken, authenticatedUser } = state.auth;
    const tenant = userUtils.getTenant(authenticatedUser);
    const region = userUtils.getRegion(authenticatedUser);
    const res = await interceptor({
      method: 'post',
      url: `${getInboxUrl(region)}/${tenant}/CreateQueue`,
      data: queue,
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    });
    const { data } = res;
    dispatch(createQueueSuccess(data));
    dispatch(showAlert({ type: 'success', message: SUCCESS_MESSAGE }));
  } catch (_err) {
    const status = _err?.response?.status;
    if (isUnAuth(status)) {
      dispatch(logoutUser({ location: window.location.href }));
    } else {
      dispatch(showAlert({ type: 'error', message: ERROR_MESSAGE }));
      dispatch(createQueueFail());
    }
  }
};

export const deleteQueueAttempt = () => ({ type: ADMIN_QUEUE_DELETE });
export const deleteQueueSuccess = (queue) => ({
  type: ADMIN_QUEUE_DELETE_SUCCESS,
  payload: { queue },
});
export const deleteQueueFail = () => ({ type: ADMIN_QUEUE_DELETE_FAIL });

export const deleteQueue = (queue) => async (dispatch, getState) => {
  const ERROR_MESSAGE = 'Failed to delete queue, please try again.';
  const SUCCESS_MESSAGE = 'Queue deleted.';
  dispatch(deleteQueueAttempt());
  try {
    const state = getState();
    const { authToken, authenticatedUser } = state.auth;
    const tenant = userUtils.getTenant(authenticatedUser);
    const region = userUtils.getRegion(authenticatedUser);
    await interceptor({
      method: 'post',
      url: `${getInboxUrl(region)}/${tenant}/DeleteQueue`,
      data: { queueId: queue.id },
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    });
    dispatch(deleteQueueSuccess(queue));
    dispatch(showAlert({ type: 'success', message: SUCCESS_MESSAGE }));
  } catch (_err) {
    const status = _err?.response?.status;
    if (isUnAuth(status)) {
      dispatch(logoutUser({ location: window.location.href }));
    } else {
      dispatch(showAlert({ type: 'error', message: ERROR_MESSAGE }));
      dispatch(deleteQueueFail());
    }
  }
};

export const clearQueues = () => ({ type: ADMIN_QUEUES_CLEAR });
