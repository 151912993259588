import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import i18next from 'i18next';

import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

const ONE_DAY = 86400;
const ONE_HOUR = 3600;
const TEN_MINS = 600;

function valuetext(value) {
  return `${value}°C`;
}

function RangeSlider({
  min,
  max,
  value,
  setValue,
  timePeriod,
}) {
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const getStep = () => {
    switch (timePeriod) {
      case 'MONTH':
      case 'WEEK': {
        return ONE_DAY;
      }
      case 'DAY': {
        return ONE_HOUR;
      }
      case 'HOUR': {
        return TEN_MINS;
      }
      default: {
        return ONE_DAY;
      }
    }
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        mr: 3,
        ml: 4,
      }}
      data-testid="Slider-Container"
    >
      <Slider
        getAriaLabel={() => 'Date range'}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        valueLabelFormat={(_val) => {
          if (timePeriod === 'MONTH' || timePeriod === 'WEEK') {
            return Moment.unix(_val).format(i18next.t('moment.monthDay'));
          }
          return Moment.unix(_val).format('h:mm a');
        }}
        min={min}
        max={max}
        step={getStep()}
      />
    </Box>
  );
}

RangeSlider.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.array,
  setValue: PropTypes.func,
  timePeriod: PropTypes.string,
};

// TODO - sort out defualts
RangeSlider.defaultProps = {
  min: 0,
  max: 0,
  value: [0, 0],
  setValue: () => {
    // Default
  },
  timePeriod: 'MONTH',
};

export default RangeSlider;
