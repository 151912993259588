const locales = {
  af: 'af',
  am: 'am',
  ar: 'ar',
  'ar-AE': 'ar-AE',
  'ar-BH': 'ar-BH',
  'ar-DZ': 'ar-DZ',
  'ar-EG': 'ar-EG',
  'ar-IQ': 'ar-IQ',
  'ar-JO': 'ar-JO',
  'ar-KW': 'ar-KW',
  'ar-LB': 'ar-LB',
  'ar-LY': 'ar-LY',
  'ar-MA': 'ar-MA',
  'ar-OM': 'ar-OM',
  'ar-QA': 'ar-QA',
  'ar-SA': 'ar-SA',
  'ar-SY': 'ar-SY',
  'ar-TN': 'ar-TN',
  'ar-YE': 'ar-YE',
  arn: 'arn',
  'arn-CL': 'arn-CL',
  as: 'as',
  az: 'az',
  'az-Cyrl-AZ': 'az-Cyrl-AZ',
  'az-Latn-AZ': 'az-Latn-AZ',
  ba: 'ba',
  be: 'be',
  bg: 'bg',
  bn: 'bn',
  'bn-BD': 'bn-BD',
  'bn-IN': 'bn-IN',
  'bo-CN': 'bo-CN',
  br: 'br',
  'br-FR': 'br-FR',
  bs: 'bs',
  'bs-Cyrl-BA': 'bs-Cyrl-BA',
  'bs-Latn-BA': 'bs-Latn-BA',
  ca: 'ca',
  'ca-ES': 'ca-ES',
  ceb: 'ceb',
  co: 'co',
  'co-FR': 'co-FR',
  cs: 'cs',
  cy: 'cy',
  da: 'da',
  de: 'de',
  'de-AT': 'de-AT',
  'de-CH': 'de-CH',
  'de-DE': 'de-DE',
  'de-LI': 'de-LI',
  'de-LU': 'de-LU',
  'dsb-DE': 'dsb-DE',
  dv: 'dv',
  el: 'el',
  en: 'en',
  'en-029': 'en-029',
  'en-AU': 'en-AU',
  'en-BZ': 'en-BZ',
  'en-CA': 'en-CA',
  'en-GB': 'en-GB',
  'en-IE': 'en-IE',
  'en-IN': 'en-IN',
  'en-JM': 'en-JM',
  'en-MY': 'en-MY',
  'en-NZ': 'en-NZ',
  'en-PH': 'en-PH',
  'en-SG': 'en-SG',
  'en-TT': 'en-TT',
  'en-US': 'en-US',
  'en-ZA': 'en-ZA',
  'en-ZW': 'en-ZW',
  eo: 'eo',
  es: 'es',
  'es-AR': 'es-AR',
  'es-BO': 'es-BO',
  'es-CL': 'es-CL',
  'es-CO': 'es-CO',
  'es-CR': 'es-CR',
  'es-DO': 'es-DO',
  'es-EC': 'es-EC',
  'es-ES': 'es-ES',
  'es-GT': 'es-GT',
  'es-HN': 'es-HN',
  'es-MX': 'es-MX',
  'es-NI': 'es-NI',
  'es-PA': 'es-PA',
  'es-PE': 'es-PE',
  'es-PR': 'es-PR',
  'es-PY': 'es-PY',
  'es-SV': 'es-SV',
  'es-US': 'es-US',
  'es-UY': 'es-UY',
  'es-VE': 'es-VE',
  et: 'et',
  eu: 'eu',
  'eu-ES': 'eu-ES',
  fa: 'fa',
  fi: 'fi',
  'fil-PH': 'fil-PH',
  fo: 'fo',
  fr: 'fr',
  'fr-BE': 'fr-BE',
  'fr-CA': 'fr-CA',
  'fr-CH': 'fr-CH',
  'fr-FR': 'fr-FR',
  'fr-LU': 'fr-LU',
  'fr-MC': 'fr-MC',
  fy: 'fy',
  'fy-NL': 'fy-NL',
  ga: 'ga',
  'ga-IE': 'ga-IE',
  gd: 'gd',
  gl: 'gl',
  'gsw-FR': 'gsw-FR',
  gu: 'gu',
  ha: 'ha',
  'ha-Latn-NG': 'ha-Latn-NG',
  haw: 'haw',
  he: 'he',
  'he-IL': 'he-IL',
  hi: 'hi',
  'hi-IN': 'hi-IN',
  hmn: 'hmn',
  hr: 'hr',
  'hr-BA': 'hr-BA',
  'hr-HR': 'hr-HR',
  hsb: 'hsb',
  ht: 'ht',
  hu: 'hu',
  hy: 'hy',
  id: 'id',
  ig: 'ig',
  ii: 'ii',
  is: 'is',
  it: 'it',
  'it-CH': 'it-CH',
  'iu-Cans-CA': 'iu-Cans-CA',
  'iu-Latn-CA': 'iu-Latn-CA',
  iw: 'iw',
  ja: 'ja',
  'ja-JP': 'ja-JP',
  jw: 'jw',
  ka: 'ka',
  kk: 'kk',
  'kl-GL': 'kl-GL',
  km: 'km',
  kn: 'kn',
  ko: 'ko',
  'ko-KR': 'ko-KR',
  kok: 'kok',
  ku: 'ku',
  ky: 'ky',
  la: 'la',
  lb: 'lb',
  lo: 'lo',
  lt: 'lt',
  lv: 'lv',
  mi: 'mi',
  mk: 'mk',
  mg: 'mg',
  ml: 'mk',
  mn: 'mn',
  'mn-Mong-CN': 'mn-Mong-CN',
  'moh-CA': 'moh-CA',
  mr: 'mr',
  ms: 'ms',
  'ms-BN': 'ms-BN',
  'ms-MY': 'ms-MY',
  mt: 'mt',
  my: 'my',
  'nb-NO': 'nb-NO',
  ne: 'ne',
  nl: 'nl',
  'nl-BE': 'nl-BE',
  'nl-NL': 'nl-NL',
  'nn-NO': 'nn-NO',
  no: 'no',
  'nso-ZA': 'nso-ZA',
  ny: 'ny',
  'oc-FR': 'oc-FR',
  or: 'or',
  pa: 'pa',
  pl: 'pl',
  'prs-AF': 'prs-AF',
  ps: 'ps',
  pt: 'pt',
  'pt-BR': 'pt-BR',
  'pt-PT': 'pt-PT',
  'qut-GT': 'qut-GT',
  'quz-BO': 'quz-BO',
  'quz-EC': 'quz-EC',
  'quz-PE': 'quz-PE',
  'rm-CH': 'rm-CH',
  ro: 'ro',
  ru: 'ru',
  rw: 'rw',
  'sah-RU': 'sah-RU',
  sa: 'sa',
  sd: 'sd',
  'se-FI': 'se-FI',
  'se-NO': 'se-NO',
  se: 'se',
  si: 'si',
  sk: 'sk',
  sl: 'sl',
  sm: 'sm',
  'sma-NO': 'sma-NO',
  'sma-SE': 'sma-SE',
  'smj-NO': 'smj-NO',
  'smj-SE': 'smj-SE',
  'smn-FI': 'smn-FI',
  'sms-FI': 'sms-FI',
  sn: 'sn',
  so: 'so',
  sq: 'sq',
  sr: 'sr',
  'sr-Cyrl-BA': 'sr-Cyrl-BA',
  'sr-Cyrl-CS': 'sr-Cyrl-CS',
  'sr-Cyrl-ME': 'sr-Cyrl-ME',
  'sr-Cyrl-RS': 'sr-Cyrl-RS',
  'sr-Latn-BA': 'sr-Latn-BA',
  'sr-Latn-CS': 'sr-Latn-CS',
  'sr-Latn-ME': 'sr-Latn-ME',
  'sr-Latn-RS': 'sr-Latn-RS',
  st: 'st',
  su: 'su',
  sv: 'sv',
  'sv-FI': 'sv-FI',
  'sv-SE': 'sv-SE',
  sw: 'sw',
  'sw-KE': 'sw-KE',
  'syr-SY': 'syr-SY',
  ta: 'ta',
  te: 'te',
  tg: 'tg',
  'tg-Cyrl-TJ': 'tg-Cyrl-TJ',
  th: 'th',
  tk: 'tk',
  tl: 'tl',
  'tn-ZA': 'tn-ZA',
  tr: 'tr',
  tt: 'tt',
  'tzm-Latn-DZ': 'tzm-Latn-DZ',
  ug: 'ug',
  uk: 'uk',
  ur: 'ur',
  uz: 'uz',
  'uz-Cyrl-UZ': 'uz-Cyrl-UZ',
  'uz-Latn-UZ': 'uz-Latn-UZ',
  vi: 'vi',
  'wo-SN': 'wo-SN',
  xh: 'xh',
  'xh-ZA': 'xh-ZA',
  yi: 'yi',
  yo: 'yo',
  zh: 'zh',
  'zh-CN': 'zh-CN',
  'zh-HK': 'zh-HK',
  'zh-MO': 'zh-MO',
  'zh-SG': 'zh-SG',
  'zh-TW': 'zh-SG',
  zu: 'zu',
};

export default locales;
