import {
  ROUTE_INFERENCE_EVENTS,
  ROUTE_INFERENCE_EVENT,
} from '../../../Routes/routes';

const breadcrumbs = (t, id) => {
  const bc = [{
    title: t('Views.inferenceEventTitle'),
    route: `/${ROUTE_INFERENCE_EVENTS}`,
  }];
  if (id) {
    bc.push({
      title: id,
      route: `/${ROUTE_INFERENCE_EVENT}/${id}`,
    });
  }
  return bc;
};

export default breadcrumbs;
