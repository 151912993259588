import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ApexCharts from 'apexcharts';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';

import AddIcon from '@mui/icons-material/Add';

import ViewPage from '../../../Components/ViewPage';

import graphOptions from './graphOptions';

import {
  ROUTE_ANALYTICS,
  ROUTE_ANALYTICS_CUSTOM,
} from '../../../Routes/routes';

const breadcrumbs = (t) => [{
  title: t('Views.analyticsTitle'),
  route: `/${ROUTE_ANALYTICS}`,
}, {
  title: t('Analytics.customTitle'),
  route: `/${ROUTE_ANALYTICS_CUSTOM}`,
}];

const LINE_TYPE = 'LINE';
const BAR_TYPE = 'BAR';

const getSubTitle = (period) => {
  const map = {
    YEAR: 'the last 12 months',
    '3MONTHS': 'the last 3 months',
    '6MONTHS': 'the last 6 months',
  };
  return map[period];
};

let chart;

const selectableGoals = (available, selected) => available.filter((el) => !selected.includes(el));

function Custom({
  toggleDrawer,
  loading,
  data,
  getCustom,
  clearCustom,
  availableGoals,
  selectedGoals,
  addGoal,
  removeGoal,
}) {
  const { t } = useTranslation();
  const chartRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [type, setType] = useState(BAR_TYPE);
  const [timePeriod, setTimePeriod] = useState('YEAR');

  useEffect(() => {
    getCustom(timePeriod);
    return () => {
      clearCustom();
    };
  }, [timePeriod]);

  useEffect(() => {
    if (chartRef.current) {
      chart = new ApexCharts(chartRef.current, graphOptions(data, type));
      chart.render();
    }
  }, [chartRef, data, type]);

  useEffect(() => {
    if (chart) {
      chart.updateOptions(graphOptions(data, type, selectedGoals));
    }
  }, [data, type, selectedGoals]);

  const openMenu = (event) => setAnchorEl(event.currentTarget);
  const closeMenu = () => setAnchorEl(null);

  return (
    <ViewPage
      title={t('Analytics.customTitle')}
      toggleDrawer={toggleDrawer}
      loading={loading}
      breadcrumbs={breadcrumbs(t)}
    >
      <Box
        data-testid="Custom-View-Container"
        sx={{
          mt: 2,
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
        }}
      >
        <Card sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
          <CardHeader
            title="Custom Goals"
            titleTypographyProps={{ color: 'primary.main' }}
            subheader={`Goal details for ${getSubTitle(timePeriod)}`}
            action={(
              <Box sx={{ display: 'flex' }}>
                <Box sx={{ width: 220, mr: 2 }}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="chart-type">Group By</InputLabel>
                    <Select
                      labelId="chart-type"
                      id="chart-type"
                      label="Group By"
                      defaultValue={BAR_TYPE}
                      value={type}
                      onChange={(e) => setType(e.target.value)}
                    >
                      <MenuItem value={BAR_TYPE}>Totals</MenuItem>
                      <MenuItem value={LINE_TYPE}>5 Mins</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Box sx={{ width: 220 }}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="time-period">Time Period</InputLabel>
                    <Select
                      labelId="time-period"
                      id="time-period"
                      label="Time Period"
                      defaultValue="YEAR"
                      value={timePeriod}
                      onChange={(e) => setTimePeriod(e.target.value)}
                    >
                      <MenuItem value="3MONTHS">3 Months</MenuItem>
                      <MenuItem value="6MONTHS">6 Months</MenuItem>
                      <MenuItem value="YEAR">12 Months</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Box>
              )}
          />
          <CardContent
            sx={{
              display: 'flex',
              flex: 1,
              mr: 1,
              flexDirection: 'column',
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', flexWrap: 'wrap' }}>
              {(selectedGoals).map((_val) => (
                <Chip
                  label={_val}
                  key={_val}
                  sx={{ mr: 1, mt: 1 }}
                  onDelete={() => removeGoal(_val)}
                />
              ))}
              {(selectableGoals(availableGoals, selectedGoals)).length > 0 && (
                <>
                  <IconButton aria-label="add-goal" onClick={openMenu}>
                    <AddIcon />
                  </IconButton>
                  <Menu
                    id="goal-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={closeMenu}
                    MenuListProps={{ 'aria-labelledby': 'goal-button' }}
                  >
                    {(selectableGoals(availableGoals, selectedGoals) || []).map((_goal) => (
                      <MenuItem
                        key={_goal}
                        value={_goal}
                        onClick={() => {
                          addGoal(_goal);
                          closeMenu();
                        }}
                      >
                        {_goal}
                      </MenuItem>
                    ))}
                  </Menu>
                </>
              )}
            </Box>
            <Box ref={chartRef} sx={{ width: '100%', height: 300 }} />
          </CardContent>
        </Card>
      </Box>
    </ViewPage>
  );
}

Custom.propTypes = {
  toggleDrawer: PropTypes.func,
  loading: PropTypes.bool,
  data: PropTypes.object,
  getCustom: PropTypes.func,
  clearCustom: PropTypes.func,
  availableGoals: PropTypes.array,
  selectedGoals: PropTypes.array,
  addGoal: PropTypes.func,
  removeGoal: PropTypes.func,
};

Custom.defaultProps = {
  toggleDrawer: () => {
    // Default
  },
  loading: false,
  data: {},
  getCustom: () => {
    // Default
  },
  clearCustom: () => {
    // Default
  },
  availableGoals: [],
  selectedGoals: [],
  addGoal: () => {
    // Default
  },
  removeGoal: () => {
    // Default
  },
};

export default Custom;
