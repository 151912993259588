import {
  ADMIN_REDACTION_GET,
  ADMIN_REDACTION_GET_SUCCESS,
  ADMIN_REDACTION_GET_FAIL,
  ADMIN_REDACTION_CLEAR,
} from './types';
import { showAlert } from '../../state/actions';
import userUtils from '../../../util/user';
import isUnAuth from '../../../util/unauth';
import { logoutUser } from '../../auth/actions';
import { getRedactionTotals } from '../../analytics/redaction/actions';

export const getRedactionAttempt = () => ({
  type: ADMIN_REDACTION_GET,
});

export const getRedactionSuccess = (data) => ({
  type: ADMIN_REDACTION_GET_SUCCESS,
  payload: { data },
});

export const getRedactionFail = () => ({
  type: ADMIN_REDACTION_GET_FAIL,
});

export const getRedaction = (period = 'day') => async (dispatch, getState) => {
  const ERROR_MESSAGE = 'Failed to load data, please try again.';
  dispatch(getRedactionAttempt());
  try {
    const state = getState();
    const { authToken, authenticatedUser } = state.auth;
    const tenant = userUtils.getTenant(authenticatedUser);
    const region = userUtils.getRegion(authenticatedUser);
    const data = await getRedactionTotals(region, tenant, authToken, period);
    dispatch(getRedactionSuccess(data));
  } catch (_err) {
    const status = _err?.response?.status;
    if (isUnAuth(status)) {
      dispatch(logoutUser({ location: window.location.href }));
    } else {
      dispatch(showAlert({ type: 'error', message: ERROR_MESSAGE }));
      dispatch(getRedactionFail());
    }
  }
};

export const clearRedaction = () => ({ type: ADMIN_REDACTION_CLEAR });
