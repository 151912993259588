import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import EditIcon from '@mui/icons-material/Edit';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';

import Queue from './Queue';
import Create from './Create';
import Delete from './Delete';
import SortableColumn, { ASC } from '../../../Components/Table/SortableColumn';
import sorting from '../../../util/sorting';

import ViewPage from '../../../Components/ViewPage';
import breadcrumbs from './breadcrumbs';

const COLUMNS = [{
  title: 'Name',
  sort: true,
  sortKey: 'name',
}, {
  title: 'Description',
  sort: true,
  sortKey: 'description',
}, {
  title: 'Sources',
  sort: false,
  sortKey: '',
}, {
  title: 'Assigned Users',
  sort: false,
  sortKey: '',
}, {
  title: '',
  sort: false,
  sortKey: '',
}];

const searchSource = (_source, term) => _source?.resourceName?.toLowerCase()?.includes(term);
const searchUser = (_user, term) => _user?.userId?.toLowerCase()?.includes(term);

const filterAndSort = (searchterm, array, sortOptions) => {
  let filtered = [];
  try {
    if (!searchterm) {
      filtered = array;
    }
    const term = searchterm.toLowerCase();
    filtered = array.filter((queue) => queue?.name?.toLowerCase().includes(term)
    || queue?.description?.toLowerCase()?.includes(term)
    || queue?.sourcesFilter?.sources?.find((_source) => searchSource(_source, term))
    || queue?.permittedUsers?.users?.find((_user) => searchUser(_user, term)));
  } catch (_err) {
    filtered = [];
  }
  return sorting.sortByOptions(filtered, sortOptions);
};

function Queues({
  loading,
  toggleDrawer,
  listQueues,
  queues,
  clearQueues,
}) {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState({
    title: 'Name',
    sortKey: 'name',
    direction: ASC,
  });
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [selectedQueue, setSelectedQueue] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    listQueues();
    return () => {
      clearQueues();
    };
  }, []);

  const refreshQueues = () => {
    listQueues();
  };

  const openEditModal = (index) => {
    setSelectedQueue(index);
    setShowEdit(true);
  };

  const closeEditModal = () => {
    setShowEdit(false);
    setSelectedQueue(null);
  };

  const openDeleteModal = (index) => {
    setSelectedQueue(index);
    setShowDelete(true);
  };

  const closeDeleteModal = () => {
    setShowDelete(false);
    setSelectedQueue(null);
  };

  const filteredAndSorted = filterAndSort(search, queues, sort);
  const rowsToDisplay = rowsPerPage > 0
    ? filteredAndSorted.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : filteredAndSorted;

  return (
    <ViewPage
      title={t('Admin.queuesTitle')}
      toggleDrawer={toggleDrawer}
      breadcrumbs={breadcrumbs(t)}
      loading={loading}
    >
      <Box
        data-testid="Queues-View-Container"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          mt: 2,
          width: '100%',
          mb: 2,
        }}
      >
        <Card sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
          <CardHeader
            title="Queues"
            titleTypographyProps={{ color: 'primary.main' }}
            subheader="Available Queues"
            action={(
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  autoComplete="off"
                  size="small"
                  placeholder="Search"
                  id="Search-Field"
                  sx={{ mr: 2 }}
                  onChange={(e) => {
                    setPage(0);
                    setSearch(e.target.value);
                  }}
                  value={search}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <Tooltip title="Refresh Queues" placement="top">
                  <IconButton aria-label="Refresh Queues" onClick={refreshQueues}>
                    <RefreshIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Add new Queue" placement="top" onClick={() => setShowCreate(true)}>
                  <IconButton aria-label="Add Queue">
                    <AddCircleOutlineIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          />
          <CardContent sx={{ display: 'flex', flex: 1, mr: 1 }}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    {COLUMNS.map((_col) => (
                      <SortableColumn
                        key={_col?.title}
                        column={_col}
                        sort={sort}
                        setSort={setSort}
                      />
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsToDisplay.map((row, index) => (
                    <TableRow key={`${row.id}${row.name}`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.description}</TableCell>
                      <TableCell>
                        <Stack direction="row" spacing={1}>
                          {(row?.sourcesFilter?.sources || []).map((_source) => (
                            <Chip
                              key={_source.resourceName}
                              label={_source.resourceName}
                            />
                          ))}
                        </Stack>
                      </TableCell>
                      <TableCell>
                        <Stack direction="row" spacing={1}>
                          {(row?.permittedUsers?.users || []).map((_user) => (
                            <Chip
                              key={_user.userId}
                              label={_user.userId}
                            />
                          ))}
                        </Stack>
                      </TableCell>
                      <TableCell align="right">
                        <Tooltip title="Edit Queue" placement="top">
                          <IconButton aria-label="Edit Queue" onClick={() => openEditModal(index)}>
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete Queue" placement="top">
                          <IconButton aria-label="Delete Queue" onClick={() => openDeleteModal(index)}>
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[10, 20, 50, { label: 'All', value: -1 }]}
                      colSpan={COLUMNS.length}
                      count={search ? filteredAndSorted.length : queues.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          'aria-label': 'rows per page',
                        },
                        native: true,
                      }}
                      onPageChange={(e, _page) => setPage(_page)}
                      onRowsPerPageChange={(e) => {
                        setRowsPerPage(parseInt(e.target.value, 10));
                        setPage(0);
                      }}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </Box>
      {showEdit && (
        <Queue
          selectedQueue={rowsToDisplay?.[selectedQueue] || null}
          onClose={() => closeEditModal()}
        />
      )}
      {showDelete && (
        <Delete
          selectedQueue={rowsToDisplay?.[selectedQueue] || null}
          onClose={() => closeDeleteModal()}
        />
      )}
      {showCreate && <Create onClose={() => setShowCreate(false)} />}
    </ViewPage>
  );
}

Queues.propTypes = {
  toggleDrawer: PropTypes.func,
  loading: PropTypes.bool,
  listQueues: PropTypes.func,
  queues: PropTypes.array,
  clearQueues: PropTypes.func,
};

Queues.defaultProps = {
  toggleDrawer: () => {
    // Default
  },
  loading: false,
  listQueues: () => {
    // Default
  },
  queues: [],
  clearQueues: () => {
    // Default
  },
};

export default Queues;
