const user = {
  getTenant: (decodedToken) => {
    const tenant = decodedToken?.['custom:org'] || '';
    return tenant;
  },
  getRegion: (decodedToken) => {
    const region = decodedToken?.['custom:sb_region'] || '';
    return region;
  },
};

export default user;
