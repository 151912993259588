import React from 'react';
import PropTypes from 'prop-types';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';

import {
  ROUTE_WILDCARD,
  ROUTE_HOME,
  ROUTE_OPERATIONAL_INSIGHTS,
  ROUTE_OPERATIONAL_INSIGHTS_TREATMENT,
  ROUTE_OPERATIONAL_INSIGHTS_TREATMENT_INFERENCE_EVENTS,
  ROUTE_OPERATIONAL_INSIGHTS_TREATMENT_INFERENCE_EVENT,
  ROUTE_OPERATIONAL_INSIGHTS_DASHBOARD,
  ROUTE_OPERATIONAL_INSIGHTS_DASHBOARD_SOURCE,
  ROUTE_OPERATIONAL_INSIGHTS_HISTORICAL,
  ROUTE_OPERATIONAL_INSIGHTS_DISCUSSION,
  ROUTE_OPERATIONAL_INSIGHTS_UNSUPERISED_JOBS,
  ROUTE_COMPLIANCE,
  ROUTE_COMPLIANCE_CHANGESET,
  ROUTE_COMPLIANCE_CHANGESET_IMPACT_ANALYSIS,
  ROUTE_DESIGN_INSIGHTS,
  ROUTE_DESIGN_INSIGHTS_DESIGN_REGISTRY,
  ROUTE_DESIGN_INSIGHTS_DESIGN_REGISTRY_DESIGN,
  ROUTE_DESIGN_INSIGHTS_DESIGN_REGISTRY_REPORT,
  ROUTE_DESIGN_INSIGHTS_ACCURACY_SUBMISSION,
  ROUTE_DESIGN_INSIGHTS_ACCURACY_REPORT,
  ROUTE_DESIGN_INSIGHTS_ACCURACY_OPTIMIZE,
  ROUTE_ANALYTICS,
  ROUTE_ANALYTICS_USAGE,
  ROUTE_ANALYTICS_PERFORMANCE,
  ROUTE_ANALYTICS_CUSTOM,
  ROUTE_ANALYTICS_LEARNING,
  ROUTE_ANALYTICS_TREATMENT,
  ROUTE_ANALYTICS_REDACTION,
  ROUTE_ADMIN,
  ROUTE_ADMIN_CONFIGURATION,
  ROUTE_ADMIN_USERS,
  ROUTE_ADMIN_QUEUES,
  ROUTE_ADMIN_BILLING,
  ROUTE_ADMIN_TREATMENT,
  ROUTE_ADMIN_REDACTION,
  ROUTE_INFERENCE_EVENT,
} from './routes';

// General
import Home from '../Views/Home';
// OperationalInsights
import OperationalInsights from '../Views/OperationalInsights';
import OperationalInsightsTreatment from '../Views/OperationalInsights/Treatment';
import OperationalInsightsTreatmentInferenceEvents from '../Views/OperationalInsights/Treatment/InferenceEvents';
import OperationalInsightsTreatmentInferenceEvent from '../Views/OperationalInsights/Treatment/InferenceEvents/Event';
import OperationalInsightsDashBoard from '../Views/OperationalInsights/Dashboard';
import OperationalInsightsDashBoardSource from '../Views/OperationalInsights/Dashboard/Source';
import OperationalInsightsDiscussion from '../Views/OperationalInsights/Discussion';
import OperationalInsightsHistorical from '../Views/OperationalInsights/Historical';
import OperationalInsightsUnsuperviedJobs from '../Views/OperationalInsights/UnsupervisedJobs';
// Compliance
import Compliance from '../Views/Compliance';
import ComplianceChangeSet from '../Views/Compliance/ChangeSet';
import ComplianceChangeSetImpactAnalysis from '../Views/Compliance/ImpactAnalysis';
// Design Insights
import DesignInsights from '../Views/DesignInsights';
import DesignRegistry from '../Views/DesignInsights/DesignRegistry';
import DesignRegistryDesign from '../Views/DesignInsights/DesignRegistry/Design';
import DesignRegistryReport from '../Views/DesignInsights/DesignRegistry/Report';
import DesingInsightsOptimize from '../Views/DesignInsights/Optimize';
import DesignInsightsReport from '../Views/DesignInsights/Report';
import DesignInsightsSubmission from '../Views/DesignInsights/Submission';
// Analytics
import Analytics from '../Views/Analytics';
import UsageAnalytics from '../Views/Analytics/Usage';
import PerformanceAnalytics from '../Views/Analytics/Performance';
import CustomAnalytics from '../Views/Analytics/Custom';
import LearningAnalytics from '../Views/Analytics/Learning';
import TreatmentAnalytics from '../Views/Analytics/Treatment';
import RedactionAnalytics from '../Views/Analytics/Redaction';
// Admin
import Admin from '../Views/Admin';
import AdminUsers from '../Views/Admin/Users';
import AdminConfiguration from '../Views/Admin/Configuration';
import AdminQueues from '../Views/Admin/Queues';
import AdminBilling from '../Views/Admin/Billing';
import AdminTreatments from '../Views/Admin/Treatment';
import AdminRedaction from '../Views/Admin/Redaction';
// Inference Events
import EventView from '../Views/InferenceEvents/Event';

import ErrorBoundary from '../Components/ErrorBoundary';
import Drawer from '../Components/Drawer';

function Authenticated({
  drawerOpen,
  toggleDrawer,
}) {
  const ROUTES = [{
    path: ROUTE_HOME,
    element: Home,
    props: { toggleDrawer },
  }, {
    path: ROUTE_OPERATIONAL_INSIGHTS,
    element: OperationalInsights,
    props: { toggleDrawer },
  }, {
    path: ROUTE_OPERATIONAL_INSIGHTS_TREATMENT,
    element: OperationalInsightsTreatment,
    props: { toggleDrawer },
  }, {
    path: ROUTE_OPERATIONAL_INSIGHTS_TREATMENT_INFERENCE_EVENTS,
    element: OperationalInsightsTreatmentInferenceEvents,
    props: { toggleDrawer },
  }, {
    path: ROUTE_OPERATIONAL_INSIGHTS_TREATMENT_INFERENCE_EVENT,
    element: OperationalInsightsTreatmentInferenceEvent,
    props: { toggleDrawer },
  }, {
    path: ROUTE_OPERATIONAL_INSIGHTS_DASHBOARD,
    element: OperationalInsightsDashBoard,
    props: { toggleDrawer },
  }, {
    path: ROUTE_OPERATIONAL_INSIGHTS_DASHBOARD_SOURCE,
    element: OperationalInsightsDashBoardSource,
    props: { toggleDrawer },
  }, {
    path: ROUTE_OPERATIONAL_INSIGHTS_HISTORICAL,
    element: OperationalInsightsHistorical,
    props: { toggleDrawer },
  }, {
    path: ROUTE_OPERATIONAL_INSIGHTS_DISCUSSION,
    element: OperationalInsightsDiscussion,
    props: { toggleDrawer },
  }, {
    path: ROUTE_OPERATIONAL_INSIGHTS_UNSUPERISED_JOBS,
    element: OperationalInsightsUnsuperviedJobs,
    props: { toggleDrawer },
  }, {
    path: ROUTE_COMPLIANCE,
    element: Compliance,
    props: { toggleDrawer },
  }, {
    path: ROUTE_COMPLIANCE_CHANGESET,
    element: ComplianceChangeSet,
    props: { toggleDrawer },
  }, {
    path: ROUTE_COMPLIANCE_CHANGESET_IMPACT_ANALYSIS,
    element: ComplianceChangeSetImpactAnalysis,
    props: { toggleDrawer },
  }, {
    path: ROUTE_DESIGN_INSIGHTS,
    element: DesignInsights,
    props: { toggleDrawer },
  }, {
    path: ROUTE_DESIGN_INSIGHTS_DESIGN_REGISTRY,
    element: DesignRegistry,
    props: { toggleDrawer },
  }, {
    path: ROUTE_DESIGN_INSIGHTS_DESIGN_REGISTRY_DESIGN,
    element: DesignRegistryDesign,
    props: { toggleDrawer },
  }, {
    path: ROUTE_DESIGN_INSIGHTS_DESIGN_REGISTRY_REPORT,
    element: DesignRegistryReport,
    props: { toggleDrawer },
  }, {
    path: ROUTE_DESIGN_INSIGHTS_ACCURACY_SUBMISSION,
    element: DesignInsightsSubmission,
    props: { toggleDrawer },
  }, {
    path: ROUTE_DESIGN_INSIGHTS_ACCURACY_REPORT,
    element: DesignInsightsReport,
    props: { toggleDrawer },
  }, {
    path: ROUTE_DESIGN_INSIGHTS_ACCURACY_OPTIMIZE,
    element: DesingInsightsOptimize,
    props: { toggleDrawer },
  }, {
    path: ROUTE_ANALYTICS,
    element: Analytics,
    props: { toggleDrawer },
  }, {
    path: ROUTE_ANALYTICS_USAGE,
    element: UsageAnalytics,
    props: { toggleDrawer },
  }, {
    path: ROUTE_ANALYTICS_PERFORMANCE,
    element: PerformanceAnalytics,
    props: { toggleDrawer },
  }, {
    path: ROUTE_ANALYTICS_CUSTOM,
    element: CustomAnalytics,
    props: { toggleDrawer },
  }, {
    path: ROUTE_ANALYTICS_LEARNING,
    element: LearningAnalytics,
    props: { toggleDrawer },
  }, {
    path: ROUTE_ANALYTICS_TREATMENT,
    element: TreatmentAnalytics,
    props: { toggleDrawer },
  }, {
    path: ROUTE_ANALYTICS_REDACTION,
    element: RedactionAnalytics,
    props: { toggleDrawer },
  }, {
    path: ROUTE_ADMIN,
    element: Admin,
    props: { toggleDrawer },
  }, {
    path: ROUTE_ADMIN_CONFIGURATION,
    element: AdminConfiguration,
    props: { toggleDrawer },
  }, {
    path: ROUTE_ADMIN_USERS,
    element: AdminUsers,
    props: { toggleDrawer },
  }, {
    path: ROUTE_ADMIN_QUEUES,
    element: AdminQueues,
    props: { toggleDrawer },
  }, {
    path: ROUTE_ADMIN_BILLING,
    element: AdminBilling,
    props: { toggleDrawer },
  }, {
    path: ROUTE_ADMIN_TREATMENT,
    element: AdminTreatments,
    props: { toggleDrawer },
  }, {
    path: ROUTE_ADMIN_REDACTION,
    element: AdminRedaction,
    props: { toggleDrawer },
  }, {
    path: ROUTE_INFERENCE_EVENT,
    element: EventView,
    props: { toggleDrawer },
  }];
  return (
    <BrowserRouter>
      <Drawer open={drawerOpen} toggleDrawer={toggleDrawer} />
      <Routes>
        {/* General */}
        <Route
          path={ROUTE_WILDCARD}
          element={<Navigate push to={ROUTE_HOME} />}
        />
        {ROUTES.map((_route) => {
          const Component = _route.element;
          return (
            <Route
              key={_route.path}
              path={_route.path}
              element={<ErrorBoundary><Component {..._route.props} /></ErrorBoundary>}
            />
          );
        })}
      </Routes>
    </BrowserRouter>
  );
}

Authenticated.propTypes = {
  drawerOpen: PropTypes.bool,
  toggleDrawer: PropTypes.func,
};

Authenticated.defaultProps = {
  drawerOpen: false,
  toggleDrawer: () => {
    // Default
  },
};

export default Authenticated;
