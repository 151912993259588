const i18n = {
  en: {
    homeTitle: 'Home',
    operationalInsightsTitle: 'Operational Insights',
    complianceTitle: 'Compliance',
    designInsightsTitle: 'Design Insights',
    analyticsTitle: 'Analytics',
    adminTitle: 'Admin',
    inferenceEventTitle: 'Inference Event',
  },
  fr: {
    homeTitle: 'Domicile',
    operationalInsightsTitle: 'Informations Opérationnelles',
    complianceTitle: 'Conformité',
    designInsightsTitle: 'Aperçus de conception',
    analyticsTitle: 'Analytique',
    adminTitle: 'Administrateur',
  },
};

export default i18n;
