import moment from 'moment';
import i18next from 'i18next';

import {
  BLUE_ONE,
  BLUE_TWO,
  BLUE_THREE,
} from '../../../colors';

export const pieChartOptions = () => {
  const options = {
    series: [50, 100, 150],
    chart: {
      width: 300,
      type: 'pie',
    },
    labels: ['Ignored', 'Utterance Added', 'Intent Created'],
    colors: [BLUE_ONE, BLUE_TWO, BLUE_THREE],
    legend: {
      show: true,
      position: 'bottom',
    },
    plotOptions: {
      pie: {
        dataLabels: {
          offset: -20,
        },
      },
    },
  };
  return options;
};

export const selectedGraphOptions = (selectedData) => {
  const index = selectedData?.index || 0;
  let series = [];
  let labels = [];

  const titles = ['Inference Events', 'Missed Input', 'Treatments'];
  const colors = [BLUE_ONE, BLUE_TWO, BLUE_THREE];
  if (selectedData) {
    const { data } = selectedData;
    series = [{
      name: titles[index],
      data: data?.map((_dat) => {
        const ie = _dat?.inferenceEvents || 0;
        const mi = _dat?.missedinput || 0;
        const t = _dat?.has_treatment || 0;
        const collection = [ie, mi, t];
        return collection[index];
      }),
    }];
    labels = data?.map((_dat) => _dat.name);
  }
  return {
    chart: {
      type: 'bar',
      height: '400px',
      toolbar: {
        show: false,
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
      },
      selection: {
        enabled: false,
      },
    },
    series,
    labels,
    colors: colors[index],
    dataLabels: {
      enabled: false,
    },
  };
};

const graphOptions = (data, onSelect) => {
  const inferenceEvents = (data?.periodValues || []).map((_per) => _per?.values?.inferenceEvents);
  const missedInputs = (data?.periodValues || []).map((_per) => _per?.values?.missedinput);
  const treatments = (data?.periodValues || []).map((_per) => _per?.values?.treatments);
  const series = [{
    name: 'Inference Events',
    data: inferenceEvents,
    sources: (data?.periodValues || []).map((_per) => _per?.values?.sources || []),
  }, {
    name: 'Missed Inputs',
    data: missedInputs,
    sources: (data?.periodValues || []).map((_per) => _per?.values?.sources || []),
  }, {
    name: 'Treatments',
    data: treatments,
    sources: (data?.periodValues || []).map((_per) => _per?.values?.sources || []),
  }];
  const labels = (data?.periodValues || []).map((_per) => moment(_per?.period).format(i18next.t('moment.monthDay')));
  return {
    chart: {
      type: 'bar',
      height: '400px',
      toolbar: {
        show: false,
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
      },
      events: {
        dataPointSelection(event, chartContext, config) {
          const {
            dataPointIndex,
            seriesIndex,
            selectedDataPoints,
            w,
          } = config;
          if (selectedDataPoints[seriesIndex].length === 0) {
            return onSelect(null);
          }
          return onSelect({
            index: seriesIndex,
            data: w?.config?.series?.[seriesIndex]?.sources?.[dataPointIndex],
          });
        },
      },
    },
    series,
    labels,
    colors: [BLUE_ONE, BLUE_TWO, BLUE_THREE],
    tooltip: {
      y: {
        formatter: (_val) => `${_val}`,
      },
    },
    dataLabels: {
      enabled: false,
    },
  };
};

export default graphOptions;
