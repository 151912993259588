import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import InsightsIcon from '@mui/icons-material/Insights';

import {
  ROUTE_COMPLIANCE,
} from '../../../Routes/routes';

function Actions({
  data,
  selectedRows,
}) {
  const navigate = useNavigate();
  if (!selectedRows?.length || selectedRows?.length === 0) {
    return null;
  }
  const event = selectedRows.length === 1
    ? data.find((_event) => _event.id === selectedRows[0])
    : data.filter((_event) => selectedRows.includes(_event.id));
  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} data-testid="Actions-Container">
      {selectedRows.length === 1 && (
        <>
          <Tooltip title="Impact Analysis" placement="top">
            <IconButton
              data-testid="ImpactAnalysis-Button"
              aria-label="View"
              onClick={() => navigate(`/${ROUTE_COMPLIANCE}/${event?.id}/impact-analysis`)}
            >
              <InsightsIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="View Change Set" placement="top">
            <IconButton
              data-testid="ViewChangeSet-Button"
              aria-label="View"
              onClick={() => navigate(`/${ROUTE_COMPLIANCE}/${event?.id}/changeset`)}
            >
              <MoreHorizIcon />
            </IconButton>
          </Tooltip>
        </>
      )}
      {selectedRows.length > 1 && (
        <Tooltip title="Impact Analysis" placement="top">
          <IconButton
            data-testid="ImpactAnalysis-Button"
            aria-label="View"
            onClick={() => navigate(`/${ROUTE_COMPLIANCE}/${event?.id}/impact-analysis`)}
          >
            <InsightsIcon />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
}

Actions.propTypes = {
  selectedRows: PropTypes.array,
  data: PropTypes.array,
};

Actions.defaultProps = {
  selectedRows: [],
  data: [],
};

export default Actions;
