import {
  INBOX_TREATMENT_GET_INFERENCE_EVENT,
  INBOX_TREATMENT_GET_INFERENCE_EVENT_SUCCESS,
  INBOX_TREATMENT_GET_INFERENCE_EVENT_FAIL,
  INBOX_TREATMENT_GET_INFERENCE_EVENT_CLEAR,
} from './types';
import { showAlert } from '../../../../state/actions';
import userUtils from '../../../../../util/user';
import isUnAuth from '../../../../../util/unauth';
import interceptor, { getInboxUrl } from '../../../../../util/interceptor';
import { logoutUser } from '../../../../auth/actions';

export const getEventAttempt = () => ({ type: INBOX_TREATMENT_GET_INFERENCE_EVENT });
export const getEventSuccess = (event) => ({
  type: INBOX_TREATMENT_GET_INFERENCE_EVENT_SUCCESS,
  payload: { event },
});
export const getEventFail = () => ({ type: INBOX_TREATMENT_GET_INFERENCE_EVENT_FAIL });

export const getEvent = (inferenceEventId) => async (dispatch, getState) => {
  const ERROR_MESSAGE = 'Failed to list Events, please try again.';
  dispatch(getEventAttempt());
  try {
    const state = getState();
    const { authToken, authenticatedUser } = state.auth;
    const tenant = userUtils.getTenant(authenticatedUser);
    const region = userUtils.getRegion(authenticatedUser);
    await interceptor({
      method: 'post',
      url: `${getInboxUrl(region)}/${tenant}/DescribeInferenceEvent`,
      data: {
        inferenceEventId,
        view: 'raw',
        properties: {},
      },
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    });
    dispatch(getEventSuccess({}));
  } catch (_err) {
    const status = _err?.response?.status;
    if (isUnAuth(status)) {
      dispatch(logoutUser({ location: window.location.href }));
    } else {
      dispatch(getEventFail());
      dispatch(showAlert({ type: 'error', message: ERROR_MESSAGE }));
    }
  }
};

export const clearEvent = () => ({ type: INBOX_TREATMENT_GET_INFERENCE_EVENT_CLEAR });
