import { connect } from 'react-redux';

import Design from './Design';
import { getBotDesign, clearBotDesign } from '../../../../store/designInsights/designRegistry/design/actions';

const mapStateToProps = (state) => ({
  loading: state?.designInsights?.designRegistry?.design?.loading,
  design: state?.designInsights?.designRegistry?.design?.design,
});

const mapDispatchToProps = (dispatch) => ({
  getBotDesign: (designId) => dispatch(getBotDesign(designId)),
  clearBotDesign: () => dispatch(clearBotDesign()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Design);
