import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

function AppBarBreadCrumbs({
  breadcrumbs,
}) {
  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      sx={{
        '& .MuiBreadcrumbs-separator': {
          color: 'white',
        },
      }}
    >
      {breadcrumbs.map((breadcrumb) => (
        <Link
          key={breadcrumb?.title}
          underline="hover"
          style={{ color: 'white' }}
          to={breadcrumb?.route}
          component={RouterLink}
        >
          {breadcrumb?.title}
        </Link>
      ))}
    </Breadcrumbs>
  );
}

AppBarBreadCrumbs.propTypes = {
  breadcrumbs: PropTypes.array,
};

AppBarBreadCrumbs.defaultProps = {
  breadcrumbs: [],
};

export default AppBarBreadCrumbs;
