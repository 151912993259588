import jwtdecode from 'jwt-decode';
import {
  AUTH_LOG_OUT,
  AUTH_REHYDRATE_USER,
  AUTH_REHYDRATE_USER_SUCCESS,
  AUTH_REHYDRATE_USER_FAIL,
  AUTH_AUTHENTICATE_USER,
  AUTH_AUTHENTICATE_USER_SUCCESS,
  AUTH_AUTHENTICATE_USER_FAIL,
} from './types';
import {
  DASHBOARD_FETCH_TOTALS_SUCCESS,
} from '../inbox/dashboard/types';

export const initialState = {
  isAuthenticating: true,
  authenticated: false,
  authToken: '',
  authenticatedUser: null,
  logoutReason: '',
  redirectLocation: '',
  queues: [],
  discovery: {},
  authenticationErrorMessage: '',
};

const reducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case AUTH_LOG_OUT: {
      const { reason, location } = action.payload;
      return {
        ...state,
        isAuthenticating: false,
        logoutReason: reason,
        redirectLocation: location,
        queues: [],
        discovery: {},
        authenticationErrorMessage: '',
      };
    }
    case AUTH_REHYDRATE_USER: {
      return {
        ...state,
        isAuthenticating: true,
        authenticated: false,
        authToken: '',
        authenticatedUser: null,
        queues: [],
        discovery: {},
        authenticationErrorMessage: '',
      };
    }
    case AUTH_REHYDRATE_USER_SUCCESS: {
      const { token, discovery } = action.payload;
      return {
        ...state,
        isAuthenticating: false,
        authenticated: true,
        authToken: token,
        authenticatedUser: jwtdecode(token),
        queues: [],
        discovery,
        authenticationErrorMessage: '',
      };
    }
    case AUTH_REHYDRATE_USER_FAIL: {
      const { error } = action.payload;
      return {
        ...state,
        isAuthenticating: false,
        authenticated: false,
        authToken: '',
        authenticatedUser: null,
        queues: [],
        discovery: {},
        authenticationErrorMessage: error,
      };
    }
    case AUTH_AUTHENTICATE_USER: {
      return {
        ...state,
        isAuthenticating: true,
        authenticated: false,
        authToken: '',
        authenticatedUser: null,
        queues: [],
        discovery: {},
        authenticationErrorMessage: '',
      };
    }
    case AUTH_AUTHENTICATE_USER_SUCCESS: {
      const { token, queues, discovery } = action.payload;
      return {
        ...state,
        isAuthenticating: false,
        authenticated: true,
        authToken: token,
        authenticatedUser: jwtdecode(token),
        queues,
        discovery,
        authenticationErrorMessage: '',
      };
    }
    case AUTH_AUTHENTICATE_USER_FAIL: {
      const { error } = action.payload;
      return {
        ...state,
        isAuthenticating: false,
        authenticated: false,
        authToken: '',
        authenticatedUser: null,
        queues: [],
        discovery: {},
        authenticationErrorMessage: error,
      };
    }
    case DASHBOARD_FETCH_TOTALS_SUCCESS: {
      const { queues } = action.payload;
      return {
        ...state,
        queues,
      };
    }
    default:
      return state;
  }
};

export default reducer;
