import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import { AUTH_LOG_OUT } from './auth/types';
import authReducer from './auth/reducer';
import stateReducer from './state/reducer';
import inbox from './inbox';
import admin from './admin';
import designInsights from './designInsights';
import analytics from './analytics';
import compliance from './compliance';
import inferenceEvents from '../Views/InferenceEvents/store';

const appReducers = combineReducers({
  auth: authReducer,
  state: stateReducer,
  inbox,
  admin,
  designInsights,
  analytics,
  compliance,
  inferenceEvents,
});

/*
* Any time the AUTH_LOG_OUT action is fired
* we want to completely nuke all state the app has
* to prevent stale state being presented to the user
*/
const rootReducer = (state, action) => {
  if (action.type === AUTH_LOG_OUT) {
    return appReducers(undefined, action);
  }
  return appReducers(state, action);
};

const composeEnhancers = composeWithDevTools({});
const store = () => createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

export default store;
