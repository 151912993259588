import { connect } from 'react-redux';
import Redirect from './Redirect';

const mapStateToProps = (state) => ({
  reason: state?.auth?.logoutReason,
  location: state?.auth?.redirectLocation,
  errorMessage: state?.auth?.authenticationErrorMessage,
});

export default connect(mapStateToProps)(Redirect);
