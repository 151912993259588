import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import FlowStats from './FlowStats';
import IntentStats from './IntentStats';
import ResponseStats from './ResponseStats';
import GoalStats from './GoalStats';
import ExternalStats from './ExternalStats';

function Stats({
  data,
}) {
  const [expanded, setExpanded] = useState(false);
  const SECTIONS = [{
    title: 'Flow',
    data: data?.flowStats || {},
    component: FlowStats,
  }, {
    title: 'Intent',
    data: data?.intentStats || {},
    component: IntentStats,
  }, {
    title: 'Response',
    data: data?.responseStats || {},
    component: ResponseStats,
  }, {
    title: 'Goal',
    data: data?.goalStats || {},
    component: GoalStats,
  }, {
    title: 'External References',
    data: data?.externalHttpRefs || {},
    component: ExternalStats,
  }];
  return (
    <Box
      data-testid="Stats"
      sx={{
        display: 'flex',
        mt: 2,
        flexDirection: 'column',
        flexWrap: 'wrap',
      }}
    >
      {SECTIONS.map((_section) => {
        const Component = _section.component;
        return (
          <Accordion
            key={_section.title}
            disableGutters
            square
            onChange={(e, exp) => {
              if (exp) {
                setExpanded(_section.title);
              } else {
                setExpanded('');
              }
            }}
            expanded={expanded === _section.title}
            sx={{ background: 'transparent', boxShadow: 'none' }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6" color="text.secondary">{_section.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Component data={_section?.data || {}} />
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
}

Stats.propTypes = {
  data: PropTypes.object,
};

Stats.defaultProps = {
  data: {},
};

export default Stats;
