import {
  ROUTE_OPERATIONAL_INSIGHTS,
  ROUTE_OPERATIONAL_INSIGHTS_DASHBOARD,
} from '../../../../Routes/routes';

const breadcrumbs = (t, sourceId) => {
  const bc = [{
    title: t('Views.operationalInsightsTitle'),
    route: `/${ROUTE_OPERATIONAL_INSIGHTS}`,
  }, {
    title: t('OperationalInsights.inboxTitle'),
    route: `/${ROUTE_OPERATIONAL_INSIGHTS_DASHBOARD}`,
  }];
  if (sourceId) {
    bc.push({
      title: t('OperationalInsights.sourceTitle'),
      route: `/${ROUTE_OPERATIONAL_INSIGHTS_DASHBOARD}/source/${sourceId}`,
    });
    bc.push({
      title: sourceId,
      route: `/${ROUTE_OPERATIONAL_INSIGHTS_DASHBOARD}/source/${sourceId}`,
    });
  }
  return bc;
};

export default breadcrumbs;
