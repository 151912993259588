import { connect } from 'react-redux';

import ChangeSet from './ChangeSet';
import { getChangeSet, clearChangeSet } from '../../../store/compliance/changeset/actions';

const mapStateToProps = (state) => ({
  loading: state?.compliance?.changeSet?.loading,
  data: state?.compliance?.changeSet?.data,
});

const mapDispatchToProps = (dispatch) => ({
  getChangeSet: (id) => dispatch(getChangeSet(id)),
  clearChangeSet: () => dispatch(clearChangeSet()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangeSet);
