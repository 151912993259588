export const ADMIN_QUEUES_LIST = 'ADMIN_QUEUES_LIST';
export const ADMIN_QUEUES_LIST_SUCCESS = 'ADMIN_QUEUES_LIST_SUCCESS';
export const ADMIN_QUEUES_LIST_FAIL = 'ADMIN_QUEUES_LIST_FAIL';

export const ADMIN_QUEUE_EDIT = 'ADMIN_QUEUE_EDIT';
export const ADMIN_QUEUE_EDIT_SUCCESS = 'ADMIN_QUEUE_EDIT_SUCCESS';
export const ADMIN_QUEUE_EDIT_FAIL = 'ADMIN_QUEUE_EDIT_FAIL';

export const ADMIN_QUEUE_CREATE = 'ADMIN_QUEUE_CREATE';
export const ADMIN_QUEUE_CREATE_SUCCESS = 'ADMIN_QUEUE_CREATE_SUCCESS';
export const ADMIN_QUEUE_CREATE_FAIL = 'ADMIN_QUEUE_CREATE_FAIL';

export const ADMIN_QUEUE_DELETE = 'ADMIN_QUEUE_DELETE';
export const ADMIN_QUEUE_DELETE_SUCCESS = 'ADMIN_QUEUE_DELETE_SUCCESS';
export const ADMIN_QUEUE_DELETE_FAIL = 'ADMIN_QUEUE_DELETE_FAIL';

export const ADMIN_QUEUES_CLEAR = 'ADMIN_QUEUES_CLEAR';
