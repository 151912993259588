export const ROUTE_WILDCARD = '*';

export const ROUTE_HOME = 'home';

export const ROUTE_OPERATIONAL_INSIGHTS = 'operational-insights';
export const ROUTE_OPERATIONAL_INSIGHTS_TREATMENT = `${ROUTE_OPERATIONAL_INSIGHTS}/treatment`;
export const ROUTE_OPERATIONAL_INSIGHTS_TREATMENT_INFERENCE_EVENTS = `${ROUTE_OPERATIONAL_INSIGHTS}/treatment/inference-events/:queueId`;
export const ROUTE_OPERATIONAL_INSIGHTS_TREATMENT_INFERENCE_EVENT = `${ROUTE_OPERATIONAL_INSIGHTS}/treatment/inference-events/event/:eventId`;
export const ROUTE_OPERATIONAL_INSIGHTS_DASHBOARD = `${ROUTE_OPERATIONAL_INSIGHTS}/dashboard`;
export const ROUTE_OPERATIONAL_INSIGHTS_DASHBOARD_SOURCE = `${ROUTE_OPERATIONAL_INSIGHTS}/dashboard/source/:sourceId`;
export const ROUTE_OPERATIONAL_INSIGHTS_HISTORICAL = `${ROUTE_OPERATIONAL_INSIGHTS}/historical`;
export const ROUTE_OPERATIONAL_INSIGHTS_DISCUSSION = `${ROUTE_OPERATIONAL_INSIGHTS}/discussion`;
export const ROUTE_OPERATIONAL_INSIGHTS_UNSUPERISED_JOBS = `${ROUTE_OPERATIONAL_INSIGHTS}/unsupervised-jobs`;
export const ROUTE_OPERATIONAL_INSIGHTS_SOURCE = `${ROUTE_OPERATIONAL_INSIGHTS}/source`;

export const ROUTE_COMPLIANCE = 'compliance';
export const ROUTE_COMPLIANCE_CHANGESET = 'compliance/:changeSetId/changeset';
export const ROUTE_COMPLIANCE_CHANGESET_IMPACT_ANALYSIS = 'compliance/:changeSetId/impact-analysis';

export const ROUTE_DESIGN_INSIGHTS = 'design-insights';
export const ROUTE_DESIGN_INSIGHTS_DESIGN_REGISTRY = `${ROUTE_DESIGN_INSIGHTS}/design-registry`;
export const ROUTE_DESIGN_INSIGHTS_DESIGN_REGISTRY_DESIGN = `${ROUTE_DESIGN_INSIGHTS}/design-registry/:designId`;
export const ROUTE_DESIGN_INSIGHTS_DESIGN_REGISTRY_REPORT = `${ROUTE_DESIGN_INSIGHTS}/design-registry/report/:botName`;
export const ROUTE_DESIGN_INSIGHTS_ACCURACY_SUBMISSION = `${ROUTE_DESIGN_INSIGHTS}/accuracy-submission`;
export const ROUTE_DESIGN_INSIGHTS_ACCURACY_REPORT = `${ROUTE_DESIGN_INSIGHTS}/accuracy-report`;
export const ROUTE_DESIGN_INSIGHTS_ACCURACY_OPTIMIZE = `${ROUTE_DESIGN_INSIGHTS}/optimize`;

export const ROUTE_ANALYTICS = 'analytics';
export const ROUTE_ANALYTICS_USAGE = 'analytics/usage';
export const ROUTE_ANALYTICS_PERFORMANCE = 'analytics/performance';
export const ROUTE_ANALYTICS_CUSTOM = 'analytics/custom';
export const ROUTE_ANALYTICS_LEARNING = 'analytics/learning';
export const ROUTE_ANALYTICS_TREATMENT = 'analytics/treatment';
export const ROUTE_ANALYTICS_REDACTION = 'analytics/redaction';

export const ROUTE_ADMIN = 'admin';
export const ROUTE_ADMIN_CONFIGURATION = `${ROUTE_ADMIN}/configuration`;
export const ROUTE_ADMIN_USERS = `${ROUTE_ADMIN}/users`;
export const ROUTE_ADMIN_QUEUES = `${ROUTE_ADMIN}/queues`;
export const ROUTE_ADMIN_BILLING = `${ROUTE_ADMIN}/billing`;
export const ROUTE_ADMIN_TREATMENT = `${ROUTE_ADMIN}/treatment`;
export const ROUTE_ADMIN_REDACTION = `${ROUTE_ADMIN}/redaction`;

export const ROUTE_INFERENCE_EVENTS = 'inference-events';
export const ROUTE_INFERENCE_EVENT = 'inference-events/:id';
