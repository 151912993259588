import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';

import ViewPage from '../../../../Components/ViewPage';
import Intent from './Intent';
import HeaderDetails from '../../../../Components/HeaderDetails';
import SortableColumn, { ASC } from '../../../../Components/Table/SortableColumn';
import sorting from '../../../../util/sorting';

import {
  ROUTE_DESIGN_INSIGHTS,
  ROUTE_DESIGN_INSIGHTS_DESIGN_REGISTRY,
} from '../../../../Routes/routes';

const COLUMNS = [{
  title: 'Name',
  sort: true,
  sortKey: 'name',
}, {
  title: 'Display Name',
  sort: true,
  sortKey: 'displayName',
}, {
  title: 'Scope',
  sort: false,
  sortKey: '',
}, {
  title: '',
  sort: false,
  sortKey: '',
}];

const formatData = (data) => [{
  title: 'Name',
  data: data?.name || 'N/A',
}, {
  title: 'Version',
  data: data?.version || 'N/A',
}, {
  title: 'Locale',
  data: data?.locale || 'N/A',
}];

const breadCrumbs = (t, id) => {
  const items = [{
    title: t('Views.designInsightsTitle'),
    route: `/${ROUTE_DESIGN_INSIGHTS}`,
  }, {
    title: t('DesignInsights.designRegistryTitle'),
    route: `/${ROUTE_DESIGN_INSIGHTS_DESIGN_REGISTRY}`,
  }];
  if (id) {
    items.push({
      title: id,
      route: `/${ROUTE_DESIGN_INSIGHTS_DESIGN_REGISTRY}/${id}`,
    });
  }
  return items;
};

const filterAndSort = (searchterm, array, sortOptions) => {
  let filtered = [];
  try {
    if (!searchterm) {
      filtered = array;
    }
    const term = searchterm.toLowerCase();
    filtered = array.filter((item) => item?.displayName?.toLowerCase()?.includes(term)
    || item?.name?.toLowerCase()?.includes(term)
    || item?.scope?.toLowerCase()?.includes(term));
  } catch (_err) {
    filtered = [];
  }
  return sorting.sortByOptions(filtered, sortOptions);
};

function Design({
  toggleDrawer,
  loading,
  getBotDesign,
  design,
  clearBotDesign,
}) {
  const { t } = useTranslation();
  const params = useParams();
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState({
    title: 'Name',
    sortKey: 'name',
    direction: ASC,
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  useEffect(() => {
    getBotDesign(params?.designId);
    return () => {
      clearBotDesign();
    };
  }, []);

  const filteredAndSorted = filterAndSort(search, design?.intents, sort);
  const rowsToDisplay = rowsPerPage > 0
    ? filteredAndSorted.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : filteredAndSorted;
  return (
    <ViewPage
      title={t('DesignInsights.designTitle')}
      toggleDrawer={toggleDrawer}
      breadcrumbs={breadCrumbs(t, params?.designId)}
      loading={loading}
    >
      <Box
        data-testid="Design-View-Container"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          mt: 2,
          width: '100%',
          mb: 2,
        }}
      >
        <HeaderDetails title="Design Details" data={formatData(design)} />
        <Card sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          mt: 2,
        }}
        >
          <CardHeader
            title="Intents"
            titleTypographyProps={{ color: 'primary.main' }}
            subheader="List of intents contained within the bot design"
            action={(
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  autoComplete="off"
                  size="small"
                  placeholder="Search"
                  id="Search-Field"
                  sx={{ mr: 2 }}
                  onChange={(e) => {
                    setPage(0);
                    setSearch(e.target.value);
                  }}
                  value={search}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <Tooltip title="Refresh Details" placement="top">
                  <IconButton aria-label="Refresh" onClick={() => getBotDesign(params?.designId)}>
                    <RefreshIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          />
          <CardContent sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    {COLUMNS.map((_col) => (
                      <SortableColumn
                        key={_col?.title}
                        column={_col}
                        sort={sort}
                        setSort={setSort}
                      />
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsToDisplay.map((_intent) => (
                    <Intent key={_intent?.name} intent={_intent} />
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[10, 20, 50, { label: 'All', value: -1 }]}
                      colSpan={COLUMNS.length}
                      count={search ? filteredAndSorted.length : design?.intents?.length || 0}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          'aria-label': 'rows per page',
                        },
                        native: true,
                      }}
                      onPageChange={(e, _page) => setPage(_page)}
                      onRowsPerPageChange={(e) => {
                        setRowsPerPage(parseInt(e.target.value, 10));
                        setPage(0);
                      }}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </Box>
    </ViewPage>
  );
}

Design.propTypes = {
  loading: PropTypes.bool,
  toggleDrawer: PropTypes.func,
  getBotDesign: PropTypes.func,
  design: PropTypes.object,
  clearBotDesign: PropTypes.func,
};

Design.defaultProps = {
  loading: false,
  toggleDrawer: () => {
    // Default
  },
  getBotDesign: () => {
    // Default
  },
  design: {},
  clearBotDesign: () => {
    // Default
  },
};

export default Design;
