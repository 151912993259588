const i18n = {
  en: {
    queuesTitle: 'Queues',
    billingTitle: 'Billing',
    treatmentTitle: 'Treatment',
    redactionTitle: 'Redaction',
  },
  fr: {
    queuesTitle: 'Files d\'attente',
    billingTitle: 'Facturation',
    treatmentTitle: 'Traitement',
    redactionTitle: 'Rédaction',
  },
};

export default i18n;
