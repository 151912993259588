import React from 'react';
import PropTypes from 'prop-types';

function Custom({ width, height }) {
  return (
    <svg width={width} height={height} viewBox="0 0 129 129" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="20" y="20" width="25" height="25" fill="white" />
      <rect x="85" y="20" width="25" height="25" fill="white" />
      <rect x="85" y="85" width="25" height="25" fill="white" />
      <rect x="20" y="85" width="25" height="25" fill="white" />
      <rect x="33" y="36" width="62" height="62" stroke="white" strokeWidth="6" />
    </svg>
  );
}

Custom.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

Custom.defaultProps = {
  width: 129,
  height: 129,
};

export default Custom;
