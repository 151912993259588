const i18n = {
  en: {
    changesetTitle: 'Change Set',
    impactAnalysisTitle: 'Impact Analysis',
  },
  fr: {
    changesetTitle: 'Modifier l\'ensemble',
  },
};

export default i18n;
