import React from 'react';

import PropTypes from 'prop-types';

function Queues({ width, height }) {
  return (
    <svg width={width} height={height} viewBox="0 0 101 101" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.2656 51.0312C19.4323 51.0312 37.0656 51.0312 42.2656 51.0312" stroke="white" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M43.2656 51.5312C50.7656 65.5312 51.7656 74.5312 80.7656 72.0312" stroke="white" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M44.7656 48.9476C52.2656 34.9476 53.2656 25.9476 82.2656 28.4476" stroke="white" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M80.7656 72.0312L72.2656 81.5312" stroke="white" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M80.7656 71.5312L72.2656 62.0312" stroke="white" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M82.7656 28.0312L74.2656 37.5312" stroke="white" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M82.7656 27.5312L74.2656 18.0312" stroke="white" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

Queues.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

Queues.defaultProps = {
  width: 101,
  height: 101,
};

export default Queues;
