import {
  STATE_DRAWER_OPEN,
  STATE_DRAWER_CLOSE,
  STATE_DRAWER_TOGGLE,
  STATE_ALERT_SHOW,
  STATE_ALERT_HIDE,
  STATE_SET_QUEUE,
  STATE_SET_LANG,
} from './types';
import {
  ADMIN_QUEUE_EDIT_SUCCESS,
  ADMIN_QUEUE_DELETE_SUCCESS,
} from '../admin/queues/types';
import { getLocale, setLocale } from './util';
import {
  DASHBOARD_FETCH_TOTALS_SUCCESS,
} from '../inbox/dashboard/types';

export const initialState = {
  drawerOpen: true,
  alertOpen: false,
  alertMessage: '',
  alertType: 'info',
  selectedQueue: null,
  lang: getLocale(),
};

const reducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case STATE_DRAWER_OPEN: {
      return { ...state, drawerOpen: true };
    }
    case STATE_DRAWER_CLOSE: {
      return { ...state, drawerOpen: false };
    }
    case STATE_DRAWER_TOGGLE: {
      return { ...state, drawerOpen: !state.drawerOpen };
    }
    case STATE_ALERT_SHOW: {
      const { payload } = action;
      const { type: alertType, message: alertMessage } = payload;
      return {
        ...state,
        alertOpen: true,
        alertMessage,
        alertType,
      };
    }
    case STATE_ALERT_HIDE: {
      return {
        ...state,
        alertOpen: false,
        alertMessage: '',
        alertType: 'info',
      };
    }
    case STATE_SET_QUEUE: {
      const { queue } = action.payload;
      return { ...state, selectedQueue: queue };
    }
    case STATE_SET_LANG: {
      const { lang } = action.payload;
      setLocale(lang);
      return {
        ...state,
        lang,
      };
    }
    case ADMIN_QUEUE_DELETE_SUCCESS:
    case ADMIN_QUEUE_EDIT_SUCCESS: {
      const { queue } = action.payload;
      const { id } = queue;
      let { selectedQueue } = state;
      if (id === selectedQueue) {
        selectedQueue = null;
      }
      return {
        ...state,
        selectedQueue,
      };
    }
    case DASHBOARD_FETCH_TOTALS_SUCCESS: {
      const { queues } = action.payload;
      let currentSelectedQueue = state.selectedQueue;
      if (!currentSelectedQueue) {
        currentSelectedQueue = queues?.[0]?.id || null;
      }
      return {
        ...state,
        selectedQueue: currentSelectedQueue,
      };
    }
    default:
      return state;
  }
};

export default reducer;
