import { connect } from 'react-redux';

import Approve from './Approve';
import { approveChangeSet } from '../../../../store/compliance/changeset/actions';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  approve: (changeSetId) => dispatch(approveChangeSet(changeSetId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Approve);
