import { combineReducers } from 'redux';

import users from './users/reducer';
import queues from './queues/reducer';
import billing from './billing/reducer';
import treatment from './treatment/reducer';
import redaction from './redaction/reducer';

const reducers = combineReducers({
  users,
  queues,
  billing,
  treatment,
  redaction,
});

export default reducers;
