import {
  COMPLIANCE_IMPACT_ANALYSIS_GET_SUMMARY,
  COMPLIANCE_IMPACT_ANALYSIS_GET_SUMMARY_SUCCESS,
  COMPLIANCE_IMPACT_ANALYSIS_GET_SUMMARY_FAIL,
  COMPLIANCE_IMPACT_ANALYSIS_GET_SUMMARY_CLEAR,
} from './types';
import { showAlert } from '../../state/actions';

// eslint-disable-next-line
const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export const getSummarySuccess = (data) => ({
  type: COMPLIANCE_IMPACT_ANALYSIS_GET_SUMMARY_SUCCESS,
  payload: { data },
});

export const getSummaryFail = () => ({
  type: COMPLIANCE_IMPACT_ANALYSIS_GET_SUMMARY_FAIL,
});

export const getSummaryAttempt = () => ({
  type: COMPLIANCE_IMPACT_ANALYSIS_GET_SUMMARY,
});

export const getSummary = () => async (dispatch) => {
  const ERROR_MESSAGE = 'Failed to load data, please try again.';
  dispatch(getSummaryAttempt());
  try {
    await delay(1000);
    dispatch(getSummarySuccess());
  } catch (_err) {
    dispatch(showAlert({ type: 'error', message: ERROR_MESSAGE }));
    dispatch(getSummaryFail());
  }
};

export const clearSummary = () => ({
  type: COMPLIANCE_IMPACT_ANALYSIS_GET_SUMMARY_CLEAR,
});
