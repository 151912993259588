import {
  DESIGN_REGISTRY_GET_BOT_DESIGN,
  DESIGN_REGISTRY_GET_BOT_DESIGN_SUCCESS,
  DESIGN_REGISTRY_GET_BOT_DESIGN_FAIL,
  DESIGN_REGISTRY_GET_BOT_DESIGN_CLEAR,
} from './types';
import { showAlert } from '../../../state/actions';
import userUtils from '../../../../util/user';
import isUnAuth from '../../../../util/unauth';
import interceptor, { getInboxUrl } from '../../../../util/interceptor';
import { logoutUser } from '../../../auth/actions';

const getBotDesignAttempt = () => ({ type: DESIGN_REGISTRY_GET_BOT_DESIGN });
const getBotDesignSuccess = (design) => ({
  type: DESIGN_REGISTRY_GET_BOT_DESIGN_SUCCESS,
  payload: { design },
});
const getBotDesignFail = () => ({ type: DESIGN_REGISTRY_GET_BOT_DESIGN_FAIL });

export const getBotDesign = (designId) => async (dispatch, getState) => {
  const ERROR_MESSAGE = 'Failed to get Bot Design, please try again.';
  dispatch(getBotDesignAttempt());
  try {
    const state = getState();
    const { authToken, authenticatedUser } = state.auth;
    const tenant = userUtils.getTenant(authenticatedUser);
    const region = userUtils.getRegion(authenticatedUser);
    const res = await interceptor({
      method: 'post',
      url: `${getInboxUrl(region)}/${tenant}/DescribeBotDesign`,
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: {
        botDesignId: designId,
      },
    });
    const { data } = res;
    dispatch(getBotDesignSuccess(data));
  } catch (_err) {
    const status = _err?.response?.status;
    if (isUnAuth(status)) {
      dispatch(logoutUser({ location: window.location.href }));
    } else {
      dispatch(showAlert({ type: 'error', message: ERROR_MESSAGE }));
      dispatch(getBotDesignFail());
    }
  }
};

export const clearBotDesign = () => ({ type: DESIGN_REGISTRY_GET_BOT_DESIGN_CLEAR });
