import QueuesIcon from './Icons/Queues';
import BillingIcon from './Icons/Billing';

import {
  ROUTE_ADMIN_QUEUES,
  ROUTE_ADMIN_BILLING,
  ROUTE_ADMIN_TREATMENT,
  ROUTE_ADMIN_REDACTION,
} from '../../Routes/routes';

const sections = (t) => [{
  title: t('Admin.queuesTitle'),
  route: ROUTE_ADMIN_QUEUES,
  icon: QueuesIcon,
}, {
  title: t('Admin.billingTitle'),
  route: ROUTE_ADMIN_BILLING,
  icon: BillingIcon,
}, {
  title: t('Admin.treatmentTitle'),
  route: ROUTE_ADMIN_TREATMENT,
  icon: BillingIcon,
}, {
  title: t('Admin.redactionTitle'),
  route: ROUTE_ADMIN_REDACTION,
  icon: BillingIcon,
}];

export default sections;
