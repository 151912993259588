import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';

import ViewPage from '../../Components/ViewPage';
import GalleryCard from '../../Components/GalleryCard';

import InboxIcon from './Icons/Inbox';
import DiscussionIcon from './Icons/Discussion';

import {
  ROUTE_OPERATIONAL_INSIGHTS_DASHBOARD,
  ROUTE_OPERATIONAL_INSIGHTS_DISCUSSION,
} from '../../Routes/routes';

const sections = (t) => [
  {
    title: t('OperationalInsights.inboxTitle'),
    route: ROUTE_OPERATIONAL_INSIGHTS_DASHBOARD,
    icon: InboxIcon,
  },
  {
    title: t('OperationalInsights.discusstionTitle'),
    route: ROUTE_OPERATIONAL_INSIGHTS_DISCUSSION,
    icon: DiscussionIcon,
  },
];

function Inbox({
  toggleDrawer,
}) {
  const { t } = useTranslation();
  return (
    <ViewPage title={t('Views.operationalInsightsTitle')} toggleDrawer={toggleDrawer}>
      <Box
        data-testid="Operational-Insights-View-Container"
        sx={{
          display: 'flex',
          flex: 1,
          flexWrap: 'wrap',
        }}
      >
        {sections(t).map((section) => (
          <GalleryCard
            key={section?.route}
            title={section?.title}
            route={section?.route}
            description={section?.description}
            icon={section?.icon}
          />
        ))}
      </Box>
    </ViewPage>
  );
}

Inbox.propTypes = {
  toggleDrawer: PropTypes.func,
};

Inbox.defaultProps = {
  toggleDrawer: () => {
    // Default
  },
};

export default Inbox;
