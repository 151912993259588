import { connect } from 'react-redux';

import ViewPage from './ViewPage';
import { hideAlert } from '../../store/state/actions';

const mapStateToProps = (state) => ({
  drawerOpen: state?.state?.drawerOpen,
  alertOpen: state?.state?.alertOpen,
  alertMessage: state?.state?.alertMessage,
  alertType: state?.state?.alertType,
});

const mapDispatchToProps = (dispatch) => ({
  hideAlert: () => dispatch(hideAlert()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewPage);
