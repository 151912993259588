import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardActionArea from '@mui/material/CardActionArea';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';

import SearchIcon from '@mui/icons-material/Search';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';

import ViewPage from '../../../Components/ViewPage';
import HeaderDetails from '../../../Components/HeaderDetails';
import SortableColumn, { ASC } from '../../../Components/Table/SortableColumn';

import ApproveDialog from './Approve';
import RejectDialog from './Reject';
import breadcrumbs from './breadcrumbs';
import util from './util';

const COLUMNS = [{
  title: 'Utterance',
}, {
  title: 'Intent',
}, {
  title: 'Action',
}];

const filterAndSort = (searchterm, array) => {
  let filtered = [];
  try {
    if (!searchterm) {
      filtered = array;
    }
    const term = searchterm.toLowerCase();
    filtered = array.filter((_item) => _item?.action?.toLowerCase().includes(term)
    || _item?.properties?.intent?.toLowerCase().includes(term)
    || _item?.properties?.utterance?.toLowerCase().includes(term));
  } catch (_err) {
    filtered = [];
  }
  return filtered;
};

function ChangeSet({
  toggleDrawer,
  loading,
  data,
  getChangeSet,
  clearChangeSet,
}) {
  const { t } = useTranslation();
  const params = useParams();
  const [approve, setApprove] = useState(false);
  const [reject, setReject] = useState(false);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState({
    title: 'Name',
    sortKey: 'name',
    direction: ASC,
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [approved, setApproved] = useState(false);
  const { changeSetId } = params;

  useEffect(() => {
    getChangeSet(changeSetId);
    return () => {
      clearChangeSet();
    };
  }, []);

  useEffect(() => {
    setApproved(!loading && !!data?.applied ? data?.applied : '');
  }, [data, loading]);

  const filteredAndSorted = filterAndSort(search, (data?.treatmentEvents || []));
  const rowsToDisplay = rowsPerPage > 0
    ? filteredAndSorted.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : filteredAndSorted;

  return (
    <ViewPage
      title={t('Compliance.changesetTitle')}
      toggleDrawer={toggleDrawer}
      loading={loading}
      breadcrumbs={breadcrumbs(t, changeSetId)}
    >
      <Box
        data-testid="ChangeSet-View-Container"
        sx={{
          mt: 1,
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
        }}
      >
        <HeaderDetails title="Details" data={util.formatData(data, approved)} />
        {!approved && (
          <Box sx={{ display: 'flex', mt: 2 }}>
            <Card sx={{ mr: 2, width: 100 }}>
              <CardActionArea onClick={() => setApprove(true)}>
                <CardContent>
                  <ThumbUpIcon style={{ color: '#a1c586' }} />
                  <Typography color="text.secondary">
                    Approve
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
            <Card sx={{ width: 100 }}>
              <CardActionArea onClick={() => setReject(true)}>
                <CardContent>
                  <ThumbDownIcon style={{ color: '#ff2745' }} />
                  <Typography color="text.secondary">
                    Reject
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Box>
        )}
        <Card sx={{
          mt: 2,
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
        }}
        >
          <CardHeader
            title="Treatment Events"
            titleTypographyProps={{ color: 'primary.main' }}
            subheader="Treatments contained within Change Set"
            action={(
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  autoComplete="off"
                  size="small"
                  placeholder="Search"
                  id="Search-Field"
                  sx={{ mr: 2 }}
                  onChange={(e) => {
                    setPage(0);
                    setSearch(e.target.value);
                  }}
                  value={search}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            )}
          />
          <CardContent sx={{ display: 'flex', flex: 1, mr: 1 }}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    {COLUMNS.map((_col) => (
                      <SortableColumn
                        key={_col?.title}
                        column={_col}
                        sort={sort}
                        setSort={setSort}
                      />
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsToDisplay.map((row) => (
                    <TableRow key={`${row.id}${row.name}`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell>{row?.properties?.utterance}</TableCell>
                      <TableCell>{row?.properties?.intent}</TableCell>
                      <TableCell>{row?.action}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[10, 20, 50, { label: 'All', value: -1 }]}
                      colSpan={COLUMNS.length}
                      count={search
                        ? filteredAndSorted.length
                        : (data?.treatmentEvents || []).length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          'aria-label': 'rows per page',
                        },
                        native: true,
                      }}
                      onPageChange={(e, _page) => setPage(_page)}
                      onRowsPerPageChange={(e) => {
                        setRowsPerPage(parseInt(e.target.value, 10));
                        setPage(0);
                      }}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </Box>
      {approve && (
        <ApproveDialog
          onClose={() => setApprove(false)}
          changeSetId={changeSetId}
          data={data}
          setApproved={setApproved}
        />
      )}
      {reject && <RejectDialog onClose={() => setReject(false)} changeSetId={changeSetId} />}
    </ViewPage>
  );
}

ChangeSet.propTypes = {
  toggleDrawer: PropTypes.func,
  loading: PropTypes.bool,
  data: PropTypes.object,
  getChangeSet: PropTypes.func,
  clearChangeSet: PropTypes.func,
};

ChangeSet.defaultProps = {
  toggleDrawer: () => {
    // Default
  },
  loading: false,
  data: {},
  getChangeSet: () => {
    // Default
  },
  clearChangeSet: () => {
    // Default
  },
};

export default ChangeSet;
