import { connect } from 'react-redux';

import Delete from './Delete';
import { deleteQueue } from '../../../../store/admin/queues/actions';

const mapStateToProps = (state) => ({
  loading: state?.admin?.queues?.deletingQueue,
});

const mapDispatchToProps = (dispatch) => ({
  deleteQueue: (queue) => dispatch(deleteQueue(queue)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Delete);
