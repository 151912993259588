import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';

import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';

export const ASC = 'ascending';
export const DESC = 'descending';

const getDirectionIcon = (currentlySortingCell, direction) => {
  if (currentlySortingCell) {
    if (direction === ASC) {
      return <ArrowDropUpIcon data-testid="ArrowDropUp" />;
    }
    return <ArrowDropDownIcon data-testid="ArrowDropDown" />;
  }
  return <HorizontalRuleIcon data-testid="HorizontalRule" />;
};

function SortableColumn({
  column,
  sort,
  setSort,
}) {
  const currentlySortingCell = sort?.title === column?.title;
  const sortDirection = currentlySortingCell ? sort?.direction : ASC;
  return (
    <TableCell data-testid="SortableColumn" key={column?.title}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
          {column?.title}
        </Box>
        {column.sort && (
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => setSort(() => {
              let newDirection = ASC;
              if (sortDirection === ASC) {
                newDirection = DESC;
              }
              return {
                title: column?.title,
                sortKey: column?.sortKey,
                direction: newDirection,
              };
            })}
          >
            {getDirectionIcon(currentlySortingCell, sort?.direction)}
          </IconButton>
        )}
      </Box>
    </TableCell>
  );
}

SortableColumn.propTypes = {
  column: PropTypes.object,
  sort: PropTypes.object,
  setSort: PropTypes.func,
};

SortableColumn.defaultProps = {
  column: {},
  sort: {},
  setSort: () => {
    // Default
  },
};

export default SortableColumn;
