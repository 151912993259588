const ASC = 'ascending';

export const compareNumber = (aVal, bVal) => {
  if (aVal < bVal) {
    return -1;
  }
  if (aVal > bVal) {
    return 1;
  }
  return 0;
};
const compareString = (aVal, bVal) => {
  if (aVal.toString().toLowerCase() < bVal.toString().toLowerCase()) {
    return -1;
  }
  if (aVal.toString().toLowerCase() > bVal.toString().toLowerCase()) {
    return 1;
  }
  return 0;
};

const sorting = {
  sortByOptions: (array, options = {}) => array.sort((a, b) => {
    try {
      let aVal = '';
      let bVal = '';
      if (options.direction === ASC) {
        aVal = a[options.sortKey];
        bVal = b[options.sortKey];
      } else {
        aVal = b[options.sortKey];
        bVal = a[options.sortKey];
      }
      if (typeof aVal === 'number') {
        return compareNumber(aVal, bVal);
      }
      return compareString(aVal, bVal);
    } catch (_err) {
      return 0;
    }
  }),
};

export default sorting;
