import { connect } from 'react-redux';

import Queues from './Queues';
import { listQueues, clearQueues } from '../../../store/admin/queues/actions';

const mapStateToProps = (state) => ({
  loading: state?.admin?.queues?.loading,
  queues: state?.admin?.queues?.queues,
});

const mapDispatchToProps = (dispatch) => ({
  listQueues: () => dispatch(listQueues()),
  clearQueues: () => dispatch(clearQueues()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Queues);
