import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import RedoIcon from '@mui/icons-material/Redo';
import YoutubeSearchedForIcon from '@mui/icons-material/YoutubeSearchedFor';

import utils from '../util';

function Actions({
  selectedRows,
  events,
  openTreatment,
  region,
  setSearch,
  duplicateSearch,
  ignoreEvents,
  setSelectedRows,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const event = selectedRows.length === 1
    ? events.find((_event) => _event.id === selectedRows[0])
    : events.filter((_event) => selectedRows.includes(_event.id));

  const ignore = () => {
    ignoreEvents(event);
    setSelectedRows([]);
    handleClose();
  };
  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} data-testid="Actions-Container">
      {selectedRows.length === 1 && (
        <>
          <Tooltip title="Find similar utterances" placement="top">
            <IconButton
              data-testid="SimilarUtterances-Button"
              aria-label="View"
              onClick={duplicateSearch
                ? () => setSearch('')
                : () => setSearch(event?.utterance?.trim()?.toLowerCase() || '')}
            >
              <YoutubeSearchedForIcon sx={{ color: duplicateSearch ? 'black' : 'inherit' }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="View Conversation" placement="top">
            <IconButton
              data-testid="ViewConversation-Button"
              aria-label="View"
              onClick={() => utils.viewConversation(region, event?.conversationId)}
            >
              <RedoIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Add Treatment" placement="top">
            <IconButton
              data-testid="AddTreatment-Button"
              aria-label="Treatment"
              onClick={() => openTreatment(event)}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        </>
      )}
      {selectedRows.length > 1 && (
        <>
          <Tooltip title="View Conversations" placement="top">
            <IconButton
              data-testid="ViewConversation-Button"
              aria-label="View"
              onClick={() => utils.viewConversations(region, event)}
            >
              <RedoIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Add Treatment" placement="top">
            <IconButton
              data-testid="AddTreatment-Button"
              aria-label="Treatment"
              onClick={openMenu}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
          <Menu
            id="options-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{ 'aria-labelledby': 'options-menu' }}
          >
            <MenuItem onClick={ignore}>Ignore Events</MenuItem>
          </Menu>
        </>
      )}
    </Box>
  );
}

Actions.propTypes = {
  selectedRows: PropTypes.array,
  events: PropTypes.array,
  openTreatment: PropTypes.func,
  region: PropTypes.string,
  setSearch: PropTypes.func,
  duplicateSearch: PropTypes.string,
  ignoreEvents: PropTypes.func,
  setSelectedRows: PropTypes.func,
};

Actions.defaultProps = {
  selectedRows: [],
  events: [],
  openTreatment: () => {
    // Default
  },
  region: '',
  setSearch: () => {
    // Default
  },
  duplicateSearch: '',
  ignoreEvents: () => {
    // Default
  },
  setSelectedRows: () => {
    // Default
  },
};

export default Actions;
