import {
  DESIGN_REGISTRY_LIST_BOT_DESIGNS,
  DESIGN_REGISTRY_LIST_BOT_DESIGNS_SUCCESS,
  DESIGN_REGISTRY_LIST_BOT_DESIGNS_FAIL,
  DESIGN_REGISTRY_IMPORT_BOT_DESIGNS,
  DESIGN_REGISTRY_IMPORT_BOT_DESIGNS_SUCCESS,
  DESIGN_REGISTRY_IMPORT_BOT_DESIGNS_FAIL,
  DESIGN_REGISTRY_BOT_DESIGNS_CLEAR,
} from './types';

const initialState = {
  loading: false,
  botDesigns: [],
  importing: false,
};

const reducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case DESIGN_REGISTRY_LIST_BOT_DESIGNS: {
      return { ...state, loading: true, botDesigns: [] };
    }
    case DESIGN_REGISTRY_LIST_BOT_DESIGNS_SUCCESS: {
      const { botDesigns } = action.payload;
      return { ...state, loading: false, botDesigns };
    }
    case DESIGN_REGISTRY_LIST_BOT_DESIGNS_FAIL: {
      return { ...state, loading: false };
    }
    case DESIGN_REGISTRY_IMPORT_BOT_DESIGNS: {
      return { ...state, importing: true };
    }
    case DESIGN_REGISTRY_IMPORT_BOT_DESIGNS_SUCCESS: {
      const { botDesign } = action.payload;
      const currentBotDesigns = [...state.botDesigns];
      currentBotDesigns.push(botDesign);
      return { ...state, importing: false, botDesigns: currentBotDesigns };
    }
    case DESIGN_REGISTRY_IMPORT_BOT_DESIGNS_FAIL: {
      return { ...state, importing: false };
    }
    case DESIGN_REGISTRY_BOT_DESIGNS_CLEAR: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default reducer;
