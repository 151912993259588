import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

function SourceDetails({
  data,
  selectedSource,
  setSelectedSource,
}) {
  const [hover, setHover] = useState({});
  return (
    <Box sx={{ display: 'flex', mt: 2, flexDirection: 'column' }} data-testid="SourceDetails-Container">
      {Object.keys(data).length !== 0 && (
        <Typography variant="h6" color="text.secondary">
          Source Details
        </Typography>
      )}
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        {Object.keys(data).map((key, index) => (
          <Card
            elevation={(hover[index] || selectedSource === key) ? 4 : 2}
            sx={{
              mt: 2,
              mr: 2,
              cursor: 'pointer',
              background: (hover[index] || selectedSource === key) ? 'rgba(110, 189, 211, 0.6)' : 'white',
            }}
            key={key}
            onMouseEnter={() => setHover({ [index]: true })}
            onMouseLeave={() => setHover({ [index]: false })}
            onClick={() => {
              if (selectedSource === key) {
                setSelectedSource(null);
              } else {
                setSelectedSource(key);
              }
            }}
          >
            <CardContent>
              <Box sx={{ display: 'flex' }}>
                <Typography sx={{ mr: 1 }} color="primary.main">
                  Name:
                </Typography>
                <Typography color={(hover[index] || selectedSource === key) ? 'text.white' : 'text.secondary'}>
                  {key}
                </Typography>
              </Box>
              {Object.keys(data[key]).map((datKey) => (
                <Box sx={{ display: 'flex' }} key={datKey}>
                  <Typography sx={{ mr: 1 }} color="primary.main">
                    {datKey}
                  </Typography>
                  <Typography color={(hover[index] || selectedSource === key) ? 'text.white' : 'text.secondary'}>
                    {data[key][datKey]}
                  </Typography>
                </Box>
              ))}
            </CardContent>
          </Card>
        ))}
      </Box>
    </Box>
  );
}

SourceDetails.propTypes = {
  data: PropTypes.object,
  selectedSource: PropTypes.any,
  setSelectedSource: PropTypes.func,
};

SourceDetails.defaultProps = {
  data: {},
  selectedSource: null,
  setSelectedSource: () => {
    // Default
  },
};

export default SourceDetails;
