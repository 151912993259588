import {
  ANALYTICS_TREATMENT_GET,
  ANALYTICS_TREATMENT_GET_SUCCESS,
  ANALYTICS_TREATMENT_GET_FAIL,
  ANALYTICS_TREATMENT_CLEAR,
} from './types';
import { showAlert } from '../../state/actions';
import userUtils from '../../../util/user';
import isUnAuth from '../../../util/unauth';
import interceptor, { getSauronUrl } from '../../../util/interceptor';
import { logoutUser } from '../../auth/actions';
import { formatData } from './util';

export const getTreatmentAttempt = () => ({
  type: ANALYTICS_TREATMENT_GET,
});

export const getTreatmentSuccess = (data) => ({
  type: ANALYTICS_TREATMENT_GET_SUCCESS,
  payload: { data },
});

export const getTreatmentFail = () => ({
  type: ANALYTICS_TREATMENT_GET_FAIL,
});

export const getTreatment = (period) => async (dispatch, getState) => {
  const ERROR_MESSAGE = 'Failed to load data, please try again.';
  dispatch(getTreatmentAttempt());
  try {
    const state = getState();
    const { authToken, authenticatedUser } = state.auth;
    const tenant = userUtils.getTenant(authenticatedUser);
    const region = userUtils.getRegion(authenticatedUser);
    const res = await interceptor({
      method: 'post',
      url: `${getSauronUrl(region)}/${tenant}/ListTreatmentActivityReports`,
      data: { period },
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    });
    const data = res?.data?.treatmentActivityReports || [];
    dispatch(getTreatmentSuccess(formatData(data)));
  } catch (_err) {
    const status = _err?.response?.status;
    if (isUnAuth(status)) {
      dispatch(logoutUser({ location: window.location.href }));
    } else {
      dispatch(showAlert({ type: 'error', message: ERROR_MESSAGE }));
      dispatch(getTreatmentFail());
    }
  }
};

export const clearTreatment = () => ({ type: ANALYTICS_TREATMENT_CLEAR });
