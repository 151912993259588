import {
  ROUTE_ADMIN,
  ROUTE_ADMIN_QUEUES,
} from '../../../Routes/routes';

const breadcrumbs = (t) => [{
  title: t('Views.adminTitle'),
  route: `/${ROUTE_ADMIN}`,
}, {
  title: t('Admin.queuesTitle'),
  route: `/${ROUTE_ADMIN_QUEUES}`,
}];

export default breadcrumbs;
