import { connect } from 'react-redux';

import Queue from './Queue';
import { updateQueue } from '../../../../store/admin/queues/actions';

const mapStateToProps = (state) => ({
  loading: state?.admin?.queues?.updatingQueue,
  sources: state?.admin?.queues?.sources,
});

const mapDispatchToProps = (dispatch) => ({
  save: (queue) => dispatch(updateQueue(queue)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Queue);
