import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';

const formatSummary = (_summary) => Object.keys(_summary).map((key) => ({
  title: key,
  value: _summary[key],
}));

function GoalStats({
  data,
}) {
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
      <Card sx={{ mr: 2, mb: 2, minWidth: 400 }}>
        <CardHeader
          title="Summary"
          titleTypographyProps={{ color: 'primary.main' }}
          subheader="Goals reached across all bots"
        />
        <CardContent>
          {formatSummary(data?.summary || []).map((_summary) => (
            <Box sx={{ display: 'flex', mb: 1 }}>
              <Typography gutterBottom variant="body2" sx={{ mr: 1 }} color="text.secondary">
                {_summary.title}
              </Typography>
              <Typography variant="body2">
                {_summary?.value}
              </Typography>
            </Box>
          ))}
        </CardContent>
      </Card>
    </Box>
  );
}

GoalStats.propTypes = {
  data: PropTypes.object,
};

GoalStats.defaultProps = {
  data: {},
};

export default GoalStats;
