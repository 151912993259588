import {
  ROUTE_OPERATIONAL_INSIGHTS,
  ROUTE_OPERATIONAL_INSIGHTS_DASHBOARD,
} from '../../../Routes/routes';

const breadcrumbs = (t) => [{
  title: t('Views.operationalInsightsTitle'),
  route: `/${ROUTE_OPERATIONAL_INSIGHTS}`,
}, {
  title: t('OperationalInsights.inboxTitle'),
  route: `/${ROUTE_OPERATIONAL_INSIGHTS_DASHBOARD}`,
}];

export default breadcrumbs;
