import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import PageviewOutlinedIcon from '@mui/icons-material/PageviewOutlined';
import PageviewIcon from '@mui/icons-material/Pageview';
import IconButton from '@mui/material/IconButton';

import options from './options';

function AdvancedSearch({
  handleOpenRegexMenu,
  advancedSearch,
  regexAnchor,
  handleCloseRegexMenu,
  advSearch,
}) {
  return (
    <>
      <Tooltip title="Advanced Search" placement="top">
        <IconButton aria-label="Regex" onClick={handleOpenRegexMenu}>
          {advancedSearch ? <PageviewIcon /> : <PageviewOutlinedIcon />}
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: '45px' }}
        id="menu-appbar"
        anchorEl={regexAnchor}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(regexAnchor)}
        onClose={handleCloseRegexMenu}
      >
        {options.map((_opt) => (
          <MenuItem onClick={() => advSearch(_opt.pattern)} key={_opt.title}>
            <Typography textAlign="center" sx={{ mr: 2 }}>
              {_opt.title}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

AdvancedSearch.propTypes = {
  handleOpenRegexMenu: PropTypes.func,
  advancedSearch: PropTypes.string,
  regexAnchor: PropTypes.any,
  handleCloseRegexMenu: PropTypes.func,
  advSearch: PropTypes.func,
};

AdvancedSearch.defaultProps = {
  handleOpenRegexMenu: () => {
    // Default
  },
  advancedSearch: '',
  regexAnchor: null,
  handleCloseRegexMenu: () => {
    // Default
  },
  advSearch: () => {
    // Default
  },
};

export default AdvancedSearch;
