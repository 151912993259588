import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import EditIcon from '@mui/icons-material/Edit';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SearchIcon from '@mui/icons-material/Search';

import User from './User';
import Create from './Create';

import ViewPage from '../../../Components/ViewPage';
import breadcrumbs from './breadcrumbs';

const COLUMNS = ['Email Address', 'First Name', 'Last Name', 'Role', 'Queues', ''];

const filterUsers = (searchterm, users) => {
  try {
    if (!searchterm) {
      return users;
    }
    const term = searchterm.toLowerCase();
    return users.filter((user) => user?.firstName?.toLowerCase()?.includes(term)
      || user?.lastName?.toLowerCase()?.includes(term)
      || user?.email?.toLowerCase().includes(term)
      || user?.role?.toLowerCase().includes(term));
  } catch (_err) {
    return [];
  }
};

function Users({
  loading,
  toggleDrawer,
  listUsers,
  users,
  clearUsers,
}) {
  const [search, setSearch] = useState('');
  const [editUser, setEditUser] = useState(false);
  const [createUser, setCreateUser] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    listUsers();
    return () => {
      clearUsers();
    };
  }, []);

  const openModal = (index) => {
    setSelectedUser(index);
    setEditUser(true);
  };
  const closeModal = () => {
    setEditUser(false);
    setSelectedUser(null);
  };
  const refreshUsers = () => {
    listUsers();
  };
  return (
    <ViewPage
      title="Users"
      toggleDrawer={toggleDrawer}
      breadcrumbs={breadcrumbs}
      loading={loading}
    >
      <Box
        data-testid="Users-View-Container"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          mt: 2,
          width: '100%',
          mb: 2,
        }}
      >
        <Card sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
          <CardHeader
            title="Users"
            titleTypographyProps={{ color: 'primary.main' }}
            subheader="Registered users who can access the console"
            action={(
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  size="small"
                  placeholder="Search"
                  id="Search-Field"
                  sx={{ mr: 2 }}
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <Tooltip title="Refresh User List" placement="top">
                  <IconButton aria-label="Refresh Users" onClick={refreshUsers}>
                    <RefreshIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Add new User" placement="top" onClick={() => setCreateUser(true)}>
                  <IconButton aria-label="Add User">
                    <AddCircleOutlineIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          />
          <CardContent sx={{ display: 'flex', flex: 1, mr: 1 }}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    {COLUMNS.map((_col) => <TableCell key={_col}>{_col}</TableCell>)}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filterUsers(search, users).map((row, index) => (
                    <TableRow key={row.email} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell>{row.email}</TableCell>
                      <TableCell>{row.firstName}</TableCell>
                      <TableCell>{row.lastName}</TableCell>
                      <TableCell>{row.role}</TableCell>
                      <TableCell>
                        <Stack direction="row" spacing={1}>
                          {(row?.queues || [])
                            .map((_queue) => <Chip key={_queue} label={_queue} />)}
                        </Stack>
                      </TableCell>
                      <TableCell align="right">
                        <Tooltip title="Edit User" placement="top">
                          <IconButton aria-label="Edit User" onClick={() => openModal(index)}>
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </Box>
      {editUser && (
        <User
          selectedUser={users?.[selectedUser] || null}
          onClose={() => closeModal()}
        />
      )}
      {createUser && <Create onClose={() => setCreateUser(false)} />}
    </ViewPage>
  );
}

Users.propTypes = {
  toggleDrawer: PropTypes.func,
  loading: PropTypes.bool,
  listUsers: PropTypes.func,
  users: PropTypes.array,
  clearUsers: PropTypes.func,
};

Users.defaultProps = {
  toggleDrawer: () => {
    // Default
  },
  loading: false,
  listUsers: () => {
    // Default
  },
  users: [],
  clearUsers: () => {
    // Default
  },
};

export default Users;
