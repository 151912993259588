import { connect } from 'react-redux';

import Custom from './Custom';
import {
  getCustom,
  clearCustom,
  addGoal,
  removeGoal,
} from '../../../store/analytics/custom/actions';

const mapStateToProps = (state) => ({
  loading: state?.analytics?.custom?.loading,
  data: state?.analytics?.custom?.data,
  availableGoals: state?.analytics?.custom?.availableGoals,
  selectedGoals: state?.analytics?.custom?.selectedGoals,
});

const mapDispatchToProps = (dispatch) => ({
  getCustom: (period) => dispatch(getCustom(period)),
  clearCustom: () => dispatch(clearCustom()),
  addGoal: (goal) => dispatch(addGoal(goal)),
  removeGoal: (goal) => dispatch(removeGoal(goal)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Custom);
