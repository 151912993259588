import { connect } from 'react-redux';

import Authenticated from './Authenticated';
import { toggleDrawer, hideAlert } from '../store/state/actions';

const mapStateToProps = (state) => ({
  drawerOpen: state?.state?.drawerOpen,
  alertOpen: state?.state?.alertOpen,
  alertMessage: state?.state?.alertMessage,
  alertType: state?.state?.alertType,
});

const mapDispatchToProps = (dispatch) => ({
  toggleDrawer: () => dispatch(toggleDrawer()),
  hideAlert: () => dispatch(hideAlert()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Authenticated);
