import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import CircleIcon from '@mui/icons-material/Circle';

const formatLink = (links) => links.map((_link) => ({
  value: _link,
}));

function ExternalStats({
  data,
}) {
  const SECTIONS = [{
    title: 'Url',
    data: data?.['L1.url'] || [],
  }, {
    title: 'Video Url',
    data: data?.['L1.videoUrl'] || [],
  }, {
    title: 'API Connector',
    data: data?.apiconnector || [],
  }];
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
      <Card sx={{ mr: 2, mb: 2, minWidth: 400 }}>
        <CardContent>
          {SECTIONS.map((_section) => (
            <Box key={_section.title}>
              <Typography variant="h6" color="text.secondary">{_section.title}</Typography>
              <List sx={{ wordBreak: 'break-word' }}>
                {formatLink(_section.data).map((_link) => (
                  <ListItem dense disablePadding>
                    <ListItemIcon sx={{ minWidth: 16 }}>
                      <CircleIcon sx={{ width: 8 }} />
                    </ListItemIcon>
                    <ListItemText primary={_link.value} />
                  </ListItem>
                ))}
              </List>
            </Box>
          ))}
        </CardContent>
      </Card>
    </Box>
  );
}

ExternalStats.propTypes = {
  data: PropTypes.object,
};

ExternalStats.defaultProps = {
  data: {},
};

export default ExternalStats;
