const util = {
  consoleUrl: (errorMessage) => {
    try {
      let url = new URL(`${process.env.REACT_APP_CONSOLE_URL}/login/auth`).href;
      if (errorMessage) {
        url = new URL(`${process.env.REACT_APP_CONSOLE_URL}/login/auth?message=${errorMessage}`).href;
      }
      return url;
    } catch (_err) {
      return '';
    }
  },
  consoleUrlWithLocation: (location, errorMessage) => {
    try {
      let url = new URL(`${process.env.REACT_APP_CONSOLE_URL}/login/auth?ssoUrl=${location}`).href;
      if (errorMessage) {
        url = new URL(`${process.env.REACT_APP_CONSOLE_URL}/login/auth?ssoUrl=${location}&message=${errorMessage}`).href;
      }
      return url;
    } catch (_err) {
      return '';
    }
  },
};

export default util;
