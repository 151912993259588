import moment from 'moment';
import i18next from 'i18next';

const util = ({
  formatData: (data, approved) => {
    const items = [{
      title: 'Name',
      data: data?.name || 'N/A',
    }, {
      title: 'Created',
      data: data?.created ? moment(data?.created).format(i18next.t('moment.standard')) : 'N/A',
    }, {
      title: 'Applied',
      data: approved ? moment(approved).format(i18next.t('moment.standard')) : 'Not Applied',
    }];
    return items;
  },
});

export default util;
