import {
  ROUTE_ADMIN,
  ROUTE_ADMIN_USERS,
} from '../../../Routes/routes';

const breadcrumbs = [{
  title: 'Admin',
  route: `/${ROUTE_ADMIN}`,
}, {
  title: 'Users',
  route: `/${ROUTE_ADMIN_USERS}`,
}];

export default breadcrumbs;
