import { connect } from 'react-redux';

import Users from './Users';
import { listUsers, clearUsers } from '../../../store/admin/users/actions';

const mapStateToProps = (state) => ({
  loading: state?.admin?.users?.loading,
  users: state?.admin?.users?.users,
});

const mapDispatchToProps = (dispatch) => ({
  listUsers: () => dispatch(listUsers()),
  clearUsers: () => dispatch(clearUsers()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
