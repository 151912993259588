import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Chip from '@mui/material/Chip';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Row from './Row';
import SortableColumn, { ASC } from '../../../../../Components/Table/SortableColumn';
import sorting from '../../../../../util/sorting';
import COLUMNS from './columns';

const filterAndSort = (searchterm, array, sortOptions) => {
  let filtered = [];
  try {
    if (!searchterm) {
      filtered = array;
    }
    const term = searchterm.toLowerCase();
    filtered = array.filter((item) => item?.conversationId?.toLowerCase()?.includes(term));
  } catch (_err) {
    filtered = [];
  }
  return sorting.sortByOptions(filtered, sortOptions);
};

function Rollup({
  events,
  getSource,
  filter,
  setFilter,
  filters,
  openTreatment,
  region,
}) {
  const params = useParams();
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState({
    title: 'Age',
    sortKey: 'messageIngressTimestamp',
    direction: ASC,
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [expanded, setExpanded] = useState('');

  const filteredAndSorted = filterAndSort(search, events, sort);
  const rowsToDisplay = rowsPerPage > 0
    ? filteredAndSorted.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : filteredAndSorted;

  return (
    <Box
      data-testid="Rollup-Events-Container"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        mt: 2,
        width: '100%',
        mb: 2,
      }}
    >
      <Card sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
        <CardHeader
          title="Inference Events"
          titleTypographyProps={{ color: 'primary.main' }}
          subheader={`Inference Events for source: ${params.sourceId}`}
          action={(
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography sx={{ mr: 2 }}>
                Group By:
              </Typography>
              {filters.map((_filter) => (
                <Chip
                  key={_filter?.title}
                  sx={{ mr: 1 }}
                  label={_filter?.title}
                  onClick={() => setFilter(_filter?.value)}
                  variant={filter === _filter?.value ? 'filled' : 'outlined'}
                />
              ))}
              <TextField
                autoComplete="off"
                size="small"
                placeholder="Search"
                id="Search-Field"
                sx={{ ml: 2, mr: 2 }}
                onChange={(e) => {
                  setPage(0);
                  setSearch(e.target.value);
                }}
                value={search}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <Tooltip title="Refresh Details" placement="top">
                <IconButton aria-label="Refresh" onClick={() => getSource()}>
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
            </Box>
            )}
        />
        <CardContent
          sx={{
            display: 'flex',
            flex: 1,
            mr: 1,
            flexDirection: 'column',
          }}
        >
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Conversation Id</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
          {rowsToDisplay.map((_event) => {
            const convoEvents = filterAndSort(search, _event?.inferenceEvents || [], sort);
            return (
              <Accordion
                disableGutters
                square
                onChange={(e, exp) => {
                  if (exp) {
                    setExpanded(_event.conversationId);
                  } else {
                    setExpanded('');
                  }
                }}
                key={_event.conversationId}
                expanded={_event.conversationId === expanded}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>{_event.conversationId}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          {COLUMNS.map((_col) => (
                            <SortableColumn
                              key={_col?.title}
                              column={_col}
                              sort={sort}
                              setSort={setSort}
                            />
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {convoEvents.map((row) => (
                          <Row
                            region={region}
                            key={row?.id}
                            data={row}
                            openTreatment={openTreatment}
                          />
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
            );
          })}
          <TablePagination
            rowsPerPageOptions={[10, 20, 50, { label: 'All', value: -1 }]}
            colSpan={COLUMNS.length}
            count={search ? filteredAndSorted.length : events?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: {
                'aria-label': 'rows per page',
              },
              native: true,
            }}
            onPageChange={(e, _page) => setPage(_page)}
            onRowsPerPageChange={(e) => {
              setRowsPerPage(parseInt(e.target.value, 10));
              setPage(0);
            }}
          />
        </CardContent>
      </Card>
    </Box>
  );
}

Rollup.propTypes = {
  events: PropTypes.array,
  getSource: PropTypes.func,
  filter: PropTypes.string,
  setFilter: PropTypes.func,
  filters: PropTypes.array,
  openTreatment: PropTypes.func,
  region: PropTypes.string,
};

Rollup.defaultProps = {
  events: [],
  getSource: () => {
    // Default
  },
  filter: '',
  setFilter: () => {
    // Default
  },
  filters: [],
  openTreatment: () => {
    // Default
  },
  region: '',
};

export default Rollup;
