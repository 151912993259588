import { combineReducers } from 'redux';

import custom from './custom/reducer';
import treatment from './treatment/reducer';
import redaction from './redaction/reducer';

const reducers = combineReducers({
  custom,
  treatment,
  redaction,
});

export default reducers;
