import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import viewsI18n from './Views/i18n';
import operationalInsights from './Views/OperationalInsights/i18n';
import compliance from './Views/Compliance/i18n';
import designInsights from './Views/DesignInsights/i18n';
import analytics from './Views/Analytics/i18n';
import admin from './Views/Admin/i18n';

import { getLocale } from './store/state/util';

const resources = {
  en: {
    translation: {
      moment: {
        standard: 'Do MMM YYYY',
        monthDay: 'MMM DD',
      },
      Views: { ...viewsI18n.en },
      OperationalInsights: { ...operationalInsights.en },
      Compliance: { ...compliance.en },
      DesignInsights: { ...designInsights.en },
      Analytics: { ...analytics.en },
      Admin: { ...admin.en },
    },
  },
  'en-US': {
    translation: {
      moment: {
        standard: 'MMM Do YYYY',
        monthDay: 'DD MMM',
      },
    },
  },
  fr: {
    translation: {
      Views: { ...viewsI18n.fr },
      OperationalInsights: { ...operationalInsights.fr },
      DesignInsights: { ...designInsights.fr },
      Compliance: { ...compliance.fr },
      Analytics: { ...analytics.fr },
      Admin: { ...admin.fr },
    },
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    lng: getLocale(),
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
