import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Button from '@mui/material/Button';

import ViewPage from '../../../Components/ViewPage';
import Details from './Details';
import Recommendation from './Recommendation';
import Treatments from './Treatments';
import utils from '../../OperationalInsights/Dashboard/Source/Events/util';
import breadcrumbs from './breadcrumbs';

function Event({
  toggleDrawer,
  getInferenceEvent,
  clearInferenceEvent,
  data,
  region,
}) {
  const { t } = useTranslation();
  const params = useParams();
  const { id } = params;

  useEffect(() => {
    getInferenceEvent(id);
    return () => {
      clearInferenceEvent();
    };
  }, []);

  const openConversation = () => {
    utils.viewConversation(region, data?.conversationId);
  };
  return (
    <ViewPage title={t('Views.inferenceEventTitle')} toggleDrawer={toggleDrawer} breadcrumbs={breadcrumbs(t, id)}>
      <Box
        data-testid="Inference-Event-View-Container"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          flexWrap: 'wrap',
          mt: 2,
        }}
      >
        <Box sx={{ display: 'flex' }}>
          <Card sx={{ mr: 2 }}>
            <CardContent>
              <Details data={data} />
              <Box sx={{ mt: 2 }}>
                <Button size="small" variant="outlined" sx={{ width: 180 }} onClick={openConversation}>View Conversation</Button>
              </Box>
            </CardContent>
          </Card>
          <Card>
            <CardContent>
              <Recommendation data={data} />
            </CardContent>
          </Card>
        </Box>
        <Box sx={{ display: 'flex', mt: 2, flex: 1 }}>
          <Card sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
            <CardHeader
              title="Treatment Events"
              titleTypographyProps={{ color: 'primary.main' }}
              subheader="Applied Treatments"
            />
            <CardContent>
              <Treatments data={data?.treatmentEvents || []} />
            </CardContent>
          </Card>
        </Box>
      </Box>
    </ViewPage>
  );
}

Event.propTypes = {
  toggleDrawer: PropTypes.func,
  getInferenceEvent: PropTypes.func,
  clearInferenceEvent: PropTypes.func,
  data: PropTypes.object,
  region: PropTypes.string,
};

Event.defaultProps = {
  toggleDrawer: () => {
    // Default
  },
  getInferenceEvent: () => {
    // Default
  },
  clearInferenceEvent: () => {
    // Default
  },
  data: {},
  region: '',
};

export default Event;
