import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Raw from './Raw';
import Rollup from './Rollup';
import Treatment from './Treatment';

const FilterMap = {
  raw: Raw,
  rollup: Rollup,
};

function Events({
  events,
  getSource,
  filter,
  setFilter,
  region,
  pagination,
  filters,
  ignoreEvents,
}) {
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [treatmentDialogOpen, setTreatmentDialogOpen] = useState(false);
  const Component = FilterMap[filter] || Raw;

  const openTreatment = (_selectedEvent) => {
    setSelectedEvent(_selectedEvent);
    setTreatmentDialogOpen(true);
  };

  return (
    <>
      <Component
        region={region}
        events={events}
        getSource={getSource}
        filter={filter}
        setFilter={setFilter}
        filters={filters}
        openTreatment={openTreatment}
        pagination={pagination}
        ignoreEvents={ignoreEvents}
      />
      {treatmentDialogOpen && (
        <Treatment
          event={selectedEvent}
          onClose={() => setTreatmentDialogOpen(false)}
        />
      )}
    </>
  );
}

Events.propTypes = {
  events: PropTypes.array,
  getSource: PropTypes.func,
  filter: PropTypes.string,
  setFilter: PropTypes.func,
  region: PropTypes.string,
  pagination: PropTypes.object,
  filters: PropTypes.array,
  ignoreEvents: PropTypes.func,
};

Events.defaultProps = {
  events: [],
  getSource: () => {
    // Default
  },
  filter: '',
  setFilter: () => {
    // Default
  },
  region: '',
  pagination: {
    nextOffset: 51,
    limit: 50,
    offset: 0,
    totalRows: 0,
  },
  filters: [],
  ignoreEvents: () => {
    // Default
  },
};

export default Events;
