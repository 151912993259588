import {
  BLUE_ONE,
  BLUE_TWO,
  BLUE_THREE,
  BLUE_FIVE,
} from '../../../colors';

import { compareNumber } from '../../../util/sorting';

const bar = (data, selectedGoals) => {
  // Highest number first, removed unselected goals
  const series = (data?.values || []).sort((a, b) => compareNumber(a.value, b.value))
    .reverse().filter((_dat) => selectedGoals.includes(_dat.name));
  return {
    chart: {
      type: 'bar',
      height: '500px',
      toolbar: {
        show: false,
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
      },
    },
    series: [{
      name: 'Goal Count',
      data: (series || []).map((_dat) => _dat.value),
    }],
    labels: (series).map((_dat) => _dat.name),
    colors: [BLUE_ONE, BLUE_TWO, BLUE_THREE, BLUE_FIVE],
    tooltip: {
      y: {
        formatter: (_val) => `${_val}`,
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
  };
};

const line = (data, selectedGoals) => {
  // Highest number first, removed unselected goals
  const series = (data?.values || []).sort((a, b) => compareNumber(a.value, b.value))
    .reverse().filter((_dat) => selectedGoals.includes(_dat.name));
  return {
    chart: {
      type: 'line',
      height: '500px',
      toolbar: {
        show: false,
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
      },
    },
    series: series.map((_val) => ({ name: _val.name, data: _val.monthly })),
    labels: ['Jane', 'Feb', 'Mar', 'Apr', 'May', 'June', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    colors: [BLUE_ONE, BLUE_TWO, BLUE_THREE, BLUE_FIVE],
  };
};

const graphOptions = (data, type, selectedGoals = []) => {
  if (type === 'LINE') {
    return line(data, selectedGoals);
  }
  return bar(data, selectedGoals);
};

export default graphOptions;
