export const COLOR_GREEN = '#489b5a';
export const COLOR_ORANGE = '#dd9b67';
export const COLOR_YELLOW = '#ddca67';
export const COLOR_RED = '#dd677e';
export const COLOR_PINK = '#dd67b0';

export const BLUE_ONE = '#73c4db';
export const BLUE_TWO = '#3e95a9';
export const BLUE_THREE = '#4ca4b3';
export const BLUE_FOUR = '#4c9cb3';
export const BLUE_FIVE = '#52a8bd';

export default {
  GREEN: COLOR_GREEN,
  ORANGE: COLOR_ORANGE,
  YELLOW: COLOR_YELLOW,
  RED: COLOR_RED,
  PINK: COLOR_PINK,
};
