import {
  DESIGN_REGISTRY_GET_BOT_REPORT,
  DESIGN_REGISTRY_GET_BOT_REPORT_SUCCESS,
  DESIGN_REGISTRY_GET_BOT_REPORT_FAIL,
  DESIGN_REGISTRY_GET_BOT_REPORT_CLEAR,
} from './types';

import { showAlert } from '../../../state/actions';
import isUnAuth from '../../../../util/unauth';
import { logoutUser } from '../../../auth/actions';

// eslint-disable-next-line
const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export const getBotReportAttempt = () => ({ type: DESIGN_REGISTRY_GET_BOT_REPORT });
export const getBotReportSuccess = (data) => ({
  type: DESIGN_REGISTRY_GET_BOT_REPORT_SUCCESS,
  payload: {
    data,
  },
});
export const getBotReportFail = () => ({ type: DESIGN_REGISTRY_GET_BOT_REPORT_FAIL });

export const DATA = {
  region: 'eu-1',
  organization: 'sbdemo3',
  bot: 'ABC',
  botName: 'ABC',
  version: '0.8.1',
  resources: {
    endpoints: [
      'sbdemo3-ABC',
    ],
    workers: [
      {
        Name: 'ABCStarterWorker',
        Type: 'nlp-worker',
        Id: 'ca172be2-46c7-4c49-a9c8-a895425642ed',
      },
    ],
    intents: [
      'Name',
      'welcome',
      'testtransfer',
      'baasintegration',
      'Hello',
      'fallback',
      'help',
      'showcontext',
      'actionflow',
      'cmsdemo',
      'setcontext',
    ],
  },
  dependencies: {
    apiConnectors: [
      'balance',
      'customerLookup',
      'languageDetect',
      'languageTranslate',
    ],
    flows: [
      '0b4306ef-7cf1-4658-af2d-ca56d920c979',
      '62e05f26-3de4-429c-9b98-ea02e0466684',
      'a8be04a5-46f6-4c5c-b981-8e3db76fae09',
    ],
    cmsItems: [
      'coronavirusvideo',
      'menu',
      'whatsappvideo',
      'actionflowvideo',
      'blankpdf',
      'kbcvideo',
      'qjumpimage',
    ],
    bots: [
      'Once',
    ],
    unknown: [],
  },
  references: {
    secrets: [
      'srn:vault::sbdemo3:aws-cross-account-role:comprehend',
    ],
    externalReferences: {
      dfProjectId: [],
      lex: [],
      'aws-sdk': [
        'Translate',
      ],
      http: [
        'https://3t72350dxa.execute-api.eu-west-1.amazonaws.com/api/lookup',
        // eslint-disable-next-line
        'https://gistcdn.githack.com/cianclarke/afd70c5c94fe90152354aa21123a0e7e/raw/238dfe6fafa69e3188ab16720c98ddafdf0aaf59/${account}.json',
        'https://sborg-heupper-sbdemo3.s3.eu-west-1.amazonaws.com/cms/assets/7c8e9160-3c25-49c5-a473-33b9e6aa6b3e.mp4',
        'https://wonderful-euler-636c85.netlify.app/.netlify/functions/customer',
        'https://youtu.be/8I4bbNpwYbY',
        '{asset:ActionFlowVideo}',
        '{asset:blankpdf}',
      ],
    },
    allowedDomains: [
      '*.production.helium.servismatrix.com',
      '*.production.helium.servismatrixcdn.com',
      '*.servisbot.com',
      'https://servisbot.com',
    ],
  },
  errors: [],
  stats: {
    flowStats: {
      details: [
        {
          flowId: '0b4306ef-7cf1-4658-af2d-ca56d920c979',
          nodeCount: 7,
        },
        {
          flowId: 'a8be04a5-46f6-4c5c-b981-8e3db76fae09',
          nodeCount: 40,
        },
        {
          flowId: '62e05f26-3de4-429c-9b98-ea02e0466684',
          nodeCount: 8,
        },
      ],
      summary: {
        tab: 3,
        actionStart: 2,
        actionComplete: 3,
        dialogue: 22,
        content: 4,
        menu: 6,
        input: 3,
        baas: 4,
        setContext: 1,
        change: 2,
        markup: 1,
        markupInteraction: 1,
        start: 1,
        pushBotToStack: 1,
        transfer: 1,
      },
      summaryTotal: 55,
    },
    intentStats: {
      summary: {
        markup: 5,
        pushBotToStack: 1,
        flow: 4,
        goal: 2,
        message: 8,
        hostnotification: 1,
        messageVariance: 1,
        content: 3,
        setContext: 1,
      },
      TimelineElementDistro: {
        TextMsg: 4,
        List: 1,
      },
    },
    markupStats: {
      summary: {},
    },
    responseStats: {
      TimelineElementDistro: {
        FileItem: 2,
        Card: 3,
        DetailView: 1,
      },
      contentDistro: {
        dialogue: {
          str: 18,
          msg: 4,
        },
        content: {
          str: 4,
        },
        cmsItem: {
          Markup: 4,
        },
        markup: {
          str: 1,
        },
        markupInteraction: {
          default: 1,
        },
      },
      context: {
        vtl: {},
        mustache: {},
        changeContext: {
          'msg.originalLanguage': 1,
          'msg.payload.user.message': 1,
        },
        sbContext: {
          msg: {
            originalLanguage: '',
            payload: {
              user: {
                message: '',
              },
            },
          },
        },
      },
    },
    goalStats: {
      summary: {
        Transfer: 1,
        ActionFlowGoal: 1,
      },
      longest: 'ActionFlowGoal',
      longestSize: 14,
    },
    externalHttpRefs: {
      'L1.url': [
        '{asset:ActionFlowVideo}',
        'https://sborg-heupper-sbdemo3.s3.eu-west-1.amazonaws.com/cms/assets/7c8e9160-3c25-49c5-a473-33b9e6aa6b3e.mp4',
        '{asset:blankpdf}',
      ],
      'L1.videoUrl': [
        'https://youtu.be/8I4bbNpwYbY',
      ],
      apiconnector: [
        // eslint-disable-next-line
        'https://gistcdn.githack.com/cianclarke/afd70c5c94fe90152354aa21123a0e7e/raw/238dfe6fafa69e3188ab16720c98ddafdf0aaf59/${account}.json',
        'https://wonderful-euler-636c85.netlify.app/.netlify/functions/customer',
        'https://3t72350dxa.execute-api.eu-west-1.amazonaws.com/api/lookup',
      ],
    },
  },
  processingTime: {
    startTime: 1634666618398,
    endTime: 1634666631955,
    totalDuration: 13557,
  },
};

export const getBotReport = () => async (dispatch) => {
  const ERROR_MESSAGE = 'Failed to get Bot Report, please try again.';
  dispatch(getBotReportAttempt());
  try {
    await delay(1000);
    dispatch(getBotReportSuccess(DATA));
  } catch (_err) {
    const status = _err?.response?.status;
    if (isUnAuth(status)) {
      dispatch(logoutUser({ location: window.location.href }));
    } else {
      dispatch(showAlert({ type: 'error', message: ERROR_MESSAGE }));
      dispatch(getBotReportFail());
    }
  }
};

export const clearBotReport = () => ({ type: DESIGN_REGISTRY_GET_BOT_REPORT_CLEAR });
