import { connect } from 'react-redux';

import App from './App';
import { rehydrateUser } from './store/auth/actions';

const mapStateToProps = (state) => ({
  isAuthenticating: state?.auth?.isAuthenticating,
  authenticated: state?.auth?.authenticated,
});

const mapDispatchToProps = (dispatch) => ({
  rehydrateUser: () => dispatch(rehydrateUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
