import {
  INBOX_TREATMENT_LIST_QUEUES,
  INBOX_TREATMENT_LIST_QUEUES_SUCCESS,
  INBOX_TREATMENT_LIST_QUEUES_FAIL,
  INBOX_TREATMENT_LIST_QUEUES_CLEAR,
} from './types';

export const initialState = {
  loading: false,
  queues: [],
};

const reducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case INBOX_TREATMENT_LIST_QUEUES: {
      return { ...state, loading: true, queues: [] };
    }
    case INBOX_TREATMENT_LIST_QUEUES_SUCCESS: {
      const { queues } = action.payload;
      return { ...state, loading: false, queues };
    }
    case INBOX_TREATMENT_LIST_QUEUES_FAIL: {
      return { ...state, loading: false, queues: [] };
    }
    case INBOX_TREATMENT_LIST_QUEUES_CLEAR: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default reducer;
