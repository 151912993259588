import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const getInferenceEventTotals = (data) => (data?.periodValues || [])
  .reduce((_curr, _next) => _curr + (_next?.values?.inferenceEvents || 0), 0);
const getMissedInputTotals = (data) => (data?.periodValues || [])
  .reduce((_curr, _next) => _curr + (_next?.values?.missedinput || 0), 0);
const getTreatedTotals = (data) => (data?.periodValues || [])
  .reduce((_curr, _next) => _curr + (_next?.values?.treatments || 0), 0);

const getSelectedInferenceEvents = (data) => (data?.data || [])
  .reduce((_curr, _next) => _curr + (_next?.inferenceEvents || 0), 0);
const getSelectedMissedInput = (data) => (data?.data || [])
  .reduce((_curr, _next) => _curr + (_next?.missedinput || 0), 0);
const getSelectedTreated = (data) => (data?.data || [])
  .reduce((_curr, _next) => _curr + (_next?.has_treatment || 0), 0);

const titles = ['Inference Events:', 'Missed Input:', 'Treatments:'];
const selectedCollection = [getSelectedInferenceEvents, getSelectedMissedInput, getSelectedTreated];

function Totals({
  chartSelected,
  data,
}) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }} data-testid="Totals-container">
      {!chartSelected ? (
        <>
          <Box sx={{ display: 'flex', mr: 2 }}>
            <Typography color="primary.main">
              {titles[0]}
            </Typography>
            <Typography color="text.secondary" sx={{ ml: 1 }}>
              {getInferenceEventTotals(data)}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', mr: 2 }}>
            <Typography color="primary.main">
              {titles[1]}
            </Typography>
            <Typography color="text.secondary" sx={{ ml: 1 }}>
              {getMissedInputTotals(data)}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', mr: 2 }}>
            <Typography color="primary.main">
              {titles[2]}
            </Typography>
            <Typography color="text.secondary" sx={{ ml: 1 }}>
              {getTreatedTotals(data)}
            </Typography>
          </Box>
        </>
      ) : (
        <Box sx={{ display: 'flex', mr: 2 }}>
          <Typography color="primary.main">
            {titles[chartSelected?.index]}
          </Typography>
          <Typography color="text.secondary" sx={{ ml: 1 }}>
            {selectedCollection[chartSelected?.index](chartSelected)}
          </Typography>
        </Box>
      )}
    </Box>
  );
}

Totals.propTypes = {
  data: PropTypes.object,
  chartSelected: PropTypes.any,
};

Totals.defaultProps = {
  data: {},
  chartSelected: false,
};

export default Totals;
