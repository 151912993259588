const mappings = {
  'eu-1': 'https://portal.servisbot.com',
  'eu-private-1': 'https://portal.staging.helium.servismatrix.com',
  us1: 'https://portal.us1.helium.servismatrix.com',
  usscif1: 'https://portal.usscif1.servisbotconnect.com',
};

const getPortalUrl = (region) => mappings?.[region] || mappings['eu-1'];

export default getPortalUrl;
