import React from 'react';
import PropTypes from 'prop-types';
import AceEditor from 'react-ace';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';

import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-tomorrow';
import 'ace-builds/src-noconflict/ext-language_tools';

import Info from './Info';

const formatContent = (summary) => {
  const summaryDetails = [];
  if (summary?.dialogue?.str) {
    summaryDetails.push({
      title: 'Dialog:',
      value: summary?.dialogue?.str,
    });
  }
  if (summary?.content?.str) {
    summaryDetails.push({
      title: 'Content:',
      value: summary?.content?.str,
    });
  }
  if (summary?.cmsItem?.Markup) {
    summaryDetails.push({
      title: 'CMS Item:',
      value: summary?.cmsItem?.Markup,
    });
  }
  if (summary?.markupInteraction?.default) {
    summaryDetails.push({
      title: 'Markup Interaction:',
      value: summary?.markupInteraction?.default,
    });
  }
  return summaryDetails;
};

const formatTimelineElements = (_timeline) => Object.keys(_timeline).map((key) => ({
  title: key,
  value: _timeline[key],
}));

const formatChangeContext = (_change) => Object.keys(_change).map((key) => ({
  title: key,
  value: _change[key],
}));

function ResponseStats({
  data,
}) {
  return (
    <Box
      data-testid="ResponseStats"
      sx={{ display: 'flex', flexWrap: 'wrap' }}
    >
      <Card sx={{ mr: 2, mb: 2, minWidth: 400 }}>
        <CardHeader
          title="Timeline Distribution"
          titleTypographyProps={{ color: 'primary.main' }}
          subheader="Timeline elements sent as a part of a bot response"
        />
        <CardContent>
          {formatTimelineElements(data?.TimelineElementDistro || []).map((_summary) => (
            <Box sx={{ display: 'flex', mb: 1 }} key={_summary.title}>
              <Typography gutterBottom variant="body2" sx={{ mr: 1 }} color="text.secondary">
                {_summary.title}
              </Typography>
              <Typography variant="body2">
                {_summary?.value}
              </Typography>
            </Box>
          ))}
        </CardContent>
      </Card>
      <Card sx={{ mr: 2, mb: 2, minWidth: 400 }}>
        <CardHeader
          title="Content Distribution"
          titleTypographyProps={{ color: 'primary.main' }}
          subheader="Content sent as a part of a bot response"
        />
        <CardContent>
          {formatContent(data?.contentDistro || []).map((_summary) => (
            <Info
              summary={_summary}
              key={_summary.title}
            />
          ))}
        </CardContent>
      </Card>
      <Card sx={{ mr: 2, mb: 2, minWidth: 400 }}>
        <CardHeader
          title="Change Context"
          titleTypographyProps={{ color: 'primary.main' }}
          subheader="Context value changes within nodes"
        />
        <CardContent>
          {formatChangeContext(data?.context?.changeContext || []).map((_data) => (
            <Info
              summary={_data}
              key={_data.title}
            />
          ))}
        </CardContent>
      </Card>
      <Card sx={{ mr: 2, mb: 2, minWidth: 400 }}>
        <CardHeader
          title="Context"
          titleTypographyProps={{ color: 'primary.main' }}
          subheader="Content sent as a part of a bot response"
        />
        <CardContent>
          <AceEditor
            readOnly
            mode="json"
            theme="tomorrow"
            fontSize={14}
            showPrintMargin
            showGutter
            value={JSON.stringify(data?.context?.sbContext || {}, null, 2)}
            setOptions={{
              enableBasicAutocompletion: false,
              enableLiveAutocompletion: false,
              enableSnippets: false,
              showLineNumbers: true,
              tabSize: 2,
            }}
            maxLines={20}
            highlightActiveLine={false}
          />
        </CardContent>
      </Card>
    </Box>
  );
}

ResponseStats.propTypes = {
  data: PropTypes.object,
};

ResponseStats.defaultProps = {
  data: {},
};

export default ResponseStats;
