import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';

import General from './General';
import Ignore from './Ignore';

import {
  CREATE_INTENT,
  ADD_UTTERANCE,
  REMOVE_UTTERANCE,
  IGNORE,
  getPayloadForType,
} from './util';

import { TEXT_TERTIARY } from '../../../../../../theme';

const getType = (data) => {
  if (data?.reviewDetails?.type === 'NOTHING_SIMILAR') {
    return CREATE_INTENT;
  }
  if (data?.reviewDetails?.type === 'REFINE_TOPIC') {
    return ADD_UTTERANCE;
  }
  return ADD_UTTERANCE;
};

function Treatment({
  onClose,
  event,
  addTreatment,
}) {
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState(getType(event));
  const [treatment, setTreatment] = useState(null);

  useEffect(() => {
    setTreatment(getPayloadForType(event, type));
  }, [type]);

  const createTreatment = async () => {
    setLoading(true);
    await addTreatment(treatment);
    setLoading(false);
    onClose();
  };

  const getRecommended = (data) => {
    if (data?.reviewDetails?.type === 'NOTHING_SIMILAR') {
      return (
        <Box>
          <Box sx={{ display: 'flex' }}>
            <Typography variant="body1" sx={{ mr: 2 }}>
              Recommendation:
            </Typography>
            <Typography color="text.secondary">
              Intent was not matched. Create new intent with utterance.
            </Typography>
          </Box>
        </Box>
      );
    }
    if (data?.reviewDetails?.type === 'REFINE_TOPIC') {
      const distance = data?.reviewDetails?.properties?.distance || 0;
      const convertedDistance = (1 - distance) * 100;
      return (
        <Box sx={{ mb: 4 }}>
          <Box sx={{ display: 'flex' }}>
            <Typography variant="body1" sx={{ mr: 2 }}>
              Recommendation:
            </Typography>
            <Typography color="text.secondary">
              Add utterance to existing intent
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', mb: 2 }}>
            <Typography variant="body1" sx={{ mr: 2 }}>
              Nearest Utterance:
            </Typography>
            <Typography color="text.secondary">
              <span style={{ color: TEXT_TERTIARY }}>
                {data?.reviewDetails?.properties?.closestUtterance}
              </span>
              {' '}
              with
              {' '}
              {`${Math.round(convertedDistance)}%`}
              {' '}
              match
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ minWidth: 35, display: 'flex', justifyContent: 'flex-start' }}>
              <Typography variant="body2" color="text.secondary">
                0%
              </Typography>
            </Box>
            <Box sx={{ width: '100%' }}>
              <LinearProgress variant="determinate" value={convertedDistance} />
            </Box>
            <Box sx={{ minWidth: 35, display: 'flex', justifyContent: 'flex-end' }}>
              <Typography variant="body2" color="text.secondary">
                {`${Math.round(convertedDistance)}%`}
              </Typography>
            </Box>
          </Box>
        </Box>
      );
    }
    return (
      <Box>
        <Box sx={{ display: 'flex' }}>
          <Typography variant="body1" sx={{ mr: 2 }}>
            Recommendation:
          </Typography>
          <Typography color="text.secondary">
            This event is currently awaiting review. No recommendation is currently available.
          </Typography>
        </Box>
        <Typography color="text.secondary">
          You can manually apply a treatment below.
        </Typography>
      </Box>
    );
  };
  return (
    <Dialog
      data-testid="Treatment-Dialog-Container"
      fullWidth
      maxWidth="sm"
      open
      onClose={onClose}
    >
      <DialogTitle>
        Add Treatment
      </DialogTitle>
      <DialogContent>
        <Box>
          {getRecommended(event)}
        </Box>
        <Box sx={{ mt: 2 }}>
          <FormControl fullWidth>
            <InputLabel id="treatment-action">Action</InputLabel>
            <Select
              labelId="treatment-action"
              id="treatment-action"
              value={type}
              label="Action"
              onChange={(e) => setType(e.target.value)}
            >
              <MenuItem value={CREATE_INTENT}>Create Intent</MenuItem>
              <MenuItem value={ADD_UTTERANCE}>Add Utterance</MenuItem>
              <MenuItem value={REMOVE_UTTERANCE}>Remove Utterance</MenuItem>
              <MenuItem value={IGNORE}>Ignore</MenuItem>
            </Select>
          </FormControl>
          {type === CREATE_INTENT && (
            <General
              treatment={treatment}
              updateTreatment={setTreatment}
            />
          )}
          {type === ADD_UTTERANCE && (
            <General
              treatment={treatment}
              updateTreatment={setTreatment}
            />
          )}
          {type === REMOVE_UTTERANCE && (
            <General
              treatment={treatment}
              updateTreatment={setTreatment}
            />
          )}
          {type === IGNORE && <Ignore treatment={treatment} updateTreatment={setTreatment} />}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box sx={{ m: 2 }}>
          <Button
            onClick={onClose}
            disabled={loading}
            sx={{ mr: 2, width: 100 }}
          >
            Cancel
          </Button>
          <Button
            sx={{ width: 100 }}
            onClick={createTreatment}
            variant="contained"
            disabled={loading}
          >
            {loading ? 'Applying...' : 'Apply'}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

Treatment.propTypes = {
  onClose: PropTypes.func,
  event: PropTypes.object,
  addTreatment: PropTypes.func,
};

Treatment.defaultProps = {
  onClose: () => {
    // Default
  },
  event: {},
  addTreatment: () => {
    // Default
  },
};

export default Treatment;
