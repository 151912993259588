import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import ViewPage from '../../../../Components/ViewPage';
import HeaderDetails from '../../../../Components/HeaderDetails';
import Resources from './Resources';
import Dependencies from './Dependencies';
import References from './References';
import Stats from './Stats';

import {
  ROUTE_DESIGN_INSIGHTS,
  ROUTE_DESIGN_INSIGHTS_DESIGN_REGISTRY,
} from '../../../../Routes/routes';

const formatData = (data) => [{
  title: 'Bot Name',
  data: `${data?.botName || 'N/A'}`,
}, {
  title: 'Version',
  data: `${data?.version || 'N/A'}`,
}];

const breadCrumbs = (t, botName) => {
  const items = [{
    title: t('Views.designInsightsTitle'),
    route: `/${ROUTE_DESIGN_INSIGHTS}`,
  }, {
    title: t('DesignInsights.designRegistryTitle'),
    route: `/${ROUTE_DESIGN_INSIGHTS_DESIGN_REGISTRY}`,
  }];
  if (botName) {
    items.push({
      title: t('DesignInsights.reportTitle'),
      route: `/${ROUTE_DESIGN_INSIGHTS_DESIGN_REGISTRY}/report/${botName}`,
    });
    items.push({
      title: botName,
      route: `/${ROUTE_DESIGN_INSIGHTS_DESIGN_REGISTRY}/${botName}`,
    });
  }
  return items;
};

function Report({
  toggleDrawer,
  loading,
  getBotReport,
  data,
  clearBotReport,
}) {
  const { t } = useTranslation();
  const params = useParams();
  const [tab, setTab] = useState(0);

  useEffect(() => {
    getBotReport(params?.botName);
    return () => {
      clearBotReport();
    };
  }, []);

  return (
    <ViewPage
      title={t('DesignInsights.designTitle')}
      toggleDrawer={toggleDrawer}
      breadcrumbs={breadCrumbs(t, params?.botName)}
      loading={loading}
    >
      <Box
        data-testid="Report-View-Container"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          mt: 2,
          width: '100%',
          mb: 2,
        }}
      >
        <HeaderDetails title="Bot Report" data={formatData(data)} />
        <Box sx={{ display: 'flex', mt: 2, flexDirection: 'column' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tab} onChange={(e, val) => setTab(val)} aria-label="Resources">
              <Tab label="Resources" />
              <Tab label="Dependencies" />
              <Tab label="References" />
              <Tab label="Errors" />
              <Tab label="Stats" />
            </Tabs>
          </Box>
          {tab === 0 && <Resources data={data?.resources || {}} />}
          {tab === 1 && <Dependencies data={data?.dependencies || {}} />}
          {tab === 2 && <References data={data?.references || {}} />}
          {tab === 4 && <Stats data={data?.stats || {}} />}
        </Box>
      </Box>
    </ViewPage>
  );
}

Report.propTypes = {
  loading: PropTypes.bool,
  toggleDrawer: PropTypes.func,
  getBotReport: PropTypes.func,
  data: PropTypes.object,
  clearBotReport: PropTypes.func,
};

Report.defaultProps = {
  loading: false,
  toggleDrawer: () => {
    // Default
  },
  getBotReport: () => {
    // Default
  },
  data: {},
  clearBotReport: () => {
    // Default
  },
};

export default Report;
