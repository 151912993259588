import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';

import ViewPage from '../../../Components/ViewPage';

import {
  ROUTE_ANALYTICS,
  ROUTE_ANALYTICS_LEARNING,
} from '../../../Routes/routes';

const breadcrumbs = (t) => [{
  title: t('Views.analyticsTitle'),
  route: `/${ROUTE_ANALYTICS}`,
}, {
  title: t('Analytics.learningTitle'),
  route: `/${ROUTE_ANALYTICS_LEARNING}`,
}];

function Learning({
  toggleDrawer,
  loading,
}) {
  const { t } = useTranslation();
  return (
    <ViewPage
      title={t('Analytics.learningTitle')}
      toggleDrawer={toggleDrawer}
      loading={loading}
      breadcrumbs={breadcrumbs(t)}
    >
      <Box
        data-testid="Learning-View-Container"
        sx={{
          mt: 1,
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
        }}
      />
    </ViewPage>
  );
}

Learning.propTypes = {
  toggleDrawer: PropTypes.func,
  loading: PropTypes.bool,
};

Learning.defaultProps = {
  toggleDrawer: () => {
    // Default
  },
  loading: false,
};

export default Learning;
