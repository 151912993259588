import {
  ROUTE_COMPLIANCE,
} from '../../../Routes/routes';

const breadcrumbs = (t, id) => {
  const bc = [{
    title: t('Views.complianceTitle'),
    route: `/${ROUTE_COMPLIANCE}`,
  }];
  if (id) {
    bc.push({
      title: id,
      route: `/${ROUTE_COMPLIANCE}/${id}/changeset`,
    });
    bc.push({
      title: t('Compliance.impactAnalysisTitle'),
      route: `/${ROUTE_COMPLIANCE}/${id}/impact-analysis`,
    });
  }
  return bc;
};

export default breadcrumbs;
