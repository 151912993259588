import React from 'react';
import PropTypes from 'prop-types';

import TextField from '@mui/material/TextField';

function Add({
  treatment,
  updateTreatment,
}) {
  const updateIntent = (val) => {
    const updated = {
      ...treatment,
      properties: {
        ...treatment.properties,
        intent: val,
      },
    };
    updateTreatment(() => updated);
  };

  const updateUtterance = (val) => {
    const updated = {
      ...treatment,
      properties: {
        ...treatment.properties,
        utterance: val,
      },
    };
    updateTreatment(() => updated);
  };
  return (
    <>
      <TextField
        autoComplete="off"
        fullWidth
        id="intent"
        label="Intent"
        variant="outlined"
        margin="normal"
        value={treatment?.properties?.intent || ''}
        onChange={(e) => updateIntent(e.target.value)}
      />
      <TextField
        autoComplete="off"
        fullWidth
        id="topic"
        label="Utterance"
        variant="outlined"
        margin="normal"
        value={treatment?.properties?.utterance || ''}
        onChange={(e) => updateUtterance(e.target.value)}
      />
    </>
  );
}

Add.propTypes = {
  treatment: PropTypes.object,
  updateTreatment: PropTypes.func,
};

Add.defaultProps = {
  treatment: {},
  updateTreatment: () => {
    // Default
  },
};

export default Add;
