import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Chip from '@mui/material/Chip';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import sorting from '../../../../util/sorting';

const sort = (array) => sorting.sortByOptions(array, { sortKey: 'utterance', direction: 'ascending' });

function Intent({ intent }) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <TableRow
        data-testid="Intent-Container"
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell>{intent.name}</TableCell>
        <TableCell>{intent.displayName}</TableCell>
        <TableCell>{intent.scope}</TableCell>
        <TableCell align="right">
          <Tooltip title="View Utterances" placement="top">
            <IconButton aria-label="View" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ display: 'flex', m: 1, flexWrap: 'wrap' }}>
              {sort(intent?.utterances || [])
                .map((_utterance) => (
                  <Chip
                    sx={{ mr: 2, mt: 2 }}
                    key={_utterance.utterance}
                    label={_utterance.utterance}
                  />
                ))}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

Intent.propTypes = {
  intent: PropTypes.object,
};

Intent.defaultProps = {
  intent: {},
};

export default Intent;
