import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import ApexCharts from 'apexcharts';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import chartOptions from './chartOptions';
import Slider from './Slider';

let chart;

function TopicDistribution({
  data,
  setSelectedDataPoint,
  defaultMin,
  sliderMin,
  setSliderMin,
  defaultMax,
  sliderMax,
  setSliderMax,
  sliderValue,
  setSliderValue,
  setSelectedTopic,
  timePeriod,
  updateTimePeriod,
  lang,
}) {
  const { t } = useTranslation();
  const chartRef = useRef(null);
  useEffect(() => {
    if (chartRef.current) {
      const [min, max] = sliderValue;
      chart = new ApexCharts(chartRef.current, chartOptions({
        data,
        min,
        max,
        setSliderMin,
        setSliderMax,
        setSliderValue,
        resetSlider: () => {
          setSliderMin(defaultMin);
          setSliderValue([defaultMin, defaultMax]);
          setSliderMax(defaultMax);
        },
        setSelectedDataPoint,
        setSelectedTopic,
        timePeriod,
      }));
      chart.render();
    }
  }, [chartRef]);
  useEffect(() => {
    if (chart) {
      const [min, max] = sliderValue;
      chart.updateOptions(chartOptions({
        data,
        min,
        max,
        setSliderMin,
        setSliderMax,
        setSliderValue,
        resetSlider: () => {
          setSliderMin(defaultMin);
          setSliderValue([defaultMin, defaultMax]);
          setSliderMax(defaultMax);
        },
        setSelectedDataPoint,
        setSelectedTopic,
        timePeriod,
      }));
    }
  }, [sliderValue, lang]);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
      }}
      data-testid="TopicDistribution-Container"
    >
      <Card
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
        }}
      >
        <CardHeader
          title={t('OperationalInsights.Discussion.TopicDistribution.topicDistributionTitle')}
          titleTypographyProps={{ color: 'primary.main' }}
          subheader="Discovered Topics over the last 3 months"
          action={(
            <Box sx={{ width: 220 }}>
              <FormControl fullWidth size="small">
                <InputLabel id="time-period">Time Period</InputLabel>
                <Select
                  labelId="time-period"
                  id="time-period"
                  label="Time Period"
                  defaultValue="MONTH"
                  value={timePeriod}
                  onChange={updateTimePeriod}
                >
                  <MenuItem value="MONTH">Month</MenuItem>
                  <MenuItem value="WEEK">Week</MenuItem>
                  <MenuItem value="DAY">Day</MenuItem>
                  <MenuItem value="HOUR">Hour</MenuItem>
                </Select>
              </FormControl>
            </Box>
          )}
        />
        <CardContent sx={{ display: 'flex', flex: 1, mr: 1 }}>
          <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
            <Box ref={chartRef} />
            <Slider
              timePeriod={timePeriod}
              min={sliderMin}
              max={sliderMax}
              value={sliderValue}
              setValue={setSliderValue}
            />
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}

TopicDistribution.propTypes = {
  data: PropTypes.array,
  setSelectedDataPoint: PropTypes.func,
  defaultMin: PropTypes.number,
  sliderMin: PropTypes.number,
  setSliderMin: PropTypes.func,
  defaultMax: PropTypes.number,
  sliderMax: PropTypes.number,
  setSliderMax: PropTypes.func,
  sliderValue: PropTypes.array,
  setSliderValue: PropTypes.func,
  setSelectedTopic: PropTypes.func,
  timePeriod: PropTypes.string,
  updateTimePeriod: PropTypes.func,
  lang: PropTypes.string,
};

TopicDistribution.defaultProps = {
  data: [],
  setSelectedDataPoint: () => {
    // Default
  },
  defaultMin: 0,
  sliderMin: 0,
  setSliderMin: () => {
    // Default
  },
  defaultMax: 0,
  sliderMax: 0,
  setSliderMax: () => {
    // Default
  },
  sliderValue: [0, 0],
  setSliderValue: () => {
    // Default
  },
  setSelectedTopic: () => {
    // Default
  },
  timePeriod: 'MONTH',
  updateTimePeriod: () => {
    // Default
  },
  lang: '',
};

export default TopicDistribution;
