import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import TablePagination from '@mui/material/TablePagination';
import Button from '@mui/material/Button';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import RefreshIcon from '@mui/icons-material/Refresh';
import UploadIcon from '@mui/icons-material/Upload';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AssessmentIcon from '@mui/icons-material/Assessment';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import DifferenceIcon from '@mui/icons-material/Difference';
import RouteIcon from '@mui/icons-material/Route';

import ViewPage from '../../../Components/ViewPage';
import Import from './Import';
import SortableColumn, { ASC } from '../../../Components/Table/SortableColumn';
import sorting from '../../../util/sorting';

import {
  ROUTE_DESIGN_INSIGHTS,
  ROUTE_DESIGN_INSIGHTS_DESIGN_REGISTRY,
} from '../../../Routes/routes';

const breadcrumbs = (t) => [{
  title: t('Views.designInsightsTitle'),
  route: `/${ROUTE_DESIGN_INSIGHTS}`,
}, {
  title: t('DesignInsights.designRegistryTitle'),
  route: `/${ROUTE_DESIGN_INSIGHTS_DESIGN_REGISTRY}`,
}];

const COLUMNS = [{
  title: 'Version',
  sort: true,
  sortKey: 'version',
}, {
  title: 'Locale',
  sort: true,
  sortKey: 'locale',
}, {
  title: '',
  sort: false,
  sortKey: '',
}];

const filterAndSort = (searchterm, array, sortOptions) => {
  let filtered = [];
  try {
    if (!searchterm) {
      filtered = array;
    }
    const term = searchterm.toLowerCase();
    filtered = array.filter((item) => item?.name?.toLowerCase()?.includes(term)
    || item?.locale?.toLowerCase()?.includes(term)
    || item?.version?.toLowerCase()?.includes(term));
  } catch (_err) {
    filtered = [];
  }
  return sorting.sortByOptions(filtered, sortOptions);
};

function DesignRegistry({
  toggleDrawer,
  botDesigns,
  loading,
  listBotDesigns,
  clearBotDesigns,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState({
    title: 'Bot Name',
    sortKey: 'name',
    direction: ASC,
  });
  const [showImportDialog, setShowImportDialog] = useState(false);
  const [expanded, setExpanded] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    listBotDesigns();
    return () => {
      clearBotDesigns();
    };
  }, []);
  const refreshDesigns = () => {
    setSearch('');
    listBotDesigns();
  };

  const groupedDesigns = {};
  const converted = [];
  botDesigns.forEach((_design) => {
    const currentDesigns = groupedDesigns?.[_design?.name] || [];
    currentDesigns.push(_design);
    groupedDesigns[_design.name] = currentDesigns;
  });
  Object.keys(groupedDesigns).forEach((key) => {
    const values = groupedDesigns[key];
    converted.push({
      name: key,
      designs: values,
    });
  });

  const filteredAndSorted = filterAndSort(search, converted, sort);
  const rowsToDisplay = rowsPerPage > 0
    ? filteredAndSorted.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : filteredAndSorted;
  return (
    <ViewPage
      title={t('DesignInsights.designRegistryTitle')}
      toggleDrawer={toggleDrawer}
      breadcrumbs={breadcrumbs(t)}
      loading={loading}
    >
      <Box
        data-testid="DesignRegistry-View-Container"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          mt: 2,
          width: '100%',
          mb: 2,
        }}
      >
        <Box sx={{ display: 'flex', mb: 2, justifyContent: 'flex-end' }}>
          <Card sx={{ mr: 2 }}>
            <Button variant="text" onClick={() => setShowImportDialog(true)}>
              <UploadIcon sx={{ mr: 1 }} />
              Import Bot
            </Button>
          </Card>
          <Card sx={{ mr: 2 }}>
            <Button variant="text" disabled>
              <CompareArrowsIcon sx={{ mr: 1 }} />
              Compare Bot
            </Button>
          </Card>
          <Card sx={{ mr: 2 }}>
            <Button variant="text" disabled>
              <DifferenceIcon sx={{ mr: 1 }} />
              Run Compatibility Report
            </Button>
          </Card>
          <Card>
            <Button variant="text" disabled>
              <RouteIcon sx={{ mr: 1 }} />
              Pathway Analysis
            </Button>
          </Card>
        </Box>
        <Card sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
          <CardHeader
            title="Bot Designs"
            titleTypographyProps={{ color: 'primary.main' }}
            subheader="Available Bot Designs"
            action={(
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  autoComplete="off"
                  size="small"
                  placeholder="Search"
                  id="Search-Field"
                  sx={{ mr: 2 }}
                  onChange={(e) => {
                    setPage(0);
                    setSearch(e.target.value);
                  }}
                  value={search}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <Tooltip title="Refresh Bot Designs" placement="top">
                  <IconButton aria-label="Refresh" onClick={refreshDesigns}>
                    <RefreshIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          />
          <CardContent
            sx={{
              display: 'flex',
              flex: 1,
              mr: 1,
              flexDirection: 'column',
            }}
          >
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <SortableColumn
                      column={{
                        title: 'Bot Name',
                        sort: true,
                        sortKey: 'name',
                      }}
                      sort={sort}
                      setSort={setSort}
                    />
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>
            {rowsToDisplay.map((groupedDesign) => {
              const { designs } = groupedDesign;
              return (
                <Accordion
                  disableGutters
                  square
                  onChange={(e, exp) => {
                    if (exp) {
                      setExpanded(groupedDesign.name);
                    } else {
                      setExpanded('');
                    }
                  }}
                  key={groupedDesign.name}
                  expanded={groupedDesign.name === expanded}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>{groupedDesign.name}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TableContainer>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            {COLUMNS.map((_col) => (
                              <TableCell key={_col?.title}>
                                {_col?.title}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {designs.map((row) => (
                            <TableRow key={`${row.id}${row.version}${row.locale}`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              <TableCell>{row.version}</TableCell>
                              <TableCell>{row.locale}</TableCell>
                              <TableCell align="right">
                                <Tooltip title="View Bot Report" placement="top">
                                  <IconButton aria-label="View" onClick={() => navigate(`/${ROUTE_DESIGN_INSIGHTS_DESIGN_REGISTRY}/report/${row.name}`)}>
                                    <AssessmentIcon />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="View Bot Design" placement="top">
                                  <IconButton aria-label="View" onClick={() => navigate(`/${ROUTE_DESIGN_INSIGHTS_DESIGN_REGISTRY}/${row.id}`)}>
                                    <MoreHorizIcon />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </AccordionDetails>
                </Accordion>
              );
            })}
            <TablePagination
              rowsPerPageOptions={[10, 20, 50, { label: 'All', value: -1 }]}
              colSpan={COLUMNS.length}
              count={search ? filteredAndSorted.length : converted.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={(e, _page) => setPage(_page)}
              onRowsPerPageChange={(e) => {
                setRowsPerPage(parseInt(e.target.value, 10));
                setPage(0);
              }}
            />
          </CardContent>
        </Card>
      </Box>
      {showImportDialog && <Import onClose={() => setShowImportDialog(false)} />}
    </ViewPage>
  );
}

DesignRegistry.propTypes = {
  loading: PropTypes.bool,
  toggleDrawer: PropTypes.func,
  listBotDesigns: PropTypes.func,
  botDesigns: PropTypes.array,
  clearBotDesigns: PropTypes.func,
};

DesignRegistry.defaultProps = {
  loading: false,
  toggleDrawer: () => {
    // Default
  },
  listBotDesigns: () => {
    // Default
  },
  botDesigns: [],
  clearBotDesigns: () => {
    // Default
  },
};

export default DesignRegistry;
