import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import UploadIcon from '@mui/icons-material/Upload';

import locales from '../../../../util/locales';

function File({
  loading,
  onClose,
  importBotDesign,
  onBack,
}) {
  const selectFileRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [botDesign, setBotDesign] = useState({
    name: '',
    version: '',
    locale: '',
  });
  const onImport = async () => {
    await importBotDesign(botDesign, selectedFile);
    onClose();
  };

  const updateBotDesignValue = (key, value) => {
    const currentBotDesign = { ...botDesign };
    currentBotDesign[key] = value || '';
    setBotDesign(currentBotDesign);
  };

  const selectFile = () => {
    selectFileRef.current.click();
  };

  const onChangeFile = (e) => {
    const file = e?.target?.files?.[0];
    if (file) {
      setSelectedFile(file);
    }
  };
  return (
    <>
      <DialogContent>
        <TextField
          autoComplete="off"
          fullWidth
          id="BotDesign-Name"
          label="Name"
          variant="outlined"
          margin="normal"
          value={botDesign.name}
          onChange={(e) => updateBotDesignValue('name', e.target.value)}
        />
        <TextField
          autoComplete="off"
          fullWidth
          id="BotDesign-Version"
          label="Version"
          variant="outlined"
          margin="normal"
          value={botDesign.version}
          onChange={(e) => updateBotDesignValue('version', e.target.value)}
        />
        <Autocomplete
          fullWidth
          id="BotDesign-Locale"
          label="Locale"
          options={Object.keys(locales).map((loc) => loc)}
          value={botDesign.locale}
          onChange={(e, val) => updateBotDesignValue('locale', val)}
          renderInput={(params) => (
            <TextField
              {...params}
              autoComplete="off"
              fullWidth
              margin="normal"
              label="Locale"
              placeholder="Locale"
            />
          )}
        />
        <Button
          onClick={selectFile}
          disabled={loading}
          variant="contained"
          sx={{ mt: 2 }}
        >
          <UploadIcon sx={{ mr: 2 }} />
          {selectedFile ? selectedFile.name : 'Attach CSV'}
        </Button>
        <input
          data-testid="file-upload-input"
          type="file"
          accept=".csv"
          ref={selectFileRef}
          style={{ display: 'none' }}
          onChange={(e) => onChangeFile(e)}
        />
      </DialogContent>
      <DialogActions>
        <Box sx={{ display: 'flex', flex: 1, m: 2 }}>
          <Button
            onClick={onBack}
            disabled={loading}
            sx={{ mr: 2, width: 100 }}
          >
            Back
          </Button>
        </Box>
        <Box sx={{ m: 2 }}>
          <Button
            onClick={onClose}
            disabled={loading}
            sx={{ mr: 2, width: 100 }}
          >
            Cancel
          </Button>
          <Button
            sx={{ width: 100 }}
            onClick={onImport}
            variant="contained"
            disabled={!selectedFile || loading}
          >
            {loading ? 'Importing...' : 'Import'}
          </Button>
        </Box>
      </DialogActions>
    </>
  );
}

File.propTypes = {
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  importBotDesign: PropTypes.func,
  onBack: PropTypes.func,
};

File.defaultProps = {
  loading: false,
  onClose: () => {
    // Defaults
  },
  importBotDesign: () => {
    // Defaults
  },
  onBack: () => {
    // Defaults
  },
};

export default File;
