import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const QUEUES = [{
  name: 'First Queue',
}, {
  name: 'Second Queue',
}, {
  name: 'Third Queue',
}];

function Create({
  onClose,
  loading,
  create,
}) {
  const [user, setUser] = useState({
    email: '',
    firstName: '',
    lastName: '',
    role: '',
    queues: [],
  });
  const updateUserValue = (key, value) => {
    const currentUser = { ...user };
    currentUser[key] = value;
    setUser(currentUser);
  };
  const onCreate = async () => {
    await create(user);
    onClose();
  };
  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open
      onClose={onClose}
    >
      <DialogTitle>
        Create a new User
      </DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          id="User-Email-Address"
          label="Email Address"
          variant="outlined"
          margin="normal"
          value={user.email}
          onChange={(e) => updateUserValue('email', e.target.value)}
        />
        <TextField
          fullWidth
          id="User-First-Name"
          label="First Name"
          variant="outlined"
          margin="normal"
          value={user.firstName}
          onChange={(e) => updateUserValue('firstName', e.target.value)}
        />
        <TextField
          fullWidth
          id="User-Last-Name"
          label="Last Name"
          variant="outlined"
          margin="normal"
          value={user.lastName}
          onChange={(e) => updateUserValue('lastName', e.target.value)}
        />
        <FormControl fullWidth margin="normal">
          <InputLabel id="User-Role-Label">Role</InputLabel>
          <Select
            labelId="User-Role-Label"
            id="User-Role"
            value={user.role}
            label="Role"
            onChange={(e) => updateUserValue('role', e.target.value)}
          >
            <MenuItem value="Admin">Admin</MenuItem>
            <MenuItem value="Reviewer">Reviewer</MenuItem>
          </Select>
        </FormControl>
        <Autocomplete
          fullWidth
          multiple
          id="User-Queue-List"
          options={QUEUES}
          disableCloseOnSelect
          getOptionLabel={(option) => option.name}
          value={(user?.queues || []).map((_queue) => ({ name: _queue }))}
          isOptionEqualToValue={(option, value) => option.name === value.name}
          onChange={(event, val) => updateUserValue('queues', val.map((_val) => _val.name))}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              margin="normal"
              label="Assigned Queues"
              placeholder="Assigned Queues"
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Box sx={{ m: 2 }}>
          <Button
            onClick={onClose}
            disabled={loading}
            sx={{ mr: 2, width: 100 }}
          >
            Cancel
          </Button>
          <Button
            sx={{ width: 100 }}
            onClick={onCreate}
            variant="contained"
            disabled={loading}
          >
            {loading ? 'Creating...' : 'Create'}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

Create.propTypes = {
  onClose: PropTypes.func,
  loading: PropTypes.bool,
  create: PropTypes.func,
};

Create.defaultProps = {
  onClose: () => {
    // Default
  },
  loading: false,
  create: () => {
    // Default
  },
};

export default Create;
