export const formatData = () => [{
  title: 'Inference Events',
  data: 2000,
}, {
  title: 'Missed Inputs',
  data: 1500,
}, {
  title: 'Treatments',
  data: 500,
}, {
  title: 'Recommendations',
  data: 200,
}, {
  title: 'Treatment Rate: 20%',
  data: 200,
}, {
  title: 'Avg. treatments per event',
  data: 2,
}];

export const getTotalsHeader = (period) => {
  const str = 'Totals over the last ';
  if (period === 'DAY') {
    return `${str} 24 hours`;
  }
  if (period === 'WEEK') {
    return `${str} 7 days`;
  }
  return `${str} month`;
};

export const getSubHeader = (period) => {
  const str = 'Events by source over the last ';
  if (period === 'DAY') {
    return `${str} 24 hours`;
  }
  if (period === 'WEEK') {
    return `${str} 7 days`;
  }
  return `${str} month`;
};

export const sortOrgs = (data) => data.sort((a, b) => {
  if (a.inferenceEvents > b.inferenceEvents) {
    return -1;
  }
  if (a.inferenceEvents < b.inferenceEvents) {
    return 1;
  }
  return 0;
});
