import { connect } from 'react-redux';

import Treatment from './Treatment';
import {
  getTreatment,
  clearTreatment,
} from '../../../store/analytics/treatment/actions';

const mapStateToProps = (state) => ({
  loading: state?.analytics?.treatment?.loading,
  data: state?.analytics?.treatment?.data,
  lang: state?.state?.lang,
});

const mapDispatchToProps = (dispatch) => ({
  getTreatment: (period) => dispatch(getTreatment(period)),
  clearTreatment: () => dispatch(clearTreatment()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Treatment);
