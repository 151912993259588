import { calculateRedaction, formatTotalData } from '../../Analytics/Redaction/util';

export const formatOrgs = (data) => {
  const redactionData = data?.redactionActivityReports || [];
  if (!redactionData || data.redactionData === 0) {
    return [];
  }
  const orgs = {};
  redactionData.forEach((_dat) => {
    (_dat?.orgs || []).forEach((_org) => {
      if (!orgs[_org.name]) {
        orgs[_org.name] = { totals: {}, redactionRates: [] };
      }
      orgs[_org.name].redactionRates.push(_org.redactionRate || 0);
      (_org?.activity?.totals || []).forEach((_total) => {
        const name = _total?.name;
        let count = 0;
        (_total?.entities || []).forEach((_entity) => {
          count += _entity.count;
        });
        if (!orgs[_org.name].totals?.[name]) {
          orgs[_org.name].totals[name] = count;
        } else {
          orgs[_org.name].totals[name] += count;
        }
      });
    });
  });
  Object.keys(orgs).forEach((org) => {
    const rates = orgs[org].redactionRates;
    let redactionRate = 0;
    if (rates.length > 0) {
      redactionRate = Math.round(rates
        .reduce((curr, next) => curr + next, 0) / rates.length);
    }
    orgs[org].redactionRate = redactionRate;
  });
  return orgs;
};

export const formatTotals = (data) => {
  const totals = {};
  const redactionRates = [];
  const redactionData = data?.redactionActivityReports || [];
  if (!redactionData || data.redactionData === 0) {
    return [];
  }
  redactionData.forEach((_dat) => {
    (_dat?.orgs || []).forEach((_org) => {
      redactionRates.push(_org.redactionRate || 0);
      (_org?.activity?.totals || []).forEach((_total) => {
        const name = _total?.name;
        let count = 0;
        (_total?.entities || []).forEach((_entity) => {
          count += _entity.count;
        });
        if (!totals[name]) {
          totals[name] = count;
        } else {
          totals[name] += count;
        }
      });
    });
  });
  const redactionRate = calculateRedaction(redactionRates);
  return formatTotalData(totals, redactionRate);
};

export const formatSources = (data, org) => {
  const redactionData = data?.redactionActivityReports || [];
  if (!redactionData || data.redactionData === 0) {
    return [];
  }
  const totals = {};
  redactionData.forEach((_dat) => {
    const foundOrg = _dat.orgs.find((_org) => _org.name === org);
    if (foundOrg) {
      (foundOrg?.activity?.sources || []).forEach((_source) => {
        const name = _source?.name;
        if (!totals[name]) {
          totals[name] = {};
        }
        (_source?.categories || []).forEach((_category) => {
          let total = 0;
          _category.entities.forEach((_ent) => {
            total += _ent.count;
          });
          if (!totals[name]?.[_category.name]) {
            totals[name][_category.name] = total;
          } else {
            totals[name][_category.name] += total;
          }
        });
      });
    }
  });
  return totals;
};
