import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';

function Delete({
  loading,
  onClose,
  selectedQueue,
  deleteQueue,
}) {
  const onDelete = async () => {
    await deleteQueue(selectedQueue);
    onClose();
  };
  if (!selectedQueue) {
    return null;
  }
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open
      onClose={onClose}
    >
      <DialogTitle>
        Delete Queue?
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to permanently delete
          {' '}
          {selectedQueue.name}
          ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Box sx={{ m: 2 }}>
          <Button
            onClick={onClose}
            disabled={loading}
            sx={{ mr: 2, width: 100 }}
          >
            Cancel
          </Button>
          <Button
            sx={{ width: 100 }}
            onClick={onDelete}
            variant="contained"
            disabled={loading}
          >
            {loading ? 'Deleting...' : 'Delete'}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

Delete.propTypes = {
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  selectedQueue: PropTypes.any,
  deleteQueue: PropTypes.func,
};

Delete.defaultProps = {
  loading: false,
  onClose: () => {
    // Default
  },
  selectedQueue: null,
  deleteQueue: () => {
    // Default
  },
};

export default Delete;
