export const formatData = (data) => {
  let tr = 0;
  if (data?.values?.inferenceEvents && data?.values?.treatments) {
    tr = Math.round((data.values.treatments / data.values.inferenceEvents) * 100);
  }
  return [{
    title: 'Inference Events',
    data: data?.values?.inferenceEvents || 0,
  }, {
    title: 'Missed Inputs',
    data: data?.values?.missedinput || 0,
  }, {
    title: 'Treatments',
    data: data?.values?.treatments || 0,
  }, {
    title: 'Recommendations',
    data: data?.values?.reviews || 0,
  }, {
    title: 'Treatment Rate',
    data: `${tr}%`,
  }, {
    title: 'Avg. treatments per event',
    data: 1,
  }];
};

export const dummy = () => {};
