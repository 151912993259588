import {
  ROUTE_ANALYTICS,
  ROUTE_ANALYTICS_REDACTION,
} from '../../../Routes/routes';

const breadcrumbs = (t) => [{
  title: t('Views.analyticsTitle'),
  route: `/${ROUTE_ANALYTICS}`,
}, {
  title: t('Analytics.redactionTitle'),
  route: `/${ROUTE_ANALYTICS_REDACTION}`,
}];

export default breadcrumbs;
