export const ADMIN_USERS_LIST = 'ADMIN_USERS_LIST';
export const ADMIN_USERS_LIST_SUCCESS = 'ADMIN_USERS_LIST_SUCCESS';
export const ADMIN_USERS_LIST_FAIL = 'ADMIN_USERS_LIST_FAIL';

export const ADMIN_USER_EDIT = 'ADMIN_USER_EDIT';
export const ADMIN_USER_EDIT_SUCCESS = 'ADMIN_USER_EDIT_SUCCESS';
export const ADMIN_USER_EDIT_FAIL = 'ADMIN_USER_EDIT_FAIL';

export const ADMIN_USER_CREATE = 'ADMIN_USER_CREATE';
export const ADMIN_USER_CREATE_SUCCESS = 'ADMIN_USER_CREATE_SUCCESS';
export const ADMIN_USER_CREATE_FAIL = 'ADMIN_USER_CREATE_FAIL';

export const ADMIN_USERS_CLEAR = 'ADMIN_USERS_CLEAR';
