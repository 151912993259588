import { connect } from 'react-redux';

import Create from './Create';
import { createChangeSet } from '../../../store/compliance/actions';

const mapStateToProps = (state) => ({
  loading: state?.compliance?.index?.creating,
  botDesigns: state?.compliance?.index?.botDesigns,
});

const mapDispatchToProps = (dispatch) => ({
  create: (changeSet) => dispatch(createChangeSet(changeSet)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Create);
