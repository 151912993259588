import React from 'react';

import PropTypes from 'prop-types';

function OperationalInsights({ width, height }) {
  return (
    <svg width={width} height={height} viewBox="0 0 130 130" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3320_749)">
        <path d="M6.97063 38.9012C6.42244 40.1684 7.00532 41.6401 8.27254 42.1883C9.53976 42.7365 11.0114 42.1536 11.5596 40.8864L6.97063 38.9012ZM114.797 42.1359C116.035 42.7466 117.534 42.2379 118.145 40.9995L128.097 20.8202C128.708 19.5819 128.199 18.083 126.961 17.4723C125.722 16.8616 124.223 17.3703 123.613 18.6086L114.766 36.5458L96.8291 27.6995C95.5908 27.0888 94.0919 27.5976 93.4812 28.8359C92.8705 30.0742 93.3792 31.5731 94.6176 32.1838L114.797 42.1359ZM11.5596 40.8864C21.7695 17.285 43.3164 5.53125 64.3012 5.53125C85.2673 5.53125 105.576 17.2463 113.535 40.6973L118.27 39.0902C109.547 13.3912 87.1678 0.531249 64.3012 0.53125C41.4534 0.531251 18.0229 13.3525 6.97063 38.9012L11.5596 40.8864Z" fill="white" />
        <path d="M123.197 89.0239C123.746 87.7567 123.163 86.285 121.895 85.7368C120.628 85.1886 119.157 85.7715 118.608 87.0387L123.197 89.0239ZM15.3711 85.7891C14.1328 85.1784 12.6338 85.6872 12.0231 86.9255L2.07105 107.105C1.46034 108.343 1.9691 109.842 3.20741 110.453C4.44571 111.063 5.94463 110.555 6.55534 109.316L15.4017 91.3792L33.3388 100.226C34.5771 100.836 36.0761 100.327 36.6868 99.0892C37.2975 97.8509 36.7887 96.3519 35.5504 95.7412L15.3711 85.7891ZM118.608 87.0387C108.398 110.64 86.8515 122.394 65.8667 122.394C44.9007 122.394 24.5924 110.679 16.6326 87.2277L11.8979 88.8348C20.6207 114.534 43.0001 127.394 65.8667 127.394C88.7146 127.394 112.145 114.573 123.197 89.0239L118.608 87.0387Z" fill="white" />
        <circle cx="66.9163" cy="63.5102" r="11.5672" stroke="white" strokeWidth="5" />
        <path d="M39.3136 51.4101L42.6471 53.4791C43.8794 54.244 44.6012 55.6161 44.5332 57.065L43.8626 71.3727C43.8009 72.6883 43.0955 73.8893 41.9764 74.5839L38.6072 76.6752C36.7675 77.817 36.2145 80.2414 37.3769 82.0681C38.4916 83.8197 40.7855 84.387 42.5881 83.3569L45.5541 81.6621C46.8027 80.9486 48.3383 80.9603 49.5759 81.6928L59.9714 87.8452C61.188 88.5652 61.9341 89.8739 61.9341 91.2876V92.3335C61.9341 94.5426 63.725 96.3335 65.9341 96.3335H68.1915C70.4006 96.3335 72.1915 94.5426 72.1915 92.3335V91.2876C72.1915 89.8739 72.9376 88.5652 74.1542 87.8452L84.4783 81.735C85.7538 80.9802 87.3422 80.9924 88.6059 81.7669L92.194 83.9661C94.0901 85.1283 96.57 84.5207 97.7142 82.6137L97.9162 82.277C99.0754 80.3451 98.411 77.8373 96.4473 76.7327L92.5855 74.5605C91.3842 73.8847 90.6154 72.6382 90.5509 71.2614L89.8805 56.959C89.8152 55.5671 90.479 54.2415 91.6327 53.46L95.631 50.7514C97.3889 49.5606 97.794 47.1418 96.52 45.4432C95.3587 43.8947 93.2033 43.4965 91.5653 44.5278L88.5476 46.4279C87.3161 47.2033 85.7606 47.2486 84.4859 46.5463L74.261 40.9121C72.9844 40.2087 72.1915 38.8664 72.1915 37.4088V37.0313C72.1915 34.8221 70.4006 33.0312 68.1915 33.0312H65.9341C63.725 33.0312 61.9341 34.8221 61.9341 37.0312V37.4566C61.9341 38.8887 61.1685 40.2116 59.9266 40.925L50.0848 46.5788C48.8876 47.2666 47.4204 47.2883 46.2034 46.6363L43.0465 44.9452C41.3083 44.014 39.1456 44.5947 38.1077 46.2714C37.023 48.0236 37.5626 50.3233 39.3136 51.4101Z" stroke="white" strokeWidth="5" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_3320_749">
          <rect width="129" height="129" fill="white" transform="translate(0.265137 0.03125)" />
        </clipPath>
      </defs>
    </svg>

  );
}

OperationalInsights.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

OperationalInsights.defaultProps = {
  width: 130,
  height: 130,
};

export default OperationalInsights;
