import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';

import ViewPage from '../../Components/ViewPage';
import GalleryCard from '../../Components/GalleryCard';

import DesignRegistryIcon from './Icons/DesignRegistry';

import {
  ROUTE_DESIGN_INSIGHTS_DESIGN_REGISTRY,
} from '../../Routes/routes';

const sections = (t) => [{
  title: t('DesignInsights.designRegistryTitle'),
  route: ROUTE_DESIGN_INSIGHTS_DESIGN_REGISTRY,
  icon: DesignRegistryIcon,
}];

function DesignInsights({
  toggleDrawer,
}) {
  const { t } = useTranslation();
  return (
    <ViewPage title={t('Views.designInsightsTitle')} toggleDrawer={toggleDrawer}>
      <Box
        data-testid="DesignInsights-View-Container"
        sx={{
          display: 'flex',
          flex: 1,
          flexWrap: 'wrap',
        }}
      >
        {sections(t).map((section) => (
          <GalleryCard
            key={section?.route}
            title={section?.title}
            route={section?.route}
            description={section?.description}
            icon={section?.icon}
          />
        ))}
      </Box>
    </ViewPage>
  );
}

DesignInsights.propTypes = {
  toggleDrawer: PropTypes.func,
};

DesignInsights.defaultProps = {
  toggleDrawer: () => {
    // Default
  },
};

export default DesignInsights;
