import { connect } from 'react-redux';

import User from './User';
import { updateUser } from '../../../../store/admin/users/actions';

const mapStateToProps = (state) => ({
  loading: state?.admin?.users?.updatingUser,
});

const mapDispatchToProps = (dispatch) => ({
  save: (user) => dispatch(updateUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(User);
