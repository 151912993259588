import { connect } from 'react-redux';

import Reject from './Reject';
import { rejectChangeSet } from '../../../../store/compliance/changeset/actions';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  reject: (changeSetId) => dispatch(rejectChangeSet(changeSetId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Reject);
