import {
  INBOX_TREATMENT_LIST_INFERENCE_EVENTS,
  INBOX_TREATMENT_LIST_INFERENCE_EVENTS_SUCCESS,
  INBOX_TREATMENT_LIST_INFERENCE_EVENTS_FAIL,
  INBOX_TREATMENT_LIST_INFERENCE_EVENTS_CLEAR,
} from './types';
import { showAlert } from '../../../state/actions';
import userUtils from '../../../../util/user';
import isUnAuth from '../../../../util/unauth';
import interceptor, { getInboxUrl } from '../../../../util/interceptor';
import { logoutUser } from '../../../auth/actions';

export const listEventsAttempt = () => ({ type: INBOX_TREATMENT_LIST_INFERENCE_EVENTS });
export const listEventsSuccess = (events) => ({
  type: INBOX_TREATMENT_LIST_INFERENCE_EVENTS_SUCCESS,
  payload: {
    events,
  },
});
export const listEventsFail = () => ({ type: INBOX_TREATMENT_LIST_INFERENCE_EVENTS_FAIL });

export const listEvents = (queueId) => async (dispatch, getState) => {
  const ERROR_MESSAGE = 'Failed to list Events, please try again.';
  dispatch(listEventsAttempt());
  try {
    const state = getState();
    const { authToken, authenticatedUser } = state.auth;
    const tenant = userUtils.getTenant(authenticatedUser);
    const region = userUtils.getRegion(authenticatedUser);
    const res = await interceptor({
      method: 'post',
      url: `${getInboxUrl(region)}/${tenant}/ListQueueInferenceEvents`,
      data: {
        queueId,
        view: 'raw',
        properties: {},
      },
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    });
    const { data } = res;
    const { inferenceEvents } = data;
    dispatch(listEventsSuccess(inferenceEvents));
  } catch (_err) {
    const status = _err?.response?.status;
    if (isUnAuth(status)) {
      dispatch(logoutUser({ location: window.location.href }));
    } else {
      dispatch(listEventsFail());
      dispatch(showAlert({ type: 'error', message: ERROR_MESSAGE }));
    }
  }
};

export const clearEvents = () => ({ type: INBOX_TREATMENT_LIST_INFERENCE_EVENTS_CLEAR });
