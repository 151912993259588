export const getLocale = () => {
  const defaultLocale = 'en';
  try {
    const storage = localStorage || sessionStorage;
    if (storage) {
      return storage.getItem('locale') || defaultLocale;
    }
    return defaultLocale;
  } catch (_err) {
    return defaultLocale;
  }
};

export const setLocale = (locale) => {
  try {
    const storage = localStorage || sessionStorage;
    if (storage) {
      return storage.setItem('locale', locale);
    }
    return '';
  } catch (_err) {
    return '';
  }
};
