import {
  DISCUSSION_FETCH_TOPIC_DISTRIBUTION,
  DISCUSSION_FETCH_TOPIC_DISTRIBUTION_SUCCESS,
  DISCUSSION_FETCH_TOPIC_DISTRIBUTION_FAIL,
} from './types';
import { showAlert } from '../../state/actions';

import monthNetworkData from '../../../util/month-data.json';
import weekNetworkData from '../../../util/week-data.json';
import datNetworkData from '../../../util/day-data.json';
import hourNetworkData from '../../../util/hour-data.json';

export const fetchTopicDistributionAttempt = () => ({
  type: DISCUSSION_FETCH_TOPIC_DISTRIBUTION,
});

export const fetchTopicDistributionSuccess = (data) => ({
  type: DISCUSSION_FETCH_TOPIC_DISTRIBUTION_SUCCESS,
  payload: { data },
});

export const fetchTopicDistributionFail = () => ({
  type: DISCUSSION_FETCH_TOPIC_DISTRIBUTION_FAIL,
});

// eslint-disable-next-line
const delay = (ms) => new Promise((res) => setTimeout(res, ms));

const formatData = (data) => {
  const { Topics } = data;
  const renderableData = Object.keys(Topics).map((key) => {
    const topicsPerDay = Topics[key];
    return ({
      x: parseInt(key, 10),
      y: topicsPerDay.length,
      additional: {
        topics: topicsPerDay.map((topic) => ({
          text: topic.Unigram,
          value: topic.Utterances.length,
          utterances: topic.Utterances,
          unigram: topic.Unigram,
          bigrams: topic.Bigrams,
        })),
      },
    });
  });
  return renderableData;
};

export const fetchTopicDistributionData = (period) => async (dispatch) => {
  const ERROR_MESSAGE = 'Failed to load data, please try again.';
  dispatch(fetchTopicDistributionAttempt());
  try {
    await delay(1000);
    const PERIOD_MAP = {
      MONTH: formatData(monthNetworkData),
      WEEK: formatData(weekNetworkData),
      DAY: formatData(datNetworkData),
      HOUR: formatData(hourNetworkData),
    };
    dispatch(fetchTopicDistributionSuccess(PERIOD_MAP[period]));
  } catch (_err) {
    dispatch(fetchTopicDistributionFail());
    dispatch(showAlert({ type: 'error', message: ERROR_MESSAGE }));
  }
};
