import {
  STATE_DRAWER_OPEN,
  STATE_DRAWER_CLOSE,
  STATE_DRAWER_TOGGLE,
  STATE_ALERT_SHOW,
  STATE_ALERT_HIDE,
  STATE_SET_QUEUE,
  STATE_SET_LANG,
} from './types';

export const openDrawer = () => ({
  type: STATE_DRAWER_OPEN,
});

export const closeDrawer = () => ({
  type: STATE_DRAWER_CLOSE,
});

export const toggleDrawer = () => ({
  type: STATE_DRAWER_TOGGLE,
});

export const showAlert = ({ type, message }) => ({
  type: STATE_ALERT_SHOW,
  payload: { type, message },
});

export const hideAlert = () => ({
  type: STATE_ALERT_HIDE,
});

export const setQueue = (queue) => ({
  type: STATE_SET_QUEUE,
  payload: { queue },
});

export const setLang = (lang) => ({
  type: STATE_SET_LANG,
  payload: { lang },
});
