import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import ViewPage from '../ViewPage';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return (
        <ViewPage title="Error">
          <Card sx={{ mt: 2 }}>
            <CardHeader
              title="An error has occurred, please try again"
              titleTypographyProps={{ color: 'primary.main' }}
            />
            <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography color="text.secondary">
                Contact your administrator if this error message persists
              </Typography>
              <Button
                variant="text"
                onClick={() => this.setState({ hasError: false })}
                sx={{ mt: 2, alignSelf: 'flex-end' }}
                data-testid="Retry-Button"
              >
                Try Again
              </Button>
            </CardContent>
          </Card>
        </ViewPage>
      );
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
