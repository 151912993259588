import { combineReducers } from 'redux';

import designRegistry from './designRegistry/reducer';
import design from './designRegistry/design/reducer';
import report from './designRegistry/report/reducer';

const reducers = combineReducers({
  designRegistry: combineReducers({
    index: designRegistry,
    design,
    report,
  }),
});

export default reducers;
