import { connect } from 'react-redux';

import AppBar from './AppBar';
import { setLang } from '../../store/state/actions';
import { logout } from '../../store/auth/actions';

const mapStateToProps = (state) => ({
  drawerOpen: state?.state?.drawerOpen,
  authenticatedUser: state?.auth?.authenticatedUser,
  queues: state?.auth?.queues,
  selectedQueue: state?.state?.selectedQueue,
  region: state?.auth?.authenticatedUser?.['custom:sb_region'] || '',
  org: state?.auth?.authenticatedUser?.['custom:org'] || '',
  lang: state?.state?.lang,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
  setLang: (lang) => dispatch(setLang(lang)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppBar);
