import React from 'react';
import getPortalUrl from '../../../../../util/getPortalUrl';
import sorting from '../../../../../util/sorting';
import { COLOR_PINK } from '../../../../../colors';

const api = {
  viewConversation: (region, conversationId) => {
    const url = `${getPortalUrl(region)}/conversations/${conversationId}`;
    window.open(url, '_blank').focus();
  },
  viewConversations: (region, events) => {
    let str = '?';
    const uniq = [...new Set(events)];
    uniq.forEach((_ev) => {
      str += `&conversationid=${_ev.conversationId}`;
    });
    const url = `${getPortalUrl(region)}/conversations${str}`;
    window.open(url, '_blank').focus();
  },
  filterAndSortRaw: (searchterm, array, sortOptions) => {
    let filtered = [];
    try {
      if (!searchterm) {
        filtered = array;
      } else {
        try {
          // attempt regex
          const flags = searchterm.replace(/.*\/([gimy]*)$/, '$1');
          const pattern = searchterm.replace(new RegExp(`^/(.*?)/${flags}$`), '$1');
          const regex = new RegExp(pattern, flags);
          filtered = array.filter((item) => regex.test(item?.utterance));
        } catch (_err) {
          const term = searchterm.toLowerCase();
          filtered = array.filter((item) => item?.matchedIntent?.toLowerCase()?.includes(term)
            || item?.outcome?.toLowerCase()?.includes(term)
            || item?.utterance?.toLowerCase().includes(term));
        }
      }
    } catch (_err) {
      filtered = [];
    }
    return sorting.sortByOptions(filtered, sortOptions);
  },
  highlightRedaction: (data) => {
    if (!data?.properties?.piiRedactionResults?.Entities) {
      return data?.utterance;
    }
    const entities = data.properties.piiRedactionResults.Entities.map((_ent) => _ent.Type);
    let pattern = '';
    entities.forEach((_entity) => {
      pattern = !pattern ? `${_entity}` : `${pattern}|${_entity}`;
    });
    const parts = data.utterance.split(new RegExp(`(${pattern})`, 'gi'));
    return (
      <span>
        {parts.map((part, idx) => (
          // eslint-disable-next-line
          <span key={`${part}-${idx}`} style={entities.includes(part) ? { fontWeight: 'bold', color: COLOR_PINK } : {}}>
            {part}
          </span>
        ))}
      </span>
    );
  },
};

export default api;
