import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

function OrgDetails({
  data,
  selectedOrg,
  setSelectedOrg,
}) {
  const [hover, setHover] = useState({});
  if (data && data.length === 0) {
    return null;
  }
  return (
    <Box sx={{ display: 'flex', mt: 2, flexDirection: 'column' }} data-testid="SourceDetails-Container">
      {Object.keys(data).length !== 0 && (
        <Typography variant="h6" color="text.secondary">
          Organization Details
        </Typography>
      )}
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        {Object.keys(data).map((key, index) => (
          <Card
            elevation={(hover[index] || selectedOrg === key) ? 4 : 2}
            sx={{
              mt: 2,
              mr: 2,
              cursor: 'pointer',
              background: (hover[index] || selectedOrg === key) ? 'rgba(110, 189, 211, 0.6)' : 'white',
            }}
            key={key}
            onMouseEnter={() => setHover({ [index]: true })}
            onMouseLeave={() => setHover({ [index]: false })}
            onClick={() => {
              if (selectedOrg === key) {
                setSelectedOrg(null);
              } else {
                setSelectedOrg(key);
              }
            }}
          >
            <CardContent>
              <Box sx={{ display: 'flex' }}>
                <Typography sx={{ mr: 1 }} color="primary.main">
                  Name:
                </Typography>
                <Typography color={(hover[index] || selectedOrg === key) ? 'text.white' : 'text.secondary'} gutterBottom>
                  {key}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex' }}>
                <Typography sx={{ mr: 1 }} color="primary.main">
                  Redaction Rate:
                </Typography>
                <Typography color={(hover[index] || selectedOrg === key) ? 'text.white' : 'text.secondary'}>
                  {`${data[key]?.redactionRate}%`}
                </Typography>
              </Box>
              {Object.keys(data[key].totals).map((datKey) => (
                <Box sx={{ display: 'flex' }} key={datKey}>
                  <Typography sx={{ mr: 1 }} color="primary.main">
                    {datKey}
                  </Typography>
                  <Typography color={(hover[index] || selectedOrg === key) ? 'text.white' : 'text.secondary'}>
                    {data[key].totals[datKey]}
                  </Typography>
                </Box>
              ))}
            </CardContent>
          </Card>
        ))}
      </Box>
    </Box>
  );
}

OrgDetails.propTypes = {
  data: PropTypes.object,
  selectedOrg: PropTypes.any,
  setSelectedOrg: PropTypes.func,
};

OrgDetails.defaultProps = {
  data: {},
  selectedOrg: null,
  setSelectedOrg: () => {
    // Default
  },
};

export default OrgDetails;
