import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';

function GalleryCard({
  title,
  route,
  description,
  image,
  icon,
}) {
  const navigate = useNavigate();
  const Icon = icon;
  return (
    <Card
      sx={{
        width: 300,
        maxWidth: '300px',
        mt: 2,
        mr: 2,
      }}
      key={title}
    >
      <CardActionArea onClick={() => navigate(`/${route}`)}>
        {!icon ? (
          <CardMedia
            component="img"
            height="200"
            image={image}
            alt={`${title}-icon`}
          />
        ) : (
          <Box
            sx={{
              background: '#73C4DB',
              display: 'flex',
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
              height: 200,
            }}
          >
            <Icon />
          </Box>
        )}
        <CardContent>
          <Typography gutterBottom variant="h5" component="div" color="primary.main">
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

GalleryCard.propTypes = {
  title: PropTypes.string,
  route: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  icon: PropTypes.any,
};

GalleryCard.defaultProps = {
  title: '',
  route: '',
  description: '',
  image: '',
  icon: null,
};

export default GalleryCard;
