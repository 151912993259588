export const sortByCreated = (a, b) => {
  if (a.created > b.created) {
    return -1;
  }
  if (a.created < b.created) {
    return 1;
  }
  return 0;
};

export const formatData = (data) => {
  if (!data || data.length === 0) {
    return {
      values: {
        inferenceEvents: 0,
        missedInputs: 0,
        treatment: 0,
      },
      periodValues: [],
    };
  }
  const sorted = data.sort(sortByCreated);
  const currentData = sorted[0];
  return {
    values: { ...currentData?.values },
    periodValues: sorted.reverse().map((_period) => ({
      period: _period?.created,
      values: _period.values,
    })),
  };
};
