import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import SortableColumn, { ASC } from '../../../../Components/Table/SortableColumn';
import sorting from '../../../../util/sorting';

const COLUMNS = [{
  title: 'Created',
  sort: true,
  sortKey: 'created',
}, {
  title: 'Action',
  sort: true,
  sortKey: 'action',
}, {
  title: 'Intent',
  sort: true,
  sortKey: 'intent',
}, {
  title: 'Utterance',
  sort: true,
  sortKey: 'utterance',
}];

function Treatments({ data }) {
  const { t } = useTranslation();
  const [sort, setSort] = useState({
    title: 'Created',
    sortKey: 'created',
    direction: ASC,
  });

  const filteredAndSorted = sorting.sortByOptions(data.map((_dat) => ({
    ..._dat,
    ..._dat.properties,
  })), sort);

  return (
    <TableContainer data-testid="Treatments-Container">
      <Table size="small">
        <TableHead>
          <TableRow>
            {COLUMNS.map((_col) => (
              <SortableColumn
                key={_col?.title}
                column={_col}
                sort={sort}
                setSort={setSort}
              />
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredAndSorted.map((row) => (
            <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell>{moment(row.created).format(t('moment.standard'))}</TableCell>
              <TableCell>{row.action}</TableCell>
              <TableCell>{row.intent}</TableCell>
              <TableCell>{row.utterance}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

Treatments.propTypes = {
  data: PropTypes.array,
};

Treatments.defaultProps = {
  data: [],
};

export default Treatments;
