import {
  ADMIN_BILLING_GET,
  ADMIN_BILLING_GET_SUCCESS,
  ADMIN_BILLING_GET_FAIL,
  ADMIN_BILLING_CLEAR,
} from './types';
import { showAlert } from '../../state/actions';

// eslint-disable-next-line
const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export const getBillingAttempt = () => ({ type: ADMIN_BILLING_GET });
export const getBillingSuccess = (data) => ({ type: ADMIN_BILLING_GET_SUCCESS, payload: { data } });
export const getBillingFail = () => ({ type: ADMIN_BILLING_GET_FAIL });

export const YEAR_DATA = {
  denomination: 'USD',
  total: {
    value: '15,000',
  },
  currentMonth: {
    value: '3,000',
  },
  values: [{
    period: 'Jan',
    value: 500,
    requests: 125,
    reviews: 100,
    treatments: 125,
    applies: 100,
  }, {
    period: 'Feb',
    value: 568,
    requests: 125,
    reviews: 125,
    treatments: 200,
    applies: 100,
  }, {
    period: 'Mar',
    value: 1790,
    requests: 900,
    reviews: 200,
    treatments: 300,
    applies: 400,
  }, {
    period: 'Apr',
    value: 1278,
    requests: 500,
    reviews: 200,
    treatments: 300,
    applies: 200,
  }, {
    period: 'May',
    value: 1366,
    requests: 700,
    reviews: 200,
    treatments: 300,
    applies: 200,
  }, {
    period: 'Jun',
    value: 450,
    requests: 100,
    reviews: 100,
    treatments: 100,
    applies: 150,
  }, {
    period: 'Jul',
    value: 1982,
    requests: 300,
    reviews: 900,
    treatments: 800,
    applies: 100,
  }, {
    period: 'Aug',
    value: 3000,
    requests: 1000,
    reviews: 900,
    treatments: 500,
    applies: 500,
  }, {
    period: 'Sep',
    value: 0,
    requests: 0,
    reviews: 0,
    treatments: 0,
    applies: 0,
  }, {
    period: 'Oct',
    value: 0,
    requests: 0,
    reviews: 0,
    treatments: 0,
    applies: 0,
  }, {
    period: 'Nov',
    value: 0,
    requests: 0,
    reviews: 0,
    treatments: 0,
    applies: 0,
  }, {
    period: 'Dec',
    value: 0,
    requests: 0,
    reviews: 0,
    treatments: 0,
    applies: 0,
  }],
};

const HALF_YEAR_DATA = {
  denomination: 'USD',
  total: {
    value: '15,000',
  },
  currentMonth: {
    value: '3,000',
  },
  values: [{
    period: 'Jan',
    value: 500,
    requests: 125,
    reviews: 100,
    treatments: 125,
    applies: 100,
  }, {
    period: 'Feb',
    value: 568,
    requests: 125,
    reviews: 125,
    treatments: 200,
    applies: 100,
  }, {
    period: 'Mar',
    value: 1790,
    requests: 900,
    reviews: 200,
    treatments: 300,
    applies: 400,
  }, {
    period: 'Apr',
    value: 1278,
    requests: 500,
    reviews: 200,
    treatments: 300,
    applies: 200,
  }, {
    period: 'May',
    value: 1366,
    requests: 700,
    reviews: 200,
    treatments: 300,
    applies: 200,
  }, {
    period: 'Jun',
    value: 450,
    requests: 100,
    reviews: 100,
    treatments: 100,
    applies: 150,
  }],
};

const QUARTER_YEAR_DATA = {
  denomination: 'USD',
  total: {
    value: '15,000',
  },
  currentMonth: {
    value: '3,000',
  },
  values: [{
    period: 'Jan',
    value: 500,
    requests: 125,
    reviews: 100,
    treatments: 125,
    applies: 100,
  }, {
    period: 'Feb',
    value: 568,
    requests: 125,
    reviews: 125,
    treatments: 200,
    applies: 100,
  }, {
    period: 'Mar',
    value: 1790,
    requests: 900,
    reviews: 200,
    treatments: 300,
    applies: 400,
  }],
};

const periodMap = {
  YEAR: YEAR_DATA,
  '3MONTHS': QUARTER_YEAR_DATA,
  '6MONTHS': HALF_YEAR_DATA,
};

export const getBilling = (period) => async (dispatch) => {
  const ERROR_MESSAGE = 'Failed to load data, please try again.';
  dispatch(getBillingAttempt());
  try {
    await delay(1000);
    dispatch(getBillingSuccess(periodMap[period]));
  } catch (_err) {
    dispatch(showAlert({ type: 'error', message: ERROR_MESSAGE }));
    dispatch(getBillingFail());
  }
};

export const clearBilling = () => ({ type: ADMIN_BILLING_CLEAR });
