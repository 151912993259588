import React from 'react';
import PropTypes from 'prop-types';

function Performance({ width, height }) {
  return (
    <svg width={width} height={height} viewBox="0 0 130 129" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="23.5303" y="81" width="15" height="19" fill="white" />
      <rect x="46.5303" y="72" width="15" height="28" fill="white" />
      <rect x="69.5303" y="64" width="15" height="36" fill="white" />
      <rect x="92.5303" y="55" width="15" height="45" fill="white" />
      <path d="M28.5303 60L60.0303 39.5L73.5303 54.5L98.5303 31" stroke="white" strokeWidth="6" strokeLinecap="round" />
      <path d="M86.5303 30L99.0303 30V42.5" stroke="white" strokeWidth="6" strokeLinecap="round" />
    </svg>
  );
}

Performance.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

Performance.defaultProps = {
  width: 130,
  height: 129,
};

export default Performance;
