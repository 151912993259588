import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

function Metric({ title, data }) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', mb: 2 }}>
      <Typography variant="h6" color="text.secondary">
        {title}
      </Typography>
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        {data.map((_data) => (
          <Card
            sx={{
              mt: 1,
              mr: 2,
              display: 'flex',
              flexDirection: 'column',
              width: '18%',
              minWidth: 140,
            }}
            key={_data.title}
          >
            <CardContent sx={{ display: 'flex', flex: 1 }}>
              <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                <Box sx={{ display: 'flex' }}>
                  <Typography variant="h5" color="primary.main">
                    {_data.data}
                  </Typography>
                  <ArrowDropUpIcon style={{ color: '#4BD8A4' }} />
                </Box>
                <Typography color="text.secondary">
                  {_data.title}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex' }}>
                <Tooltip title={_data.tooltip} placement="top">
                  <InfoOutlinedIcon style={{ color: '#8899AD' }} />
                </Tooltip>
              </Box>
            </CardContent>
          </Card>
        ))}
      </Box>
    </Box>
  );
}

Metric.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
};

Metric.defaultProps = {
  title: '',
  data: [],
};

export default Metric;
