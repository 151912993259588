import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import GalleryCard from '../../Components/GalleryCard';

import ViewPage from '../../Components/ViewPage';
import sections from './sections';

function Admin({
  toggleDrawer,
}) {
  const { t } = useTranslation();
  return (
    <ViewPage title={t('Views.adminTitle')} toggleDrawer={toggleDrawer}>
      <Box
        data-testid="Admin-View-Container"
        sx={{
          display: 'flex',
          flex: 1,
          flexWrap: 'wrap',
        }}
      >
        {sections(t).map((section) => (
          <GalleryCard
            key={section?.route}
            title={section?.title}
            route={section?.route}
            description={section?.description}
            icon={section?.icon}
          />
        ))}
      </Box>
    </ViewPage>
  );
}

Admin.propTypes = {
  toggleDrawer: PropTypes.func,
};

Admin.defaultProps = {
  toggleDrawer: () => {
    // Default
  },
};

export default Admin;
