import {
  ADMIN_QUEUES_LIST,
  ADMIN_QUEUES_LIST_SUCCESS,
  ADMIN_QUEUES_LIST_FAIL,
  ADMIN_QUEUE_EDIT,
  ADMIN_QUEUE_EDIT_SUCCESS,
  ADMIN_QUEUE_EDIT_FAIL,
  ADMIN_QUEUE_CREATE,
  ADMIN_QUEUE_CREATE_SUCCESS,
  ADMIN_QUEUE_CREATE_FAIL,
  ADMIN_QUEUE_DELETE,
  ADMIN_QUEUE_DELETE_SUCCESS,
  ADMIN_QUEUE_DELETE_FAIL,
  ADMIN_QUEUES_CLEAR,
} from './types';

export const initialState = {
  loading: false,
  updatingQueue: false,
  creatingQueue: false,
  deletingQueue: false,
  queues: [],
  sources: [],
};

const reducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case ADMIN_QUEUES_LIST: {
      return {
        ...state,
        queues: [],
        sources: [],
        loading: true,
      };
    }
    case ADMIN_QUEUES_LIST_SUCCESS: {
      const { queues, sources } = action.payload;
      return {
        ...state,
        loading: false,
        queues,
        sources,
      };
    }
    case ADMIN_QUEUES_LIST_FAIL: {
      return {
        ...state,
        queues: [],
        sources: [],
        loading: false,
      };
    }
    case ADMIN_QUEUE_EDIT: {
      return { ...state, updatingQueue: true };
    }
    case ADMIN_QUEUE_EDIT_SUCCESS: {
      const { queue } = action.payload;
      const currentQueues = [...state.queues];
      const currentQueueIndex = currentQueues.findIndex((_queue) => _queue.id === queue.id);
      if (currentQueueIndex !== -1) {
        currentQueues[currentQueueIndex] = queue;
      }
      return { ...state, updatingQueue: false, queues: currentQueues };
    }
    case ADMIN_QUEUE_EDIT_FAIL: {
      return { ...state, updatingQueue: false };
    }
    case ADMIN_QUEUE_CREATE: {
      return { ...state, creatingQueue: true };
    }
    case ADMIN_QUEUE_CREATE_SUCCESS: {
      const { queue } = action.payload;
      const currentQueues = [...state.queues];
      currentQueues.push(queue);
      return { ...state, creatingQueue: false, queues: currentQueues };
    }
    case ADMIN_QUEUE_CREATE_FAIL: {
      return { ...state, creatingQueue: false };
    }
    case ADMIN_QUEUE_DELETE: {
      return { ...state, deletingQueue: true };
    }
    case ADMIN_QUEUE_DELETE_SUCCESS: {
      const { queue } = action.payload;
      const currentQueues = state.queues.filter((_queue) => _queue.id !== queue.id);
      return { ...state, deletingQueue: false, queues: currentQueues };
    }
    case ADMIN_QUEUE_DELETE_FAIL: {
      return { ...state, deletingQueue: false };
    }
    case ADMIN_QUEUES_CLEAR: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default reducer;
