import { createTheme } from '@mui/material/styles';

// #488b9b
// #0492CF
// #252E42
// #303B52
// #2E3B52
export const DRAWER_OPEN_WIDTH = 220;
export const DRAWER_CLOSED_WIDTH = 60;

export const PRIMARY_MAIN = '#488b9b';

export const TEXT_SECONDARY = '#8899AD';
export const TEXT_TERTIARY = '#dd67b0';

const theme = createTheme({
  palette: {
    primary: {
      main: PRIMARY_MAIN,
    },
    text: {
      secondary: TEXT_SECONDARY,
      tertiary: TEXT_TERTIARY,
      white: 'white',
    },
  },
});

export default theme;
