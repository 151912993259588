import { connect } from 'react-redux';

import InferenceEvents from './InferenceEvents';
import { listEvents, clearEvents } from '../../../../store/inbox/treatment/inferenceEvents/actions';

const mapStateToProps = (state) => ({
  loading: state?.inbox?.treatment?.inferenceEvents?.index?.loading,
  events: state?.inbox?.treatment?.inferenceEvents?.index?.events,
});

const mapDispatchToProps = (dispatch) => ({
  listEvents: (queueId) => dispatch(listEvents(queueId)),
  clearEvents: () => dispatch(clearEvents()),
});

export default connect(mapStateToProps, mapDispatchToProps)(InferenceEvents);
