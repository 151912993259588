export const STATE_DRAWER_OPEN = 'STATE_DRAWER_OPEN';
export const STATE_DRAWER_CLOSE = 'STATE_DRAWER_CLOSE';
export const STATE_DRAWER_TOGGLE = 'STATE_DRAWER_TOGGLE';

export const STATE_ALERT_SHOW = 'STATE_ALERT_SHOW';
export const STATE_ALERT_HIDE = 'STATE_ALERT_HIDE';

export const STATE_SET_QUEUE = 'STATE_SET_QUEUE';

export const STATE_SET_LANG = 'STATE_SET_LANG';
