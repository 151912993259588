import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import ViewPage from '../../../Components/ViewPage';

import {
  ROUTE_ADMIN,
  ROUTE_ADMIN_CONFIGURATION,
} from '../../../Routes/routes';

const BREADCRUMBS = [{
  title: 'Admin',
  route: `/${ROUTE_ADMIN}`,
}, {
  title: 'Configuration',
  route: `/${ROUTE_ADMIN_CONFIGURATION}`,
}];

function Configuration({
  toggleDrawer,
}) {
  return (
    <ViewPage title="Configuration" toggleDrawer={toggleDrawer} breadcrumbs={BREADCRUMBS}>
      <Box data-testid="Configuration-View-Container">
        <h1>Configuration</h1>
      </Box>
    </ViewPage>
  );
}

Configuration.propTypes = {
  toggleDrawer: PropTypes.func,
};

Configuration.defaultProps = {
  toggleDrawer: () => {
    // Default
  },
};

export default Configuration;
