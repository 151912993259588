import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

import {
  ROUTE_COMPLIANCE,
} from '../../../../Routes/routes';

function Reject({
  onClose,
  reject,
  changeSetId,
}) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const onReject = async () => {
    setLoading(true);
    const res = await reject(changeSetId);
    setLoading(false);
    if (res) {
      onClose();
      navigate(`/${ROUTE_COMPLIANCE}`);
    }
  };
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open
      onClose={onClose}
    >
      <DialogTitle>
        Reject Change Set
      </DialogTitle>
      <DialogContent>
        <Typography color="text.secondary">
          Un-Stage all treatment events within the current Change Set
        </Typography>
      </DialogContent>
      <DialogActions>
        <Box sx={{ m: 2 }}>
          <Button
            onClick={onClose}
            disabled={loading}
            sx={{ mr: 2, width: 100 }}
          >
            Cancel
          </Button>
          <Button
            sx={{ width: 100 }}
            onClick={onReject}
            variant="contained"
            disabled={loading}
          >
            {loading ? 'Rejecting...' : 'Reject'}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

Reject.propTypes = {
  onClose: PropTypes.func,
  reject: PropTypes.func,
  changeSetId: PropTypes.string,
};

Reject.defaultProps = {
  onClose: () => {
    // Default
  },
  reject: () => {
    // Default
  },
  changeSetId: '',
};

export default Reject;
