import React from 'react';
import PropTypes from 'prop-types';

function Usage({ width, height }) {
  return (
    <svg width={width} height={height} viewBox="0 0 130 130" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M32.2656 67.5716C32.2656 88.7691 48.9667 100.893 65.6677 100.974C82.3687 101.054 99.0697 90.0538 99.0697 67.5716C99.0697 45.7318 88.7656 29.0312 65.6677 29.0312C47.0396 29.0312 39.2656 47.0312 39.2656 47.0312M64.383 52.1557V67.5716H79.7993" stroke="white" strokeWidth="6" strokeLinecap="round" />
      <path d="M37.9021 29.0313L39.3036 47.4761L55.888 45.7323" stroke="white" strokeWidth="6" strokeLinecap="round" />
    </svg>
  );
}

Usage.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

Usage.defaultProps = {
  width: 130,
  height: 130,
};

export default Usage;
