import { connect } from 'react-redux';

import Treatment from './Treatment';
import { addTreatment } from '../../../../../../store/inbox/dashboard/source/actions';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  addTreatment: (treatment) => dispatch(addTreatment(treatment)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Treatment);
