import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import HeaderDetails from '../../../../../Components/HeaderDetails';

const formatApiConnectors = (connectors) => connectors.map((_connector) => ({
  title: 'Name',
  data: _connector,
}));

const formatFlows = (flows) => flows.map((_flow) => ({
  title: 'Flow Id',
  data: _flow,
}));

const formatCMSItems = (cms) => cms.map((_cms) => ({
  title: 'Name',
  data: _cms,
}));

const formatBots = (bots) => bots.map((_bot) => ({
  title: 'Bot Name',
  data: _bot,
}));

function Dependencies({
  data,
}) {
  return (
    <Box
      data-testid="Dependencies"
      sx={{
        display: 'flex',
        mt: 2,
        flexDirection: 'column',
        flexWrap: 'wrap',
      }}
    >
      <HeaderDetails title="Api Connectors" data={formatApiConnectors(data?.apiConnectors || [])} />
      <HeaderDetails title="Flows" data={formatFlows(data?.flows || [])} mt={2} />
      <HeaderDetails title="CMS Items" data={formatCMSItems(data?.cmsItems || [])} mt={2} />
      <HeaderDetails title="Bots" data={formatBots(data?.bots || [])} mt={2} />
    </Box>
  );
}

Dependencies.propTypes = {
  data: PropTypes.object,
};

Dependencies.defaultProps = {
  data: {},
};

export default Dependencies;
