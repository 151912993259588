import {
  ROUTE_OPERATIONAL_INSIGHTS,
  ROUTE_OPERATIONAL_INSIGHTS_TREATMENT,
} from '../../../../Routes/routes';

const breadcrumbs = (queueId) => {
  const bc = [{
    title: 'Inbox',
    route: `/${ROUTE_OPERATIONAL_INSIGHTS}`,
  }, {
    title: 'Treatment',
    route: `/${ROUTE_OPERATIONAL_INSIGHTS_TREATMENT}`,
  }];
  if (queueId) {
    bc.push({
      title: 'Inference Events',
      route: `/${ROUTE_OPERATIONAL_INSIGHTS_TREATMENT}/inference-events/${queueId}`,
    });
  }
  return bc;
};

export default breadcrumbs;
