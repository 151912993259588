import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';

import ViewPage from '../../../Components/ViewPage';
import TopicDistribution from './TopicDistribution';
import TopicTrends from './TopicTrends';
import TopicKeyWords from './TopicKeyWords';

import {
  ROUTE_OPERATIONAL_INSIGHTS,
  ROUTE_OPERATIONAL_INSIGHTS_DISCUSSION,
} from '../../../Routes/routes';

const breadcrumbs = (t) => [{
  title: t('OperationalInsights.inboxTitle'),
  route: `/${ROUTE_OPERATIONAL_INSIGHTS}`,
}, {
  title: t('OperationalInsights.discusstionTitle'),
  route: `/${ROUTE_OPERATIONAL_INSIGHTS_DISCUSSION}`,
}];

const getWords = (selectedDataPoint, sliderValue, data) => {
  let words = [];
  // Specific data point
  if (selectedDataPoint && selectedDataPoint[0].length > 0) {
    selectedDataPoint[0].forEach((dat) => {
      words = [...words, ...data[dat].additional.topics];
    });
    return words;
  }
  const [min, max] = sliderValue;
  // Use range
  const inRange = data.filter((_dat) => (_dat.x >= min && _dat.x <= max));
  inRange.forEach((_dat) => {
    words = [...words, ..._dat.additional.topics];
  });
  return words;
};

// TODO - add extra time to slider to align correclty with graph
function Discussion({
  toggleDrawer,
  loading,
  fetchTopicDistributionData,
  defaultXMin,
  defaultXMax,
  data,
}) {
  const { t } = useTranslation();
  const [topicDistributionTimePeriod, setTopicDistributionTimePeriod] = useState('MONTH');
  const [selectedDataPoint, setSelectedDataPoint] = useState(null);
  const [sliderMin, setSliderMin] = useState(defaultXMin);
  const [sliderMax, setSliderMax] = useState(defaultXMax);
  const [sliderValue, setSliderValue] = useState([defaultXMin, defaultXMax]);
  const [selectedTopic, setSelectedTopic] = useState('');

  useEffect(() => {
    setSelectedTopic('');
    fetchTopicDistributionData(topicDistributionTimePeriod);
  }, [topicDistributionTimePeriod]);

  useEffect(() => {
    setSliderMin(defaultXMin);
    setSliderMax(defaultXMax);
    setSliderValue([defaultXMin, defaultXMax]);
  }, [defaultXMin, defaultXMax]);

  const updateTopicDistributionTimePeriod = (event) => {
    const { target } = event;
    const { value } = target;
    setTopicDistributionTimePeriod(value);
  };
  return (
    <ViewPage title={t('OperationalInsights.discusstionTitle')} toggleDrawer={toggleDrawer} breadcrumbs={breadcrumbs(t)} loading={loading}>
      <Box
        data-testid="Discussion-View-Container"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          mt: 2,
          width: '100%',
          mb: 2,
        }}
      >
        <Box sx={{ display: 'flex', width: '100%' }}>
          <TopicDistribution
            data={data}
            setSelectedDataPoint={setSelectedDataPoint}
            defaultMin={defaultXMin}
            sliderMin={sliderMin}
            setSliderMin={setSliderMin}
            defaultMax={defaultXMax}
            sliderMax={sliderMax}
            setSliderMax={setSliderMax}
            sliderValue={sliderValue}
            setSliderValue={setSliderValue}
            setSelectedTopic={setSelectedTopic}
            timePeriod={topicDistributionTimePeriod}
            updateTimePeriod={updateTopicDistributionTimePeriod}
          />
        </Box>
        <Box sx={{ display: 'flex', width: '100%', mt: 2 }}>
          <TopicKeyWords
            selectedTopic={selectedTopic}
            setSelectedTopic={setSelectedTopic}
            data={getWords(selectedDataPoint, sliderValue, data)}
          />
        </Box>
        <Box sx={{ display: 'flex', width: '100%', mt: 2 }}>
          <TopicTrends />
        </Box>
      </Box>
    </ViewPage>
  );
}

Discussion.propTypes = {
  toggleDrawer: PropTypes.func,
  loading: PropTypes.bool,
  fetchTopicDistributionData: PropTypes.func,
  defaultXMin: PropTypes.number,
  defaultXMax: PropTypes.number,
  data: PropTypes.array,
};

Discussion.defaultProps = {
  toggleDrawer: () => {
    // Default
  },
  loading: false,
  fetchTopicDistributionData: () => {
    // Default
  },
  defaultXMin: 0,
  defaultXMax: 0,
  data: [],
};

export default Discussion;
