import {
  ANALYTICS_REDACTION_GET,
  ANALYTICS_REDACTION_GET_SUCCESS,
  ANALYTICS_REDACTION_GET_FAIL,
  ANALYTICS_REDACTION_CLEAR,
} from './types';
import { showAlert } from '../../state/actions';
import userUtils from '../../../util/user';
import isUnAuth from '../../../util/unauth';
import interceptor, { getSauronUrl } from '../../../util/interceptor';
import { logoutUser } from '../../auth/actions';

export const getRedactionAttempt = () => ({
  type: ANALYTICS_REDACTION_GET,
});

export const getRedactionSuccess = (data) => ({
  type: ANALYTICS_REDACTION_GET_SUCCESS,
  payload: { data },
});

export const getRedactionFail = () => ({
  type: ANALYTICS_REDACTION_GET_FAIL,
});

export const getRedactionTotals = async (region, tenant, authToken, period) => {
  const res = await interceptor({
    method: 'post',
    url: `${getSauronUrl(region)}/${tenant}/ListRedactionActivityReports`,
    data: { period },
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
  });
  const { data } = res;
  return data;
};

export const getRedaction = (period = 'day') => async (dispatch, getState) => {
  const ERROR_MESSAGE = 'Failed to load data, please try again.';
  dispatch(getRedactionAttempt());
  try {
    const state = getState();
    const { authToken, authenticatedUser } = state.auth;
    const tenant = userUtils.getTenant(authenticatedUser);
    const region = userUtils.getRegion(authenticatedUser);
    const data = await getRedactionTotals(region, tenant, authToken, period);
    dispatch(getRedactionSuccess(data));
  } catch (_err) {
    const status = _err?.response?.status;
    if (isUnAuth(status)) {
      dispatch(logoutUser({ location: window.location.href }));
    } else {
      dispatch(showAlert({ type: 'error', message: ERROR_MESSAGE }));
      dispatch(getRedactionFail());
    }
  }
};

export const clearRedaction = () => ({ type: ANALYTICS_REDACTION_CLEAR });
