import Moment from 'moment';
import i18next from 'i18next';
import {
  BLUE_ONE,
} from '../../../../colors';

const chartOptions = ({
  data,
  min,
  max,
  setSliderMin,
  setSliderMax,
  resetSlider,
  setSliderValue,
  setSelectedDataPoint,
  setSelectedTopic,
  timePeriod,
}) => ({
  chart: {
    type: 'bar',
    height: 180,
    animations: {
      enabled: false,
    },
    events: {
      dataPointSelection(event, chartContext, config) {
        setSelectedTopic('');
        setSelectedDataPoint(() => [...config.selectedDataPoints]);
      },
      zoomed(chartContext, { xaxis }) {
        if (xaxis.min && xaxis.max) {
          const newMin = Math.floor(xaxis.min);
          const newMax = Math.floor(xaxis.max);
          setSliderMin(newMin);
          setSliderMax(newMax);
          setSliderValue([newMin, newMax]);
        } else {
          resetSlider();
        }
      },
      scrolled(chartContext, { xaxis }) {
        if (xaxis.min && xaxis.max) {
          const newMin = Math.floor(xaxis.min);
          const newMax = Math.floor(xaxis.max);
          setSliderMin(newMin);
          setSliderMax(newMax);
          setSliderValue([newMin, newMax]);
        } else {
          resetSlider();
        }
      },
    },
    toolbar: {
      tools: {
        download: false,
      },
    },
  },
  series: [{
    data,
  }],
  grid: {
    show: false,
  },
  xaxis: {
    min,
    max,
    tickPlacement: (timePeriod === 'MONTH') ? 'between' : 'on',
    labels: {
      show: true,
      formatter: (_val) => {
        if (timePeriod === 'MONTH' || timePeriod === 'WEEK') {
          return Moment.unix(_val).format(i18next.t('moment.monthDay'));
        }
        return Moment.unix(_val).format('h:mm a');
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    show: false,
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  colors: [BLUE_ONE],
  legend: {
    show: false,
  },
  plotOptions: {
    bar: {
      columnWidth: '100%',
    },
  },
  tooltip: {
    enabled: true,
    y: {
      title: {
        formatter: () => 'Topics: ',
      },
    },
  },
  dataLabels: {
    enabled: false,
  },
  states: {
    active: {
      allowMultipleDataPointsSelection: true,
    },
  },
});

export default chartOptions;
