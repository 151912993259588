import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Divider from '@mui/material/Divider';

import PersonIcon from '@mui/icons-material/Person';

import AppBarBreadCrumbs from './BreadCrumbs';
import { DRAWER_OPEN_WIDTH, DRAWER_CLOSED_WIDTH } from '../../theme';
import regionMap from './regionMap';

function ApplicationBar({
  title,
  toggleDrawer,
  authenticatedUser,
  breadcrumbs,
  fullWidth,
  visible,
  drawerOpen,
  region,
  org,
  logout,
  lang,
  setLang,
}) {
  const { i18n } = useTranslation();
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  if (!visible) {
    return null;
  }

  const changeLanguage = (_val) => {
    setLang(_val);
    i18n.changeLanguage(_val);
    moment.locale(_val);
  };

  const DRAWER_WIDTH = drawerOpen ? DRAWER_OPEN_WIDTH : DRAWER_CLOSED_WIDTH;
  return (
    <AppBar
      data-testid="AppBar-Container"
      position="fixed"
      sx={{
        height: 66,
        left: fullWidth ? '16px' : `${DRAWER_WIDTH}px`,
        width: fullWidth ? '100%' : `calc(100% - ${DRAWER_WIDTH}px)`,
        backgroundColor: 'primary.main',
        '& .MuiToolbar-root': {
          backgroundColor: 'primary.main',
        },
      }}
    >
      <Toolbar>
        {toggleDrawer && (
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => toggleDrawer(true)}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
        )}
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h6" component="div">
            {title}
          </Typography>
          <AppBarBreadCrumbs breadcrumbs={breadcrumbs} />
        </Box>
        {region && org && (
          <Box sx={{ display: 'flex', mr: 4 }}>
            <Box sx={{ mr: 1, borderRight: '1px solid white' }}>
              <Typography sx={{ mr: 1 }}>
                {regionMap[region]}
              </Typography>
            </Box>
            <Typography>
              {org}
            </Typography>
          </Box>
        )}
        {authenticatedUser && (
          <Box sx={{ flexGrow: 0 }} data-testid="AppBar-Profile-Icon">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <PersonIcon alt="Some User" src="/static/images/avatar/2.jpg" style={{ color: 'white' }} />
            </IconButton>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <Typography textAlign="center" color="primary" sx={{ ml: 2, mr: 2, mb: 1 }}>
                {authenticatedUser.email}
              </Typography>
              <Divider />
              <MenuItem onClick={() => logout()}>
                <Typography textAlign="center" color="primary" sx={{ mr: 2 }}>
                  Logout
                </Typography>
              </MenuItem>
            </Menu>
          </Box>
        )}
        <Box sx={{ ml: 2, minWidth: 50 }}>
          <FormControl fullWidth variant="standard" style={{ color: 'white' }}>
            <Select
              style={{ color: 'white' }}
              labelId="language-select-label"
              id="language-select-label"
              value={lang}
              onChange={(e) => changeLanguage(e.target.value)}
              sx={{
                ':before': { borderBottomColor: 'white' },
                ':after': { borderBottomColor: 'white' },
              }}
            >
              <MenuItem value="en">en</MenuItem>
              <MenuItem value="en-US">en-US</MenuItem>
              <MenuItem value="fr">fr</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

ApplicationBar.propTypes = {
  title: PropTypes.string,
  toggleDrawer: PropTypes.any,
  authenticatedUser: PropTypes.any,
  breadcrumbs: PropTypes.array,
  fullWidth: PropTypes.bool,
  visible: PropTypes.bool,
  drawerOpen: PropTypes.bool,
  region: PropTypes.string,
  org: PropTypes.string,
  logout: PropTypes.func,
  lang: PropTypes.string,
  setLang: PropTypes.func,
};

ApplicationBar.defaultProps = {
  title: '',
  toggleDrawer: null,
  authenticatedUser: null,
  breadcrumbs: [],
  fullWidth: false,
  visible: true,
  drawerOpen: true,
  region: '',
  org: '',
  logout: () => {
    // Default
  },
  lang: '',
  setLang: () => {
    // Default
  },
};

export default ApplicationBar;
