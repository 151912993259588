import {
  DESIGN_REGISTRY_GET_BOT_DESIGN,
  DESIGN_REGISTRY_GET_BOT_DESIGN_SUCCESS,
  DESIGN_REGISTRY_GET_BOT_DESIGN_FAIL,
  DESIGN_REGISTRY_GET_BOT_DESIGN_CLEAR,
} from './types';

const initialState = {
  loading: false,
  design: {},
};

const reducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case DESIGN_REGISTRY_GET_BOT_DESIGN: {
      return { ...state, loading: true, design: {} };
    }
    case DESIGN_REGISTRY_GET_BOT_DESIGN_SUCCESS: {
      const { design } = action.payload;
      return { ...state, loading: false, design };
    }
    case DESIGN_REGISTRY_GET_BOT_DESIGN_FAIL: {
      return { ...state, loading: false, design: {} };
    }
    case DESIGN_REGISTRY_GET_BOT_DESIGN_CLEAR: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default reducer;
