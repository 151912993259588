import { connect } from 'react-redux';

import Discussion from './Discussion';
import { fetchTopicDistributionData } from '../../../store/inbox/discussion/actions';

const mapStateToProps = (state) => ({
  defaultXMin: state?.inbox?.discussion?.defaultXMin,
  defaultXMax: state?.inbox?.discussion?.defaultXMax,
  loading: state?.inbox?.discussion?.loading,
  data: state?.inbox?.discussion?.data,
});

const mapDispatchToProps = (dispatch) => ({
  fetchTopicDistributionData: (period) => dispatch(fetchTopicDistributionData(period)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Discussion);
