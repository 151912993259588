import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import ViewPage from '../../../Components/ViewPage';

import {
  ROUTE_OPERATIONAL_INSIGHTS,
  ROUTE_OPERATIONAL_INSIGHTS_HISTORICAL,
} from '../../../Routes/routes';

const BREADCRUMBS = [{
  title: 'Inbox',
  route: `/${ROUTE_OPERATIONAL_INSIGHTS}`,
}, {
  title: 'Historical',
  route: `/${ROUTE_OPERATIONAL_INSIGHTS_HISTORICAL}`,
}];

function Historical({
  toggleDrawer,
}) {
  return (
    <ViewPage title="Historical" toggleDrawer={toggleDrawer} breadcrumbs={BREADCRUMBS}>
      <Box data-testid="Historical-View-Container">
        <h1>Historical</h1>
      </Box>
    </ViewPage>
  );
}

Historical.propTypes = {
  toggleDrawer: PropTypes.func,
};

Historical.defaultProps = {
  toggleDrawer: () => {
    // Default
  },
};

export default Historical;
