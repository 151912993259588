import { connect } from 'react-redux';

import Report from './Report';
import { getBotReport, clearBotReport } from '../../../../store/designInsights/designRegistry/report/actions';

const mapStateToProps = (state) => ({
  loading: state?.designInsights?.designRegistry?.report?.loading,
  data: state?.designInsights?.designRegistry?.report?.data,
});

const mapDispatchToProps = (dispatch) => ({
  getBotReport: (botName) => dispatch(getBotReport(botName)),
  clearBotReport: () => dispatch(clearBotReport()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Report);
