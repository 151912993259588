import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';

const formatSummary = (summary) => {
  const summaryDetails = [];
  if (summary?.tab) {
    summaryDetails.push({
      title: 'Tab:',
      value: summary?.tab,
    });
  }
  if (summary?.actionStart) {
    summaryDetails.push({
      title: 'Action Start:',
      value: summary?.actionStart,
    });
  }
  if (summary?.actionComplete) {
    summaryDetails.push({
      title: 'Action Complete:',
      value: summary?.actionComplete,
    });
  }
  if (summary?.content) {
    summaryDetails.push({
      title: 'Content:',
      value: summary?.content,
    });
  }
  if (summary?.input) {
    summaryDetails.push({
      title: 'Input:',
      value: summary?.input,
    });
  }
  if (summary?.baas) {
    summaryDetails.push({
      title: 'BAAS:',
      value: summary?.baas,
    });
  }
  if (summary?.setContext) {
    summaryDetails.push({
      title: 'Set Context:',
      value: summary?.setContext,
    });
  }
  if (summary?.change) {
    summaryDetails.push({
      title: 'Change:',
      value: summary?.change,
    });
  }
  if (summary?.markup) {
    summaryDetails.push({
      title: 'Markup:',
      value: summary?.markup,
    });
  }
  if (summary?.markupInteraction) {
    summaryDetails.push({
      title: 'Markup Interaction:',
      value: summary?.markupInteraction,
    });
  }
  if (summary?.start) {
    summaryDetails.push({
      title: 'Start:',
      value: summary?.start,
    });
  }
  if (summary?.pushBotToStack) {
    summaryDetails.push({
      title: 'Push Bot to Stack:',
      value: summary?.pushBotToStack,
    });
  }
  if (summary?.transfer) {
    summaryDetails.push({
      title: 'Transfer:',
      value: summary?.transfer,
    });
  }
  return summaryDetails;
};

function FlowStats({
  data,
}) {
  return (
    <Box
      data-testid="FlowStats-Container"
      sx={{ display: 'flex', flexWrap: 'wrap' }}
    >
      <Card sx={{ mr: 2, mb: 2, minWidth: 400 }}>
        <CardHeader
          title="Details"
          titleTypographyProps={{ color: 'primary.main' }}
          subheader="Node count breakdown per flow"
        />
        <CardContent>
          {(data?.details || []).map((_detail) => (
            <Box key={_detail.flowId}>
              <Box sx={{ display: 'flex' }}>
                <Typography gutterBottom variant="body2" sx={{ mr: 1 }} color="text.secondary">
                  FlowId:
                </Typography>
                <Typography variant="body2">
                  {_detail?.flowId}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', mb: 1 }}>
                <Typography gutterBottom variant="body2" sx={{ mr: 1 }} color="text.secondary">
                  Node Count:
                </Typography>
                <Typography variant="body2">
                  {_detail?.nodeCount}
                </Typography>
              </Box>
            </Box>
          ))}
        </CardContent>
      </Card>
      <Card sx={{ mr: 2, mb: 2, minWidth: 400 }}>
        <CardHeader
          title="Summary"
          titleTypographyProps={{ color: 'primary.main' }}
          subheader={`${data?.summaryTotal || 0} nodes across ${data?.details?.length || 0} flows`}
        />
        <CardContent>
          {formatSummary(data?.summary || []).map((_summary) => (
            <Box sx={{ display: 'flex', mb: 1 }} key={_summary.title}>
              <Typography gutterBottom variant="body2" sx={{ mr: 1 }} color="text.secondary">
                {_summary.title}
              </Typography>
              <Typography variant="body2">
                {_summary?.value}
              </Typography>
            </Box>
          ))}
        </CardContent>
      </Card>
    </Box>
  );
}

FlowStats.propTypes = {
  data: PropTypes.object,
};

FlowStats.defaultProps = {
  data: {},
};

export default FlowStats;
