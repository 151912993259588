import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';

function HeaderDetails({
  title,
  data,
  mt,
  elevation,
}) {
  return (
    <Box sx={{ mt }}>
      <Typography variant="h6" color="text.secondary">
        {title}
      </Typography>
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        {data.map((_data) => (
          <Card sx={{ mt: 2, mr: 2 }} key={_data.title} elevation={elevation}>
            <CardHeader
              title={_data.data}
              titleTypographyProps={{ color: 'primary.main' }}
              subheader={_data.title}
            />
          </Card>
        ))}
      </Box>
    </Box>
  );
}

HeaderDetails.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  data: PropTypes.array,
  mt: PropTypes.number,
  elevation: PropTypes.number,
};

HeaderDetails.defaultProps = {
  title: '',
  data: [],
  mt: 0,
  elevation: 1,
};

export default HeaderDetails;
