import {
  COMPLIANCE_IMPACT_ANALYSIS_GET_SUMMARY,
  COMPLIANCE_IMPACT_ANALYSIS_GET_SUMMARY_SUCCESS,
  COMPLIANCE_IMPACT_ANALYSIS_GET_SUMMARY_FAIL,
  COMPLIANCE_IMPACT_ANALYSIS_GET_SUMMARY_CLEAR,
} from './types';

export const initialState = {
  loading: false,
  data: {},
};

const reducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case COMPLIANCE_IMPACT_ANALYSIS_GET_SUMMARY: {
      return {
        ...state,
        loading: true,
        data: {},
      };
    }
    case COMPLIANCE_IMPACT_ANALYSIS_GET_SUMMARY_SUCCESS: {
      const { data } = action.payload;
      return {
        loading: false,
        data,
      };
    }
    case COMPLIANCE_IMPACT_ANALYSIS_GET_SUMMARY_FAIL: {
      return {
        ...state,
        loading: false,
        data: {},
      };
    }
    case COMPLIANCE_IMPACT_ANALYSIS_GET_SUMMARY_CLEAR: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default reducer;
