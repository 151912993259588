import React from 'react';

import PropTypes from 'prop-types';

function Insights({ width, height }) {
  return (
    <svg width={width} height={height} viewBox="0 0 120 112" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M41.6548 96.0887H75.3826" stroke="white" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M45.7017 102.834H70.6602" stroke="white" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M49.749 108.905H67.2875" stroke="white" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M53.7969 83.2722V48.8698C53.4595 37.4024 40.6431 39.0887 40.9802 48.5325C41.2592 56.3507 49.075 54.9408 57.5071 54.9408C65.9393 54.9408 74.371 56.9645 74.371 48.5325C74.371 38.4142 61.217 37.7396 61.217 48.8698V83.2722" stroke="white" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M70.6979 83.9468H44.6428C42.4336 83.9468 40.6514 82.1537 40.8121 79.9504C41.405 71.8206 42.8302 64.5637 32.5482 57.6391C16.0215 46.5089 27.4889 7.38464 58.1813 7.38464C93.2581 7.38464 96.9683 43.4734 82.8027 57.6391C73.526 66.9157 73.7866 73.2091 74.5565 79.6273C74.8352 81.9508 73.0381 83.9468 70.6979 83.9468Z" stroke="white" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M18.0451 17.1657L9.27588 3" stroke="white" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16.696 36.7278H2.53027" stroke="white" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M18.7199 57.639L9.95068 72.4793" stroke="white" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M99.6661 17.1657L109.11 3" stroke="white" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M101.015 36.7278H116.53" stroke="white" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M98.3172 57.639L108.436 72.4793" stroke="white" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M43.0039 90.0178H74.0335" stroke="white" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  );
}

Insights.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

Insights.defaultProps = {
  width: 120,
  height: 112,
};

export default Insights;
