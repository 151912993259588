import {
  ADMIN_TREATMENT_GET,
  ADMIN_TREATMENT_GET_SUCCESS,
  ADMIN_TREATMENT_GET_FAIL,
  ADMIN_TREATMENT_CLEAR,
} from './types';
import { showAlert } from '../../state/actions';

// eslint-disable-next-line
const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export const getTreatmentAttempt = () => ({
  type: ADMIN_TREATMENT_GET,
});

export const getTreatmentSuccess = (data) => ({
  type: ADMIN_TREATMENT_GET_SUCCESS,
  payload: { data },
});

export const getTreatmentFail = () => ({
  type: ADMIN_TREATMENT_GET_FAIL,
});

export const SAMPLE_DATA = {
  values: {
    inferenceEvents: 2000,
    missedInputs: 1500,
    treatment: 500,
  },
  orgValues: [
    {
      id: 1,
      name: 'tools',
      inferenceEvents: 50,
      missedInputs: 40,
      treatment: 40,
      sources: [
        {
          name: 'Source One',
          inferenceEvents: 200,
          missedInputs: 50,
          treatment: 20,
        },
        {
          name: 'Source Two',
          inferenceEvents: 300,
          missedInputs: 60,
          treatment: 30,
        },
        {
          name: 'Source Three',
          inferenceEvents: 400,
          missedInputs: 70,
          treatment: 40,
        },
      ],
    },
    {
      id: 1,
      name: 'mikesorg',
      inferenceEvents: 150,
      missedInputs: 140,
      treatment: 140,
      sources: [
        {
          name: 'Source Four',
          inferenceEvents: 400,
          missedInputs: 300,
          treatment: 100,
        },
        {
          name: 'Source Five',
          inferenceEvents: 600,
          missedInputs: 550,
          treatment: 300,
        },
        {
          name: 'Source Six',
          inferenceEvents: 1200,
          missedInputs: 900,
          treatment: 150,
        },
      ],
    },
  ],
};

export const getTreatment = () => async (dispatch) => {
  const ERROR_MESSAGE = 'Failed to load data, please try again.';
  dispatch(getTreatmentAttempt());
  try {
    await delay(1000);
    dispatch(getTreatmentSuccess(SAMPLE_DATA));
  } catch (_err) {
    dispatch(showAlert({ type: 'error', message: ERROR_MESSAGE }));
    dispatch(getTreatmentFail());
  }
};

export const clearTreatment = () => ({ type: ADMIN_TREATMENT_CLEAR });
