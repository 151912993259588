import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';

import ViewPage from '../../../../../Components/ViewPage';

import {
  ROUTE_OPERATIONAL_INSIGHTS,
  ROUTE_OPERATIONAL_INSIGHTS_TREATMENT,
} from '../../../../../Routes/routes';

const breadcrumbs = (eventId) => {
  const bc = [{
    title: 'Inbox',
    route: `/${ROUTE_OPERATIONAL_INSIGHTS}`,
  }, {
    title: 'Treatment',
    route: `/${ROUTE_OPERATIONAL_INSIGHTS_TREATMENT}`,
  }];
  if (eventId) {
    bc.push({
      title: 'Event',
      route: `/${ROUTE_OPERATIONAL_INSIGHTS_TREATMENT}/inference-events/event/${eventId}`,
    });
    bc.push({
      title: eventId,
      route: `/${ROUTE_OPERATIONAL_INSIGHTS_TREATMENT}/inference-events/event/${eventId}`,
    });
  }
  return bc;
};

function Event({
  loading,
  toggleDrawer,
  getEvent,
  clearEvent,
}) {
  const params = useParams();

  useEffect(() => {
    getEvent(params.eventId);
    return () => {
      clearEvent();
    };
  }, []);
  return (
    <ViewPage
      title="Inference Event"
      toggleDrawer={toggleDrawer}
      breadcrumbs={breadcrumbs(params?.eventId)}
      loading={loading}
    >
      <Box
        data-testid="InferenceEvent-View-Container"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          mt: 2,
          width: '100%',
          mb: 2,
        }}
      >
        <Card sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
          <CardHeader
            title="Inference Event"
            titleTypographyProps={{ color: 'primary.main' }}
            subheader="Inference Events Details"
          />
          <CardContent sx={{ display: 'flex', flex: 1, mr: 1 }}>
            Event Info
          </CardContent>
        </Card>
      </Box>
    </ViewPage>
  );
}

Event.propTypes = {
  toggleDrawer: PropTypes.func,
  loading: PropTypes.bool,
  getEvent: PropTypes.func,
  clearEvent: PropTypes.func,
};

Event.defaultProps = {
  toggleDrawer: () => {
    // Default
  },
  loading: false,
  getEvent: () => {
    // Default
  },
  clearEvent: () => {
    // Default
  },
};

export default Event;
