import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import ViewPage from '../../../Components/ViewPage';
import HeaderDetails from '../../../Components/HeaderDetails';
import SourceDetails from './SourceDetails';
import Empty from './Empty';
import SelectedSource from './SelectedSource';
import breadcrumbs from './breadcrumbs';
import { formatTotals, getSubtitle, formatSources } from './util';

function Redaction({
  toggleDrawer,
  loading,
  getRedaction,
  clearRedaction,
  data,
}) {
  const [timePeriod, setTimePeriod] = useState('day');
  const [selectedSource, setSelectedSource] = useState(null);
  const { t } = useTranslation();
  useEffect(() => {
    getRedaction(timePeriod);
    return () => {
      clearRedaction();
    };
  }, [timePeriod]);
  return (
    <ViewPage
      title={t('Analytics.redactionTitle')}
      toggleDrawer={toggleDrawer}
      loading={loading}
      breadcrumbs={breadcrumbs(t)}
    >
      <Box
        data-testid="Redaction-View-Container"
        sx={{
          mt: 2,
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
        }}
      >
        <Box sx={{ display: 'flex' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              mt: 1,
            }}
          >
            {formatTotals(data).length > 1
              ? <HeaderDetails title={getSubtitle(timePeriod)} data={formatTotals(data)} />
              : <Empty />}
            <SourceDetails
              data={formatSources(data)}
              selectedSource={selectedSource}
              setSelectedSource={setSelectedSource}
            />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            <FormControl size="small" sx={{ width: 220 }}>
              <InputLabel id="time-period">Period</InputLabel>
              <Select
                labelId="time-period"
                id="time-period"
                label="Time Period"
                value={timePeriod}
                onChange={(e) => {
                  setSelectedSource(null);
                  setTimePeriod(e.target.value);
                }}
              >
                <MenuItem value="day">Day</MenuItem>
                <MenuItem value="week">Week</MenuItem>
                <MenuItem value="month">Month</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
        {selectedSource && <SelectedSource data={data} selectedSource={selectedSource} />}
      </Box>
    </ViewPage>
  );
}

Redaction.propTypes = {
  toggleDrawer: PropTypes.func,
  loading: PropTypes.bool,
  getRedaction: PropTypes.func,
  clearRedaction: PropTypes.func,
  data: PropTypes.object,
};

Redaction.defaultProps = {
  toggleDrawer: () => {
    // Default
  },
  loading: false,
  getRedaction: () => {
    // Default
  },
  clearRedaction: () => {
    // Default
  },
  data: {},
};

export default Redaction;
