import React from 'react';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

function Loader() {
  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        height: 'calc(100vh - 80px)',
      }}
      data-testid="ViewPage-Loader-Container"
    >
      <CircularProgress />
    </Box>
  );
}

export default Loader;
